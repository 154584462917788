/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, InputLabel, OutlinedInput, Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WriteIcon from '../../assets/static_images/writeicon.svg';
import avatar1 from '../../assets/images/users/avatar-1.png';
import EmojiIcon from '../../assets/static_images/emojiicon.svg';
import PhotoIcon from '../../assets/static_images/photoicon.svg';
import PhotoIconActive from '../../assets/static_images/photoisonactive.svg';
import VideoIcon from '../../assets/static_images/videoicon.svg';
import VideoIconActive from '../../assets/static_images/videoiconactive.svg';
import AudioIcon from '../../assets/static_images/audioicon.svg';
import AudioIconActive from '../../assets/static_images/audioiconactive.svg';
import FileIcon from '../../assets/static_images/fileicon.svg';
import FileIconActive from '../../assets/static_images/fileiconactive.svg';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import Badge from '@mui/material/Badge';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import parse from 'html-react-parser';
import Picture from '../../assets/static_images/picture.svg';
import axios from 'lib/axios';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';
const NewsModal = ({ setOpenEditor, news, content, user, setNewsList, setOpenOption, showSnack }) => {
    const imageInput = useRef(null);
    const textInput = useRef(null);
    const pdfInput = useRef(null);
    const audioInput = useRef(null);
    const [openImageUploader, setOpenImageUploader] = useState(news?.image.length > 0 || news?.video.length > 0 ? true : false);
    const [openPdfUploader, setOpenPdfUploader] = useState(news?.files?.length > 0 ? true : false);
    const [openAudioUploader, setOpenAudioUploader] = useState(news?.audio.length > 0 ? true : false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [text, setText] = useState(content);
    const [initial, setInitial] = useState(true);
    const [boxHeight, setBoxHeight] = useState('4rem');
    const theme = useTheme();
    const [postImage, setPostImage] = useState(null);
    const [postImages, setPostImages] = useState([]);
    const [existingpostImages, setExistingPostImages] = useState(news?.image.length > 0 ? news?.image : []);
    const [existingpostAudios, setExistingpostAudios] = useState(news?.audio.length > 0 ? news?.audio : []);
    const [existingpostPdfs, setExistingpostPdfs] = useState(news?.files ?? []);
    const [existingpostVideos, setExistingpostVideos] = useState(news?.video.length > 0 ? news?.video : []);
    const [postAudios, setPostAudios] = useState([]);
    const [postaudiosData, setPostaudiosData] = useState([]);
    const [postMediaData, setPostMediaData] = useState([]);
    const [postPdfs, setPostPdfs] = useState([]);
    const [postPdfNames, setPostPdfNames] = useState([]);
    const [postPdfsData, setPostPdfsData] = useState([]);
    const [postAudioFile, setPostAudioFile] = useState([]);
    const [postMediaFile, setPostMediaFile] = useState([]);
    const [submitUrl, setSubmitUrl] = useState(news ? '/news/update' : '/news');
    const [title, setTitle] = useState(news ? 'Edit' : 'Create New');
    const [processing, setProcessing] = useState(false);
    const [validationMessage, setValidationMessage] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        if (text && text != '') {
            setValidationMessage(() => ({}));
        }
    }, [text]);
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onprimary = (primary) => reject(primary);
        });
    async function convertMedia() {
        Array.from(imageInput.current.files).forEach(async (f) => {
            if (f.size < 52453290) {
                const file = await toBase64(f);
                const url = URL.createObjectURL(f);
                setPostMediaData((postMediaData) => [...postMediaData, file]);
                setPostMediaFile((postMediaFile) => [...postMediaFile, f]);
                setPostImages((postImages) => [...postImages, url]);
            } else {
                alert('Size Must Be Smaller Than 5MB');
            }
        });
    }
    async function convertAudio() {
        if (audioInput.current.files[0].size < 52453290) {
            const file = await toBase64(audioInput.current.files[0]);
            const url = URL.createObjectURL(audioInput.current.files[0]);
            setPostAudioFile([...postAudioFile, f]);
            setPostaudiosData([...postaudiosData, file]);
            setPostAudios([...postAudios, url]);
        } else {
            alert('Size Must Be Smaller Than 50MB');
        }
    }
    async function convertPdf() {
        if (pdfInput.current.files[0].size < 52453290) {
            const file = await toBase64(pdfInput.current.files[0]);
            const url = URL.createObjectURL(pdfInput.current.files[0]);
            setPostPdfsData([...postPdfsData, file]);
            setPostPdfs([...postPdfs, url]);
            setPostPdfNames([...postPdfNames, pdfInput.current.files[0].name]);
        } else {
            alert('Size Must Be Smaller Than 50MB');
        }
    }
    const submitRequest = async () => {
        const formData = new FormData();
        if (news) {
            formData.append('news_id', news.news_id);
        }
        if (text) {
            formData.append('content', text === undefined ? '' : text);
        } else {
            setValidationMessage((prev) => ({
                content: 'Must Need To Add Content'
            }));
            return false;
        }
        // media
        formData.append('image_exist', existingpostImages.length > 0 ? true : false);
        formData.append('new_image_exist', postMediaData.length > 0 ? true : false);
        formData.append('old_images', JSON.stringify(existingpostImages));
        // media
        formData.append('video_exist', existingpostVideos.length > 0 ? true : false);
        formData.append('old_videos', JSON.stringify(existingpostVideos));
        // audio
        formData.append('audio_exist', existingpostAudios.length > 0 ? true : false);
        formData.append('new_audio_exist', postaudiosData.length > 0 ? true : false);
        formData.append('old_audios', JSON.stringify(existingpostAudios));
        // pdfs
        formData.append('pdf_exist', existingpostPdfs.length > 0 ? true : false);
        formData.append('new_pdf_exist', postPdfsData.length > 0 ? true : false);
        formData.append('old_pdfs', JSON.stringify(existingpostPdfs));
        formData.append('news_pdf_names', JSON.stringify(postPdfNames));
        postAudioFile.forEach((audio, index) => {
            formData.append(`audio-${index}`, audio);
        });
        postMediaFile.forEach((media, index) => {
            formData.append(`media-${index}`, media);
        });
        postPdfsData.forEach((pdf, index) => {
            formData.append(`docs-${index}`, pdf);
        });
        setProcessing(true);
        dispatch(activeLoader(true));
        await axios
            .post(`${submitUrl}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                if (data.success) {
                    if (showSnack) {
                        showSnack(false, `${data.message}`);
                    }
                    dispatch(activeLoader(false));
                    if (news) {
                        setNewsList((item) => {
                            return item.map((itm) => {
                                if (itm.news_id == news.news_id) {
                                    itm = data.news;
                                }
                                return itm;
                            });
                        });
                    } else {
                        setNewsList((news) => [data.news, ...news]);
                    }
                    setOpenEditor(false);
                    setOpenOption(false);
                }
            })
            .catch((err) => {
                dispatch(activeLoader(false));
                console.log(err);
            });
    };
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item>
                <Card
                    sx={{
                        padding: '.5rem',
                        borderRadius: '10px',
                        width: { md: '50vw' },
                        marginTop: '6rem',
                        maxHeight: '80vh',
                        marginX: '20px'
                    }}
                >
                    <CardContent>
                        <Box container display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <img src={WriteIcon} alt="edit" />
                                </Box>
                                <Box>
                                    <Typography variant="h6" color="primary">
                                        {title} News
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                <Badge
                                    color="primary"
                                    badgeContent={'X'}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenEditor(false);
                                    }}
                                    sx={{ marginLeft: 'auto' }}
                                ></Badge>
                            </Box>
                        </Box>
                        {/* text editor  */}
                        {/* <CreateVoice text={text} setText={setText} initial={initial} setInitial={setInitial} /> */}
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item sm={12}>
                                <textarea
                                    ref={textInput}
                                    placeholder="Write your News ..."
                                    onChange={(e) => {
                                        setText(e.target.value);
                                    }}
                                    rows="3"
                                    cols="100"
                                    fullWidth
                                    style={{
                                        borderRadius: '5px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.08);',
                                        padding: '1rem',
                                        opacity: '.9',
                                        width: '100%',
                                        fontSize: '1rem'
                                    }}
                                    type="text"
                                    value={text}
                                ></textarea>
                                {validationMessage && validationMessage.content && (
                                    <Typography color="error" sx={{ fontSize: '12px' }}>
                                        {validationMessage.content}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item sx={{ position: 'absolute', bottom: '1rem', right: '1rem' }}>
                                <Grid container>
                                    <Grid
                                        item
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setShowEmojis(!showEmojis);
                                        }}
                                    >
                                        <img src={EmojiIcon} style={{ marginRight: '1rem' }} alt="EmojiIcon" />
                                    </Grid>
                                    {showEmojis && (
                                        <div style={{ marginBottom: '-14rem', zIndex: '1023' }}>
                                            <Picker
                                                data={data}
                                                onEmojiSelect={(e) => {
                                                    setText(`${text} ${e.native}`);
                                                    setShowEmojis(!showEmojis);
                                                }}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* options bar  */}
                        <Grid container display="flex" justifyContent="between" alignItems="cetner" sx={{ marginY: '1rem' }}>
                            <Grid item sm={12} sx={{ paddingX: '1rem' }}>
                                <Grid container spacing={3} display="flex" alignItems="center" sm={12}>
                                    <Grid item sm={4}>
                                        {openImageUploader ? (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                    backgroundColor: `${theme.palette.primary.lighter}`,
                                                    borderRadius: '10px',
                                                    p: '5px'
                                                }}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setOpenImageUploader(!openImageUploader);
                                                    setOpenAudioUploader(false);
                                                    setOpenPdfUploader(false);
                                                    setPostAudios([]);
                                                    setOpenPdfUploader(false);
                                                    setPostPdfs([]);
                                                    setPostPdfsData([]);
                                                    setPostaudiosData([]);
                                                    setTimeout(() => {
                                                        imageInput.current.click();
                                                    }, 0.5);
                                                }}
                                            >
                                                <Grid item sm={1} display="flex" justifyContent="center" alignItems="center">
                                                    <img src={PhotoIconActive} style={{ marginRight: '1rem' }} alt="PicIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                        Add photos/videos
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                sx={{ rderRadius: '10px' }}
                                                onClick={() => {
                                                    setOpenImageUploader(!openImageUploader);
                                                    setOpenAudioUploader(false);
                                                    setOpenPdfUploader(false);
                                                    setPostAudios([]);
                                                    setOpenPdfUploader(false);
                                                    setPostPdfs([]);
                                                    setPostPdfsData([]);
                                                    setPostaudiosData([]);
                                                    setTimeout(() => {
                                                        imageInput.current.click();
                                                    }, 0.5);
                                                }}
                                            >
                                                <Grid item sm={1}>
                                                    <img src={PhotoIcon} style={{ marginRight: '1rem' }} alt="PicIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Add photos/videos</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item sm={4} display="flex" alignItems="center" justifyContent="center">
                                        {openAudioUploader ? (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    backgroundColor: `${theme.palette.primary.lighter}`,
                                                    borderRadius: '10px',
                                                    p: '5px'
                                                }}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setOpenAudioUploader(!openAudioUploader);
                                                    setOpenPdfUploader(false);
                                                    setOpenImageUploader(false);
                                                    setPostImages([]);
                                                    setPostMediaData([]);
                                                    setOpenPdfUploader(false);
                                                    setPostPdfs([]);
                                                    setPostPdfsData([]);
                                                    // setTimeout(() => {
                                                    //     audioInput.current.click();
                                                    // }, 0.5);
                                                }}
                                            >
                                                <Grid item sm={1} display="flex" justifyContent="center" alignItems="center">
                                                    <img src={AudioIconActive} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                        Add audio
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                    borderRadius: '10px'
                                                }}
                                                onClick={() => {
                                                    setOpenAudioUploader(!openAudioUploader);
                                                    setOpenPdfUploader(false);
                                                    setOpenImageUploader(false);
                                                    setPostImages([]);
                                                    setPostMediaData([]);
                                                    setOpenPdfUploader(false);
                                                    setPostPdfs([]);
                                                    setPostPdfsData([]);
                                                    // setTimeout(() => {
                                                    //     audioInput.current.click();
                                                    // }, 0.5);
                                                }}
                                            >
                                                <Grid item sm={1} display="flex" justifyContent="center" alignItems="center">
                                                    <img src={AudioIcon} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Attach audio files</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item sm={4}>
                                        {openPdfUploader ? (
                                            <Grid
                                                sm={12}
                                                container
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                    backgroundColor: `${theme.palette.primary.lighter}`,
                                                    borderRadius: '10px',
                                                    p: '5px'
                                                }}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setOpenPdfUploader(!openPdfUploader);
                                                    setOpenAudioUploader(false);
                                                    setOpenImageUploader(false);
                                                    setPostAudios([]);
                                                    setPostImages([]);
                                                    setPostaudiosData([]);
                                                    setPostMediaData([]);
                                                    setTimeout(() => {
                                                        pdfInput.current.click();
                                                    }, 0.5);
                                                }}
                                            >
                                                <Grid item sm={1} display="flex" justifyContent="center" alignItems="center">
                                                    <img src={FileIconActive} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                        Attach document
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                sm={12}
                                                container
                                                display="flex"
                                                alignItems="center"
                                                sx={{ borderRadius: '10px' }}
                                                onClick={() => {
                                                    setOpenPdfUploader(!openPdfUploader);
                                                    setOpenAudioUploader(false);
                                                    setOpenImageUploader(false);
                                                    setPostAudios([]);
                                                    setPostImages([]);
                                                    setPostaudiosData([]);
                                                    setPostMediaData([]);
                                                    setTimeout(() => {
                                                        pdfInput.current.click();
                                                    }, 0.5);
                                                }}
                                            >
                                                <Grid item sm={1} display="flex" justifyContent="center" alignItems="center">
                                                    <img src={FileIcon} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Attach document</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                maxHeight: '35vh',
                                overFlowY: 'scroll'
                            }}
                            className="scroll"
                        >
                            {/* image upload card  */}
                            {openImageUploader ? (
                                <>
                                    <>
                                        {existingpostImages.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Images
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {existingpostImages
                                                            .filter((image) => image !== null)
                                                            .map((image, index) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        sm={6}
                                                                        key={`img@${index}`}
                                                                        sx={{ position: 'relative' }}
                                                                        className="img-box"
                                                                    >
                                                                        <img src={image} alt="uploaded" className="uploaded-img" />

                                                                        <Grid
                                                                            item
                                                                            onClick={() => {
                                                                                {
                                                                                    console.log();
                                                                                }
                                                                                setExistingPostImages((existingpostImages) => {
                                                                                    let temp = [...existingpostImages];
                                                                                    temp.splice(index, 1);
                                                                                    return temp;
                                                                                });

                                                                                setTimeout(() => {
                                                                                    textInput.current.focus();
                                                                                }, 0.5);
                                                                            }}
                                                                            display="flex"
                                                                            direction="column"
                                                                            justifyContent="start"
                                                                            alignItems="start"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                right: '25px',
                                                                                opacity: '0'
                                                                            }}
                                                                            className="trash cursor-pointer"
                                                                        >
                                                                            <Grid>
                                                                                {' '}
                                                                                <Badge
                                                                                    color="primary"
                                                                                    badgeContent={'X'}
                                                                                    sx={{ marginLeft: 'auto' }}
                                                                                ></Badge>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}

                                        {existingpostVideos.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Videos
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {existingpostVideos
                                                            .filter((video) => video !== null)
                                                            .map((image, index) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        sm={6}
                                                                        key={`img@${index}`}
                                                                        sx={{ position: 'relative' }}
                                                                        className="img-box"
                                                                    >
                                                                        <video className="uploaded-img" width="100%" controls src={image} />

                                                                        <Grid
                                                                            item
                                                                            onClick={() => {
                                                                                setExistingpostVideos((existingpostVideos) => {
                                                                                    let temp = [...existingpostVideos];
                                                                                    temp.splice(index, 1);
                                                                                    return temp;
                                                                                });
                                                                            }}
                                                                            display="flex"
                                                                            direction="column"
                                                                            justifyContent="start"
                                                                            alignItems="start"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                right: '25px',
                                                                                opacity: '0'
                                                                            }}
                                                                            className="trash cursor-pointer"
                                                                        >
                                                                            <Grid>
                                                                                {' '}
                                                                                <Badge
                                                                                    color="primary"
                                                                                    badgeContent={'X'}
                                                                                    sx={{ marginLeft: 'auto' }}
                                                                                ></Badge>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}

                                        {postImages.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {postImages.map((image, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={6}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    {postMediaData[index].split(';base64')[0].split(':')[1].split('/')[0] ==
                                                                    'image' ? (
                                                                        <img src={image} alt="uploaded" className="uploaded-img" />
                                                                    ) : (
                                                                        <video className="uploaded-img" width="100%" controls src={image} />
                                                                    )}

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            {
                                                                                console.log();
                                                                            }
                                                                            setPostImages((postImages) => {
                                                                                let temp = [...postImages];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                textInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '25px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '3rem',
                                                    marginTop: '2rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Badge
                                                    color="primary"
                                                    className="float-end cursor-pointer"
                                                    badgeContent={'X'}
                                                    onClick={() => {
                                                        setOpenImageUploader(false);
                                                    }}
                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                ></Badge>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => imageInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '2rem' }}
                                                    >
                                                        <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                            {' '}
                                                            <img src={PhotoIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postImages.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        imageInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ ADD MORE PHOTOS OR VIDEOS </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    <div sx={{ display: 'none' }}>
                                        <input
                                            ref={imageInput}
                                            type="file"
                                            accept="image/*,video/*"
                                            name="imageInput"
                                            onChange={convertMedia}
                                            className="hidden"
                                            multiple
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {/* ausio upload card  */}
                            {openAudioUploader ? (
                                <>
                                    <>
                                        {existingpostAudios.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Files
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {existingpostAudios.map((audio, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    key={`aud@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <Grid container sm={12}>
                                                                        <Grid item sm={12}>
                                                                            <audio controls>
                                                                                <source
                                                                                    src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                        user.user_id
                                                                                    }/News/${news.news_id.toString()}/${audio}`}
                                                                                    type="audio/ogg"
                                                                                />
                                                                                <source
                                                                                    src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                        user.user_id
                                                                                    }/News/${news.news_id.toString()}/${audio}`}
                                                                                    type="audio/mpeg"
                                                                                />
                                                                            </audio>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            setExistingpostAudios((existingpostAudios) => {
                                                                                let temp = [...existingpostAudios];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                audioInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '25px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        {postAudios.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={2} display="flex" direction="column">
                                                        {postAudios.map((audio, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <Grid container sm={12}>
                                                                        <Grid item sm={12}>
                                                                            <audio controls>
                                                                                <source src={audio} type="audio/ogg" />
                                                                                <source src={audio} type="audio/mpeg" />
                                                                            </audio>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            setPostAudios((postAudios) => {
                                                                                let temp = [...postAudios];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                audioInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '25px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '3rem',
                                                    marginTop: '2rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => audioInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '2rem' }}
                                                    >
                                                        <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                            {' '}
                                                            <img src={AudioIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postAudios.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        audioInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ ADD MORE AUDIO FILES </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    <div sx={{ display: 'none' }}>
                                        <input
                                            ref={audioInput}
                                            type="file"
                                            accept="audio/*"
                                            name="audioInput"
                                            onChange={convertAudio}
                                            className="hidden"
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            {openPdfUploader ? (
                                <>
                                    <>
                                        {existingpostPdfs.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Files
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        <Grid item sm={12}>
                                                            <Grid container spacing={2} display="flex" direction="column">
                                                                {existingpostPdfs.map((video, index) => {
                                                                    let source = video.split('/')[1];
                                                                    return (
                                                                        <Grid
                                                                            item
                                                                            sm={12}
                                                                            key={`img@${index}`}
                                                                            sx={{ position: 'relative' }}
                                                                            className="img-box"
                                                                        >
                                                                            <Grid container display="flex" alignItems="center" sm={12}>
                                                                                <Grid
                                                                                    item
                                                                                    sm={2}
                                                                                    display="flex"
                                                                                    justifyContent="center"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <img
                                                                                        src={PdfIcon}
                                                                                        width={32}
                                                                                        height={32}
                                                                                        alt="pdficon"
                                                                                    ></img>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    sm={10}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    className="cursor-pointer"
                                                                                    onClick={() => {
                                                                                        window.open(
                                                                                            `${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                                user.user_id
                                                                                            }/News/${news.news_id.toString()}/${source}`,
                                                                                            '_blank',
                                                                                            'fullscreen=yes'
                                                                                        );
                                                                                        return false;
                                                                                    }}
                                                                                >
                                                                                    <Typography sx={{ fontSize: '16px' }}>
                                                                                        {video.split('/')[0]}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid
                                                                                item
                                                                                onClick={() => {
                                                                                    setExistingpostPdfs((existingpostPdfs) => {
                                                                                        let temp = [...existingpostPdfs];
                                                                                        temp.splice(index, 1);
                                                                                        return temp;
                                                                                    });

                                                                                    setTimeout(() => {
                                                                                        pdfInput.current.focus();
                                                                                    }, 0.5);
                                                                                }}
                                                                                display="flex"
                                                                                direction="column"
                                                                                justifyContent="start"
                                                                                alignItems="start"
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    top: '0px',
                                                                                    right: '25px',
                                                                                    opacity: '0'
                                                                                }}
                                                                                className="trash cursor-pointer"
                                                                            >
                                                                                <Grid>
                                                                                    {' '}
                                                                                    <Badge
                                                                                        color="primary"
                                                                                        badgeContent={'X'}
                                                                                        sx={{ marginLeft: 'auto' }}
                                                                                    ></Badge>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        {postPdfs.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={2} display="flex" direction="column">
                                                        {postPdfs.map((pdf, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <Grid container display="flex" alignItems="center" sm={12}>
                                                                        <Grid
                                                                            item
                                                                            sm={2}
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                        >
                                                                            <img src={PdfIcon} width={32} height={32} alt="pdficon"></img>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            sm={10}
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                window.open(pdf, '_blank', 'fullscreen=yes');
                                                                                return false;
                                                                            }}
                                                                        >
                                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                                {postPdfNames[index]}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            setPostPdfs((postPdfs) => {
                                                                                let temp = [...postPdfs];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });
                                                                            setPostPdfNames((postPdfNames) => {
                                                                                let temp = [...postPdfNames];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                pdfInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '25px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '3rem',
                                                    marginTop: '2rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Badge
                                                    color="primary"
                                                    className="float-end cursor-pointer"
                                                    badgeContent={'X'}
                                                    onClick={() => {
                                                        setOpenPdfUploader(false);
                                                    }}
                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                ></Badge>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => pdfInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '2rem' }}
                                                    >
                                                        <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                            {' '}
                                                            <img src={FileIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postPdfs.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        pdfInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ ADD MORE DOCUMENTS </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    <div sx={{ display: 'none' }}>
                                        <input
                                            ref={pdfInput}
                                            type="file"
                                            accept=".pdf"
                                            name="pdfInput"
                                            onChange={convertPdf}
                                            className="hidden"
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid container xs={12}>
                            {processing ? (
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        submitRequest();
                                    }}
                                    sx={{
                                        backgroundColor: '#FC5856',
                                        color: '#FFFFFF',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    <Typography variant="h6">PROCESSING</Typography>
                                </Button>
                            ) : (
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        submitRequest();
                                    }}
                                >
                                    <Typography variant="h6">{`${news ? 'UPDATE' : 'PUBLISH'}`}</Typography>
                                </Button>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default NewsModal;
