// assets
import { DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const footNote = {
    id: 'group-footNote',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'footNote',
            title: 'FootNotes',
            type: 'item',
            url: '/dashboard/footnotes',
            icon: icons.footNoteOutlined,
            breadcrumbs: false
        }
    ]
};

export default footNote;
