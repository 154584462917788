/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Post from 'pages/leaders-voice/Post';
import Gallery from 'components/Slider';
import Cross from '../../assets/static_images/cross.svg';

const PostViewer = ({ model, modelId, sharedId, setOpenViewer }) => {
    const [sharedMedias, setSharedMedias] = useState();
    const [reactions, setReactions] = useState([]);
    const [modelse, setModelse] = useState();
    useEffect(() => {
        fetchMedia();
        fetchModel();
        axios.get('/reacts').then(({ data }) => {
            if (data.success) {
                setReactions(data.reacts);
            }
        });
    }, []);
    async function fetchMedia() {
        await axios
            .post('/shared/selected', {
                model: model,
                model_id: modelId
            })
            .then(({ data }) => {
                if (data.success) {
                    let selected = null;
                    let notsel = [];
                    data.shared?.map((item) => {
                        if (item.shared_id == sharedId) {
                            selected = [
                                `${item.type == 'image' ? 'image' : 'video'}/${item.url.split('/')[item.url.split('/').length - 1]}`
                            ];
                        } else {
                            notsel.push(
                                `${item.type == 'image' ? 'image' : 'video'}/${item.url.split('/')[item.url.split('/').length - 1]}`
                            );
                        }
                    });

                    setSharedMedias([...selected, ...notsel]);
                }
            })
            .catch((err) => console.log(err));
    }
    async function fetchModel() {
        if (model == 'post') {
            await axios
                .get(`/social-posts/post/${modelId}/0`)
                .then(({ data }) => {
                    if (data.success) {
                        setModelse(data.post);
                    }
                })
                .catch((err) => console.log(err));
        }
    }
    return (
        <Grid container sm={12} display="flex" alignItems="center">
            <Grid item sm={12} display="flex" justifyContent="end">
                <Grid
                    item
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        position: 'absolute',
                        right: '1rem',
                        top: '6rem',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '50%',
                        padding: '.7rem'
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                        setOpenViewer(false);
                    }}
                >
                    <img src={Cross} alt="img" />
                </Grid>
            </Grid>
            <Grid item sm={7} sx={{ marginTop: '1.5rem' }}>
                {sharedMedias ? (
                    <Gallery arr={sharedMedias} user={JSON.parse(window.localStorage.getItem('authUser'))} post={{ post_id: modelId }} />
                ) : (
                    <></>
                )}
            </Grid>
            <Grid item sm={5} sx={{ height: '65vh', overFlowY: 'scroll', marginTop: '5rem' }} className="scroll">
                {modelse ? <Post post={modelse} reacts={reactions} type={`view`} /> : <></>}
            </Grid>
        </Grid>
    );
};

export default PostViewer;
