import React, { useEffect, useState } from 'react';
import threeDot from '../../assets/static_images/threedot.svg';
import { Grid, Card, Box, Typography, Menu, MenuItem, Modal, CardContent, Button, Badge } from '@mui/material';
import axios from 'lib/axios';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';

const GroupCard = ({ group, setGroups }) => {
    const [showModal, setShowModal] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userId, setUserId] = useState(null);

    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLeaveGroup = async () => {
        try {
            dispatch(activeLoader(true));
            const res = await axios.post('/groups/leave-group', {
                user_id: userId,
                group_id: group.group_id
            });

            if (res.status === 200) {
                setGroups((prev) => prev.filter((g) => g.group_id !== group.group_id));
                dispatch(activeLoader(false));
                setShowModal(false);
            }
        } catch (error) {
            dispatch(activeLoader(false));
            console.log(error);
        }
    };
    useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem('authUser'));

        if (user) {
            setUserId(user.user_id);
        }
    }, []);
    return (
        <Grid item sm={6} xs={12} sx={{ width: '100%', p: 1 }} className="cursor-pointer">
            <Card
                sx={{
                    borderRadius: '.5rem',
                    padding: '16px',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start'
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ gap: '16px' }}
                    onClick={() => {
                        location.replace(`${window.location.origin}/group/${group.group_id}`);
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <img
                            src={group.group_profile_picture ? group.group_profile_picture : GroupProfileImg}
                            className="img-fit"
                            style={{
                                width: '104px',
                                height: '104px',
                                objectFit: 'cover',
                                flexShrink: 0,
                                borderRadius: '4px'
                            }}
                            alt="imggroup"
                        />
                    </Box>
                    <Box>
                        <Box display="flex" flexDirection="column">
                            <Box>
                                <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                                    {group.group_name.length > 50 ? group.group_name.substr(0, 50) + '...' : group.group_name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ opacity: '60%' }}>
                                    {group.group_description.length > 60
                                        ? group.group_description.substr(0, 60) + '...'
                                        : group.group_description}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* ---------- Menu ----------- */}
                <Box>
                    <Box
                        className="cursor-pointer"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        display="flex"
                        alignItems="start"
                        justifyContent="center"
                    >
                        <img src={threeDot} alt="threedot" style={{ width: '20px' }} className="img-fit" />
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setShowModal(true);
                            }}
                        >
                            Leave group
                        </MenuItem>
                    </Menu>
                </Box>
            </Card>
            {/* modal */}
            <Modal
                keepMounted
                open={showModal}
                onClose={setShowModal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw', height: '100vh' }}>
                    <Grid item sx={{ position: 'relative' }}>
                        <Card
                            sx={{
                                borderRadius: '10px',
                                width: '40vw',
                                marginTop: '1rem',
                                paddingX: '1rem',
                                position: 'relative'
                            }}
                        >
                            <Box sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                                <Badge
                                    color="primary"
                                    badgeContent={'X'}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                    sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                                ></Badge>
                            </Box>
                            <CardContent className="scroll">
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Typography variant="h5">Are You Sure, You Want To Do This ? </Typography>
                                </Box>

                                <Grid container sm={12} spacing={2} mt={3}>
                                    <Grid item sm={6}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                handleLeaveGroup();
                                            }}
                                        >
                                            <Typography variant="h6">Yes</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setShowModal(false);
                                            }}
                                            sx={{
                                                backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                                color: '#000000'
                                            }}
                                        >
                                            <Typography variant="h6">Cancel</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
};

export default GroupCard;
