import React from 'react';
import { Typography, Box } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                maxHeight: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                oMirror User Terms & Conditions:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%', fontWeight: 'bold' }} mt={1}>
                Document Effective Date: March 31, 2024 (Version A)
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                oMirror client organization and its users agrees to the following terms and conditions as they subscribe oMirror Web and
                Mobile application services. Please read the documents carefully before and after you subscribe oMirror. We also suggest to
                read future updated terms and conditions we publish time to time to improve oMirror experience.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Translations of our policies are provided for your convenience. If there is a conflict between the text of this policy and
                the text of the English language version of the policy, the text of the English language version will take precedence.
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} mt={2}>
                oMirror User Terms & Conditions:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Your organization OMirror contents shall remain your asset, no matter you have subscribed oMirror in our cloud or your own
                server. Under no circumstances, we access to your content, text, data, information, and files that you upload, share, or
                stored in our or your Cloud, Server, etc. In any extenuating circumstances and with your permission, we may have to access a
                subscribed oMirror content to troubleshoot a problem or resolve an issue reported from your end.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                OMirror gives its developer and/or third party companies no rights to access OMirror subscriber data or information to
                protect from any unauthorized disclosure of your contents, confidential information, etc. unless permitted by subscribers.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                The “owner” of the content is completely the subscriber organization.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We will not use your contents for marketing or any promotional campaigns.
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} mt={2}>
                oMirror Copyright Infringement:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Respect copyright laws. Do not copy OMirror designs, features, and/or processes that may lead to legal proceedings against
                you as per local and international laws. It is our policy to respond to copyright infringement, intellectual property
                rights, etc. that will result in account termination. If you see a violation of OMirror copyright registration, please
                report to{' '}
                <span>
                    <a href="mailto:info@eyevary.com">info@eyevary.com</a>
                </span>{' '}
                immediately.
            </Typography>
        </Box>
    );
};

export default TermsAndConditions;
