/* eslint-disable prettier/prettier */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
// material-ui
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';
import { Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Button } from '@mui/material';
const AddSearch = ({ text, setOpenModal, setOperation }) => {
    return (
        // eslint-disable-next-line prettier/prettier
        <Grid container sm={12} display="flex" sx={{ flexDirection: { md: 'row', xs: 'column' } }}>
            <Grid item sm={6}>
                <Button
                    size="large"
                    type="submitS"
                    variant="contained"
                    onClick={() => {
                        setOperation((prev) => {
                            prev = 'create';
                            return prev;
                        });
                        setOpenModal(true);
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Box>
                            <Typography variant="h5">+</Typography>
                        </Box>
                        <Box>{`${text}`}</Box>
                    </Box>
                </Button>
            </Grid>
            <Grid item sm={6} display="flex" alignItems="center" justifyContent={{ md: 'end', xs: 'start' }} sx={{ marginTop: '5px' }}>
                <Grid container sm={12} display="flex" alignItems="center" spacing={2}>
                    <Grid item sm={8}>
                        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
                            <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                                <OutlinedInput
                                    sx={{ width: '100%' }}
                                    id="header-search"
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                                            <SearchOutlined />
                                        </InputAdornment>
                                    }
                                    aria-describedby="header-search-text"
                                    inputProps={{
                                        'aria-label': 'weight'
                                    }}
                                    placeholder="Search Here..."
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item sm={3}>
                        <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            // onClick={() => {
                            //     submitForm();
                            // }}
                        >
                            <Grid container sm={12} display="flex" alignItems="center">
                                <Grid item>Search</Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddSearch;
