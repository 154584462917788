/* eslint-disable prettier/prettier */
const Districts = {
    id: 'districts',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'districts',
            title: 'Districts',
            type: 'item',
            url: '/dashboard/districts',
            breadcrumbs:false
            // target: true //in order to open in new tab
        }
    ]
};

export default Districts;
 