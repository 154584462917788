/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Posts from 'pages/leaders-voice/Posts';
import Modal from '@mui/material/Modal';
import PostViewer from 'components/modals/PostViewer';
import AnalyticsChart from './AnalyticsChart';
import EngagementTable from './EngagementTable';
import DateFilter from 'components/modals/DateFilter';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import SelfAnalytics from './SelfAnalytics';
import TabSelector from 'components/TabSelector';
import TeamAnalytics from './TeamAnalytics';
import HasPermission from 'utils/HasPermission';
import { Box } from '@mui/material';
import { Toolbar, useMediaQuery } from '@mui/material';
import About from 'pages/Profile/About';
import SelfAnalytics from 'pages/self-analytics/index';

const Analytics = () => {
    const [userData, setUserDate] = useState();
    const [offest, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sahredMedia, setSharedMedia] = useState();
    const [activeTab, setActiveTab] = useState('team');
    const [tabs, setTabs] = useState({
        // self: <SelfAnalytics />,
        self: <SelfAnalytics />,
        team: <TeamAnalytics />
    });
    const [hasPermission, setHasPermission] = useState(false);
    async function fetchUserData() {
        await axios
            .post(`/users/user`, {
                id: JSON.parse(window.localStorage.getItem('authUser')).user_id.toString()
            })
            .then(({ data }) => {
                setUserDate(data.data);
            })
            .catch((err) => console.log(err));
    }
    async function sahredFiles() {
        await axios
            .post('/shared', {
                offset: offest,
                limit: limit
            })
            .then(({ data }) => {
                if (data.success) {
                    setSharedMedia(data.shared);
                }
            })
            .catch((err) => console.log(err));
    }
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        HasPermission(setHasPermission, 'Project', 'supervise', 'supervise_project');
        if (!userData) {
            fetchUserData();
        }
        if (!sahredMedia) {
            sahredFiles();
        }
    }, []);
    return (
        <Grid container sm={12}>
            <Grid item sm={12}>
                {hasPermission ? (
                    <Grid container sm={12} display="flex" alignItems="center" sx={{ m: '15px' }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={
                                activeTab == 'self'
                                    ? { background: 'rgba(30, 124, 136, 1)', borderRadius: '10px', marginX: '.25rem', p: '10px' }
                                    : { background: '#e6e6e6', borderRadius: '10px', p: '10px' }
                            }
                            onClick={() => {
                                setActiveTab('self');
                            }}
                            className="cursor-pointer"
                        >
                            <Grid container sm={12} display="flex" alignItems="center">
                                <Grid item>
                                    <Typography color={activeTab == 'self' ? '#FFFFFF' : '#000000'}>Self Analytics</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid
                            item
                            sm={2}
                            display="flex"
                            alignItems="center"
                            sx={
                                activeTab != 'self'
                                    ? { background: 'rgba(30, 124, 136, 1)', borderRadius: '10px', marginX: '.25rem', p: '10px' }
                                    : { background: '#e6e6e6', borderRadius: '10px', p: '10px' }
                            }
                            onClick={() => {
                                setActiveTab('team');
                            }}
                            className="cursor-pointer"
                        >
                            <Grid container sm={12} display="flex" alignItems="center">
                                <Grid item>
                                    <Typography color={activeTab != 'self' ? '#FFFFFF' : '#000000'}>Team Analytics</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                <TabSelector activeTab={activeTab} tabs={tabs} />
            </Grid>
        </Grid>
    );
};

export default Analytics;
