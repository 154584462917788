import {
    Grid,
    Card,
    OutlinedInput,
    InputAdornment,
    Button,
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    CardContent,
    CircularProgress
} from '@mui/material';
import avatar1 from '../assets/images/users/avatar-1.png';
import UserAvatarComponent from 'components/UserAvatarComponent';

const SelectionItem = ({ item, id_key, name, targetedArray, setTargetedArray, has_avater = false }) => {
    const checkExists = (array, key, compare_value, compare_key) => {
        return array.filter((itm) => {
            if (itm[key] == compare_value[compare_key]) {
                return itm;
            }
        }).length > 0
            ? true
            : false;
    };
    const manageClickOnEntries = (item, check_key, name_key) => {
        if (checkExists(targetedArray, 'id', item, check_key)) {
            setTargetedArray(() => {
                return targetedArray.filter((itm) => {
                    if (itm.id != item[check_key]) {
                        return itm;
                    }
                });
            });
        } else {
            setTargetedArray((data) => [...data, { id: item[id_key], name: item[name_key] }]);
        }
    };
    return (
        <Grid item sm={12} sx={{ mb: '5px' }}>
            <Grid container display="flex" alignItems="center" sm={12} spacing={1} className="cursor-pointer">
                <Grid item display="flex" alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={() => {
                                    manageClickOnEntries(item, id_key, name);
                                }}
                                sx={{ marginX: '0px' }}
                                checked={checkExists(targetedArray, 'id', item, id_key)}
                            />
                        }
                        label={''}
                        sx={{ marginX: '0px' }}
                    />
                </Grid>
                <Grid item>
                    {has_avater ? (
                        <Grid
                            item
                            onClick={() => {
                                manageClickOnEntries(item, id_key, name);
                            }}
                        >
                            <Grid container display="flex" alignItems="center" spacing={1}>
                                <Grid item sx={{ pointerEvents: 'none' }}>
                                    <UserAvatarComponent
                                        userData={item?.user}
                                        style={{ borderRadius: '50%', height: '2.5rem', width: '2.5rem' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">{item?.user_name}</Typography>
                                    <Typography sx={{ fontSize: '13px', opacity: '60%' }}>{item?.role_name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            onClick={() => {
                                manageClickOnEntries(item, id_key, name);
                            }}
                        >
                            <Typography variant="h6">{item[name]}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SelectionItem;
