/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import axios from 'lib/axios';
import Modal from '@mui/material/Modal';

const DeleteComment = ({ commentId, model, setOpenDeleteModal, setComments, setCommentCount }) => {
    const [processing, setProcessing] = useState(false);
    const [url, setUrl] = useState(model == 'post' ? '/comments/comment/delete' : `/${model}-comments/comment/delete`);
    async function submit() {
        await axios
            .post(`${url}`, { comment_id: commentId })
            .then(({ data }) => {
                if (data.success) {
                    if (setComments) {
                        setComments((comments) => {
                            return [
                                ...comments.filter((comment) => {
                                    if (comment.comment_id !== commentId) {
                                        return comment;
                                    }
                                })
                            ];
                        });
                    }
                    if (setCommentCount) {
                        setCommentCount((prev) => prev - 1);
                    }
                    setOpenDeleteModal(false);
                }
            })
            .catch((err) => console.log(err));
    }
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item sx={{ position: 'relative', top: '6rem' }}>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: { md: '40vw', xs: '90vw' },
                        marginTop: '1rem',
                        height: '30vh',
                        overFlowY: 'scroll',
                        paddingX: '1rem'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Grid container sm={12} display="flex" aligItems="center" justifyContent="end">
                            <Grid item sm={1} display="flex" aligItems="center" justifyContent="end">
                                <Badge
                                    color="primary"
                                    badgeContent={'X'}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenDeleteModal(false);
                                    }}
                                    sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                                ></Badge>
                            </Grid>
                        </Grid>
                        <Grid container display="flex" aligItems="center" justifyContent="cetner" sm={12} sx={{ marginY: '4rem' }}>
                            <Grid item display="flex" aligItems="center" justifyContent="cetner">
                                <Typography variant="h5">Are You Sure, You Want To Delete The Comment ? </Typography>
                            </Grid>
                        </Grid>
                        {!processing ? (
                            <Grid container sm={12} spacing={2}>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        <Typography variant="h6">Proceed</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenDeleteModal(false);
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container sm={12} spacing={2}>
                                <Grid item sm={12}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        sx={{
                                            backgroundColor: '#FC5856',
                                            color: '#FFFFFF',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <Typography variant="h6">PROCESSING</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DeleteComment;
