/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Grid from '@mui/material/Unstable_Grid2';
import { materialLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Box, Button } from '@mui/material';
import Cross from '../assets/static_images/cross.svg';
import { useLocation } from 'react-router-dom';
const Gallery = ({ arr, user, post, model = 'post', activeIndex = 0, setOpenGallery, type = '' }) => {
    const [showCross, setShowCross] = useState(true);
    const sliderRef = useRef();
    const [zoomLevel, setZoomLevel] = useState(1);
    const { pathname } = useLocation();
    const handleOnClick = (index) => {
        sliderRef.current.slickGoTo(index);
    };

    const handleZoomIn = () => {
        if (zoomLevel < 5) {
            setZoomLevel((prevZoom) => prevZoom * 1.2);
        }
    };

    const handleZoomOut = () => {
        if (zoomLevel > 1) {
            setZoomLevel((prevZoom) => prevZoom / 1.2);
        }
    };

    const settings = {
        infinite: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: false,
        autoplaySpeed: 2000,
        beforeChange: () => setZoomLevel(1)
    };

    useEffect(() => {
        handleOnClick(activeIndex);
    }, [activeIndex]);

    const [id, setId] = useState(
        model == 'post'
            ? () => post.post_id
            : () => {
                  if (model == 'news') {
                      return post.news_id;
                  } else {
                      return post.event_id;
                  }
              }
    );
    const [url, setUrl] = useState(
        model == 'post'
            ? () => `Social-Post/${post.post_id?.toString()}`
            : () => {
                  if (model == 'news') {
                      return `News/${post.news_id.toString()}`;
                  } else {
                      return `Events/${post.event_id?.toString()}`;
                  }
              }
    );
    useEffect(() => {
        handleOnClick(activeIndex);
    }, []);
    useEffect(() => {
        switch (pathname) {
            case '/get-social':
                setShowCross(false);
                break;
            case '/leadersvoice':
                setShowCross(false);
                break;
            case '/news-events/news':
                setShowCross(false);
                break;

            default:
                setShowCross(true);
        }
    }, [pathname]);
    return (
        <Grid container sm={12} display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
            <Grid item sm={12} sx={{ width: '100%' }}>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            flexDirection: 'column',
                            top: '0',
                            left: '0',
                            zIndex: 99,
                            padding: '10px',
                            marginTop: '1rem',
                            marginLeft: '1rem'
                        }}
                    >
                        <Button variant="contained" color="primary" onClick={handleZoomIn} sx={{ fontSize: { md: '20px' } }}>
                            +
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleZoomOut}
                            sx={{ fontSize: { md: '20px' }, backgroundColor: '#fff', color: 'black' }}
                        >
                            -
                        </Button>
                    </div>
                    {showCross && (
                        <div
                            style={{
                                position: 'absolute',
                                right: '1rem',
                                top: '1rem',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '50%',
                                padding: '.7rem',
                                zIndex: 99,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '1rem',
                                marginRight: '1rem'
                            }}
                            className="cursor-pointer"
                            onClick={() => setOpenGallery(false)}
                        >
                            <img src={Cross} alt="img" />
                        </div>
                    )}
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {arr.map((item, index) => {
                        // if (item.split('/')[0] == 'image') {
                        return (
                            <div key={`gallery@${index}${id}`} className="img-fit-slide" sx={{ padding: '1rem', marginLeft: '3rem' }}>
                                <div className="center-center imgcontainer" style={{ background: 'black' }}>
                                    <div>
                                        <input type="checkbox" id={`zoomCheck-${index}-${model}`} />
                                        <label for={`zoomCheck-${index}-${model}`}>
                                            <img
                                                src={item}
                                                alt="pic"
                                                style={{
                                                    maxHeight: '38.9vw',
                                                    width: '100%',
                                                    objectFit: 'contain',
                                                    transform: `scale(${zoomLevel})`,
                                                    transition: 'transform 0.3s ease'
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        );
                        // } else {
                        //     return (
                        //         <div key={`gallery@${index}${id}`} className="img-fit-slide">
                        //             <div className="slide-mid center-center">
                        //                 <div className="center-center">
                        //                     <video width="100%" style={{ height: '100%', maxWidth: '100%' }} controls>
                        //                         <source
                        //                             src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${user.user_id}/${url}/${
                        //                                 item.split('/')[1]
                        //                             }`}
                        //                         />
                        //                         Your browser does not support the video tag.
                        //                     </video>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //     );
                        // }
                    })}
                </Slider>
            </Grid>
        </Grid>
    );
};

export default Gallery;
