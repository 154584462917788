/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

const Timer = ({ dateTime }) => {
    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const timerProps = {
        isPlaying: true,
        size: 100,
        strokeWidth: 4
    };

    const renderTime = (dimension, time) => {
        return (
            <div className="time-wrapper">
                <div className="time">
                    <h2 style={{ color: '#000000' }}>{time}</h2>
                </div>
                {/* <div>{dimension}</div> */}
            </div>
        );
    };

    const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time) => (time / daySeconds) | 0;

    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = dateTime / 1000; // use UNIX timestamp in seconds

    const remainingTime = endTime - stratTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;

    const [strokeColor, setStrokeColor] = useState(remainingTime <= 0 ? '#FC5856' : '#1E7C88');

    return (
        <Grid container sm={12} spacing={1}>
            <Grid item sm={3}>
                <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <CountdownCircleTimer
                            {...timerProps}
                            colors={strokeColor}
                            duration={daysDuration}
                            initialRemainingTime={remainingTime}
                        >
                            {({ elapsedTime, color }) => (
                                <div style={{ color }}>{renderTime('days', getTimeDays(daysDuration - elapsedTime))}</div>
                            )}
                        </CountdownCircleTimer>
                    </Grid>
                    <Grid
                        item
                        sx={{ paddingY: '0px', marginY: '0px', height: '2rem' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                    >
                        <span sx={{ opacity: '0.5', paddingY: '0px', marginY: '0px' }}>Days</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={3}>
                <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <CountdownCircleTimer
                            {...timerProps}
                            colors={strokeColor}
                            duration={daySeconds}
                            initialRemainingTime={remainingTime % daySeconds}
                            onComplete={(totalElapsedTime) => ({
                                shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                            })}
                        >
                            {({ elapsedTime, color }) => (
                                <div style={{ color }}>{renderTime('hours', getTimeHours(daySeconds - elapsedTime))}</div>
                            )}
                        </CountdownCircleTimer>
                    </Grid>
                    <Grid
                        item
                        sx={{ paddingY: '0px', marginY: '0px', height: '2rem' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                    >
                        <span sx={{ opacity: '0.5', paddingY: '0px', marginY: '0px' }}>Hours</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={3}>
                <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <CountdownCircleTimer
                            {...timerProps}
                            colors={strokeColor}
                            duration={hourSeconds}
                            initialRemainingTime={remainingTime % hourSeconds}
                            onComplete={(totalElapsedTime) => ({
                                shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                            })}
                        >
                            {({ elapsedTime, color }) => (
                                <div style={{ color }}>{renderTime('minutes', getTimeMinutes(hourSeconds - elapsedTime))}</div>
                            )}
                        </CountdownCircleTimer>
                    </Grid>
                    <Grid
                        item
                        sx={{ paddingY: '0px', marginY: '0px', height: '2rem' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                    >
                        <span sx={{ opacity: '0.5', paddingY: '0px', marginY: '0px' }}>Minutes</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={3}>
                <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <CountdownCircleTimer
                            {...timerProps}
                            colors={strokeColor}
                            duration={minuteSeconds}
                            initialRemainingTime={remainingTime % minuteSeconds}
                            onComplete={(totalElapsedTime) => ({
                                shouldRepeat: remainingTime - totalElapsedTime > 0
                            })}
                        >
                            {({ elapsedTime, color }) => <div style={{ color }}>{renderTime('seconds', getTimeSeconds(elapsedTime))}</div>}
                        </CountdownCircleTimer>
                    </Grid>
                    <Grid
                        item
                        sx={{ paddingY: '0px', marginY: '0px', height: '2rem' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                    >
                        <span sx={{ opacity: '0.5', paddingY: '0px', marginY: '0px' }}>Seconds</span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Timer;
