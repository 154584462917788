/* eslint-disable prettier/prettier */
// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import companies from './comapanies';
import Users from './employees';
import Districts from './district';
import Divisions from './division';
import Locations from './location';
import Functions from './functions';
import Roles from './role';
import Permissions from './permission';
import project from './project';
import analytics from './analytics';
import subscription from './subscription';
import PermissionLevel from './permitionLevel';
import MenuStatus from './menuStatus';
import OfficeLocation from './officeLocation';
import client_subscription from './clientSubscription';
import footNote from './footNote';
import axios from 'lib/axios';

const HasPermission = async (model, action, key = null) => {
    await axios
        .post(`/has-permission`, {
            model: model,
            action: action,
            key: key
        })
        .then(({ data }) => {
            if (data.success) {
                return true;
            } else {
                return false;
            }
        })
        .catch((err) => console.log(err));
};
// ==============================|| MENU ITEMS ||============================== //

const menuItems = () => {
    if (HasPermission('User', 'create', 'create_user')) {
        return {
            items: [
                dashboard,
                Functions,
                companies,
                subscription,
                Divisions,
                Districts,
                Locations,
                Roles,
                Permissions,
                Users,
                analytics,
                project,
                OfficeLocation,
                PermissionLevel,
                MenuStatus,
                footNote
            ]
        };
    } else {
        return { items: [dashboard] };
    }
};
// console.log(HasPermission('User','create','create_user'));
// if(HasPermission('User','create','create_user') )  {
//     menuItems={items: [dashboard, companies,Divisions,Districts,Locations,Roles,Permissions,Employees,analytics,project,subscription]};
// }else if(HasPermission('User','create','create_user')){
//     menuItems={items: [dashboard, companies,Divisions,Districts,Locations,Roles,Permissions,Employees,analytics,project,client_subscription]};
// }
// else{
//     menuItems={items: [dashboard]};
// }
export default menuItems;
