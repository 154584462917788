/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import { Link, InputLabel, OutlinedInput, Snackbar, Box } from '@mui/material';
import { Alert } from '@mui/lab';
import parse from 'html-react-parser';
import axios from 'lib/axios';
import Comment from '../../assets/static_images/comment.svg';
import Reacts from '../../components/Reacts';
import ThreeDot from '../../assets/static_images/threedot.svg';
import CreateVoice from 'components/modals/CreateVoice';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Cross from '../../assets/static_images/cross.svg';
import Gallery from 'components/Slider';
import Button from '@mui/material/Button';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import Blank from '../../assets/static_images/blank.svg';

import NoticeIcon from '../../assets/static_images/megaphone.svg';
import NoticeGalleryModal from 'components/modals/NoticeGalleryModal';
import ViewReactModal from 'components/modals/ViewReactModal';
import { convertDateAndTime } from 'Helpers';
import { useLocation } from 'react-router-dom';
const Notice = ({ notice, reacts, setNoticeList, single = false, type }) => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const noticeId = query.get('notice_id');

    const processToUpper = (text) => {
        return text
            .split('_')
            .map((item) => item.replace(item[0], item[0].toUpperCase()))
            .join(' ');
    };
    const timeDiffer = (now, date) => {
        var diffMs = now - date; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        return diffDays > 0
            ? `${diffDays} day${diffDays > 1 ? 's' : ''}, ${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : diffHrs > 0
            ? `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
    };
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [reactCount, setReactCount] = useState(notice?.notice_reacts?.length);

    const [openGallery, setOpenGallery] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openSnack, setShowSnack] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    const [openModal, setOpenModal] = useState(false);
    const [openViewReactModal, setOpenViewReactModal] = useState(false);

    function handleSnackClose() {
        setShowSnack(false);
    }

    const [id, setId] = useState(`notice-${notice?.notice_id}`);
    const adjustReact = async () => {
        await axios
            .delete('/notice-reacts/destroy', { data: { notice_id: notice.notice_id } })
            .then(({ data }) => {
                if (data.success) {
                    setReacted(false);
                }
            })
            .catch((err) => console.log(err));
    };
    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.replace(urlRegex, function (url) {
            return '<a target="_target" href="' + url + '">' + url + '</a>';
        });
    }

    useEffect(() => {
        axios.get(`notice/reacted/${notice.notice_id}`).then(({ data }) => {
            if (data.success) {
                setReacted(data.reacted);
                if (data.react !== null) {
                    setReaction(data.react.react.react_name);
                    if (data.react.react.react_id == 1) {
                        setReactionImg(Like);
                    } else if (data.react.react.react_id == 2) {
                        setReactionImg(Love);
                    } else if (data.react.react.react_id == 3) {
                        setReactionImg(Haha);
                    } else if (data.react.react.react_id == 4) {
                        setReactionImg(Wow);
                    } else if (data.react.react.react_id == 5) {
                        setReactionImg(Sad);
                    } else if (data.react.react.react_id == 6) {
                        setReactionImg(Angry);
                    }
                }
            }
        });

        // fetchComments();
    }, []);
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

    return (
        <Grid item sm={12} id={() => id}>
            <Card
                sx={{
                    borderRadius: '10px',
                    padding: '1.5rem',
                    width: '100%',
                    border: noticeId && noticeId == notice.notice_id && '2px solid #1F7C88',
                    position: 'relative',
                    boxShadow: type === 'view' && 0,
                    mt: 2
                }}
            >
                {noticeId && noticeId == notice.notice_id && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            bgcolor: '#1F7C88',
                            color: '#ffff',
                            px: '5px',
                            borderRadius: '0px 5px'
                        }}
                    >
                        New Notice
                    </Box>
                )}

                <Grid container spacing={0} sx={{ padding: '0px' }} display="flex" direction="column" sm={12}>
                    {/* card header  */}
                    <Grid item sm={12}>
                        <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                            <Box>
                                <img
                                    src={NoticeIcon}
                                    className="img-fit"
                                    alt="avatar"
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>

                            <Box sx={{ padding: '0px' }}>
                                <Box display="flex" flexDirection="column" sx={{ ml: '10px' }}>
                                    <Box item sm={12}>
                                        <Typography color="primary" variant="h5" sx={{ fontWeight: '500' }}>
                                            Notice
                                        </Typography>
                                    </Box>
                                    <Box item sx={{ padding: '0px' }}>
                                        <Typography variant="subtitle1" sx={{ opacity: '0.5' }}>
                                            {/* {new Date(parseInt(notice.announce_date)).toLocaleDateString('en-US', options)} */}
                                            {convertDateAndTime(notice.createdAt)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/* card  body  */}
                    <Grid item sm={12} sx={{ paddingY: '1rem', borderBottom: '1px solid rgba(0,0,0,0.6)' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '500' }}>
                                {notice.notice_title}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ paddingBottom: '0.5rem', marginY: '0rem' }}>
                            {urlify(notice.plain_text_body)?.length > 150 ? (
                                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                    {showMore ? urlify(notice.plain_text_body) : `${urlify(notice.plain_text_body).substring(0, 150)}...`}
                                    {!showMore ? (
                                        <Button
                                            className="btn"
                                            onClick={() => {
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            Show more
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn"
                                            onClick={() => {
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            Show less
                                        </Button>
                                    )}
                                </Grid>
                            ) : (
                                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                    {urlify(notice.plain_text_body)}
                                </Grid>
                            )}
                        </Grid>
                        <Grid container display="flex" direction="column" className="content-body" spacing={2}>
                            {notice.images && notice.images != '' ? (
                                <Grid item sm={12} sx={{ borderRadius: '1.5rem' }} onClick={() => setOpenGallery(true)}>
                                    <img
                                        src={notice.images[0]}
                                        className="img-fit"
                                        style={{ height: '366px', width: '100%', objectFit: 'contain', background: 'lightgray' }}
                                        alt="notice-imag"
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                    {/* reaction section  */}
                    <Grid item sm={12} sx={{ paddingY: '0px' }}>
                        <Grid container sm={12} display="flex" alignItems="center" sx={{ paddingTop: '5px' }} spacing={0}>
                            {/* <Grid item sm={1} display="flex" justifyContent="start" alignItems="center"> */}
                            {/* <Grid
                                container
                                sm={12}
                                display="flex"
                                justifyContent="start"
                                alignItems="center"
                                spacing={3}
                                sx={{ padding: '0px', border: '2px solid red' }}
                            > */}
                            <Grid item sx={{ padding: '0px' }}>
                                <Grid container display="flex" alignItems="center" sx={{ position: 'relative' }}>
                                    <Grid
                                        item
                                        display="flex"
                                        alignItems="center"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            adjustReact();
                                            setReactOpen(!reactOpen);
                                        }}
                                        sm={12}
                                    >
                                        <Grid container display="flex" alignItems="center">
                                            {reacted ? (
                                                <Grid item>
                                                    <Grid container display="flex" gap={1}>
                                                        <Grid item>
                                                            <img src={reactionImg} style={{ width: '20px' }} alt="icn" />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography sx={{ opacity: '0.6' }}>Like</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Grid container display="flex" gap={1}>
                                                        <Grid item>
                                                            <img src={Blank} style={{ width: '20px', marginTop: '2px' }} alt="icn" />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography sx={{ opacity: '0.6' }}>Like</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {reactOpen ? (
                                        <Grid
                                            item
                                            sx={{
                                                zIndex: '1024',
                                                position: 'absolute',
                                                top: '-4rem',
                                                left: '1.5rem',
                                                width: '23rem'
                                            }}
                                        >
                                            <Reacts
                                                modelValue={notice}
                                                reacts={reacts}
                                                setReactOpen={setReactOpen}
                                                setReacted={setReacted}
                                                setReactCount={setReactCount}
                                                setReactionImg={setReactionImg}
                                                setReaction={setReaction}
                                                model={'notice'}
                                            />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                            {/* </Grid> */}
                            {/* </Grid> */}
                            <Box sx={{ mb: '2px' }}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setOpenViewReactModal(true);
                                    }}
                                >
                                    <Box display="flex" alignItems="center" gap={0.5} ml={2}>
                                        {reacted ? <Typography variant="subtitle1">You</Typography> : <></>}
                                        {reactCount > 1 && (
                                            <Typography variant="h6">
                                                {reacted ? 'and' : ''} {reactCount} {reactCount > 1 ? "other's" : 'other'}
                                            </Typography>
                                        )}
                                        {(reactCount > 0 || reacted) && <Typography variant="subtitle1">reacted on this notice</Typography>}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            {openGallery ? (
                <Modal
                    keepMounted
                    open={openGallery}
                    onClose={setOpenGallery}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
                        <Grid item sm={10} xs={10} sx={{ position: 'relative', top: '10vh' }}>
                            <NoticeGalleryModal model="notice" images={notice.images} setOpenGallery={setOpenGallery} />
                        </Grid>
                        {/* <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                position: 'absolute',
                                left: '5rem',
                                top: '8rem',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '50%',
                                padding: '.7rem'
                            }}
                            className="cursor-pointer"
                            onClick={() => {
                                setOpenGallery(!openGallery);
                            }}
                        >
                            <img src={Cross} alt="img" />
                        </Grid> */}
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
            {openViewReactModal && (
                <ViewReactModal
                    post={notice}
                    endpoint="/notice-reacts/list"
                    setOpenModal={setOpenViewReactModal}
                    openModal={openViewReactModal}
                    type="notice"
                />
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Notice;
