import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Link, Stack, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { InputLabel, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import axios from 'lib/axios';
const DepartmentDivisionModal = ({ isOpen, setOpen, setLoading, department, operation, handleSubmit }) => {
    const theme = useTheme();
    const [departmentName, setDepartmentName] = useState(department ? department.name : '');
    const [processing, setProcessing] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [selectedDept, setSelectedDept] = useState(operation == 'update' ? department.department.department_id : null);
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };
    async function fetchDepartments() {
        await axios.get(`/departments`).then(({ data }) => {
            if (data.success) {
                setDepartments(data.departments);
            }
        });
    }
    useEffect(() => {
        fetchDepartments();
    }, []);
    return (
        <Modal
            keepMounted
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                <Box
                    sx={{
                        heigh: '30vh',
                        width: { md: '30vw', sx: '100vw' },
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        borderRadius: '15px',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    {operation == 'create' || operation == 'update' ? (
                        <Card sx={{ padding: '1.5rem', margin: '1.5rem' }}>
                            <Stack>
                                <Badge
                                    color="secondary"
                                    badgeContent={'X'}
                                    showZero
                                    onClick={() => {
                                        handleClose();
                                    }}
                                ></Badge>
                                <Box sx={{ margin: '.7rem' }}>
                                    <InputLabel style={{ color: '#000000', margin: '.5rem' }} htmlFor="company-name">
                                        Department Division Name
                                    </InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        value={departmentName}
                                        name="email"
                                        color={theme.primary}
                                        // onBlur={handleBlur}
                                        onChange={(e) => {
                                            setDepartmentName(e.target.value);
                                        }}
                                        placeholder="Type Here .."
                                        fullWidth
                                        // error={Boolean(touched.email && errors.email)}
                                    />
                                </Box>
                                <Box sx={{ margin: '.7rem', width: '100%' }}>
                                    <InputLabel style={{ color: '#000000', margin: '.5rem' }} htmlFor="company-name">
                                        Select Department
                                    </InputLabel>
                                    <FormControl sx={{ width: '95%' }}>
                                        <Select
                                            labelId="select-label"
                                            value={selectedDept}
                                            onChange={(e) => {
                                                setSelectedDept(() => e.target.value);
                                            }}
                                            sx={{ width: '100%' }}
                                        >
                                            {departments &&
                                                departments.length > 0 &&
                                                departments.map((department) => {
                                                    return (
                                                        <MenuItem value={department.department_id} key={department.department_id}>
                                                            {department.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* departments */}
                            </Stack>
                            <Grid container xs={12} md={12} sx={{ padding: '.6rem' }}>
                                {processing ? (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        sx={{
                                            backgroundColor: '#FC5856',
                                            color: '#FFFFFF',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <Typography variant="h6">PROCESSING</Typography>
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            handleSubmit(
                                                setProcessing,
                                                departmentName,
                                                selectedDept,
                                                operation,
                                                department?.department_division_id
                                            );
                                        }}
                                    >
                                        {department ? 'UPDATE' : 'SUBMIT'}
                                    </Button>
                                )}
                            </Grid>
                        </Card>
                    ) : (
                        <></>
                    )}
                    {operation == 'delete' ? (
                        <Card
                            sx={{
                                borderRadius: '10px',
                                width: '40vw',
                                marginTop: '1rem',
                                height: '30vh',
                                overFlowY: 'scroll',
                                paddingX: '1rem'
                            }}
                            className="scroll"
                        >
                            <CardContent>
                                <Grid container sm={12} display="flex" aligItems="center" justifyContent="end">
                                    <Grid item sm={1} display="flex" aligItems="center" justifyContent="end">
                                        <Badge
                                            color="primary"
                                            badgeContent={'X'}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                handleClose(false);
                                            }}
                                            sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                                        ></Badge>
                                    </Grid>
                                </Grid>
                                <Grid container display="flex" aligItems="center" justifyContent="cetner" sm={12} sx={{ marginY: '4rem' }}>
                                    <Grid item display="flex" aligItems="center" justifyContent="cetner">
                                        <Typography variant="h5">Are You Sure, You Want To Delete This ? </Typography>
                                    </Grid>
                                </Grid>
                                {!processing ? (
                                    <Grid container sm={12} spacing={2}>
                                        <Grid item sm={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    handleSubmit(setProcessing, null, department.department_id, operation);
                                                }}
                                            >
                                                <Typography variant="h6">Proceed</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setOpenDeleteModal(false);
                                                }}
                                                sx={{
                                                    backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                                    color: '#000000'
                                                }}
                                            >
                                                <Typography variant="h6">Cancel</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container sm={12} spacing={2}>
                                        <Grid item sm={12}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className="cursor-pointer"
                                                sx={{
                                                    backgroundColor: '#FC5856',
                                                    color: '#FFFFFF',
                                                    pointerEvents: 'none'
                                                }}
                                            >
                                                <Typography variant="h6">PROCESSING</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    ) : (
                        <></>
                    )}
                </Box>
            </Grid>
        </Modal>
    );
};

export default DepartmentDivisionModal;
