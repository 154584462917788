// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
let children = [
    {
        id: 'leaders_voice',
        title: 'Leader Voice',
        type: 'item',
        url: '/leadersvoice',
        breadcrumbs: false
    },
    {
        id: 'news_notice',
        title: 'News & Notice',
        type: 'item',
        url: '/news-events/news',
        breadcrumbs: false
    },
    {
        id: 'policy',
        title: 'Policy & Procedures',
        type: 'item',
        url: '/policy-procedure',
        breadcrumbs: false
    },
    {
        id: 'recognitions',
        title: 'Recognition & Rewards',
        type: 'item',
        url: '/reward-recognition',
        breadcrumbs: false
    }
];

const Organization = {
    id: 'organization',
    title: 'Organization',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default Organization;
