import React from 'react';
import { Skeleton, Stack, Card, Box } from '@mui/material';
const RegisterUserSkeleton = () => {
    return (
        <Card spacing={1} sx={{ p: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: '20px' }}>
                <Skeleton variant="circular" width={100} height={100} />
                <Box mt={2}>
                    <Skeleton variant="rounded" width={210} height={60} />
                </Box>
                <Box mt={2}>
                    <Skeleton variant="rounded" width={210} height={60} />
                </Box>
            </Box>
        </Card>
    );
};

export default RegisterUserSkeleton;
