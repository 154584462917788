import React from 'react';
import Comment from './Comment';
const CommentList = ({ comments, post, model = 'post', setComments, setCommentCount }) => {
    return comments.map((comment) => (
        <Comment
            key={`comment-${comment.comment_id}`}
            comment={comment}
            post={post}
            model={model}
            setComments={setComments}
            setCommentCount={setCommentCount}
        />
    ));
};

export default CommentList;
