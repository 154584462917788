import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'lib/axios';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import { Typography } from '@mui/material';

const ReactSummary = ({ reactSummary }) => {
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const total = reactSummary.reduce((acc, reaction) => acc + parseInt(reaction.count), 0);
        setTotalCount(total);
    }, [reactSummary]);
    const findReact = (id) => {
        switch (id) {
            case 1:
                return Like;
            case 2:
                return Love;
            case 3:
                return Haha;
            case 4:
                return Wow;
            case 5:
                return Sad;
            case 6:
                return Angry;
            default:
                return null;
        }
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            {reactSummary.map((react, index) => {
                return (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                src={findReact(react.react_id)}
                                style={{ width: '15px', height: '15px', objectFit: 'contain', marginLeft: '-5px' }}
                                alt="react"
                            />
                        </Box>
                    </Box>
                );
            })}
            <Typography sx={{ fontSize: '13px', mx: '3px' }}>{totalCount}</Typography>
        </Box>
    );
};

export default ReactSummary;
