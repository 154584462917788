import React, { useEffect, useState, useRef } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Avatar from '../../assets/images/users/avatar-1.png';
import CompanyIcon from '../../assets/static_images/company.svg';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VoiceViewer from 'components/modals/VoiceViewer';
import { Box } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import RightSideSkeleton from 'components/skeleton/RightSideSkeleton';
import { socket } from 'socket';
import ViewPolicyModal from './ViewPolicyModal';
import MiniPolicyBody from './MiniPolicyBody';

const TopPolicies = () => {
    const [oepnViewer, setOpenViewer] = useState(false);
    const [selected, setSelected] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [policies, setPolicies] = useState([]);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        const formData = new FormData();
        formData.append('company_id', JSON.parse(window.localStorage.getItem('authUser'))?.company_id);
        formData.append('offset', offset);
        formData.append('limit', limit);
        setIsLoading(true);
        axios
            .post('/policies/most-liked', formData)
            .then(({ data }) => {
                setIsLoading(false);
                if (data.policy.length > 0) {
                    setPolicies((prev) => [...prev, ...data.policy]);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }, [offset, limit]);

    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('policy_added', (policy) => {
            if (policy) {
                setPolicies((prev) => [policy, ...prev]);
            }
        });

        socket.on('policy_removed', (policy) => {
            if (policy) {
                setPolicies((prev) => prev.filter((item) => item.policy_id !== policy.policy_id));
            }
        });
    }
    return (
        <>
            <Grid sm={12} container>
                <Grid item sm={12} display="flex" alignItems="center" sx={{ paddingX: '0px' }}>
                    <Box
                        sx={{
                            borderRadius: '10px',
                            width: '100%',
                            height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - 0.5rem)' },
                            overflowY: 'scroll',
                            border: '1px solid #1E7C8840',
                            backgroundColor: '#fff'
                        }}
                    >
                        <CardContent sx={{ paddingX: '0px' }}>
                            <Grid
                                container
                                sm={12}
                                display="flex"
                                alignItems="center"
                                spacing={1}
                                sx={{ borderBottom: '1px solid #1E7C8840', padding: '0 1rem 0.5rem' }}
                            >
                                <Grid item display="flex" alignItems="center" justifyContent="center">
                                    <img src={CompanyIcon} alt="icon" />
                                </Grid>
                                <Grid item>
                                    <Typography color="primary">Top liked policies</Typography>
                                </Grid>
                            </Grid>
                            <Box marginY="1rem">
                                {isLoading ? (
                                    <>
                                        {new Array(10).fill('loading').map((_, index) => (
                                            <RightSideSkeleton key={index} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {policies?.map((item, index) => {
                                            return <MiniPolicyBody item={item} key={`pst-${index}`} />;
                                        })}
                                    </>
                                )}

                                {policies.length > 5 ? (
                                    <Box display="flex" justifyContent="center" mb={2}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: '50px' }}
                                            onClick={() => {
                                                console.log('first');
                                                setPolicies([]);
                                                setOffset(0);
                                            }}
                                        >
                                            View Less
                                        </Button>
                                    </Box>
                                ) : (
                                    policies.length === 5 && (
                                        <Box display="flex" justifyContent="center" mb={2}>
                                            <Button
                                                variant="contained"
                                                sx={{ borderRadius: '50px' }}
                                                onClick={() => {
                                                    setOffset((prev) => prev + limit);
                                                }}
                                            >
                                                View More
                                            </Button>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TopPolicies;
