import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //
import HasPermission from 'utils/HasPermission';
const ProfileTab = ({ handleLogout }) => {
    const theme = useTheme();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [canSee, setCanSee] = useState(false);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    useEffect(() => {
        HasPermission(setCanSee, 'User', 'create', 'create_user');
    }, []);
    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            {canSee && (
                <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                        location.replace('/dashboard');
                    }}
                >
                    <ListItemIcon>
                        <EditOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            )}

            <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => {
                    location.replace('/profile/null');
                }}
            >
                <ListItemIcon>
                    <UserOutlined />
                </ListItemIcon>
                <ListItemText primary="View Profile" />
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
