import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, Button } from '@mui/material';
import { Card, Modal } from '@mui/material';
import MeetingDetails from 'components/modals/MeetingDetails';

const MeetingByDate = ({ meeting }) => {
    const [openModal, setOpenModal] = useState(false);
    const [timeLeft, setTimeLeft] = useState(true);
    const [heighLiter, setHeighLiter] = useState();
    const [closeMeeting, setCloseMeeting] = useState(false);

    const [dateTime, setDateTime] = useState(() => {
        const date = `${new Date(meeting.meeting_date).toString().split(' ')[1]} ${new Date(meeting.meeting_date).toString().split(' ')[2]}
        `;
        let hours = new Date(meeting.meeting_date).getHours();
        let minutes = new Date(meeting.meeting_date).getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return `${date} ${strTime}`;
    });
    function calctime() {
        const meeting_date = new Date(meeting.meeting_date); // Example date 1
        const current = new Date(); // Example date 2
        // Step 2: Convert the date objects to their millisecond representations
        const time1 = current.getTime();
        const time2 = meeting_date.getTime();
        // Step 3: Subtract one millisecond value from the other to get the difference in milliseconds
        const timeDifference = time2 - time1;
        // Step 4: Convert the difference in milliseconds to the desired unit
        // Milliseconds to seconds
        const differenceInSeconds = timeDifference / 1000;
        // Milliseconds to minutes
        const differenceInMinutes = timeDifference / (1000 * 60);
        // Milliseconds to hours
        const differenceInHours = timeDifference / (1000 * 60 * 60);
        // Milliseconds to days
        const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);
        if (differenceInDays > 0 || differenceInHours > 0 || differenceInMinutes > 0) {
            setTimeLeft(true);
            setHeighLiter('#1E7C88');
        } else {
            if (meeting.is_closed) {
                setHeighLiter('#0FC66A');
            } else {
                setHeighLiter('#FC5856');
            }
            setTimeLeft(false);
        }
    }
    useEffect(() => {
        setInterval(() => {
            if (timeLeft) {
                calctime();
            }
        }, 1000);
    }, []);
    return (
        <>
            <Card
                sx={{
                    borderBottom: '1px solid #EAE8E8',
                    p: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                    m: '1px',
                    mt: '10px'
                }}
            >
                {/* profile na name */}
                <Box display="flex" alignItems="center" gap="10px">
                    <Avatar src={meeting?.user?.profile_image} alt={meeting?.user?.first_name} />
                    <Box>
                        <Typography variant="h6">
                            {meeting?.user?.first_name} {meeting?.user?.last_name}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', opacity: '70%' }}>{meeting?.user?.role.role_type}</Typography>
                    </Box>
                </Box>
                {/* title */}
                <Box mt={2}>
                    <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '18px' }}>
                        {meeting?.title}
                    </Typography>
                    <Typography variant="p" sx={{ fontSize: '15px', opacity: '70%' }}>
                        {meeting?.agenda}
                    </Typography>
                </Box>
                {/* date and time */}
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" sx={{ fontSize: '18px', color: '#1E7C88', borderRight: '1px solid #EAE8E8', pr: '10px' }}>
                            {dateTime}
                        </Typography>
                        <Typography variant="h6" sx={{ fontSize: '16px', opacity: '70%', pl: '10px' }}>
                            {meeting?.meeting_duration}
                        </Typography>
                    </Box>
                    <Button onClick={() => setOpenModal(true)} variant="contained" size="small">
                        Details
                    </Button>
                </Box>
            </Card>

            {openModal ? (
                <Modal
                    keepMounted
                    open={openModal}
                    onClose={setOpenModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <MeetingDetails
                        meeting={meeting}
                        setOpenDetails={setOpenModal}
                        heighLiter={heighLiter}
                        closeMeeting={closeMeeting}
                        timeLeft={timeLeft}
                    />
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};

export default MeetingByDate;
