import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';
import BellOn from '../../../assets/static_images/ringbell.svg';
import Belloff from '../../../assets/static_images/silentbell.svg';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from '@mui/material/Badge';

const ChangeNotifications = ({ showSnack }) => {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [onAll, setOnAll] = useState(false);
    useEffect(() => {
        fecthNotificationSetting();
    }, []);

    async function fecthNotificationSetting() {
        await axios
            .get(`/sitesettings/notification`)
            .then(({ data }) => {
                if (data.success) {
                    setList(data.setting.notification_settings);
                }
            })
            .catch((err) => console.log(err));
    }
    async function updateStatus() {
        setProcessing(true);
        const body = onAll
            ? { type: 'notification' }
            : {
                  type: 'notification',
                  key: selected.key,
                  status: selected.active == 0 ? 1 : 0
              };
        await axios
            .post(`/sitesettings/update-settings`, body)
            .then(({ data }) => {
                if (data.success) {
                    setSelected(null);
                    setList(data.setting.notification_settings);
                    setOpenModal(false);
                    setProcessing(false);
                }
                if (showSnack) {
                    showSnack(false, data.message);
                }
            })
            .catch((err) => console.log(err));
    }
    return (
        <Grid sm={12} container sx={{ padding: { md: '2rem', xs: '1rem' }, paddingY: { xs: '2rem' }, width: '100%' }}>
            <Grid sm={12} item sx={{ width: '100%' }}>
                <Grid sm={12} container display="flex" alignItems="center">
                    <Grid item sm={10}>
                        <Typography variant="h5" sx={{ fontWeight: '700' }}>
                            Notification Settings
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            background: 'rgba(30, 124, 136, 0.1)',
                            borderRadius: '2rem',
                            paddingX: '1rem',
                            paddingY: '.5rem',
                            marginLeft: 'auto'
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                            setOnAll(true);
                            setOpenModal(true);
                        }}
                    >
                        <Typography color="primary">Turn on all</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sm={12} item sx={{ width: '100%' }}>
                {list?.length > 0 &&
                    list.map((notification, index) => {
                        return (
                            <Grid
                                sm={12}
                                container
                                display="flex"
                                alignItems="center"
                                key={`${index}-${notification.key}`}
                                sx={{
                                    background: 'rgba(0,0,0,0.03)',
                                    padding: '.5rem',
                                    borderRadius: '.5rem',
                                    marginY: '.5rem',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Grid sm={8.5}>{notification.name}</Grid>
                                <Grid sm={3.5} display="flex" alignItems="center">
                                    {notification.active == 0 ? (
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            onClick={() => {
                                                setSelected(notification);
                                                setOpenModal(true);
                                            }}
                                            sx={{ background: 'rgba(0,0,0,0.04)' }}
                                        >
                                            <Grid sm={12} container display="flex" alignItems="center">
                                                <Grid item sm={2} display="flex" alignItems="center" justifyContent="center">
                                                    <img src={Belloff} alt="belloff" />
                                                </Grid>
                                                <Grid item sm={10} display="flex" alignItems="center" justifyContent="center">
                                                    <Typography color="#000000" sx={{ opacity: '70%' }}>
                                                        {' '}
                                                        Notification off
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            onClick={() => {
                                                setSelected(notification);
                                                setOpenModal(true);
                                            }}
                                        >
                                            <Grid sm={12} container display="flex" alignItems="center" spacing={1}>
                                                <Grid item sm={2} display="flex" alignItems="center" justifyContent="center">
                                                    <img src={BellOn} alt="bellon" />
                                                </Grid>
                                                <Grid item sm={10} display="flex" alignItems="center" justifyContent="center">
                                                    <Typography sx={{ whiteSpace: 'nowrap' }}> Notification on</Typography>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
            </Grid>
            <Grid sm={12} item>
                <Modal
                    keepMounted
                    open={openModal}
                    onClose={setOpenModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
                        <Grid item sx={{ position: 'relative', top: '6rem' }}>
                            <Card
                                sx={{
                                    borderRadius: '10px',
                                    width: { md: '40vw', xs: '90vw' },
                                    marginTop: '1rem',
                                    height: '30vh',
                                    overFlowY: 'scroll',
                                    paddingX: '1rem'
                                }}
                                className="scroll"
                            >
                                <CardContent>
                                    <Grid container sm={12} display="flex" aligItems="center" justifyContent="end">
                                        <Grid item sm={1} display="flex" aligItems="center" justifyContent="end">
                                            <Badge
                                                color="primary"
                                                badgeContent={'X'}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setOpenModal(false);
                                                }}
                                                sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                                            ></Badge>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        display="flex"
                                        aligItems="center"
                                        justifyContent="cetner"
                                        sm={12}
                                        sx={{ marginY: '4rem' }}
                                    >
                                        <Grid item display="flex" aligItems="center" justifyContent="cetner">
                                            {!selected ? (
                                                <Typography variant="h5">Do You Want To On All This Notifications</Typography>
                                            ) : (
                                                <Typography variant="h5">
                                                    {selected?.active == 0
                                                        ? `Do You Want To On This Notification?`
                                                        : `Do You Want To Off This Notification?`}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {!processing ? (
                                        <Grid container sm={12} spacing={2}>
                                            <Grid item sm={6}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        updateStatus();
                                                    }}
                                                >
                                                    <Typography variant="h6">Proceed</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                                        color: '#000000'
                                                    }}
                                                >
                                                    <Typography variant="h6">Cancel</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container sm={12} spacing={2}>
                                            <Grid item sm={12}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    className="cursor-pointer"
                                                    sx={{
                                                        backgroundColor: '#FC5856',
                                                        color: '#FFFFFF',
                                                        pointerEvents: 'none'
                                                    }}
                                                >
                                                    <Typography variant="h6">PROCESSING</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
        </Grid>
    );
};

export default ChangeNotifications;
