import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

// material-ui
import { Box, List, Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import SubNavGroup from './SubNavGroup';
import OrgIcon from '../../../../assets/static_images/organization.svg';
import ProfileIcon from '../../../../assets/static_images/profileicon.svg';
import PeopleIcon from '../../../../assets/static_images/peopleactive.svg';
import peopleEngagementIcon from '../../../../assets/static_images/peopleEngagementIcon.svg';
import analyticsIcon from '../../../../assets/static_images/analyticsIcon.svg';
import Grid from '@mui/material/Unstable_Grid2';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;

    const navCollapse = item.children?.map((menuItem) => {
        switch (menuItem.type) {
            case 'collapse':
                return <SubNavGroup menuItem={menuItem} />;
            case 'item':
                return <NavItem key={menuItem.id} item={menuItem} level={1} />;
            default:
                return (
                    <Typography key={menuItem.id} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Items
                    </Typography>
                );
        }
    });
    const Icon = item.icon;
    return (
        <List>
            <ListItemButton
                sx={{
                    zIndex: 0,
                    pl: 1.5,
                    '&.Mui-selected': {
                        bgcolor: '#FFFFFF',
                        color: 'rgba(0, 0, 0, 0.87)',
                        '&:hover': {
                            color: 'primary',
                            bgcolor: 'primary.main'
                        }
                    }
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: 'primary.main',
                        borderRadius: 1.5,
                        width: 46,
                        height: 46,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {item.id == 'peopletab' && (
                        <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1.5rem', width: '1.5rem' }}>
                            <img src={PeopleIcon} className="img-fit" alt="orgicon" />
                        </Grid>
                    )}
                    {item.id == 'organization' && (
                        <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1.5rem', width: '1.5rem' }}>
                            <img src={OrgIcon} className="img-fit" alt="orgicon" />{' '}
                        </Grid>
                    )}
                    {item.id == 'people_engagement' && (
                        <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1.5rem', width: '1.5rem' }}>
                            <img src={peopleEngagementIcon} className="img-fit" alt="orgicon" />{' '}
                        </Grid>
                    )}
                    {item.id == 'analyticsTab' && (
                        <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1.5rem', width: '1.5rem' }}>
                            <img src={analyticsIcon} className="img-fit" alt="orgicon" />
                        </Grid>
                    )}
                    {item.id == 'profiletab' && (
                        <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1.5rem', width: '1.5rem' }}>
                            <img src={ProfileIcon} className="img-fit" alt="orgicon" />
                        </Grid>
                    )}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="h6" sx={{ color: 'primary.main' }}>
                            {item.title}
                        </Typography>
                    }
                />
            </ListItemButton>
            {navCollapse}
        </List>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
