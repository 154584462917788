/* eslint-disable prettier/prettier */
const Locations = {
    id: 'locations',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'locations',
            title: 'Locations',
            type: 'item',
            url: '/dashboard/locations',
            breadcrumbs:false
            // target: true //in order to open in new tab
        }
    ]
};

export default Locations;
 