import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';
import UpIcon from '../../../../../assets/static_images/up.svg';
import DownIcon from '../../../../../assets/static_images/down.svg';
import overviewIcon from '../../../../../assets/static_images/overview.svg';
import organizationIcon from '../../../../../assets/static_images/oranizationWhite.svg';
import menuLocationIcon from '../../../../../assets/static_images/menuLocation.svg';
import menuStatusIcon from '../../../../../assets/static_images/menuStatus.svg';
import permissionLevelIcon from '../../../../../assets/static_images/permissionLevel.svg';
import analyticsIcon from '../../../../../assets/static_images/analytics.svg';
import userIcon from '../../../../../assets/static_images/userWhite.svg';
import Grid from '@mui/material/Unstable_Grid2';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const SubNavItem = ({ item, level, collapse, setCollapse }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    let drawerOpen = true;
    let openItem = [];
    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        setCollapse((entry) => {
            return !entry;
        });
        dispatch(activeItem({ openItem: [id] }));
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
        // eslint-disable-next-line
    }, []);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => itemHandler(item.id)}
            selected={false}
            sx={{
                zIndex: 0,
                // pl: drawerOpen ? `${level * 28}px` : 1.5,
                mb: 1,
                mx: 2,
                px: 0,
                borderBottom: '2px solid rgba(0, 0, 0, 0.20)',
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.main',
                        color: '#FFFFFF',
                        borderLeft: `3px solid ${theme.palette.primary}`
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.main',
                        color: '#FFFFFF',
                        borderLeft: `3px solid ${theme.palette.primary}`
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <Grid container lg={12} sm={12} display="flex" justifyContent="between" alignItems="center">
                    <Grid item display="flex" alignItems="center" sm={10.5}>
                        {item.id == 'overview' && (
                            <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1rem', width: '1rem', mr: 1 }}>
                                <img src={overviewIcon} className="img-fit" alt="overviewIcon" />
                            </Grid>
                        )}
                        {item.id == 'users' && (
                            <Grid display="flex" alignItems="center" justifyContent="center" sx={{ height: '1rem', width: '1rem', mr: 1 }}>
                                <img src={userIcon} className="img-fit" alt="overviewIcon" />
                            </Grid>
                        )}
                        {item.id == 'officeLocation' && (
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }}
                            >
                                <img src={menuLocationIcon} className="img-fit" alt="menuLocationIcon" />
                            </Grid>
                        )}
                        {item.id == 'organizationStructure' && (
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }}
                            >
                                <img src={organizationIcon} className="img-fit" alt="overviewIcon" />
                            </Grid>
                        )}
                        {item.id == 'permissionLevel' && (
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }}
                            >
                                <img src={permissionLevelIcon} className="img-fit" alt="overviewIcon" />
                            </Grid>
                        )}
                        {item.id == 'menuStatus' && (
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }}
                            >
                                <img src={menuStatusIcon} className="img-fit" alt="overviewIcon" />
                            </Grid>
                        )}
                        {item.id == 'analytics' && (
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }}
                            >
                                <img src={analyticsIcon} className="img-fit" alt="overviewIcon" />
                            </Grid>
                        )}
                        <ListItemText
                            primary={
                                <Typography variant="h6" sx={{ color: isSelected ? '#FFFFFF' : '#FFFFFF' }}>
                                    {item.title}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            )}
        </ListItemButton>
    );
};

SubNavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default SubNavItem;
