/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
const areaChartOptions = {
    chart: {
        height: 450,
        type: 'line',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            show: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 2
    },
    grid: {
        strokeDashArray: 0
    }
};
const GraphChart = ({ series, categories, custom, height, width, color, type = 'bar' }) => {
    const theme = useTheme();
    const [options, setOptions] = useState(custom ? custom : areaChartOptions);
    useEffect(() => {
        if (categories.length > 0) {
            setOptions((prevState) => ({
                ...prevState,
                colors: [color ? color : theme.palette.primary.main],
                xaxis: {
                    categories: categories
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    }
                },

                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    labels: {
                        colors: 'grey.500'
                    }
                }
            }));
        }
    }, [series]);
    return <Chart options={options} series={series} type={type} width={'100%'} height={height ? height : screen.height / 4} />;
};

export default GraphChart;
