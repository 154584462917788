import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    status: {
        danger: '#e53e3e'
    },
    palette: {
        primary: {
            main: '#1E7C88',
            darker: '#1E7C88'
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff'
        }
    }
});

export default theme;
