/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid, Paper, Button, Modal } from '@mui/material';
import axios from 'lib/axios';
import avatar from '../../assets/images/users/avatar-2.png';
import { styled } from '@mui/material/styles';
import ViewProfileModal from 'components/modals/ViewProfileModal';
import RegisterUserSkeleton from 'components/skeleton/RegisterUserSkeleton';
import FootNote from 'components/FootNote';
import { Avatar, InputAdornment, OutlinedInput, FormControl } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import debouce from 'lodash.debounce';
import { convertDate } from 'Helpers';

const RegisteredUsers = () => {
    const [query, setQuery] = useState();
    const [employees, setEmployees] = useState([]);
    const [viewProfile, setViewProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchEmployees = async () => {
        try {
            setIsLoading(true);
            setEmployees((prev) => {
                prev = [];
                return prev;
            });
            let body = {
                company_id: JSON.parse(window.localStorage.getItem('authUser'))?.company_id,
                employee_type: '',
                name: query
            };
            await axios
                .post('companies/employee-list', body)
                .then(({ data }) => {
                    if (data.success) {
                        setIsLoading(false);
                        setEmployees((prev) => {
                            prev = data.employees;
                            return prev;
                        });
                    }
                })
                .catch((err) => console.log(err));
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };
    function handleChange(e) {
        setQuery(e.target.value);
    }
    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300);
    }, [employees]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });
    useEffect(() => {
        fetchEmployees();
    }, []);
    useEffect(() => {
        fetchEmployees();
    }, [query]);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'center',
        border: '1px solid rgba(30, 124, 136, 0.20)'
    }));

    return (
        <>
            <Box
                sx={{
                    height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - .5rem)' },
                    overflowY: 'scroll',
                    width: '100%'
                }}
            >
                <Grid container sm={12} spacing={2} display="flex" alignItems="center" sx={{ marginBottom: '.6rem' }}>
                    <Grid item md={6} sm={12} display="flex" alignItems="center" sx={{ paddingTop: '.5rem' }}>
                        <FootNote />
                    </Grid>
                    <Grid
                        item
                        lg={2.5}
                        md={3}
                        sm={12}
                        xs={12}
                        sx={{ marginBottom: { xs: '.5rem' }, marginLeft: 'auto' }}
                        display="flex"
                        alignItems="center"
                    >
                        <Grid item display="flex" alignItems="center" sm={12} xs={12}>
                            <FormControl
                                sx={{
                                    width: { xs: '100%' }
                                }}
                            >
                                <OutlinedInput
                                    size="small"
                                    id="header-search"
                                    startAdornment={
                                        <InputAdornment
                                            position="start"
                                            sx={{
                                                mr: -0.5,
                                                color: '#000000',
                                                bgColor: '#FFFFFF',
                                                input: {
                                                    '&::placeholder': {
                                                        textOverflow: 'ellipsis !important',
                                                        color: '#000000'
                                                    }
                                                }
                                            }}
                                        >
                                            <SearchOutlined style={{ color: '#000000' }} />
                                        </InputAdornment>
                                    }
                                    aria-describedby="header-search-text"
                                    inputProps={{
                                        'aria-label': 'weight'
                                    }}
                                    onChange={debouncedResults}
                                    placeholder="Search an user"
                                    variant="outlined"
                                    sx={{ bgcolor: '#FFFFFF', color: '#000000' }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sm={12} spacing={2}>
                    {isLoading && (
                        <>
                            {new Array(6).fill('loading').map((_, index) => (
                                <Grid item xs={12} sm={4}>
                                    <RegisterUserSkeleton key={index} />
                                </Grid>
                            ))}
                        </>
                    )}
                    {!isLoading && employees.length == 0 && (
                        <Grid item sm={12}>
                            <Typography variant="h5" color="error">
                                No User Found ..
                            </Typography>
                        </Grid>
                    )}
                    {employees.length > 0 &&
                        employees.map((employee, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={4}>
                                    <Item>
                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                            <Avatar
                                                src={
                                                    employee.profile_image && employee.profile_image != '' ? employee.profile_image : avatar
                                                }
                                                alt={`${employee.first_name}`}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'cover',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </Grid>
                                        <Box sx={{ mt: '24px' }}>
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)' }}>
                                                {employee.first_name} {employee.last_name}
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px', color: '#1E7C88' }}>{employee.role.role_type}</Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    mt: '12px'
                                                }}
                                            >
                                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', mr: '5px' }}>Joined at</Typography>
                                                {`${convertDate(employee.createdAt)}`}
                                            </Typography>

                                            <Box mt={2}>
                                                <Button
                                                    onClick={() => {
                                                        // const url =
                                                        //     JSON.parse(window.localStorage.getItem('authUser')).user_id == employee.user_id
                                                        //         ? `
                                                        // ${window.location.origin}/profile/${employee.user_id}`
                                                        //         : `${window.location.origin}/view-profile/${employee.user_id}`;
                                                        // window.open(`${url}`, '_blank');
                                                        setProfileData(employee);
                                                        setViewProfile(true);
                                                    }}
                                                    variant="contained"
                                                    sx={{
                                                        background: '#1E7C88',
                                                        color: 'white',
                                                        '&:hover': {
                                                            background: '#1E7C88'
                                                        }
                                                    }}
                                                >
                                                    View Profile
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Item>
                                </Grid>
                            );
                        })}
                </Grid>
            </Box>
            {viewProfile && (
                <Modal
                    keepMounted
                    open={viewProfile}
                    onClose={setViewProfile}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <ViewProfileModal
                        setViewProfile={setViewProfile}
                        viewProfile={viewProfile}
                        profileData={profileData}
                        convertDate={convertDate}
                    />
                </Modal>
            )}
        </>
    );
};

export default RegisteredUsers;
