/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import Timer from 'components/Timer';
import Badge from '@mui/material/Badge';
import axios from 'lib/axios';

import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { Avatar } from '@mui/material';
import locationIcon from '../../assets/static_images/location.svg';
import ClockIcon from '../../assets/static_images/clock.svg';
import CalenderIcon from '../../assets/static_images/calander.svg';
import usersIcon from '../../assets/static_images/users.svg';
const EventDetails = ({ event, eventUsers, timeColor, setShowDetails, remainingTime, date, submitResponse, response }) => {
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item sx={{ position: 'relative', top: '5rem' }}>
                <Card
                    sx={{
                        borderRadius: '10px',
                        maxWidth: { lg: '100vw' },
                        marginTop: '1rem',
                        maxHeight: '80vh',
                        paddingX: '1rem',
                        mx: '1rem'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Box sx={{ position: 'absolute', right: '20px' }}>
                            <Badge
                                color="primary"
                                badgeContent={'X'}
                                className="cursor-pointer"
                                onClick={() => {
                                    setShowDetails(false);
                                }}
                                sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                            ></Badge>
                        </Box>
                        {/* time date and clock */}
                        <Grid item sm={12}>
                            <Box>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Box sx={{ paddingY: '3rem' }}>
                                        <Timer dateTime={date} />
                                    </Box>
                                </Box>
                                {/* date and time */}
                                <Box display="flex" alignItems="center" gap={2}>
                                    {/* date */}
                                    <Box sx={{ paddingY: '.3rem' }} display="flex" direction="column">
                                        <Box display="flex" alignItems="center" justifyContent="center" mr={0.5}>
                                            <img src={CalenderIcon} style={{ width: '15px', height: '15px' }} alt="avatar" />
                                        </Box>
                                        <Box>
                                            <Typography variant="h6" sx={{ mr: '5px' }}>
                                                Date :
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color={timeColor}>{`${new Date(event.due_data)
                                                .toString()
                                                .substring(3, 16)} `}</Typography>
                                        </Box>
                                    </Box>
                                    {/* time */}
                                    <Box sx={{ paddingY: '.3rem' }} display="flex">
                                        <Box display="flex" alignItems="center" justifyContent="center" mr={0.5}>
                                            <img src={ClockIcon} style={{ width: '15px', height: '15px' }} alt="avatar" />
                                        </Box>
                                        <Box>
                                            <Typography variant="h6" sx={{ mr: '5px' }}>
                                                Time :{' '}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color={timeColor}>
                                                {`${
                                                    parseInt(date.getHours()) > 12
                                                        ? `${parseInt(date.getHours()) - 12 < 10 ? '0' : ''}${
                                                              parseInt(date.getHours()) - 12
                                                          }`
                                                        : date.getHours()
                                                } : ${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {/* venue */}
                        <Grid container sm={12} sx={{ paddingY: '.3rem' }} display="flex" direction="column">
                            <Grid item display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" justifyContent="center" mr={0.5}>
                                    <img src={locationIcon} style={{ width: '15px', height: '15px' }} alt="avatar" />
                                </Box>
                                <Box display="flex" alignItems="center" sx={{ gap: '5px' }}>
                                    <Typography variant="h6">Venue :</Typography>
                                    <Typography color="primary"> {event.venue}</Typography>
                                </Box>
                            </Grid>
                            {/* <Grid item></Grid> */}
                        </Grid>
                        {/* map link */}
                        {event?.venue_locaiton_map_link && (
                            <Grid container sm={12} sx={{ paddingY: '.3rem' }} display="flex" direction="column">
                                <Grid item display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center" justifyContent="center" mr={0.5}>
                                        <img src={locationIcon} style={{ width: '15px', height: '15px' }} alt="avatar" />
                                    </Box>{' '}
                                    <Typography variant="h6">Map Link</Typography>
                                </Grid>
                                <Grid item>
                                    {/* <Typography color="primary">{event?.venue_locaiton_map_link}</Typography> */}
                                    <a
                                        href={event.venue_locaiton_map_link}
                                        target="_blank"
                                        style={{ color: '#1F7C88', textDecoration: 'none' }}
                                    >
                                        {event?.venue_locaiton_map_link}
                                    </a>
                                </Grid>
                            </Grid>
                        )}
                        {/* organizer */}
                        <Grid container sm={12} sx={{ paddingY: '.3rem' }} display="flex" direction="column">
                            <Grid item display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" justifyContent="center" mr={0.5}>
                                    <img src={usersIcon} style={{ width: '15px', height: '15px' }} alt="avatar" />
                                </Box>
                                <Typography variant="h6">Organizer(s)</Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    height: '13rem',
                                    overFlowY: 'scroll'
                                }}
                                className="scroll"
                            >
                                {eventUsers?.length > 0 ? (
                                    eventUsers.map((user, index) => {
                                        if (user.is_organizer) {
                                            return (
                                                <Grid
                                                    key={`organizer-${index}-${user.user_id}`}
                                                    container
                                                    sm={12}
                                                    display="flex"
                                                    alignItems="center"
                                                    spacing={2}
                                                    mt={1}
                                                >
                                                    <Grid item sm={1} display="flex" alignItems="cetner" justifyContent="center">
                                                        {/* <img
                                                            src={avatar1}
                                                            className="img-fit"
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                borderRadius: '50%'
                                                            }}
                                                            alt="avatar"
                                                        /> */}
                                                        <Avatar
                                                            src={user.profile_image}
                                                            style={{ width: '100%', height: '3rem', width: '3rem' }}
                                                            alt={user.first_name}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <Grid container sm={12} display="flex" direction="column">
                                                            <Grid item>
                                                                <Typography variant="h6">{user.first_name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography sx={{ fontSize: '12px', opacity: '70%' }}>
                                                                    {user.role.role_type}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                        {remainingTime > 0 && response == 'maybe' ? (
                            <Grid container sm={12} spacing={2}>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submitResponse('yes');
                                        }}
                                    >
                                        <Typography variant="h6">ATTEND</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submitResponse('no');
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">DECLINE</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container sm={12}>
                                <Grid item sm={12}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setShowDetails(false);
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Close</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default EventDetails;
