import React from 'react';
import { Box, Skeleton } from '@mui/material';

const EventByDateSkeleton = () => {
    return (
        <Box>
            <Skeleton variant="rectangular" width={`100%`} height={20} />
            <Box display="flex" alignItems="center" mt={2}>
                <Skeleton variant="circular" width={40} height={40} />
                <Box display="flex" flexDirection="column" ml={2}>
                    <Skeleton variant="rounded" width={100} height={10} />
                    <Box mt={1}>
                        <Skeleton variant="rounded" width={100} height={10} />
                    </Box>
                </Box>
            </Box>
            <Box mt={2}>
                <Skeleton variant="rectangular" width={`100%`} height={20} />
                <Box my={1}>
                    <Skeleton variant="rectangular" width={`100%`} height={20} />
                </Box>
                <Skeleton variant="rectangular" width={`100%`} height={20} />
            </Box>
        </Box>
    );
};

export default EventByDateSkeleton;
