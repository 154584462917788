/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import parse from 'html-react-parser';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import axios from 'lib/axios';
import Like from '../assets/static_images/like.svg';
import Love from '../assets/static_images/love.svg';
import Haha from '../assets/static_images/haha.svg';
import Wow from '../assets/static_images/wow.svg';
import Sad from '../assets/static_images/sad.svg';
import Angry from '../assets/static_images/angry.svg';
const Reacts = ({ post, reacts, setReactOpen, setReacted, setReactCount, setReactionImg, setReaction, model = 'post', modelValue }) => {
    const [url, setUrl] = useState(model == 'post' ? '/post-reacts' : `/${model}-reacts`);

    const react_to_post = (id) => {
        const formData = new FormData();
        // if (model == 'post') {
        //     formData.append('post_id', post.post_id);
        // }
        // if (model == 'event') {
        //     formData.append('event_id', post.event_id);
        // }
        // if (model == 'policy') {
        //     formData.append('policy_id', post.policy_id);
        // }
        // if (model == 'news') {
        //     formData.append('news_id', post.news_id);
        // }
        // if (model == 'library') {
        //     formData.append('library_id', post.entry_id);
        // }
        // if (model == 'notice') {
        //     formData.append('notice_id', post.notice_id);
        // }
        // if (model == 'reward') {
        //     formData.append('reward_id', post.reward_id);
        // }
        // if (model == 'post-comment') {
        //     formData.append('post_id', modelValue.post_id);
        // }
        // if (model == 'news-comment') {
        //     formData.append('news_id', modelValue.news_id);
        //     formData.append('comment_id', modelValue.comment_id);
        // }

        formData.append(`${model.split('-')[0]}_id`, modelValue[`${model.split('-')[0]}_id`]);
        // setReactViewEndPoint(`/${model}-comment-reacts/list`);

        formData.append('comment_id', modelValue.comment_id);

        formData.append('react_id', id);
        axios.post(`${url}`, formData).then(({ data }) => {
            if (data.success) {
                // setReactCount((count) => count + 1);
                setReacted(true);
                setReactOpen(false);
            }
        });
    };
    return (
        <Card sx={{ borderRadius: '10px', padding: '1rem', width: '100%' }}>
            <Grid container display="flex" direction="row" alignItems="center" spacing={2}>
                {reacts.map((react) => {
                    if (react.react_id == 1) {
                        return (
                            <Grid
                                item
                                key={`react-${react.react_id}`}
                                onClick={() => {
                                    react_to_post(react.react_id);
                                    setReactionImg(Like);
                                    setReaction((prev) => react.react_name);
                                    setReactOpen(false);
                                }}
                            >
                                {' '}
                                <img className="react-img" src={Like} alt={react.react_name} />{' '}
                            </Grid>
                        );
                    } else if (react.react_id == 2) {
                        return (
                            <Grid
                                item
                                key={`react-${react.react_id}`}
                                onClick={() => {
                                    react_to_post(react.react_id);
                                    setReactionImg(Love);
                                    setReaction((prev) => react.react_name);
                                    setReactOpen(false);
                                }}
                            >
                                <img className="react-img" src={Love} alt={react.react_name} />{' '}
                            </Grid>
                        );
                    } else if (react.react_id == 3) {
                        return (
                            <Grid
                                item
                                key={`react-${react.react_id}`}
                                onClick={() => {
                                    react_to_post(react.react_id);
                                    setReactionImg(Haha);
                                    setReaction((prev) => react.react_name);
                                    setReactOpen(false);
                                }}
                            >
                                <img className="react-img" src={Haha} alt={react.react_name} />
                            </Grid>
                        );
                    } else if (react.react_id == 4) {
                        return (
                            <Grid
                                item
                                key={`react-${react.react_id}`}
                                onClick={() => {
                                    react_to_post(react.react_id);
                                    setReactionImg(Wow);
                                    setReaction((prev) => react.react_name);
                                    setReactOpen(false);
                                }}
                            >
                                <img className="react-img" src={Wow} alt={react.react_name} />{' '}
                            </Grid>
                        );
                    } else if (react.react_id == 5) {
                        return (
                            <Grid
                                item
                                key={`react-${react.react_id}`}
                                onClick={() => {
                                    react_to_post(react.react_id);
                                    setReactionImg(Sad);
                                    setReaction((prev) => react.react_name);
                                    setReactOpen(false);
                                }}
                            >
                                <img className="react-img" src={Sad} alt={react.react_name} />
                            </Grid>
                        );
                    } else if (react.react_id == 6) {
                        return (
                            <Grid
                                item
                                key={`react-${react.react_id}`}
                                onClick={() => {
                                    react_to_post(react.react_id);
                                    setReactionImg(Angry);
                                    setReaction((prev) => react.react_name);
                                    setReactOpen(false);
                                }}
                            >
                                <img className="react-img" src={Angry} alt={react.react_name} />
                            </Grid>
                        );
                    }
                })}
            </Grid>
        </Card>
    );
};

export default Reacts;
