/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { InputLabel, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import axios from 'lib/axios';
import VideoCall from 'components/call/VideoCall';
const NotifyCall = ({ isOpen, closeModal, data }) => {
    const [ring, setRing] = useState(isOpen);
    const audioRef = useRef(null);
    const buttonRef = useRef(null);
    const audioElement = new Audio(`${process.env.REACT_APP_BACKEND_URL}/Uploads/phone-ringing.mp3`);
    const [token, setToken] = useState();
    const [roomName, setRoomName] = useState();
    const [openCall, setOpenCall] = useState(false);
    async function fetchToken() {
        await axios
            .post(`/call/get-token`, {
                chat_id: data.chat_id
            })
            .then(({ data }) => {
                if (data.success) {
                    setToken(data.token);
                    fetchChatinfo();
                    audioElement.autoplay = false;
                }
            })
            .catch((err) => console.log(err));
    }
    async function fetchChatinfo() {
        await axios
            .get(`/messenger/chat-info/${data.chat_id}`)
            .then(({ data }) => {
                setRoomName(data.chat.room_id);
                setOpenCall(true);
                callAccepted();
            })
            .catch((err) => console.log(err));
    }
    async function callAccepted() {
        await axios
            .post(`call/call-recived`, {
                message_id: data.message_id
            })
            .then(({ data }) => {
                if (data.success) {
                    console.log(message);
                }
            })
            .catch((err) => console.log(err));
    }
    async function callRejected() {
        await axios
            .post(`call/call-rejected`, {
                message_id: data.message_id
            })
            .then(({ data }) => {
                console.log(data.message);
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        if (ring) {
            audioElement.autoplay = true;
        }

        audioElement.addEventListener('error', (error) => {
            console.error('Error playing audio:', error);
        });
        return () => {
            audioElement.pause();
            audioElement.src = '';
        };
    }, [audioRef]);
    function closeCall() {
        closeModal();
        setToken(null);
    }

    return (
        <Modal
            keepMounted
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                <Box
                    sx={{
                        heigh: '30vh',
                        width: '30vw',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        borderRadius: '15px',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    {' '}
                    <Button
                        ref={buttonRef}
                        onClick={() => {
                            // playAudio();
                            audio.play();
                        }}
                        // sx={{ display: 'none' }}
                    />
                    <audio
                        ref={audioRef}
                        src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/phone-ringing.mp3`}
                        style={{ display: 'none' }}
                    />
                    {openCall && token ? (
                        <VideoCall token={token} roomName={roomName} onClose={closeCall} />
                    ) : (
                        <Card sx={{ padding: '1.5rem', margin: '1.5rem' }}>
                            <Stack>
                                <Badge
                                    color="secondary"
                                    badgeContent={'X'}
                                    showZero
                                    onClick={() => {
                                        closeModal();
                                    }}
                                ></Badge>
                            </Stack>
                            <Grid container xs={12} md={12} spacing={2} display="flex" alignItems="center" sx={{ padding: '.6rem' }}>
                                {data ? (
                                    <Grid item sm={12}>
                                        <Grid
                                            container
                                            sm={12}
                                            display="flex"
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                sm={12}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ padding: '1rem' }}
                                            >
                                                <img src={`${data.user?.profile_image}`} className="img-fit round" alt="avatar" />
                                            </Grid>
                                            <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                <Typography variant="h5">
                                                    {data.user.first_name} {data.user.last_name} is calling...{' '}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={async () => {
                                            setRing(false);
                                            await fetchToken();
                                        }}
                                    >
                                        ACCEPT
                                    </Button>
                                </Grid>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        sx={{ background: '#FC5856', color: '#FFFFFF' }}
                                        onClick={() => {
                                            closeModal();
                                            callRejected();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    )}
                </Box>
            </Grid>
        </Modal>
    );
};

export default NotifyCall;
