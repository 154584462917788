import React, { useEffect, useState } from 'react';
import GraphChart from 'components/GraphChart';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Card,
    Select,
    FormControl,
    MenuItem,
    InputLabel
} from '@mui/material';
import axios from 'lib/axios';

const Graphs = ({ yAxisUser = null, yAxisSuccessPost, yAxisLeaderPost, yAxisNews, yAxisTask, yAxisEvent, yAxisMeeting, yAxisProject }) => {
    const [xAxisSeries, setXAxisSeries] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            if (i % 5 == 0) {
                arr.push(i.toString());
            } else {
                arr.push('');
            }
        }
        return arr;
    });
    useEffect(() => {
        console.log('reload', yAxisUser);
    }, [yAxisUser, yAxisSuccessPost, yAxisLeaderPost, yAxisNews, yAxisTask, yAxisEvent, yAxisMeeting, yAxisProject]);
    return (
        <Grid container sm={12} xs={12} spacing={2}>
            {yAxisUser && (
                <Grid item sm={4} xs={12}>
                    <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                        <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                            <Grid item sm={12}>
                                <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                    {' '}
                                    New User Joined
                                </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <GraphChart series={yAxisUser} categories={xAxisSeries} height={screen.height / 3} width={'100%'} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            )}

            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                Success Story Posts
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart
                                series={yAxisSuccessPost}
                                categories={xAxisSeries}
                                height={screen.height / 3}
                                width={'100%'}
                                type="line"
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                Leaders Voice Posts
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart series={yAxisLeaderPost} categories={xAxisSeries} height={screen.height / 3} width={'100%'} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                News
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart series={yAxisNews} categories={xAxisSeries} height={screen.height / 3} width={'100%'} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                Tasks Completed
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart series={yAxisTask} categories={xAxisSeries} height={screen.height / 3} width={'100%'} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                Events
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart
                                series={yAxisEvent}
                                categories={xAxisSeries}
                                height={screen.height / 3}
                                width={screen.width / 2.8}
                                type="line"
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                Meetings
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart series={yAxisMeeting} categories={xAxisSeries} height={screen.height / 3} width={'100%'} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Card sx={{ borderRadius: '1rem', border: '1px solid #1e7c88' }}>
                    <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h5" sx={{ fontWeight: '600' }}>
                                {' '}
                                Project Completed
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <GraphChart series={yAxisProject} categories={xAxisSeries} height={screen.height / 3} width={'100%'} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Graphs;
