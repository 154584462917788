/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Avatar from '../../assets/images/users/avatar-1.png';
import CompanyIcon from '../../assets/static_images/company.svg';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VoiceViewer from 'components/modals/VoiceViewer';
import { Box } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import RightSideSkeleton from 'components/skeleton/RightSideSkeleton';
import { processToUpper, timeDiffer } from 'Helpers';
import MiniPostCard from './MiniPostCard';
import { socket } from 'socket';

const TopNews = ({ type }) => {
    const [showMore, setShowMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(5);
    const [news, setNews] = useState([]);
    const [notices, setNotices] = useState([]);

    useEffect(() => {
        const formData = new FormData();
        formData.append('company_id', JSON.parse(window.localStorage.getItem('authUser'))?.company_id);
        formData.append('offset', offset);
        formData.append('limit', limit);
        setIsLoading(true);
        axios
            .post(`/${type}/most-liked`, formData)
            .then(({ data }) => {
                setIsLoading(false);
                if (type === 'news') {
                    if (data.news.length === 0) {
                        setShowMore(false);
                    }
                    setNotices([]);
                    setNews((prev) => [...prev, ...data.news]);
                } else {
                    if (data.notices.length === 0) {
                        setShowMore(false);
                    }
                    setNews([]);
                    setNotices((prev) => [...prev, ...data.notices]);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }, [offset, type]);

    const handleViewMore = () => {
        setOffset((prev) => prev + limit);
    };

    const handleViewLess = () => {
        setOffset(0);
        setNews([]);
        setNotices([]);
    };

    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('news_created', (news) => {
            setNews((prev) => [news, ...prev]);
        });

        socket.on('news_deleted', (news) => {
            console.log('news_deleted', news);
            setNews((prev) => {
                prev = prev
                    .filter((item) => {
                        if (item.news_id != news.news_id) {
                            return item;
                        }
                    })
                    .filter((element) => element !== null && element !== '' && element !== 'null');
                return prev;
            });
        });
    }
    return (
        <Grid sm={12} container>
            <Grid item sm={12} display="flex" alignItems="center" sx={{ paddingX: '0px' }}>
                <Box
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - 0.5rem)' },
                        overflowY: 'scroll',
                        border: '1px solid #1E7C8840',
                        backgroundColor: '#fff'
                    }}
                >
                    <CardContent sx={{ paddingX: '0px' }}>
                        <Grid
                            container
                            sm={12}
                            display="flex"
                            alignItems="center"
                            spacing={1}
                            sx={{ borderBottom: '1px solid #1E7C8840', padding: '0 1rem 0.5rem' }}
                        >
                            <Grid item display="flex" alignItems="center" justifyContent="center">
                                <img src={CompanyIcon} alt="icon" />
                            </Grid>
                            <Grid item>
                                <Typography color="primary">Most Liked {type === 'news' ? 'News' : 'Notice'}</Typography>
                            </Grid>
                        </Grid>
                        <Box marginY="1rem">
                            {isLoading && (
                                <>
                                    {new Array(10).fill('loading').map((_, index) => (
                                        <RightSideSkeleton key={index} />
                                    ))}
                                </>
                            )}
                            {news?.map((item, index) => {
                                return <MiniPostCard key={index} item={item} type={type} />;
                            })}
                            {notices?.map((item, index) => {
                                return <MiniPostCard key={index} item={item} type={type} />;
                            })}
                        </Box>
                    </CardContent>

                    <Box display="flex" justifyContent="center" mb={2}>
                        {news.length > 5 || notices.length > 5 ? (
                            <Button variant="contained" sx={{ borderRadius: '50px' }} onClick={handleViewLess}>
                                View Less
                            </Button>
                        ) : (news.length === limit || notices.length === limit) && showMore ? (
                            <Button variant="contained" sx={{ borderRadius: '50px' }} onClick={handleViewMore}>
                                View More
                            </Button>
                        ) : null}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default TopNews;
