/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WriteIcon from '../../assets/static_images/writeicon.svg';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import EmojiIcon from '../../assets/static_images/emojiicon.svg';
import PhotoIcon from '../../assets/static_images/photoicon.svg';
import PhotoIconActive from '../../assets/static_images/photoisonactive.svg';
import Button from '@mui/material/Button';
import { Link, InputLabel, OutlinedInput, Snackbar } from '@mui/material';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from 'lib/axios';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import avatar1 from '../../assets/images/users/avatar-1.png';
import serchicon from '../../assets/static_images/searchicon.svg';
import FileIcon from '../../assets/static_images/fileicon.svg';
import FileIconActive from '../../assets/static_images/fileiconactive.svg';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import Upload from '../../assets/static_images/uploadpolicyactive.svg';
import { Box } from '@mui/material';
import DbounceSearch from 'components/DbounceSearch';
import debouce from 'lodash.debounce';
import SelectionItem from 'components/SelectionItem';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';
const CreatePolicy = ({ setOpenModal, policy, setPolicies, showSnack, setOpenOption }) => {
    const pdfInput = useRef(null);
    const textInput = useRef(null);
    const dispatch = useDispatch();
    const [openPdfUploader, setOpenPdfUploader] = useState(false);
    const [postPdfs, setPostPdfs] = useState([]);
    const [postPdfNames, setPostPdfNames] = useState([]);
    const [postPdfsData, setPostPdfsData] = useState([]);
    const [eventName, setEventName] = useState(() => {
        return policy != null ? policy.policy_title : '';
    });
    const [text, setText] = useState(() => {
        return policy != null ? policy.plain_text_body : '';
    });
    const [initial, setInitial] = useState(true);
    const [boxHeight, setBoxHeight] = useState('4rem');
    const [posts, setPosts] = useState([]);
    const [userLocations, setUserLocations] = useState([]);
    const theme = useTheme();
    const [openSelectParticipant, setOpenSelectParticipant] = useState(false);
    const [loading, setLoading] = useState(false);

    const [participants, setParticipants] = useState(() => {
        return policy
            ? policy.policy_users.map((user) => {
                  return {
                      id: user.user.user_id,
                      name: user.user.first_name
                  };
              })
            : [];
    });

    const [companyUsers, setCompanyUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [filterActive, setFilterActive] = useState('all');
    const [query, setQuery] = useState('');
    const [existingpostPdfs, setExistingpostPdfs] = useState(policy && policy.video ? policy.video.split(',') : []);
    const [loadSearch, setLoadSearch] = useState(false);
    const [placeHolderText, setPlaceHolderText] = useState('Search For User');
    const [submitUrl, setSubmitUrl] = useState(() => {
        return policy != null ? '/policies/update' : '/policies';
    });
    const [processing, setProcessing] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [departmentDivisions, setDepartmentDivisions] = useState([]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [validatorMessage, setValidatorMessage] = useState([]);
    function handleChange(e) {
        setQuery(e.target.value);
    }
    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300);
    }, []);
    async function fetchUsers() {
        await axios
            .get('/users')
            .then(({ data }) => {
                if (data.success) {
                    setCompanyUsers(
                        data.users.map((item) => {
                            setUserLocations((locations) => [
                                ...locations,
                                { location_id: item.location_id, location_name: item.location.location_name }
                            ]);
                            setUserRoles((roles) => {
                                let found = false;
                                if (roles.length > 0) {
                                    roles.forEach((role) => {
                                        if (role.role_id == item.role_id) {
                                            found = true;
                                            role.count = role.count + 1;
                                        }
                                    });
                                }
                                if (!found) {
                                    return [
                                        ...roles,
                                        {
                                            role_id: item.role_id,
                                            role_name: item.role.role_type,
                                            count: 1
                                        }
                                    ];
                                } else {
                                    return [...roles];
                                }
                            });
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role.role_type
                            };
                        })
                    );
                    setFilteredUsers(
                        data.users.map((item) => {
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role.role_type
                            };
                        })
                    );
                    setFilteredUsers(
                        data.users.map((item) => {
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role.role_type
                            };
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    }

    const fetchDepartments = async () => {
        try {
            await axios.get(`/departments?s=${query}`).then(({ data }) => {
                if (data.success) {
                    setDepartments(() => data.departments);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    const fetchDivisions = async () => {
        try {
            const { data } = await axios.get(`/department-divisions?s=${query}&department_id=&with_dept=true`);

            if (data.success) {
                setDepartmentDivisions(data.departmentDivisions);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onprimary = (primary) => reject(primary);
        });
    async function convertPdf() {
        if (pdfInput.current.files[0].size < 52453290) {
            const file = pdfInput.current.files[0];
            const url = URL.createObjectURL(pdfInput.current.files[0]);
            setPostPdfsData([...postPdfsData, file]);
            setPostPdfs([...postPdfs, url]);
            setPostPdfNames([...postPdfNames, pdfInput.current.files[0].name]);
        } else {
            alert('Size Must Be Smaller Than 50MB');
        }
    }

    function submitEvent() {
        setValidatorMessage([]);
        if (eventName === '') {
            setValidatorMessage([
                {
                    field: 'eventName',
                    message: 'Event name is required'
                }
            ]);
            return;
        }
        if (text === '') {
            setValidatorMessage([
                {
                    field: 'description',
                    message: 'Description is required'
                }
            ]);
            return;
        }
        const formData = new FormData();
        formData.append('title', eventName);
        formData.append('content', text);

        if (policy != null) {
            formData.append('policy_id', policy.policy_id);
        }
        if (participants.length > 0) {
            formData.append('viewers', JSON.stringify(participants));
        }
        if (selectedDepartment.length > 0) {
            formData.append('viewers_department_ids', JSON.stringify(selectedDepartment));
        }
        if (selectedDivisions.length > 0) {
            formData.append('viewers_department_division_ids', JSON.stringify(selectedDivisions));
        }
        // pdfs
        formData.append('pdf_exist', existingpostPdfs.length > 0 ? true : false);
        formData.append('new_pdf_exist', postPdfsData.length > 0 ? true : false);
        formData.append('old_pdfs', JSON.stringify(existingpostPdfs));
        formData.append('post_pdf_names', JSON.stringify(postPdfNames));
        formData.append('post_pdfs', JSON.stringify(postPdfsData));

        postPdfsData.forEach((pdf, index) => {
            formData.append(`docs-${index}`, pdf);
        });
        dispatch(activeLoader(true));
        setProcessing(true);
        axios
            .post(`${submitUrl}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                if (data.success) {
                    dispatch(activeLoader(false));
                    if (showSnack) {
                        showSnack(false, `${data.message}`);
                    }
                    setOpenModal(false);
                    if (setOpenOption) {
                        setOpenOption(false);
                    }
                    setPolicies((policies) => {
                        return [data.policy, ...policies];
                    });
                }
            })
            .catch((err) => {
                dispatch(activeLoader(true));
                console.log(err);
            });
    }

    useEffect(() => {
        setQuery(() => '');
        setLoadSearch((prev) => {
            prev = false;
            return prev;
        });
        setPlaceHolderText((prev) => {
            if (filterActive == 'all') {
                fetchUsers();
                prev = 'Search For User';
            } else if (filterActive == 'department') {
                fetchDepartments();
                prev = 'Search For Department';
            } else if (filterActive == 'division') {
                fetchDivisions();
                prev = 'Search For Division';
            }
            setLoadSearch((prev) => {
                prev = true;
                return prev;
            });
            return prev;
        });
        // if (!metaData) {
        //     setSelectedEntries([]);
        // }
    }, [filterActive]);
    useEffect(() => {
        setLoading(true);
        Promise.all([fetchDepartments(), fetchDivisions(), fetchUsers()]).then(() => {
            setLoading(() => false);
        });
    }, []);
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            {!openSelectParticipant ? (
                <Grid item>
                    <Card
                        sx={{
                            padding: '.5rem',
                            borderRadius: '10px',
                            width: { md: '50vw' },
                            marginTop: '6rem',
                            maxHeight: '80vh',
                            marginX: '20px'
                        }}
                    >
                        <CardContent>
                            <Box container display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                                <Box display="flex" alignItems="center">
                                    <Box mr={1}>
                                        <img src={Upload} alt="edit" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h6" color="primary">
                                            Upload policy & procedure
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <Badge
                                        color="primary"
                                        badgeContent={'X'}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenModal(false);
                                        }}
                                        sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                                    ></Badge>
                                </Box>
                            </Box>
                            <Grid sx={{ height: '61vh' }}>
                                <div className="overflw">
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <Grid container display="flex" direction="column" spacing={1} sm={12}>
                                                <Grid item>
                                                    {' '}
                                                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                                                        Name
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <OutlinedInput
                                                        type="text"
                                                        value={eventName}
                                                        name="password"
                                                        color="primary"
                                                        onChange={(e) => {
                                                            setEventName(e.target.value);
                                                            setValidatorMessage((prev) =>
                                                                prev.filter((item) => item.field !== 'eventName')
                                                            );
                                                        }}
                                                        placeholder="Type Here .."
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {validatorMessage.find((error) => error.field === 'eventName') && (
                                                    <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                                        {validatorMessage.find((error) => error.field === 'eventName').message}
                                                    </Typography>
                                                )}

                                                <Grid item sm={12}>
                                                    <InputLabel>Viewers</InputLabel>
                                                </Grid>
                                                {/* selected participants */}
                                                <Grid
                                                    item
                                                    sm={12}
                                                    onClick={() => {
                                                        setOpenSelectParticipant(true);
                                                    }}
                                                >
                                                    {!participants.length > 0 &&
                                                        !selectedDepartment.length > 0 &&
                                                        !selectedDivisions.length > 0 && (
                                                            <OutlinedInput
                                                                type="text"
                                                                color="primary"
                                                                placeholder="Add team members"
                                                                fullWidth
                                                                readOnly
                                                            />
                                                        )}
                                                    {participants.length > 0 && (
                                                        <OutlinedInput
                                                            type="text"
                                                            color="primary"
                                                            value={participants.map((user) => user.name).join(', ')}
                                                            placeholder="Add team members"
                                                            fullWidth
                                                            readOnly
                                                        />
                                                    )}
                                                    {selectedDepartment.length > 0 && (
                                                        <OutlinedInput
                                                            type="text"
                                                            color="primary"
                                                            value={selectedDepartment.map((user) => user.name).join(', ')}
                                                            placeholder="Add team members"
                                                            fullWidth
                                                            readOnly
                                                        />
                                                    )}
                                                    {selectedDivisions.length > 0 && (
                                                        <OutlinedInput
                                                            type="text"
                                                            color="primary"
                                                            value={selectedDivisions.map((user) => user.name).join(', ')}
                                                            placeholder="Add team members"
                                                            fullWidth
                                                            readOnly
                                                        />
                                                    )}
                                                    {validatorMessage.find((error) => error.field === 'participants') && (
                                                        <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                                            {validatorMessage.find((error) => error.field === 'participants').message}
                                                        </Typography>
                                                    )}
                                                </Grid>

                                                <Grid item sm={12}>
                                                    <InputLabel>Description</InputLabel>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <textarea
                                                        ref={textInput}
                                                        placeholder="Type Here ..."
                                                        onChange={(e) => {
                                                            setText(e.target.value);
                                                            setValidatorMessage((prev) =>
                                                                prev.filter((item) => item.field !== 'description')
                                                            );
                                                        }}
                                                        rows="6"
                                                        cols="100"
                                                        fullWidth
                                                        style={{
                                                            borderRadius: '4px',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.08);',
                                                            padding: '1rem',
                                                            width: '100%',
                                                            fontSize: '1rem'
                                                        }}
                                                        type="text"
                                                        value={text}
                                                    ></textarea>
                                                    {validatorMessage.find((error) => error.field === 'description') && (
                                                        <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                                            {validatorMessage.find((error) => error.field === 'description').message}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {existingpostPdfs.length > 0 ? (
                                        <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                            <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                <Typography variant="h6" color="primary">
                                                    Existing Files
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Grid container spacing={1.5} display="flex">
                                                    <Grid item sm={12}>
                                                        <Grid container spacing={2} display="flex" direction="column">
                                                            {existingpostPdfs.map((video, index) => {
                                                                let source = video.split('/')[1];
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        sm={12}
                                                                        key={`img@${index}`}
                                                                        sx={{ position: 'relative' }}
                                                                        className="img-box"
                                                                    >
                                                                        <Grid container display="flex" alignItems="center" sm={12}>
                                                                            <Grid
                                                                                item
                                                                                sm={2}
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                <img
                                                                                    src={PdfIcon}
                                                                                    width={32}
                                                                                    height={32}
                                                                                    alt="pdficon"
                                                                                ></img>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                sm={10}
                                                                                display="flex"
                                                                                alignItems="center"
                                                                                className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        `${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                            user.user_id
                                                                                        }/Policies/${policy.policy_id.toString()}/${source}`,
                                                                                        '_blank',
                                                                                        'fullscreen=yes'
                                                                                    );
                                                                                    return false;
                                                                                }}
                                                                            >
                                                                                <Typography sx={{ fontSize: '16px' }}>
                                                                                    {video.split('/')[0]}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            item
                                                                            onClick={() => {
                                                                                setExistingpostPdfs((existingpostPdfs) => {
                                                                                    let temp = [...existingpostPdfs];
                                                                                    temp.splice(index, 1);
                                                                                    return temp;
                                                                                });

                                                                                setTimeout(() => {
                                                                                    pdfInput.current.focus();
                                                                                }, 0.5);
                                                                            }}
                                                                            display="flex"
                                                                            direction="column"
                                                                            justifyContent="start"
                                                                            alignItems="start"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                right: '5px',
                                                                                opacity: '0'
                                                                            }}
                                                                            className="trash cursor-pointer"
                                                                        >
                                                                            <Grid>
                                                                                {' '}
                                                                                <Badge
                                                                                    color="primary"
                                                                                    badgeContent={'X'}
                                                                                    sx={{ marginLeft: 'auto' }}
                                                                                ></Badge>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                    {/* options bar  */}
                                    {/* <Grid
                                        container
                                        display="flex"
                                        justifyContent="between"
                                        alignItems="cetner"
                                        sx={{ marginTop: '2rem', marginBottom: '4rem' }}
                                    >
                                        <Grid item sm={12} sx={{ paddingX: '1rem' }}>
                                            <Grid container spacing={3} display="flex" alignItems="center" sm={12}>
                                                <Grid
                                                    item
                                                    sm={4}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        setOpenPdfUploader(!openPdfUploader);

                                                        setTimeout(() => {
                                                            pdfInput.current.click();
                                                        }, 0.5);
                                                    }}
                                                >
                                                    {openPdfUploader ? (
                                                        <Grid
                                                            sm={12}
                                                            container
                                                            display="flex"
                                                            alignItems="center"
                                                            sx={{
                                                                backgroundColor: `${theme.palette.primary.lighter}`,
                                                                borderRadius: '10px'
                                                            }}
                                                        >
                                                            <Grid item sm={1}>
                                                                <img src={FileIconActive} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                            </Grid>
                                                            <Grid item sm={10}>
                                                                <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                                    Add attachment/file
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <Grid
                                                            sm={12}
                                                            container
                                                            display="flex"
                                                            alignItems="center"
                                                            sx={{ borderRadius: '10px' }}
                                                        >
                                                            <Grid item sm={1}>
                                                                <img src={FileIcon} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                            </Grid>
                                                            <Grid item sm={10}>
                                                                <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>
                                                                    Add attachment/file
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}

                                    {/* {openPdfUploader ? ( */}
                                    <>
                                        <>
                                            <Typography>Attach File</Typography>
                                            {postPdfs.length > 0 ? (
                                                <Grid container sx={{}}>
                                                    <Grid item sm={12}>
                                                        <Grid container spacing={2} display="flex" direction="column">
                                                            {postPdfs.map((pdf, index) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        sm={12}
                                                                        key={`img@${index}`}
                                                                        sx={{ position: 'relative' }}
                                                                        className="img-box"
                                                                        mb={1}
                                                                    >
                                                                        <Grid container display="flex" alignItems="center" sm={12}>
                                                                            <Grid
                                                                                item
                                                                                sm={2}
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                <img
                                                                                    src={PdfIcon}
                                                                                    width={20}
                                                                                    height={20}
                                                                                    alt="pdficon"
                                                                                ></img>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                sm={10}
                                                                                display="flex"
                                                                                alignItems="center"
                                                                                className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    window.open(pdf, '_blank', 'fullscreen=yes');
                                                                                    return false;
                                                                                }}
                                                                            >
                                                                                <Typography sx={{ fontSize: '16px' }}>
                                                                                    {postPdfNames[index]}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            item
                                                                            onClick={() => {
                                                                                setPostPdfs((postPdfs) => {
                                                                                    let temp = [...postPdfs];
                                                                                    temp.splice(index, 1);
                                                                                    return temp;
                                                                                });
                                                                                setPostPdfNames((postPdfNames) => {
                                                                                    let temp = [...postPdfNames];
                                                                                    temp.splice(index, 1);
                                                                                    return temp;
                                                                                });

                                                                                setTimeout(() => {
                                                                                    pdfInput.current.focus();
                                                                                }, 0.5);
                                                                            }}
                                                                            display="flex"
                                                                            direction="column"
                                                                            justifyContent="start"
                                                                            alignItems="start"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                right: '15px',
                                                                                opacity: '0'
                                                                            }}
                                                                            className="trash cursor-pointer"
                                                                        >
                                                                            <Grid>
                                                                                {' '}
                                                                                <Badge color="primary" badgeContent={'X'} sx={{}}></Badge>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    container
                                                    sm={12}
                                                    display="flex"
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    className="dashed"
                                                    sx={{
                                                        padding: '3rem',
                                                        borderRadius: '10px',
                                                        backgroundColor: `${theme.palette.primary.lighter}`
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        sm={12}
                                                        className="cursor-pointer"
                                                        onClick={() => pdfInput.current.click()}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            display="flex"
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sm={12}
                                                            sx={{ paddingY: '2rem' }}
                                                        >
                                                            <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                                {' '}
                                                                <img src={FileIcon} alt="logo" width={24} height={24} />
                                                            </Grid>
                                                            <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                                <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                    {' '}
                                                                    <a href="#" sx={{ color: 'primary' }}>
                                                                        Upload a file
                                                                    </a>{' '}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {postPdfs.length > 0 ? (
                                                <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                    <Button
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        sx={{ backgroundColor: 'primary' }}
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            pdfInput.current.click();
                                                        }}
                                                    >
                                                        <Typography variant="h6">+ ADD MORE DOCUMENTS </Typography>
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                        {/* ,.ppt,.pptx,.doc,.docx,.xls */}
                                        <div sx={{ display: 'none' }}>
                                            <input
                                                ref={pdfInput}
                                                type="file"
                                                multiple="true"
                                                accept=".pdf,.doc,.docx"
                                                name="pdfInput"
                                                onChange={convertPdf}
                                                className="hidden"
                                            />
                                        </div>
                                    </>
                                    {/* ) : (
                                        <></>
                                    )} */}
                                </div>
                            </Grid>
                            <Grid container xs={12}>
                                {processing ? (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submitRequest();
                                        }}
                                        sx={{
                                            backgroundColor: '#FC5856',
                                            color: '#FFFFFF',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <Typography variant="h6">PROCESSING</Typography>
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submitEvent();
                                        }}
                                    >
                                        <Typography variant="h6">{policy != null ? 'UPDATE' : 'SUBMIT'}</Typography>
                                    </Button>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}

            {openSelectParticipant ? (
                <Grid item className="collapse">
                    <Card
                        sx={{
                            padding: '.5rem',
                            borderRadius: '10px',
                            width: { md: '50vw' },
                            marginTop: '6rem',
                            height: '80vh',
                            overFlowY: 'scroll'
                        }}
                        className="scroll"
                    >
                        <CardContent>
                            <Grid
                                container
                                display="flex"
                                justifyContent="between"
                                alignItems="center"
                                sx={{ paddingBottom: '1rem', position: 'relative' }}
                                sm={12}
                            >
                                <Grid container sm={12}>
                                    <Grid container xs={12} sm={12} mb={2} sx={{ width: '100%' }}>
                                        <Grid item sm={12} xs={12} sx={{ width: '100%' }}>
                                            {loadSearch && (
                                                <DbounceSearch debouncedResults={debouncedResults} placeHolderText={placeHolderText} />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container display="flex" className="overflow-x" spacing={2}>
                                <Box container display="flex" className="overflow-x">
                                    <Box
                                        item
                                        className={`${
                                            filterActive == 'all' ? 'filter-badge-active  cursor-pointer' : 'filter-badge cursor-pointer'
                                        }`}
                                        onClick={() => {
                                            setParticipants([]);
                                            setSelectedDepartment([]);
                                            setSelectedDivisions([]);
                                            setFilterActive('all');
                                        }}
                                    >
                                        All({companyUsers.length})
                                    </Box>
                                    <Box
                                        className={
                                            filterActive == 'department'
                                                ? 'filter-badge-active  cursor-pointer'
                                                : 'filter-badge cursor-pointer'
                                        }
                                        sx={{
                                            marginX: '.3rem'
                                        }}
                                        onClick={() => {
                                            setParticipants([]);
                                            setSelectedDepartment([]);
                                            setSelectedDivisions([]);
                                            setFilterActive('department');
                                        }}
                                    >
                                        Department
                                    </Box>
                                    <Box
                                        className={
                                            filterActive == 'division'
                                                ? 'filter-badge-active  cursor-pointer'
                                                : 'filter-badge cursor-pointer'
                                        }
                                        sx={{
                                            marginX: '.3rem'
                                        }}
                                        onClick={() => {
                                            setParticipants([]);
                                            setSelectedDepartment([]);
                                            setSelectedDivisions([]);
                                            setFilterActive('division');
                                        }}
                                    >
                                        Division
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                container
                                display="flex"
                                justifyContent="between"
                                alignItems="center"
                                sm={12}
                                mt={2}
                                className="border-bottom"
                            >
                                <Grid item sm={6}>
                                    <Typography variant="h6" color="primary">{`Selected(${participants.length})`}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    display="flex"
                                    justifyContent="end"
                                    onClick={() => {
                                        if (filterActive === 'all') {
                                            setParticipants(companyUsers.map((part) => ({ id: part.user_id, name: part.user_name })));
                                        } else if (filterActive === 'department') {
                                            setSelectedDepartment(
                                                departments.map((part) => ({ id: part.department_id, name: part.department_name }))
                                            );
                                        } else if (filterActive === 'division') {
                                            setSelectedDivisions(
                                                departmentDivisions.map((part) => ({
                                                    id: part.department_division_id,
                                                    name: part.division_name
                                                }))
                                            );
                                        }
                                    }}
                                >
                                    <Typography color="primary" sx={{ cursor: 'pointer' }}>
                                        Select All
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* user selection */}
                            <Box sx={{ overflowY: 'scroll', height: '43vh' }}>
                                {!loading && (
                                    <>
                                        {filterActive == 'all' &&
                                            filteredUsers.length > 0 &&
                                            filteredUsers.map((item, index) => {
                                                return (
                                                    <SelectionItem
                                                        key={`${index}-users`}
                                                        item={item}
                                                        id_key={'user_id'}
                                                        name={'user_name'}
                                                        targetedArray={participants}
                                                        setTargetedArray={setParticipants}
                                                        has_avater={true}
                                                    />
                                                );
                                            })}
                                        {departments.length > 0 &&
                                            filterActive == 'department' &&
                                            departments.map((item, index) => {
                                                return (
                                                    <SelectionItem
                                                        key={`${index}-depts`}
                                                        item={item}
                                                        id_key={'department_id'}
                                                        name={'name'}
                                                        targetedArray={selectedDepartment}
                                                        setTargetedArray={setSelectedDepartment}
                                                    />
                                                );
                                            })}
                                        {departmentDivisions?.length > 0 &&
                                            filterActive == 'division' &&
                                            departmentDivisions.map((item, index) => {
                                                return (
                                                    <SelectionItem
                                                        key={`${index}-dept_divis`}
                                                        item={item}
                                                        id_key={'department_division_id'}
                                                        name={'name'}
                                                        targetedArray={selectedDivisions}
                                                        setTargetedArray={setSelectedDivisions}
                                                    />
                                                );
                                            })}
                                    </>
                                )}
                            </Box>

                            <Grid container sm={12} spacing={2} mt={4}>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenSelectParticipant(false);
                                        }}
                                    >
                                        <Typography variant="h6">Select</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setParticipants([]);
                                            setOpenSelectParticipant(false);
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default CreatePolicy;
