// // assets
// import { DashboardOutlined } from '@ant-design/icons';

// // icons
// const icons = {
//     DashboardOutlined
// };

// // ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// const dashboard = {
//     id: 'group-dashboard',
//     title: 'Navigation',
//     type: 'group',
//     children: [
//         {
//             id: 'dashboard',
//             title: 'Dashboard',
//             type: 'item',
//             url: '/dashboard',
//             icon: icons.DashboardOutlined,
//             breadcrumbs: false
//         }
//     ]
// };

// export default dashboard;
// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
const children = [
    {
        id: 'overview',
        title: 'Overview',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
                icon: icons.DashboardOutlined,
                breadcrumbs: false
            },
            {
                id: 'feedback',
                title: 'Feedback',
                type: 'item',
                url: '/dashboard/feedback',
                icon: icons.DashboardOutlined,
                breadcrumbs: false
            }
        ]
    }
];
const Overview = {
    id: 'overview',
    title: 'Overview',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default Overview;
