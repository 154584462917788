/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Card } from '@mui/material';
import threeDot from '../../assets/static_images/threedot.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ChildComment from './ChildComment';
import Blank from '../../assets/static_images/blank.svg';
import Reacts from 'components/Reacts';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import CommentBox from './CommentBox';
const ParentComment = ({ comments, setComments, setCommentCount, setOrderBy, orderBy, type }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {}, [comments]);

    return (
        <Box>
            {/* most relevant menu */}
            <Box>
                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                >
                    {orderBy == 'count' ? ' Most relevant' : ' Most recent'}
                </Button>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button'
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem
                        onClick={() => {
                            setOrderBy((prev) => {
                                prev = 'count';
                                return prev;
                            });
                            setAnchorEl(null);
                        }}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Box display="flex" sx={{ flexDirection: 'column' }}>
                            <Typography variant="h6" sx={{ ml: '5px' }}>
                                Most relevant
                            </Typography>
                            <Typography variant="p" sx={{ ml: '5px', opacity: '60%' }}>
                                See the most relevant comments
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setOrderBy((prev) => {
                                prev = 'created';
                                return prev;
                            });
                            setAnchorEl(null);
                        }}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Box display="flex" sx={{ flexDirection: 'column' }}>
                            <Typography variant="h6" sx={{ ml: '5px' }}>
                                Most recent
                            </Typography>
                            <Typography variant="p" sx={{ ml: '5px', opacity: '60%' }}>
                                See all comments, the most recent comments are first
                            </Typography>
                        </Box>
                    </MenuItem>
                </Menu>
            </Box>
            {/* parent comments */}
            {comments.map((comment, index) => {
                // console.log(comment);
                return (
                    <CommentBox
                        comment={comment}
                        key={index}
                        setCommentCount={setCommentCount}
                        setParentComments={setComments}
                        type={type}
                    />
                );
            })}
        </Box>
    );
};

export default ParentComment;
