import {
    Grid,
    Card,
    OutlinedInput,
    InputAdornment,
    Button,
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    CardContent,
    CircularProgress
} from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';

const DbounceSearch = ({ debouncedResults, placeHolderText = 'Search an user' }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = 'lol';
        }
    }, [placeHolderText]);
    return (
        <Grid item sx={{ width: '100%' }}>
            <OutlinedInput
                size="small"
                id="header-search"
                ref={inputRef}
                startAdornment={
                    <InputAdornment
                        position="start"
                        sx={{
                            mr: -0.5,
                            color: '#000000',
                            bgColor: '#FFFFFF',
                            input: {
                                '&::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: '#000000'
                                }
                            }
                        }}
                    >
                        <SearchOutlined style={{ color: '#000000' }} />
                    </InputAdornment>
                }
                aria-describedby="header-search-text"
                inputProps={{
                    'aria-label': 'weight'
                }}
                onChange={debouncedResults}
                placeholder={placeHolderText}
                variant="outlined"
                sx={{ bgcolor: '#FFFFFF', color: '#000000', width: '100%' }}
            />
        </Grid>
    );
};

export default DbounceSearch;
