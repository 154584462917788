/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, Avatar } from '@mui/material';
import threeDot from '../../assets/static_images/threedot.svg';
import Blank from '../../assets/static_images/blank.svg';
import ChildComment from './ChildComment';
import Reacts from 'components/Reacts';
import ReplayComment from './ReplayComment';
import axios from 'lib/axios';
import EditComment from './EditComment';
import DeleteComment from './DeleteComment';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import ViewReactModal from 'components/modals/ViewReactModal';
import ReactSummary from './ReactSummary';
import { socket } from '../../socket';
import { calculateTimeDifference } from 'Helpers';

const CommentBox = ({ comment, setParentComments, setChildComments, setCommentCount, type }) => {
    const [comments, setComments] = useState(comment.childComments ?? []);
    const [openOption, setOpenOption] = useState(false);
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [openReplayComment, setOpenReplayComment] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [body, setBody] = useState(comment.comment_body);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openViewReactModal, setOpenViewReactModal] = useState(false);
    const [reactSummary, setReactSummary] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [replyCommentId, setReplyCommentId] = useState(0);
    const [reactCreateModel, setReactCreateModel] = useState('');
    const [reactViewEndPoint, setReactViewEndPoint] = useState('');

    const reacts = [
        {
            react_id: 1,
            react_name: 'Like'
        },
        {
            react_id: 2,
            react_name: 'Love'
        },
        {
            react_id: 3,
            react_name: 'HaHa'
        },
        {
            react_id: 4,
            react_name: 'Wow'
        },
        {
            react_id: 5,
            react_name: 'Sad'
        },
        {
            react_id: 6,
            react_name: 'Angry'
        }
    ];

    useEffect(() => {
        const key = `${type}_id`;
        setReplyCommentId(comment[key]);
        setReactViewEndPoint(`/${type}-comment-reacts/list`);
    }, [type]);

    const fetchReactSummary = async () => {
        try {
            setIsLoading(true);
            let requestBody = {
                comment_id: comment.comment_id
            };
            if (type && type !== '') {
                const key = `${type}_id`;
                requestBody[key] = comment[key];
            }
            const res = await axios.post(`/${type}-comment-reacts/mini-statement`, requestBody);
            if (res.data.success) {
                setIsLoading(false);
                setReactSummary((prev) => {
                    prev = res.data.summary;
                    return prev;
                });
                if (res.data.my_react && res.data.my_react.react) {
                    setReaction((prev) => {
                        prev = res.data.my_react.react.react_name;
                        return prev;
                    });
                    if (res.data.my_react.react.react_id == 1) {
                        setReactionImg(Like);
                    } else if (res.data.my_react.react.react_id == 2) {
                        setReactionImg(Love);
                    } else if (res.data.my_react.react.react_id == 3) {
                        setReactionImg(Haha);
                    } else if (res.data.my_react.react.react_id == 4) {
                        setReactionImg(Wow);
                    } else if (res.data.my_react.react.react_id == 5) {
                        setReactionImg(Sad);
                    } else if (res.data.my_react.react.react_id == 6) {
                        setReactionImg(Angry);
                    }
                    setReacted(true);
                }
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };
    // useEffect(() => {
    //     fetchReactSummary();
    // }, [reaction]);
    useEffect(() => {
        fetchReactSummary();
    }, [comment]);
    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on(`${type === 'post' ? 'comment_react_added' : `${type}_comment_react_added`}`, (comment_react) => {
            if (comment_react?.comment_id == comment?.comment_id) {
                fetchReactSummary();
            }
        });
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: '10px', mt: '15px' }}>
                <Box>
                    <Avatar
                        style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                        src={comment.user?.profile_image}
                        alt="profile"
                    />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ background: '#F2F2F2', px: 2, py: 1, width: '100%', borderRadius: '0px 10px 10px 10px' }}>
                        <Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">
                                    {comment.user.first_name} {comment.user.last_name}
                                </Typography>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography sx={{ fontSize: '12px' }}>{calculateTimeDifference(comment.createdAt)}</Typography>
                                    <Box
                                        onClick={() => setOpenOption(!openOption)}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ position: 'relative', cursor: 'pointer' }}
                                    >
                                        {openOption && (
                                            <Grid
                                                item
                                                sx={{
                                                    zIndex: '1024',
                                                    position: 'absolute',
                                                    top: '1.5rem',
                                                    right: '0rem',
                                                    width: '6rem',
                                                    height: '10rem'
                                                }}
                                            >
                                                <Card sx={{ borderRadius: '10px', width: '100%' }}>
                                                    <Grid container display="flex" direction="column" justifyContent="center">
                                                        <Grid
                                                            item
                                                            display="flex"
                                                            justifyContent="center"
                                                            sx={{ borderBottom: '1px solid #000000' }}
                                                            onClick={() => {
                                                                setEditorOpen(true);
                                                            }}
                                                            className="cursor-pointer"
                                                        >
                                                            <Typography>Edit</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                setOpenDeleteModal(true);
                                                            }}
                                                        >
                                                            <Typography>Delete</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        )}
                                        <img style={{ width: '20px', height: '20px', objectFit: 'cover' }} src={threeDot} alt="profile" />
                                    </Box>
                                </Box>
                            </Box>
                            {/* comment */}
                            <Box>
                                {editorOpen ? (
                                    <EditComment
                                        setBody={setBody}
                                        setEditorOpen={setEditorOpen}
                                        body={comment.comment_body}
                                        commentId={comment.comment_id}
                                        model={type}
                                        setParentComments={setParentComments}
                                    />
                                ) : (
                                    <Typography variant="p">{comment.comment_body}</Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1} sx={{ position: 'relative' }}>
                        <Box sx={{ borderRight: '1px solid black', display: 'flex', alignItems: 'center' }}>
                            <Box
                                variant="p"
                                onClick={() => {
                                    setReactOpen(!reactOpen);
                                }}
                                sx={{
                                    pr: '10px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    userSelect: 'none'
                                }}
                            >
                                {reacted ? (
                                    <Box display="flex" alignItems="center" gap="0.5rem">
                                        <Box>
                                            <img
                                                src={reactionImg}
                                                alt="icn"
                                                style={{
                                                    height: '1rem',
                                                    width: '1rem'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ opacity: '0.8', fontSize: '0.85rem' }}>{reaction}</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box display="flex" alignItems="center" gap="0.5rem">
                                        <Box>
                                            <img
                                                src={Blank}
                                                alt="icn"
                                                style={{
                                                    height: '1rem',
                                                    width: '1rem'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '0.85rem' }}>Like</Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            {reactSummary.length > 0 && (
                                <>
                                    {isLoading ? (
                                        <Box
                                            sx={{ width: '15px', height: '15px', borderRadius: '50%', background: 'lightgray', mr: '3px' }}
                                        ></Box>
                                    ) : (
                                        <Box
                                            onClick={() => {
                                                setOpenViewReactModal(true);
                                            }}
                                        >
                                            <ReactSummary reactSummary={reactSummary} />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>

                        {reactOpen && (
                            <Grid
                                item
                                sx={{
                                    zIndex: '1024',
                                    position: 'absolute',
                                    top: '-4rem',
                                    left: '1.5rem',
                                    width: '23rem'
                                }}
                            >
                                <Reacts
                                    reacts={reacts}
                                    setReactOpen={setReactOpen}
                                    setReacted={setReacted}
                                    setReactionImg={setReactionImg}
                                    setReaction={setReaction}
                                    model={`${type}-comment`}
                                    modelValue={comment}
                                />
                            </Grid>
                        )}
                        <Typography
                            onClick={() => setOpenReplayComment(!openReplayComment)}
                            variant="p"
                            sx={{ pl: '10px', fontSize: '12px', cursor: 'pointer', userSelect: 'none' }}
                        >
                            Reply
                        </Typography>
                    </Box>

                    {openReplayComment && (
                        <Box mt={2}>
                            <ReplayComment
                                postId={replyCommentId}
                                parentId={comment.comment_id}
                                setOpenReplayComment={setOpenReplayComment}
                                setComments={setComments}
                                commentType="level-2"
                                type={type}
                            />
                        </Box>
                    )}
                    {/* child comment */}
                    {comments.length > 0 && <ChildComment childComments={comments} setParentComments={setComments} type={type} />}
                </Box>
            </Box>
            {openDeleteModal && (
                <DeleteComment
                    setCommentCount={setCommentCount}
                    commentId={comment.comment_id}
                    setStates={setParentComments}
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    type={type}
                />
            )}
            {openViewReactModal && (
                <ViewReactModal
                    post={comment}
                    setOpenModal={setOpenViewReactModal}
                    endpoint={reactViewEndPoint}
                    type={type}
                    openModal={openViewReactModal}
                />
            )}
        </>
    );
};

export default CommentBox;
