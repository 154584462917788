import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DatePicker from 'react-multi-date-picker';
import Calander from 'components/Calander';
import dayjs from 'dayjs';
import UpcomingList from 'components/UpcomingList';
import { Box } from '@mui/material';
import RightSideSkeleton from 'components/skeleton/RightSideSkeleton';
import { useSelector } from 'react-redux';
import { socket } from 'socket';

const Side = () => {
    const [upcoming, setUpcoming] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [requestLoaded, setRequestLoaded] = useState(false);
    const [links, setLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [requestMonth, setRequestMonth] = useState(() => {
        return new Date().getMonth();
    });
    const { meetingIsFormal } = useSelector((state) => state.frontMenu);

    function calculateUpcoming() {
        setUpcoming((prev) => {
            prev = [
                ...meetings.map((meeting) => {
                    setLinks((links) => {
                        return [...links, `${window.location.origin}/single-view/type=meeting&model_id=${meeting.meeting_id}&comment_id=0`];
                    });
                    return new Date(meeting.meeting_date).getDate();
                })
            ];
            return prev;
        });
    }
    async function fetchmeetings(date) {
        setMeetings([]);
        setUpcoming([]);
        setIsLoading(true);
        setRequestLoaded(false);

        await axios
            .post(`/meetings/upcoming`, {
                month: date ? date : requestMonth,
                is_formal: meetingIsFormal,
                limit: limit,
                offset: offset
            })
            .then(({ data }) => {
                if (data.success) {
                    setIsLoading(false);
                    setMeetings(() => data.meetings);
                    calculateUpcoming();
                    setRequestLoaded(true);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }
    async function fetchCalanderDate(date) {
        setUpcoming([]);
        setRequestLoaded(false);
        await axios
            .post(`/meetings/upcoming`, {
                month: date ? date : requestMonth
            })
            .then(({ data }) => {
                if (data.success) {
                    setIsLoading(false);
                    calculateUpcoming();
                    setRequestLoaded(true);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }
    async function updateHeighlitedDays(setHighlightedDays, month, setIsLoading) {
        setIsLoading(true);
        setLinks([]);
        await axios
            .post(`/meetings/calendar-upcoming`, {
                month: month
            })
            .then(({ data }) => {
                if (data.success) {
                    setHighlightedDays([
                        ...data.meetings.map((meeting) => {
                            setLinks((links) => {
                                return [
                                    ...links,
                                    `${window.location.origin}/single-view/type=meeting&model_id=${meeting.event_id}&comment_id=0`
                                ];
                            });
                            return new Date(meeting.meeting_date).getDate();
                        })
                    ]);
                    setIsLoading(false);
                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchCalanderDate();
    }, []);
    useEffect(() => {
        fetchmeetings();
    }, [meetingIsFormal]);

    useEffect(() => {
        calculateUpcoming();
    }, [meetings]);

    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('meeting_created', (meeting) => {
            setMeetings((prev) => [meeting, ...prev]);
        });

        socket.on('meeting_deleted', (meeting) => {
            setMeetings((prev) => {
                prev = prev
                    .filter((item) => {
                        if (item.meeting_id != meeting.meeting_id) {
                            return item;
                        }
                    })
                    .filter((element) => element !== null && element !== '' && element !== 'null');
                return prev;
            });
        });
    }
    return (
        <Grid sm={12} container sx={{ padding: '0px' }}>
            <Box
                sx={{
                    padding: '1rem',
                    borderRadius: '10px',
                    width: '100%',
                    height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - 0.5rem)' },
                    overflowY: 'scroll',
                    border: '1px solid #1E7C8840',
                    backgroundColor: '#fff'
                }}
            >
                <Grid item sm={12}>
                    <Grid container sm={12} display="flex" direction="column">
                        <Grid item sm={12}>
                            {/* <DatePicker multiple value={upcoming} /> */}
                            {requestLoaded ? (
                                <Calander
                                    type="meeting"
                                    initialValue={dayjs(new Date())}
                                    upcoming={upcoming}
                                    loading={!requestLoaded}
                                    updateHeighlitedDays={updateHeighlitedDays}
                                    links={links}
                                />
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item sm={12}></Grid>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '-2rem' }}>
                    {isLoading && (
                        <>
                            {new Array(10).fill('loading').map((_, index) => (
                                <RightSideSkeleton key={index} />
                            ))}
                        </>
                    )}
                    <UpcomingList list={meetings} model={'meeting'} setOffset={setOffset} />
                </Box>
            </Box>
        </Grid>
    );
};
export default Side;
