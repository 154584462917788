/* eslint-disable prettier/prettier */
// project import
import Organization from './organization';
import PeopleEngagement from './peopleEngagement';
import Profile from './profile';
import People from './people';
import Analytics from './analytics';
// ==============================|| MENU ITEMS ||============================== //

let menuItems = {};

let items = [People, PeopleEngagement, Organization];
if (JSON.parse(window.localStorage.getItem('authUser')) && JSON.parse(window.localStorage.getItem('authUser')).username) {
    items.push(Profile);
}
items.push(Analytics);
menuItems = { items: items };
export default menuItems;
