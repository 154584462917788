/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import axios from 'lib/axios';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';

const DeleteModal = ({ setOpenDeleteModal, url, id, showSnack, setPosts, model = 'post', reload, setReload }) => {
    const [processing, setProcessing] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        // Function to handle the ESC key press
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setOpenDeleteModal(false);
                // Add your logic here
            }
        };

        // Attach the event listener
        window.addEventListener('keydown', handleEsc);

        // Cleanup the event listener on component unmount
        // return () => {
        //     window.removeEventListener('keydown', handleEsc);
        // };
    }, []);
    function submit() {
        setProcessing(true);
        dispatch(activeLoader(true));
        axios
            .post(`${url}`, { id: id })
            .then(({ data }) => {
                if (data.success) {
                    dispatch(activeLoader(false));
                    if (showSnack) {
                        showSnack(false, `${data.message}`);
                    }

                    if (setPosts) {
                        setTimeout(
                            () =>
                                setPosts((posts) => [
                                    ...posts.filter((post) => {
                                        if (model == 'post') {
                                            if (post.post_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'news') {
                                            if (post.news_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'event') {
                                            if (post.event_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'policy') {
                                            if (post.policy_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'task') {
                                            if (post.task_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'study') {
                                            if (post.self_study_id != id) {
                                                return post;
                                            }
                                        } else if (model == 'meeting') {
                                            if (post.meeting_id != id) {
                                                return post;
                                            }
                                        }
                                    })
                                ]),
                            650
                        );
                    }

                    setOpenDeleteModal(false);
                }
            })
            .catch((err) => {
                dispatch(activeLoader(false));
                console.log(err);
            });
    }

    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw', height: '100vh' }}>
            <Grid item sx={{ position: 'relative', top: '6rem' }}>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: { md: '40vw', xs: '90vw' },
                        overFlowY: 'scroll',
                        paddingX: '1rem',
                        position: 'relative'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Box>
                            <Badge
                                color="primary"
                                badgeContent={'X'}
                                className="cursor-pointer"
                                onClick={() => {
                                    setOpenDeleteModal(false);
                                }}
                                sx={{ position: 'absolute', top: '2rem', right: '2rem' }}
                            ></Badge>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" mt={5}>
                            <Box item display="flex" alignItems="center" justifyContent="center">
                                <Typography variant="h5">Are You Sure, You Want To Delete This ? </Typography>
                            </Box>
                        </Box>
                        {!processing ? (
                            <Grid container xs={12} sm={12} spacing={2} mt={2}>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        <Typography variant="h6">Proceed</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenDeleteModal(false);
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container sm={12} spacing={2} mt={2}>
                                <Grid item sm={12}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        sx={{
                                            backgroundColor: '#FC5856',
                                            color: '#FFFFFF',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <Typography variant="h6">PROCESSING</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DeleteModal;
