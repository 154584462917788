import React, { useEffect } from 'react';
import { Modal, Box, Typography, Badge } from '@mui/material';
import { Avatar } from '@mui/material';
import axios from 'lib/axios';

export default function FeedbackModal({ open, onClose, feedback, markAsRead }) {
    useEffect(() => {
        if (feedback && !feedback?.is_read) {
            markAsRead();
        }
    }, [feedback]);
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="feedback-modal" aria-describedby="feedback-details">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    outline: 'none'
                }}
            >
                <Badge
                    color="primary"
                    badgeContent={'X'}
                    className="cursor-pointer"
                    onClick={onClose}
                    sx={{ marginLeft: 'auto', display: 'block', mb: 2 }}
                />
                <Box>
                    {/* user */}
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box>
                            <Avatar src={feedback?.userDetails?.profile_pic} alt="user" sx={{ width: 50, height: 50 }} />
                        </Box>
                        <Box>
                            <Typography variant="h6" fontWeight="bold">
                                {feedback?.userDetails?.first_name} {feedback?.userDetails?.last_name}
                            </Typography>
                            <Typography sx={{ color: 'rgba(30, 136, 116, 1)', fontSize: '14px' }}>
                                {feedback?.userDetails?.company?.company_name}
                            </Typography>
                        </Box>
                    </Box>
                    {/* email and number */}
                    <Box mt={2}>
                        <Typography sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)' }}>{feedback?.userDetails.email}</Typography>
                        <Typography sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)' }}>{feedback?.phone_number}</Typography>
                    </Box>
                    {/* subject */}
                    <Box mt={2}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'rgba(30, 124, 136, 1)' }}>
                            {feedback?.subject}
                        </Typography>

                        <Typography sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)' }}>{feedback?.description}</Typography>
                    </Box>

                    <Typography sx={{ fontSize: '16px', color: 'rgba(30, 124, 136, 1)', mt: 2 }}>
                        {new Date(feedback?.createdAt)
                            .toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })
                            .replace(
                                /(\d+)/,
                                '$1' +
                                    ((n) => {
                                        if (n > 3 && n < 21) return 'th';
                                        switch (n % 10) {
                                            case 1:
                                                return 'st';
                                            case 2:
                                                return 'nd';
                                            case 3:
                                                return 'rd';
                                            default:
                                                return 'th';
                                        }
                                    })(new Date(feedback?.createdAt).getDate())
                            )}
                    </Typography>
                </Box>
            </Box>
        </Modal>
    );
}
