import React, { useEffect, useState, useRef } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Avatar from '../../assets/images/users/avatar-1.png';
import CompanyIcon from '../../assets/static_images/company.svg';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VoiceViewer from 'components/modals/VoiceViewer';
import { Box } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import RightSideSkeleton from 'components/skeleton/RightSideSkeleton';

const TopLibraries = () => {
    const [oepnViewer, setOpenViewer] = useState(false);
    const [selected, setSelected] = useState();
    const [showMore, setShowMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const processToUpper = (text) => {
        return text
            ?.split('_')
            .map((item) => item.replace(item[0], item[0].toUpperCase()))
            .join(' ');
    };
    const timeDiffer = (now, date) => {
        var diffMs = now - date; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        if (diffDays > 1) {
            return `${new Date(date).toLocaleDateString('en-US', options).split(',')[1]}, ${
                new Date(date).toLocaleDateString('en-US', options).split(',')[2]
            } `;
        } else {
            return diffDays > 0
                ? `${diffDays} day${diffDays > 1 ? 's' : ''}, ${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
                : diffHrs > 0
                ? `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
                : `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
        }
    };
    const [libraries, setLibraries] = useState([]);
    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.replace(urlRegex, function (url) {
            return '<a target="_target" href="' + url + '">' + url + '</a>';
        });
    }
    useEffect(() => {
        const formData = new FormData();
        formData.append('company_id', JSON.parse(window.localStorage.getItem('authUser'))?.company_id);
        setIsLoading(true);
        axios
            .post('/libraries/most-liked', formData)
            .then(({ data }) => {
                setIsLoading(false);
                setLibraries(data.libraries);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }, []);
    return (
        <Grid sm={12} container>
            <Grid item sm={12} display="flex" alignItems="center" sx={{ paddingX: '0px', width: '100%' }}>
                <Box
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - 0.5rem)' },
                        overflowY: 'scroll',
                        border: '1px solid #1E7C8840',
                        backgroundColor: '#fff'
                    }}
                >
                    <CardContent sx={{ paddingX: '0px' }}>
                        <Grid
                            container
                            sm={12}
                            display="flex"
                            alignItems="center"
                            spacing={1}
                            sx={{ borderBottom: '1px solid #1E7C8840', padding: '0 1rem 0.5rem' }}
                        >
                            <Grid item display="flex" alignItems="center" justifyContent="center">
                                <img src={CompanyIcon} alt="icon" />
                            </Grid>
                            <Grid item>
                                <Typography color="primary">Top liked libraries</Typography>
                            </Grid>
                        </Grid>
                        <Box marginY="1rem">
                            {isLoading && (
                                <>
                                    {new Array(10).fill('loading').map((_, index) => (
                                        <RightSideSkeleton key={index} />
                                    ))}
                                </>
                            )}
                            {libraries?.map((item, index) => {
                                return (
                                    <Box paddingX="1rem" marginBottom="1rem" key={`pst-${index}`}>
                                        <Box display="flex" alignItems="center" gap="0.5rem" sx={{ position: 'relative' }}>
                                            <Box>
                                                <UserAvatarComponent userData={item.user} />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6">{`${item.user?.first_name} ${item.user?.last_name}`}</Typography>
                                                <Box display="flex" gap="0.5rem">
                                                    <Typography variant="subtitle1">{`${processToUpper(
                                                        item.user?.role?.role_type
                                                    )}`}</Typography>
                                                    <Typography variant="subtitle1" sx={{ opacity: '0.5' }}>{`${timeDiffer(
                                                        new Date(),
                                                        new Date(item.createdAt)
                                                    )}`}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Grid item sm={12} sx={{ padding: '0px' }}>
                                            {urlify(item.description).length > 35 ? (
                                                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                                    {showMore
                                                        ? urlify(item.description)
                                                        : `${urlify(item.description).substring(0, 35)}...`}
                                                    {!showMore ? (
                                                        <Button
                                                            className="btn"
                                                            sx={{ fontSize: '13px' }}
                                                            onClick={() => {
                                                                setShowMore(!showMore);
                                                            }}
                                                        >
                                                            View more
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="btn"
                                                            onClick={() => {
                                                                setShowMore(!showMore);
                                                            }}
                                                        >
                                                            View less
                                                        </Button>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                                    {urlify(item.description)}
                                                </Grid>
                                            )}
                                        </Grid>
                                        {/* <Grid item sm={12} sx={{ padding: '0px' }}>
                                            {urlify(item.description).length > 100 ? (
                                                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                                    {showMore ? urlify(item.description) : `${urlify(item.description).substring(0, 100)}`}
                                                    {!showMore ? (
                                                        <Button
                                                            className="btn"
                                                            onClick={() => {
                                                                setShowMore(!showMore);
                                                            }}
                                                        >
                                                            ...
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                                    {urlify(item.description)}
                                                </Grid>
                                            )}
                                        </Grid> */}
                                        <Grid
                                            item
                                            sm={12}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                location.replace(
                                                    `${window.location.origin}/single-view/type=libraries&model_id=${
                                                        item.libraries_id
                                                    }${`&comment_id=0`}`
                                                );
                                            }}
                                        >
                                            <Typography sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }} color="primary">
                                                View libraries
                                            </Typography>
                                        </Grid>
                                    </Box>
                                );
                            })}
                        </Box>
                    </CardContent>
                </Box>
            </Grid>
        </Grid>
    );
};

export default TopLibraries;
