// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isLoading: false
};

// ==============================|| SLICE - LOADER ||============================== //

const loaderSlice = createSlice({
    name: 'loaderSlice',
    initialState,
    reducers: {
        activeLoader(state, action) {
            state.isLoading = action.payload;
        }
    }
});

export default loaderSlice.reducer;

export const { activeLoader } = loaderSlice.actions;
