/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import BottomFilter from 'components/modals/BottomFilter';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import GraphChart from 'components/GraphChart';
import SelfAnalytics from './SelfAnalytics';
import { Box } from '@mui/material';
const TeamAnalytics = () => {
    const [oepnTeamViewer, setOpenTeamViewer] = useState(false);
    const [oepnTeamMembersViewer, setOpenTeamMembersViewer] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState();
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState();
    const [seriesProject, setSeriesProject] = useState([]);
    const [seriesTask, setSeriesTask] = useState([]);
    const [projectCategories, setProjectCategories] = useState([]);
    const [taskCategories, setTaskCategories] = useState([]);
    async function fetchTeamsInfo() {
        setTeams([]);
        await axios
            .post('/activities/activity/team-members-info')
            .then(({ data }) => {
                setTeams(() => {
                    return [
                        ...data.teams.map((team) => {
                            return { name: team.project_name, value: team.project_id, members: team.project_users };
                        })
                    ];
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    async function fetchTeamSummary(arr) {
        await axios
            .post('/activities/activity/team-summary', {
                project_id: selectedTeam.value,
                member_ids: [
                    ...selectedTeam.members.map((item) => {
                        return item.user_id;
                    })
                ]
            })
            .then(({ data }) => {
                let res_arr_project = [];
                let res_arr_task = [];
                let jsonArray = arr.map(JSON.stringify);
                let members = [...new Set(jsonArray)].map(JSON.parse);
                data.summary.project.map((project) => {
                    setProjectCategories((prev) => {
                        return [
                            ...prev,
                            members.filter((member) => {
                                if (member.value == project.user_id) {
                                    res_arr_project.push(parseInt(project.projectCount));
                                    return member;
                                }
                            })[0]?.name
                        ];
                    });
                });
                data.summary.task.map((project) => {
                    setTaskCategories((prev) => {
                        return [
                            ...prev,
                            members.filter((member) => {
                                if (member.value == project.user_id) {
                                    res_arr_task.push(parseInt(project.taskCount));
                                    return member;
                                }
                            })[0]?.name
                        ];
                    });
                });
                setSeriesProject(() => {
                    return [
                        {
                            name: 'projects',
                            data: res_arr_project
                        }
                    ];
                });
                setSeriesTask(() => {
                    return [
                        {
                            name: 'tasks',
                            data: res_arr_task
                        }
                    ];
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchTeamsInfo();
    }, []);
    useEffect(() => {
        if (selectedTeam) {
            const arr = [
                { name: 'All Members', value: 0 },
                ...selectedTeam.members.map((item) => {
                    return { name: `${item.user?.first_name} ${item.user?.last_name}`, value: item.user_id };
                })
            ];
            setTeamMembers(arr);
            fetchTeamSummary(arr);
        }
    }, [selectedTeam]);
    return (
        <Grid container sm={12} sx={{ padding: '1rem' }}>
            <Grid xs={12} sm={12}>
                <Box item display="flex" justifyContent="space-between" alignItems="center">
                    <Box
                        sx={{ opacity: '70%' }}
                        onClick={(e) => {
                            setOpenTeamViewer(!oepnTeamViewer);
                        }}
                    >
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                value={selectedTeam ? selectedTeam?.value : 0}
                                sx={{ pointerEvents: 'none' }}
                            >
                                <MenuItem value={0}>
                                    <Typography>Select Project</Typography>
                                </MenuItem>
                                {teams &&
                                    teams.length > 0 &&
                                    teams.map((team) => {
                                        return (
                                            <MenuItem value={team?.value}>
                                                <Typography>{team?.name}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Box>
                    {teamMembers.length > 0 && (
                        <Box
                            sx={{ opacity: '70%' }}
                            onClick={(e) => {
                                setOpenTeamMembersViewer(!oepnTeamMembersViewer);
                            }}
                        >
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={selectedMember ? selectedMember?.value : 0}
                                    sx={{ pointerEvents: 'none' }}
                                >
                                    <MenuItem value={0}>
                                        <Typography>All Members ({teamMembers.length})</Typography>
                                    </MenuItem>
                                    {teamMembers &&
                                        teamMembers.length > 0 &&
                                        teamMembers.map((member) => {
                                            return (
                                                <MenuItem value={member?.value}>
                                                    <Typography>{member?.name}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </Box>
            </Grid>
            {selectedMember && selectedMember.value != 0 ? (
                <SelfAnalytics member_id={selectedMember?.value} />
            ) : (
                <Grid item sm={12}>
                    <Grid container sm={12} display="flex" direction="column">
                        {seriesTask.length > 0 ? (
                            <Grid item sm={12}>
                                <Grid container sm={12} display="flex" direction="column">
                                    <Grid item sm={12} sx={{ marginY: '1rem' }}>
                                        <Typography variant="h4" color="primary">
                                            Tasks
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} sx={{ marginY: '1rem' }}>
                                        <GraphChart series={seriesTask} categories={taskCategories} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {seriesProject.length > 0 ? (
                            <Grid item sm={12}>
                                <Grid container sm={12} display="flex" direction="column">
                                    <Grid item sm={12} sx={{ marginY: '1rem' }}>
                                        <Typography variant="h4" color="primary">
                                            Projects
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} sx={{ marginY: '1rem' }}>
                                        <GraphChart series={seriesProject} categories={projectCategories} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            )}

            {oepnTeamViewer ? (
                <Modal
                    keepMounted
                    open={oepnTeamViewer}
                    onClose={setOpenTeamViewer}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="end" sx={{ width: '100vw' }}>
                        <BottomFilter items={teams} setOpenViewer={setOpenTeamViewer} setMethod={setSelectedTeam} />
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
            {oepnTeamMembersViewer ? (
                <Modal
                    keepMounted
                    open={oepnTeamMembersViewer}
                    onClose={setOpenTeamMembersViewer}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="end" sx={{ width: '100vw' }}>
                        <BottomFilter items={teamMembers} setOpenViewer={setOpenTeamMembersViewer} setMethod={setSelectedMember} />
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default TeamAnalytics;
