/* eslint-disable react-hooks/rules-of-hooks */
// assets
import { UserOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import PeopleIcon from '../assets/static_images/peopleactive.svg';
// icons
const icons = {
    UserOutlined,
    MedicineBoxOutlined
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

let children = [
    {
        id: 'registeredUser',
        title: 'Registered Users',
        type: 'item',
        url: '/registered-users',
        statCount: 0,
        breadcrumbs: false
    },
    {
        id: 'chat',
        title: 'Chat',
        type: 'item',
        url: '/messenger',
        statCount: 0,
        breadcrumbs: false
    }
];

const People = {
    id: `peopletab`,
    title: `People`,
    type: 'group',
    icon: icons.UserOutlined,
    children: children
};

export default People;
