import React, { useEffect, useState } from 'react';
import {
    Grid,
    Box,
    Typography,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Skeleton,
    TextField,
    Button
} from '@mui/material';

import Plus from '../../assets/static_images/plus.svg';
import CreateEditJobTitleModal from '../../components/modals/CreateEditJobTitleModal';
import TitleDeleteModal from '../../components/modals/TitleDeleteModal';
import axios from 'lib/axios';
import { toast } from 'sonner';

export default function JobTitle() {
    const [jobTitles, setJobTitles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedJobTitle, setSelectedJobTitle] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const handleEdit = (jobTitle) => {
        setSelectedJobTitle(jobTitle);
        setOpenModal(true);
    };

    const handleDelete = (jobTitle) => {
        setSelectedJobTitle(jobTitle);
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setSelectedJobTitle(null);
    };

    const handleConfirmDelete = async () => {
        try {
            setDeleteLoading(true);
            const response = await axios.post(`/job-title/delete`, {
                job_title_id: selectedJobTitle.job_title_id
            });
            if (response.data.success) {
                setJobTitles((prev) => prev.filter((item) => item.job_title_id !== selectedJobTitle.job_title_id));
                toast.success('Job title deleted successfully');
                handleCloseDeleteModal();
            }
        } catch (error) {
            if (error.response?.data?.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Something went wrong');
            }
            console.error('Error deleting job title:', error);
        } finally {
            setDeleteLoading(false);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedJobTitle(null);
    };

    const handleSearchClick = () => {
        setSearchQuery(searchTerm);
    };

    useEffect(() => {
        const fetchJobTitle = async () => {
            try {
                const response = await axios.get(`/job-title?search=${searchQuery}`);
                if (response.data.success) {
                    setJobTitles(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching job titles:', error);
                toast.error('Failed to load job titles');
            } finally {
                setLoading(false);
            }
        };
        fetchJobTitle();
    }, [searchQuery]);

    return (
        <>
            <Box sx={{ height: '100%' }}>
                {/* create and stats card */}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={3}>
                        <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#1E7C88',
                                    borderRadius: '50%',
                                    p: 3,
                                    cursor: 'pointer'
                                }}
                                onClick={() => setOpenModal(true)}
                            >
                                <img src={Plus} alt="plus" style={{ width: '30px', height: '30px' }} />
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                        <Card
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                height: '200px',
                                p: 2
                            }}
                        >
                            <Box>
                                <Typography variant="h4" sx={{ fontSize: '16px' }}>
                                    Total Job Title
                                </Typography>
                                {loading ? (
                                    <Skeleton variant="text" width={60} height={45} />
                                ) : (
                                    <Typography variant="h4" sx={{ fontSize: '30px', color: '#1E7C88', fontWeight: 600 }}>
                                        {jobTitles.length}
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <Typography variant="h4" sx={{ fontSize: '16px' }}>
                                    Active Job Title
                                </Typography>
                                {loading ? (
                                    <Skeleton variant="text" width={60} height={45} />
                                ) : (
                                    <Typography variant="h4" sx={{ fontSize: '30px', color: '#1E7C88', fontWeight: 600 }}>
                                        {jobTitles.length}
                                    </Typography>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
                {/* table */}

                <Card sx={{ p: 2, height: '100%', mt: 2 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" sx={{ fontSize: '26px', mb: 2, fontWeight: 600, color: '#1E7C88' }}>
                            Job Title
                        </Typography>
                        {/* search */}

                        <Box display="flex" alignItems="center" gap={1}>
                            <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                placeholder="Search job titles..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Button variant="contained" color="primary" sx={{ py: 0.8 }} onClick={handleSearchClick}>
                                Search
                            </Button>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>No.</TableCell>
                                    <TableCell>Job Title</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading
                                    ? [...Array(5)].map((_, index) => (
                                          <TableRow key={index}>
                                              <TableCell>
                                                  <Skeleton variant="text" />
                                              </TableCell>
                                              <TableCell>
                                                  <Skeleton variant="text" />
                                              </TableCell>
                                              <TableCell>
                                                  <Skeleton variant="text" width={100} />
                                              </TableCell>
                                          </TableRow>
                                      ))
                                    : jobTitles.map((jobTitle, index) => (
                                          <TableRow key={jobTitle.job_title_id}>
                                              <TableCell>{index + 1}</TableCell>
                                              <TableCell>{jobTitle.title}</TableCell>
                                              <TableCell>
                                                  <Box display="flex" justifyContent="start" gap={1}>
                                                      <Typography
                                                          sx={{ cursor: 'pointer' }}
                                                          variant="subtitle2"
                                                          onClick={() => handleEdit(jobTitle)}
                                                      >
                                                          Edit
                                                      </Typography>
                                                      <Typography
                                                          sx={{ cursor: 'pointer', color: '#d32f2f' }}
                                                          variant="subtitle2"
                                                          onClick={() => handleDelete(jobTitle)}
                                                      >
                                                          Delete
                                                      </Typography>
                                                  </Box>
                                              </TableCell>
                                          </TableRow>
                                      ))}
                            </TableBody>
                        </Table>
                        {jobTitles.length === 0 && !loading && (
                            <Box mt={5} display="flex" justifyContent="center">
                                <Typography variant="subtitle1" sx={{ opacity: '70%' }}>
                                    Title not found
                                </Typography>
                            </Box>
                        )}
                    </TableContainer>
                </Card>
            </Box>
            {openModal && (
                <CreateEditJobTitleModal
                    open={openModal}
                    handleClose={handleCloseModal}
                    jobTitle={selectedJobTitle}
                    setJobTitles={setJobTitles}
                />
            )}
            <TitleDeleteModal
                open={openDeleteModal}
                handleClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                loading={deleteLoading}
            />
        </>
    );
}
