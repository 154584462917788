/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import ThreeDot from '../../assets/static_images/threedot.svg';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import DeleteComment from 'components/modals/DeleteComment';
import EditComment from './EditComment';
import { Avatar, Box } from '@mui/material';
const Reply = ({ reply, post, model, setComments }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [body, setBody] = useState(reply.comment_body);

    const timeDiffer = (now, date) => {
        var diffMs = now - date; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        return diffDays > 0
            ? `${diffDays} day${diffDays > 1 ? 's' : ''}, ${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : diffHrs > 0
            ? `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
    };
    return (
        <Box>
            <Box display="flex">
                <Box display="flex" flex="1" alignItems="center" gap="0.5rem">
                    <Avatar src={avatar1} alt="avatar" style={{ borderRadius: '50%' }} />
                    <Box>
                        <Typography
                            variant="h6"
                            sx={{ fontSize: '13px', fontWeight: '400', lineHeight: '1rem', letterSpacing: '0em', textAlign: 'left' }}
                        >{`${reply.user.first_name} ${reply.user.last_name}`}</Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                opacity: '0.5',
                                fontSize: '13px',
                                fontWeight: '400',
                                lineHeight: '1rem',
                                letterSpacing: '0em',
                                textAlign: 'left'
                            }}
                        >{`${timeDiffer(new Date(), new Date(reply.createdAt))}`}</Typography>
                    </Box>
                </Box>
                <Box sx={{ position: 'relative' }}>
                    {JSON.parse(window.localStorage.getItem('authUser'))?.user_id == post?.user_id ? (
                        <Box
                            className="cursor-pointer"
                            onClick={() => {
                                setOpenOption(!openOption);
                            }}
                        >
                            <img src={ThreeDot} alt="dot" style={{ marginLeft: 'auto' }} />
                        </Box>
                    ) : (
                        <></>
                    )}
                    {openOption ? (
                        <Grid
                            item
                            sx={{
                                zIndex: '1024',
                                position: 'absolute',
                                top: '2rem',
                                right: '0rem',
                                width: '6rem',
                                height: '10rem'
                            }}
                        >
                            <Card sx={{ borderRadius: '10px', width: '100%' }}>
                                <Grid container display="flex" direction="column" aligItems="center" justifyContent="center">
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent="center"
                                        aligItems="center"
                                        sx={{ borderBottom: '1px solid #000000' }}
                                        onClick={() => {
                                            setEditorOpen(true);
                                            setOpenOption(false);
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <Typography>Edit</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent="center"
                                        aligItems="center"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenDeleteModal(true);
                                        }}
                                    >
                                        <Typography>Delete</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            <Box>
                {editorOpen ? (
                    <Grid item sm={12}>
                        <EditComment
                            body={body}
                            commentId={reply.comment_id}
                            placeholder={'Write Reply ..'}
                            model={model}
                            setEditorOpen={setEditorOpen}
                            setBody={setBody}
                        />
                    </Grid>
                ) : (
                    <Box marginY="0.5rem">
                        <Typography
                            sx={{ fontSize: '13px', fontWeight: '400', lineHeight: '1rem', letterSpacing: '0em', textAlign: 'left' }}
                        >
                            {body}
                        </Typography>
                    </Box>
                )}
            </Box>
            {openDeleteModal ? (
                <Modal
                    keepMounted
                    open={openDeleteModal}
                    onClose={setOpenDeleteModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <DeleteComment
                        commentId={reply.comment_id}
                        model={model}
                        setOpenDeleteModal={setOpenDeleteModal}
                        setComments={setComments}
                    />
                </Modal>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default Reply;
