/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Avatar, Box, Grid, Card, Typography } from '@mui/material';
import avatar1 from '../assets/images/users/avatar-1.png';
import { useModal } from 'context/ModalContext';
import AvatarImageViewer from 'components/modals/AvatarImageViewer';
import { Modal } from '@mui/material';
import MentionViewProfile from './modals/MentionViewProfile';
const UserAvatarComponent = ({ userData, style, offset }) => {
    const [openViewProfileModal, setOpenViewProfileModal] = useState(false);
    const sx = style ? style : { borderRadius: '50%', height: '3rem', width: '3rem' };
    const optionOffset = offset
        ? offset
        : {
              zIndex: '1024',
              position: 'absolute',
              top: '3rem',
              left: '2rem',
              width: '6rem',
              height: '10rem'
          };
    const [openOption, setOpenOpion] = useState(false);
    const { isOpen, content, openModal, closeModal } = useModal();

    return (
        <Box
            sx={{ position: 'relative' }}
            onClick={() => {
                setOpenOpion((prev) => !prev);
            }}
        >
            <Avatar
                src={`${userData?.profile_image ? userData?.profile_image : null}`}
                className="img-fit"
                alt={`${userData ? userData.first_name ?? userData.user_name : 'user'}`}
                style={sx}
            />
            {openOption ? (
                <Grid item sx={optionOffset}>
                    <Card sx={{ borderRadius: '10px', width: '100%' }}>
                        <Grid container display="flex" direction="column" justifyContent="center">
                            <Grid
                                item
                                display="flex"
                                justifyContent="center"
                                sx={{ borderBottom: '1px solid #000000' }}
                                onClick={() => {
                                    openModal(<AvatarImageViewer isOpen={true} closeModal={closeModal} data={userData} />);
                                }}
                                className="cursor-pointer"
                            >
                                <Typography>View Photo</Typography>
                            </Grid>
                            <Grid
                                item
                                display="flex"
                                justifyContent="center"
                                className="cursor-pointer"
                                onClick={() => {
                                    // const url =
                                    //     JSON.parse(window.localStorage.getItem('authUser')).user_id == userData.user_id
                                    //         ? `
                                    //                     ${window.location.origin}/profile/${userData.user_id}`
                                    //         : `${window.location.origin}/view-profile/${userData.user_id}`;
                                    // window.open(`${url}`, '_blank');
                                    // window.open(`${window.location.origin}/profile/${userData.user_id ?? null}`, '_blank');
                                    setOpenViewProfileModal(true);
                                }}
                            >
                                <Typography>View Profile</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}

            {openViewProfileModal && (
                <Modal
                    keepMounted
                    open={openViewProfileModal}
                    onClose={setOpenViewProfileModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <MentionViewProfile userId={userData.user_id} setOpenMentionProfile={setOpenViewProfileModal} />
                </Modal>
            )}
        </Box>
    );
};

export default UserAvatarComponent;
