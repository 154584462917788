// src/slices/menuStat.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'lib/axios';

// Define the initial state
const initialState = {
    stats: {
        registeredUsersCount: 0
    },
    status: 'idle',
    error: null
};

// Create an async thunk to fetch products
export const fetchMenueStat = createAsyncThunk('menue-stats', async () => {
    const { data } = await axios.get('/menue-stats');
    return data.menus_stats;
});

// Create the products slice
const menuStat = createSlice({
    name: 'products',
    initialState,
    reducers: {
        updateMenueStat: (state, action) => {
            // state.products.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenueStat.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMenueStat.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stats = action.payload;
            })
            .addCase(fetchMenueStat.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { updateMenueStat } = menuStat.actions;

export default menuStat.reducer;
