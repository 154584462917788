/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import Logo from './Logo';
import config from 'config';
import Signature from '../../assets/static_images/signature.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }) => (
    <ButtonBase disableRipple component={Link} to="/" sx={sx}>
        {/* <Logo /> */}
        {/* // eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={Signature} style={{ height: '1.7rem' }} />
    </ButtonBase>
);

LogoSection.propTypes = {
    sx: PropTypes.object,
    to: PropTypes.string
};

export default LogoSection;
