import React from 'react';
import { Box, Card, CardContent, Typography, Badge } from '@mui/material';
import EventByDateSkeleton from 'components/skeleton/EventByDateSkeleton';
import { Avatar } from '@mui/material';
import MeetingByDate from 'components/meeting/MeetingByDate';

const MeetingsByDateModal = ({ meetingsByDate, handleClose, formattedDate, isLoading }) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh', width: '100vw' }}>
            <Card
                sx={{
                    borderRadius: '10px',
                    maxWidth: { lg: '100vw' },
                    width: { lg: '30vw', md: '50vw', xs: '90vw' },
                    mx: '1rem',
                    position: 'relative'
                }}
            >
                <Box sx={{ position: 'absolute', right: '1rem', top: '0.5rem', cursor: 'pointer' }}>
                    <Badge
                        color="primary"
                        badgeContent={'X'}
                        showZero
                        onClick={() => {
                            handleClose();
                        }}
                    ></Badge>
                </Box>
                <CardContent>
                    {isLoading ? (
                        <EventByDateSkeleton />
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1F7C88' }}>
                                {new Date(formattedDate)?.toLocaleDateString('en-US', options)}
                            </Typography>
                            <Typography id="simple-modal-description" sx={{ opacity: '70%' }}>{`There  ${
                                meetingsByDate?.length > 1 ? 'are' : 'is'
                            } ${meetingsByDate?.length} ${meetingsByDate.length > 1 ? 'meetings' : 'meeting'}`}</Typography>
                            <Box sx={{ borderTop: '2px dashed #1E7C8840', pt: '10px', maxHeight: '55vh' }} className="scroll">
                                {meetingsByDate.map((meeting, index) => (
                                    <MeetingByDate key={index} meeting={meeting} />
                                ))}
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default MeetingsByDateModal;
