import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import { Link, InputLabel, OutlinedInput, Box, Toolbar, useMediaQuery, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
const FootNote = () => {
    const { pathname } = useLocation();
    const [title, setTitle] = useState();
    const [note, setNote] = useState();
    async function fetchFootNote() {
        await axios
            .post(`/footnotes/get-footnote`, { page_route: pathname })
            .then(({ data }) => {
                setNote((prev) => {
                    prev = data.footnote?.note;
                    return prev;
                });
                setTitle((prev) => {
                    prev = data.footnote?.title;
                    return prev;
                });
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        fetchFootNote();
    }, []);

    return (
        <Grid container sm={12} display="flex" alignItems="center" sx={title != '' && note != '' ? { marginBottom: '1rem' } : {}}>
            {title && title != '' ? (
                <Grid item display="flex" alignItems="center">
                    <Typography variant="h5" sx={{ fontWeight: '600' }} color="primary">
                        {title}
                    </Typography>
                </Grid>
            ) : (
                <></>
            )}
            {note && note != '' ? (
                <Grid item display="flex" alignItems="center">
                    <Typography sx={{ opacity: '60%' }}>( {note} )</Typography>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default FootNote;
