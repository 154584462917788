import React from 'react';
import { Box, Typography } from '@mui/material';

const RefundPolicy = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                maxHeight: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Subscription, Renewal, and Refund Policy
            </Typography>

            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Your subscription can be paid by cash, cheque, bank transfer to Eyevary Limited bank account, or Debit/Credit card payment
                through our payment gateway system.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                For your financial security and to avoid a mistake in your re-purchase decision, auto-renewal process is disabled at this
                moment.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Subscription validity is generally 1 year, unless you have subscribed 2, 3, 4, 5 years to enjoy our discounted offer.
                Subscription renewal date will be 1 day before ending your subscription year. (Example: If subscription date is January 15,
                2024, then renewal date will be January 14, 2025).
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Discounts or other special offers are valid for both initial subscription and subscription renewal if so applicable for a
                package.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                SSL Payment gateway may cancel a subscription, because of your insufficient fund, or inaccurate or outdated credit card
                information.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Right of access to Omirror Web Application and App is granted only upon receiving the subscription charges.
            </Typography>

            <Typography sx={{ fontWeight: 'bold' }} mt={2}>
                Cancelling Subscription :
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                You can renew or cancel a subscription from Admin Dashboard.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                The only valid method for canceling your Plan is via the subscription cancellation link provided on your Admin "dashboard"
                page, Requests to cancel the subscription by e-mail or phone are not considered valid, and do not accomplish cancellation.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Once you cancel your subscription, you will not lose access to your account immediately. Your account will continue for a
                month to decide further subscription and/or save your data before discontinuation. You can also delete all your contents
                from admin panel by clicking “Delete all contents” For example: If your credit card is charged on the 15/1/2024 and you
                cancel the renewal on 14/1/2025 you will not lose access to OMirror data until 15/2/2025.
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }} mt={2}>
                Subscription Refund :
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                First subscription fees are non-refundable because we believe you have considered all possible options before subscribing
                Omirror; but you may cancel a renewed subscription within 7 (seven) days of subscription, and you will receive full
                subscription refund in 7 days of cancellation date.
            </Typography>
        </Box>
    );
};

export default RefundPolicy;
