// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';

// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
const children = [
    {
        id: 'permissionLevel',
        title: 'Permission Level',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'title',
                title: 'Title',
                type: 'item',
                url: '/dashboard/districts',
                breadcrumbs: false
            },
            {
                id: 'permission',
                title: 'Permission',
                type: 'item',
                url: '/dashboard/locations',
                breadcrumbs: false
            }
        ]
    }
];
const PermissionLevel = {
    id: 'permissionLevel',
    title: 'Permission Level',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default PermissionLevel;
