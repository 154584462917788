/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Post from './Post';
import axios from 'lib/axios';
import { Box } from '@mui/material';
const Posts = ({ posts, setPosts, postId, type, lastPostRef }) => {
    const [reactions, setReactions] = useState([]);
    const postRefs = useRef([]);
    useEffect(() => {
        if (!reactions.length > 0) {
            axios.get('/reacts').then(({ data }) => {
                if (data.success) {
                    setReactions(data.reacts);
                }
            });
        }
    }, []);
    function scrolltoPost() {
        let id = `post-${postId}`;
        postRefs.current[id]?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <Box width="100%" display="flex" flexDirection="column" gap="1rem">
            {posts.map((post, index) => {
                if (index == posts.length - 1) {
                    setTimeout(function () {
                        scrolltoPost(true);
                    }, 800);
                }

                return (
                    <div
                        // ref={(el) => {
                        //     return (postRefs.current[`post-${post.post_id}`] = el);
                        // }}
                        ref={index === posts.length - 1 ? lastPostRef : null}
                        key={post.post_id}
                    >
                        <Post post={post} reacts={reactions} setPosts={setPosts} type={type} />
                    </div>
                );
            })}
        </Box>
    );
};

export default Posts;
