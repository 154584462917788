// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const subscription = {
    id: 'subscription',
    title: 'Subscription',
    type: 'group',
    children: [
        {
            id: 'subscription',
            title: 'Subscription',
            type: 'item',
            url: '/dashboard/subscription',
            breadcrumbs: false
        },
        {
            id: 'comsub',
            title: 'Subscribed Companies',
            type: 'item',
            url: '/dashboard/clients-under-projects',
            breadcrumbs: false
        }
    ]
};

export default subscription;
