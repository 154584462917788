import React from 'react';
import { Box, Modal, Typography, Button, Grid, Card, Badge } from '@mui/material';
import axios from 'lib/axios';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';

const DeleteComment = ({ setStates, openDeleteModal, setOpenDeleteModal, commentId, setCommentCount, type }) => {
    const dispatch = useDispatch();
    const handleDeleteModal = async () => {
        dispatch(activeLoader(true));
        const url = `${type === 'post' ? '/comments/comment/delete' : `/${type}-comments/comment/delete`}`;
        try {
            const res = await axios.post(url, { comment_id: commentId });
            if (res.data.success) {
                dispatch(activeLoader(false));
                setOpenDeleteModal(false);
                setStates((prevState) => {
                    return prevState.filter((comment) => comment.comment_id !== commentId);
                });
                if (setCommentCount) {
                    setCommentCount((prev) => {
                        prev = parseInt(prev) - 1;
                        return prev;
                    });
                }
            }
        } catch (error) {
            console.log(error);
            dispatch(activeLoader(false));
        }
    };
    return (
        <Modal
            keepMounted
            open={openDeleteModal}
            onClose={setOpenDeleteModal}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100vw', height: '100%' }}
                onClick={() => setOpenDeleteModal(false)}
            >
                <Card
                    sx={{ p: 4, position: 'relative', mx: { xs: '10px' }, minWidth: { md: '400px', xs: 'auto' } }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Badge
                        color="primary"
                        badgeContent={'X'}
                        className="cursor-pointer"
                        onClick={() => {
                            setOpenDeleteModal(false);
                        }}
                        sx={{ position: 'absolute', right: '30px' }}
                    ></Badge>
                    <Box sx={{ overflowY: 'scroll', maxHeight: '30vh' }}>
                        <Typography variant="h5" my={2}>
                            Are You Sure, You Want To Do This ?
                        </Typography>
                        <Grid container sm={12} spacing={2}>
                            <Grid item sm={6}>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        handleDeleteModal();
                                    }}
                                >
                                    <Typography variant="h6">Proceed</Typography>
                                </Button>
                            </Grid>
                            <Grid item sm={6}>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenDeleteModal(false);
                                    }}
                                    sx={{
                                        backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                        color: '#000000'
                                    }}
                                >
                                    <Typography variant="h6">Cancel</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Modal>
    );
};

export default DeleteComment;
