import { Modal, Box, Card, Button, OutlinedInput, FormControl, InputAdornment, Typography } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import AppStoreTable from './AppStoreTable';
import { useEffect, useState } from 'react';
import AppStoreModal from 'components/modals/AppStoreModal';
import axios from 'lib/axios';
const CreateApp = () => {
    const [openModal, setOpenModal] = useState(false);
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        const fetchApps = async () => {
            try {
                const res = await axios.get('/app-store');
                console.log(res);
                if (res.data.success) {
                    setApplications(res.data.applications);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchApps();
    }, []);
    return (
        <>
            <Card sx={{ padding: '1rem' }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ '&:hover': { background: '#3B8D97' } }}
                            onClick={() => setOpenModal(true)}
                        >
                            <Typography variant="h5" sx={{ mr: 1 }}>
                                +
                            </Typography>
                            Create App
                        </Button>
                        <Box>
                            <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                                <OutlinedInput
                                    sx={{ width: '100%' }}
                                    id="header-search"
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                                            <SearchOutlined />
                                        </InputAdornment>
                                    }
                                    aria-describedby="header-search-text"
                                    inputProps={{
                                        'aria-label': 'weight'
                                    }}
                                    placeholder="Search Here..."
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    {/* table */}
                    <AppStoreTable applications={applications} setApplications={setApplications} />
                </Box>
            </Card>
            {openModal && (
                <Modal
                    keepMounted
                    open={openModal}
                    onClose={setOpenModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <AppStoreModal openModal={openModal} setOpenModal={setOpenModal} setApplications={setApplications} />
                </Modal>
            )}
        </>
    );
};
export default CreateApp;
