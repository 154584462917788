// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const client_subscription = {
    id: 'client-subscription',
    title: 'Subscription',
    type: 'group',
    children: [
        {
            id: 'client-subscription',
            title: 'Subscription',
            type: 'item',
            url: '/dashboard/client-subscription',
            breadcrumbs: false
        }
    ]
};

export default client_subscription;
