import React from 'react';
import { Modal, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';

const LoadingModal = () => {
    const { isLoading } = useSelector((state) => state.loaderSlice);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Box>
                <ClipLoader color="#36d7b7" />
            </Box>
        </Box>
    );
};

export default LoadingModal;
