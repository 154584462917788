/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, InputLabel, OutlinedInput, Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WriteIcon from '../../assets/static_images/writeicon.svg';
import avatar1 from '../../assets/images/users/avatar-1.png';
import EmojiIcon from '../../assets/static_images/emojiicon.svg';
import PhotoIcon from '../../assets/static_images/photoicon.svg';
import PhotoIconActive from '../../assets/static_images/photoisonactive.svg';
import VideoIcon from '../../assets/static_images/videoicon.svg';
import VideoIconActive from '../../assets/static_images/videoiconactive.svg';
import AudioIcon from '../../assets/static_images/audioicon.svg';
import AudioIconActive from '../../assets/static_images/audioiconactive.svg';
import FileIcon from '../../assets/static_images/fileicon.svg';
import FileIconActive from '../../assets/static_images/fileiconactive.svg';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import Badge from '@mui/material/Badge';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import parse from 'html-react-parser';
import Picture from '../../assets/static_images/picture.svg';
import axios from 'lib/axios';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';
import FileDownload from 'js-file-download';

const EditPost = ({ setOpenEditor, post, content, user, setPosts, showSnack, setOpenOption }) => {
    console.log(content);
    const imageInput = useRef(null);
    const textInput = useRef(null);
    const pdfInput = useRef(null);
    const audioInput = useRef(null);
    const [openImageUploader, setOpenImageUploader] = useState(post.image.length > 0 ? true : false);
    const [openPdfUploader, setOpenPdfUploader] = useState(post.files.length > 0 && post.image.length == 0 ? true : false);
    const [openAudioUploader, setOpenAudioUploader] = useState(post.audio.length > 0 && post.image.length == 0 ? true : false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [text, setText] = useState(content);
    const [initial, setInitial] = useState(true);
    const [boxHeight, setBoxHeight] = useState('4rem');
    const theme = useTheme();
    const [postImage, setPostImage] = useState(null);
    const [postImages, setPostImages] = useState([]);
    const [existingpostImages, setExistingPostImages] = useState(post.image.length > 0 ? post.image : []);
    const [existingpostVideos, setExistingPostVideos] = useState(post.video.length > 0 ? post.video : []);
    const [existingpostAudios, setExistingpostAudios] = useState(post.audio.length > 0 ? post.audio : []);
    const [existingpostPdfs, setExistingpostPdfs] = useState(post.files.length > 0 ? post.files : []);
    const [postAudios, setPostAudios] = useState([]);
    const [postaudiosData, setPostaudiosData] = useState([]);
    const [postMediaData, setPostMediaData] = useState([]);
    const [postMediaFiles, setPostMediaFiles] = useState([]);
    const [postPdfs, setPostPdfs] = useState([]);
    const [postPdfNames, setPostPdfNames] = useState([]);
    const [postPdfsData, setPostPdfsData] = useState([]);
    const [externalLink, setExternalLink] = useState(post.external_video_link ? post.external_video_link : null);
    console.log(post.image);
    const [loading, seLoading] = useState(false);
    const dispatch = useDispatch();
    async function download_file(path) {
        await axios
            .post(`/social-posts/download`, {
                file_path: path
            })
            .then((res) => {
                FileDownload(res.data, res.data.file_name);
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setOpenEditor(false);
                // Add your logic here
            }
        };

        // Attach the event listener
        window.addEventListener('keydown', handleEsc);
    }, []);
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onprimary = (primary) => reject(primary);
        });
    async function convertMedia() {
        if (imageInput.current.files[0].size < 52453290) {
            const data = await toBase64(imageInput.current.files[0]);
            const file = imageInput.current.files[0];
            const url = URL.createObjectURL(imageInput.current.files[0]);
            setPostMediaData([...postMediaData, data]);
            setPostMediaFiles((prev) => [...prev, file]);
            setPostImages([...postImages, url]);
            setPostImage(null);
        } else {
            alert('Size Must Be Smaller Than 5MB');
        }
    }
    async function convertAudio() {
        if (audioInput.current.files[0].size < 52453290) {
            const file = audioInput.current.files[0];
            const url = URL.createObjectURL(audioInput.current.files[0]);
            setPostaudiosData([...postaudiosData, file]);
            setPostAudios([...postAudios, url]);
        } else {
            alert('Size Must Be Smaller Than 50MB');
        }
    }
    async function convertPdf() {
        if (pdfInput.current.files[0].size < 52453290) {
            const file = pdfInput.current.files[0];
            const url = URL.createObjectURL(pdfInput.current.files[0]);
            setPostPdfsData([...postPdfsData, file]);
            setPostPdfs([...postPdfs, url]);
            setPostPdfNames([...postPdfNames, pdfInput.current.files[0].name]);
        } else {
            alert('Size Must Be Smaller Than 50MB');
        }
    }
    const updatePost = async () => {
        seLoading(true);
        dispatch(activeLoader(true));
        const formData = new FormData();
        formData.append('post_id', post.post_id);
        formData.append('content', text);
        // media
        formData.append('image_exist', existingpostImages.length > 0 ? true : false);
        formData.append('new_image_exist', postMediaData.length > 0 ? true : false);
        formData.append('old_images', JSON.stringify(existingpostImages));
        // audio
        formData.append('audio_exist', existingpostAudios.length > 0 ? true : false);
        formData.append('new_audio_exist', postaudiosData.length > 0 ? true : false);
        formData.append('old_audios', JSON.stringify(existingpostAudios));
        // pdfs
        formData.append('pdf_exist', existingpostPdfs.length > 0 ? true : false);
        formData.append('new_pdf_exist', postPdfsData.length > 0 ? true : false);
        formData.append('old_pdfs', JSON.stringify(existingpostPdfs));
        formData.append('post_pdf_names', JSON.stringify(postPdfNames));
        formData.append('external_video_link', externalLink);
        formData.append('from', 'mobile');

        postaudiosData.forEach((audio, index) => {
            formData.append(`audio-${index}`, audio);
        });
        postMediaFiles.forEach((media, index) => {
            formData.append(`media-${index}`, media);
        });
        postPdfsData.forEach((pdf, index) => {
            formData.append(`docs-${index}`, pdf);
        });
        await axios
            .post('/social-posts/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                seLoading(false);
                dispatch(activeLoader(false));
                if (data.success) {
                    setOpenOption(false);
                    setPosts((posts) => [
                        data.post,
                        ...posts.filter((p) => {
                            if (p.post_id !== data.post.post_id) {
                                return p;
                            }
                        })
                    ]);
                    setOpenEditor(false);
                }
                if (showSnack) {
                    showSnack(!data.success, `${data.message}`);
                }
            })
            .catch((err) => {
                dispatch(activeLoader(true));
                console.log(err);
            });
    };
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item>
                <Card
                    sx={{
                        padding: '.5rem',
                        borderRadius: '10px',
                        width: { md: '50vw', xs: '90vw' },
                        marginTop: '6rem',
                        maxHeight: '90vh'
                    }}
                >
                    <CardContent>
                        <Grid container display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                            <Grid sm={11} spacing={2} item display="flex" justifyContent="start" alignItems="center">
                                <Grid container sm={12} spacing={2}>
                                    <Grid item>
                                        <img src={WriteIcon} alt="edit" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" color="primary">
                                            Edit Your Post
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={1}>
                                <Grid container sm={12} justifyContent="end">
                                    <Grid item>
                                        <Badge
                                            color="primary"
                                            badgeContent={'X'}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setOpenEditor(false);
                                            }}
                                            sx={{ marginLeft: 'auto' }}
                                        ></Badge>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* text editor  */}
                        {/* <CreateVoice text={text} setText={setText} initial={initial} setInitial={setInitial} /> */}
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item sm={12}>
                                <textarea
                                    ref={textInput}
                                    placeholder="Write your post ..."
                                    onChange={(e) => {
                                        setText(e.target.value);
                                    }}
                                    rows="5"
                                    cols="100"
                                    fullWidth
                                    style={{
                                        borderRadius: '4px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.08);',
                                        padding: '1rem',
                                        opacity: '.9',
                                        width: '100%',
                                        fontSize: '1rem'
                                    }}
                                    type="text"
                                    value={text}
                                    // autoFocus
                                ></textarea>
                            </Grid>

                            <Grid item sx={{ position: 'absolute', bottom: '5rem', right: '1rem' }}>
                                <Grid container>
                                    <Grid
                                        item
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setShowEmojis(!showEmojis);
                                        }}
                                    >
                                        <img src={EmojiIcon} style={{ marginRight: '1rem' }} alt="EmojiIcon" />
                                    </Grid>
                                    {showEmojis && (
                                        <div style={{ marginBottom: '-14rem', zIndex: '1023' }}>
                                            <Picker
                                                data={data}
                                                onEmojiSelect={(e) => {
                                                    setText(`${text} ${e.native}`);
                                                    setShowEmojis(!showEmojis);
                                                }}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography color="primary">Put Any External Video Link (YouTube)</Typography>
                                <OutlinedInput
                                    type="text"
                                    value={externalLink}
                                    color="primary"
                                    onChange={(e) => {
                                        setExternalLink(e.target.value);
                                    }}
                                    placeholder="Type Here .."
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {/* options bar  */}
                        <Grid container display="flex" justifyContent="between" alignItems="cetner" sx={{ marginY: '1rem' }}>
                            <Grid item sm={12} sx={{ paddingX: '1rem' }}>
                                <Grid container spacing={3} display="flex" alignItems="center" sm={12}>
                                    <Grid
                                        item
                                        sm={4}
                                        onClick={() => {
                                            setOpenImageUploader(!openImageUploader);
                                            setOpenAudioUploader(false);
                                            setOpenPdfUploader(false);
                                            setPostAudios([]);
                                            setOpenPdfUploader(false);
                                            setPostPdfs([]);
                                            setPostPdfsData([]);
                                            setPostaudiosData([]);
                                            if (existingpostImages.length == 0) {
                                                setTimeout(() => {
                                                    imageInput.current.click();
                                                }, 0.5);
                                            }
                                        }}
                                    >
                                        {openImageUploader ? (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                    backgroundColor: `${theme.palette.primary.lighter}`,
                                                    borderRadius: '10px',
                                                    padding: '.25rem'
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Grid item display="flex" alignItems="center" sm={1}>
                                                    <img src={PhotoIconActive} style={{ marginRight: '1rem' }} alt="PicIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                        Add photos/videos
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                sx={{ rderRadius: '10px', pointerEvents: 'none' }}
                                            >
                                                <Grid item sm={1}>
                                                    <img src={PhotoIcon} style={{ marginRight: '1rem' }} alt="PicIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Add photos/videos</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        onClick={() => {
                                            setOpenAudioUploader(!openAudioUploader);
                                            setOpenPdfUploader(false);
                                            setOpenImageUploader(false);
                                            setPostImages([]);
                                            setPostMediaData([]);
                                            setOpenPdfUploader(false);
                                            setPostPdfs([]);
                                            setPostPdfsData([]);
                                            if (existingpostAudios.length == 0) {
                                                setTimeout(() => {
                                                    audioInput.current.click();
                                                }, 0.5);
                                            }
                                        }}
                                    >
                                        {openAudioUploader ? (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    backgroundColor: `${theme.palette.primary.lighter}`,
                                                    borderRadius: '10px',
                                                    padding: '.25rem'
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Grid item display="flex" alignItems="center" sm={1}>
                                                    <img src={AudioIconActive} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                        Add audio
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                    borderRadius: '10px',
                                                    pointerEvents: 'none'
                                                }}
                                            >
                                                <Grid item sm={1}>
                                                    <img src={AudioIcon} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Attach audio files</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        onClick={() => {
                                            setOpenPdfUploader(!openPdfUploader);
                                            setOpenAudioUploader(false);
                                            setOpenImageUploader(false);
                                            setPostAudios([]);
                                            setPostImages([]);
                                            setPostaudiosData([]);
                                            setPostMediaData([]);
                                            if (existingpostPdfs.length == 0) {
                                                setTimeout(() => {
                                                    pdfInput.current.click();
                                                }, 0.5);
                                            }
                                        }}
                                    >
                                        {openPdfUploader ? (
                                            <Grid
                                                sm={12}
                                                container
                                                display="flex"
                                                alignItems="center"
                                                sx={{
                                                    backgroundColor: `${theme.palette.primary.lighter}`,
                                                    borderRadius: '10px',
                                                    padding: '.25rem'
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Grid item display="flex" alignItems="center" sm={1}>
                                                    <img src={FileIconActive} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography color="primary" sx={{ fontSize: '13px' }}>
                                                        Attach document
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                sm={12}
                                                container
                                                display="flex"
                                                alignItems="center"
                                                sx={{ borderRadius: '10px', pointerEvents: 'none' }}
                                            >
                                                <Grid item sm={1}>
                                                    <img src={FileIcon} style={{ marginRight: '1rem' }} alt="micIcon" />
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Attach document</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                maxHeight: '30vh',
                                overFlowY: 'scroll'
                            }}
                            className="scroll"
                        >
                            {/* image upload card  */}
                            {openImageUploader ? (
                                <>
                                    <>
                                        {existingpostImages.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Images
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {existingpostImages.map((image, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={6}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <img src={`${image}`} alt="uploaded" className="uploaded-img" />
                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            {
                                                                                console.log();
                                                                            }
                                                                            setExistingPostImages((existingpostImages) => {
                                                                                let temp = [...existingpostImages];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                textInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '5px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                        {/* <video
                                                                            className="uploaded-img"
                                                                            width="100%"
                                                                            controls
                                                                            src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                user.user_id
                                                                            }/Social-Post/${post.post_id.toString()}/${
                                                                                image.split('/')[1]
                                                                            }`}
                                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        {postImages.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {postImages.map((image, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={6}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    {postMediaData[index].split(';base64')[0].split(':')[1].split('/')[0] ==
                                                                    'image' ? (
                                                                        <img src={image} alt="uploaded" className="uploaded-img" />
                                                                    ) : (
                                                                        <video className="uploaded-img" width="100%" controls src={image} />
                                                                    )}

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            {
                                                                                console.log();
                                                                            }
                                                                            setPostImages((postImages) => {
                                                                                let temp = [...postImages];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                textInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '5px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '3rem',
                                                    marginTop: '2rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Badge
                                                    color="primary"
                                                    className="float-end cursor-pointer"
                                                    badgeContent={'X'}
                                                    onClick={() => {
                                                        setOpenImageUploader(false);
                                                    }}
                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                ></Badge>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => imageInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '2rem' }}
                                                    >
                                                        <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                            {' '}
                                                            <img src={PhotoIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postImages.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        imageInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ ADD MORE PHOTOS OR VIDEOS </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    <div sx={{ display: 'none' }}>
                                        <input
                                            ref={imageInput}
                                            type="file"
                                            accept="image/*,video/*"
                                            name="imageInput"
                                            onChange={convertMedia}
                                            className="hidden"
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {/* ausio upload card  */}
                            {openAudioUploader ? (
                                <>
                                    <>
                                        {existingpostAudios.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Files
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        {existingpostAudios.map((audio, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    key={`aud@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <Grid container sm={12}>
                                                                        <Grid item sm={12}>
                                                                            <audio controls>
                                                                                <source
                                                                                    src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                        user.user_id
                                                                                    }/Social-Post/${post.post_id.toString()}/${audio}`}
                                                                                    type="audio/ogg"
                                                                                />
                                                                                <source
                                                                                    src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                                                        user.user_id
                                                                                    }/Social-Post/${post.post_id.toString()}/${audio}`}
                                                                                    type="audio/mpeg"
                                                                                />
                                                                            </audio>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            setExistingpostAudios((existingpostAudios) => {
                                                                                let temp = [...existingpostAudios];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                audioInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '5px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        {postAudios.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={2} display="flex" direction="column">
                                                        {postAudios.map((audio, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <Grid container sm={12}>
                                                                        <Grid item sm={12}>
                                                                            <audio controls>
                                                                                <source src={audio} type="audio/ogg" />
                                                                                <source src={audio} type="audio/mpeg" />
                                                                            </audio>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            setPostAudios((postAudios) => {
                                                                                let temp = [...postAudios];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                audioInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '5px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '3rem',
                                                    marginTop: '2rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Badge
                                                    color="primary cursor-pointer"
                                                    className="float-end"
                                                    badgeContent={'X'}
                                                    onClick={() => {
                                                        setOpenAudioUploader(false);
                                                    }}
                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                ></Badge>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => audioInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '2rem' }}
                                                    >
                                                        <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                            {' '}
                                                            <img src={AudioIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postAudios.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        audioInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ ADD MORE AUDIO FILES </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    <div sx={{ display: 'none' }}>
                                        <input
                                            ref={audioInput}
                                            type="file"
                                            accept="audio/*"
                                            name="audioInput"
                                            onChange={convertAudio}
                                            className="hidden"
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            {openPdfUploader ? (
                                <>
                                    <>
                                        {existingpostPdfs.length > 0 ? (
                                            <Grid container spacing={2} sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" color="primary">
                                                        Existing Files
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={1.5} display="flex">
                                                        <Grid item sm={12}>
                                                            <Grid container spacing={2} display="flex" direction="column">
                                                                {existingpostPdfs.map((video, index) => {
                                                                    return (
                                                                        <Grid
                                                                            item
                                                                            sm={12}
                                                                            key={`img@${index}`}
                                                                            sx={{ position: 'relative' }}
                                                                            className="img-box"
                                                                        >
                                                                            <Grid
                                                                                container
                                                                                display="flex"
                                                                                alignItems="center"
                                                                                spacing={2}
                                                                                sm={12}
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    sm={2}
                                                                                    display="flex"
                                                                                    justifyContent="center"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <img
                                                                                        src={FileIconActive}
                                                                                        width={32}
                                                                                        height={32}
                                                                                        alt="pdficon"
                                                                                    ></img>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    sm={10}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    className="cursor-pointer"
                                                                                    onClick={() => {
                                                                                        download_file(video.url);
                                                                                    }}
                                                                                >
                                                                                    <Typography sx={{ fontSize: '16px' }}>
                                                                                        {video.name}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid
                                                                                item
                                                                                onClick={() => {
                                                                                    setExistingpostPdfs((existingpostPdfs) => {
                                                                                        let temp = [...existingpostPdfs];
                                                                                        temp.splice(index, 1);
                                                                                        return temp;
                                                                                    });
                                                                                }}
                                                                                display="flex"
                                                                                direction="column"
                                                                                justifyContent="start"
                                                                                alignItems="start"
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    top: '0px',
                                                                                    right: '5px',
                                                                                    opacity: '0'
                                                                                }}
                                                                                className="trash cursor-pointer"
                                                                            >
                                                                                <Grid>
                                                                                    {' '}
                                                                                    <Badge
                                                                                        color="primary"
                                                                                        badgeContent={'X'}
                                                                                        sx={{ marginLeft: 'auto' }}
                                                                                    ></Badge>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        {postPdfs.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    {postPdfs.map((pdf, index) => {
                                                        return (
                                                            <Grid
                                                                container
                                                                sm={9}
                                                                spacing={1}
                                                                key={`img@${index}`}
                                                                sx={{ position: 'relative' }}
                                                                className="img-box"
                                                                display="flex"
                                                            >
                                                                <Grid
                                                                    item
                                                                    sm={2}
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                >
                                                                    <img src={FileIconActive} width={32} height={32} alt="pdficon"></img>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    sm={6}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        download_file(video.url);
                                                                    }}
                                                                >
                                                                    <Typography sx={{ fontSize: '16px' }}>{postPdfNames[index]}</Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    sm={2}
                                                                    onClick={() => {
                                                                        setPostPdfs((postPdfs) => {
                                                                            let temp = [...postPdfs];
                                                                            temp.splice(index, 1);
                                                                            return temp;
                                                                        });
                                                                        setPostPdfNames((postPdfNames) => {
                                                                            let temp = [...postPdfNames];
                                                                            temp.splice(index, 1);
                                                                            return temp;
                                                                        });

                                                                        setTimeout(() => {
                                                                            pdfInput.current.focus();
                                                                        }, 0.5);
                                                                    }}
                                                                    display="flex"
                                                                    direction="column"
                                                                    justifyContent="start"
                                                                    alignItems="start"
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '0px',
                                                                        right: '2rem',
                                                                        opacity: '0'
                                                                    }}
                                                                    className="trash cursor-pointer"
                                                                >
                                                                    <Grid>
                                                                        {' '}
                                                                        <Badge
                                                                            color="primary"
                                                                            badgeContent={'X'}
                                                                            sx={{ marginLeft: 'auto' }}
                                                                        ></Badge>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '3rem',
                                                    marginTop: '2rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Badge
                                                    color="primary"
                                                    className="float-end cursor-pointer"
                                                    badgeContent={'X'}
                                                    onClick={() => {
                                                        setOpenPdfUploader(false);
                                                    }}
                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                ></Badge>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => pdfInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '2rem' }}
                                                    >
                                                        <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                            {' '}
                                                            <img src={FileIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postPdfs.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        pdfInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ ADD MORE DOCUMENTS </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    <div sx={{ display: 'none' }}>
                                        <input
                                            ref={pdfInput}
                                            type="file"
                                            accept=".pdf"
                                            name="pdfInput"
                                            onChange={convertPdf}
                                            className="hidden"
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </CardContent>

                    {!loading ? (
                        <Grid container xs={12}>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className="cursor-pointer"
                                onClick={() => {
                                    updatePost();
                                }}
                            >
                                <Typography variant="h6">UPDATE</Typography>
                            </Button>
                        </Grid>
                    ) : (
                        <Grid container sm={12} spacing={2}>
                            <Grid item sm={12}>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    sx={{
                                        backgroundColor: '#FC5856',
                                        color: '#FFFFFF',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    <Typography variant="h6">PROCESSING</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

export default EditPost;
