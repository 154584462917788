import Axios from 'axios';
// window.localStorage.setItem(key, JSON.stringify(value));
const access_token = window.localStorage.getItem('access_token') ? window.localStorage.getItem('access_token') : null;

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        Authorization: `Bearer ${access_token}`,
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    },
    withCredentials: true,
    origin: true
});

export default axios;
