import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Badge, Typography, Avatar } from '@mui/material';
import axios from 'lib/axios';
import { convertDate } from 'Helpers';
import avatar from '../../assets/images/users/avatar-2.png';
import ViewProfileModal from './ViewProfileModal';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';

const MentionViewProfile = ({ userId, setOpenMentionProfile }) => {
    const [profileData, setProfileData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    async function fetchUserData() {
        setIsLoading(true);
        dispatch(activeLoader(true));
        try {
            const res = await axios.post(`/users/user`, { id: userId });

            if (res.status === 200) {
                setIsLoading(false);
                dispatch(activeLoader(false));
                setProfileData(res.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(activeLoader(false));
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (userId) {
            fetchUserData();
        }
    }, [userId]);

    return <>{!isLoading && <ViewProfileModal profileData={profileData} setViewProfile={setOpenMentionProfile} />}</>;
};

export default MentionViewProfile;
