/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Post from 'pages/leaders-voice/Post';
import Gallery from 'components/Slider';
import Cross from '../../assets/static_images/cross.svg';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from '@mui/material/Badge';

const DateFilter = ({ setOpenViewer, setMonthRange }) => {
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: '25rem',
                        position: 'absolute',
                        top: '65vh',
                        left: '40vw',
                        paddingX: '1rem'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Grid container sm={12} display="flex" alignItems="center" sx={{ paddingBottom: '1rem' }}>
                            <Grid item sm={11} display="flex" alignItems="center">
                                <Typography variant="h5" color="primary" sx={{ fontweight: '500' }}>
                                    Filter
                                </Typography>
                            </Grid>
                            <Grid item sm={1} display="flex" alignmItems="center" justifyContent="center">
                                <Badge
                                    color="primary"
                                    badgeContent={'X'}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenViewer(false);
                                    }}
                                ></Badge>
                            </Grid>
                        </Grid>
                        <Grid container sm={12} display="flex" direction="column">
                            <Grid
                                item
                                sm={12}
                                className="cursor-pointer"
                                sx={{ borderBottom: '1px solid #D3D3D3', padding: '.6rem' }}
                                onClick={() => {
                                    setMonthRange(0);
                                    setOpenViewer(false);
                                }}
                            >
                                <Typography sx={{ opacity: '70%' }}>{`${new Date().toString().substring(3, 7)},${new Date()
                                    .toString()
                                    .substring(10, 16)}`}</Typography>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                className="cursor-pointer"
                                sx={{ borderBottom: '1px solid #D3D3D3', padding: '.6rem' }}
                                onClick={() => {
                                    setMonthRange(1);
                                    setOpenViewer(false);
                                }}
                            >
                                <Typography sx={{ opacity: '70%' }}>Last Month</Typography>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                className="cursor-pointer"
                                sx={{ borderBottom: '1px solid #D3D3D3', padding: '.6rem' }}
                                onClick={() => {
                                    setMonthRange(3);
                                    setOpenViewer(false);
                                }}
                            >
                                <Typography sx={{ opacity: '70%' }}>Last 3 Month</Typography>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                className="cursor-pointer"
                                sx={{ borderBottom: '1px solid #D3D3D3', padding: '.6rem' }}
                                onClick={() => {
                                    setMonthRange(6);
                                    setOpenViewer(false);
                                }}
                            >
                                <Typography sx={{ opacity: '70%' }}>Last 6 Month</Typography>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                className="cursor-pointer"
                                sx={{ padding: '.6rem' }}
                                onClick={() => {
                                    setMonthRange(12);
                                    setOpenViewer(false);
                                }}
                            >
                                <Typography sx={{ opacity: '70%' }}>Last 1 Year</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DateFilter;
