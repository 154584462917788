/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/static_images/photoicon.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WriteIcon from '../../assets/static_images/writeicon.svg';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from 'lib/axios';
import Checkbox from '@mui/material/Checkbox';
import { Link, InputLabel, OutlinedInput, Snackbar } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import avatar1 from '../../assets/images/users/avatar-1.png';
import serchicon from '../../assets/static_images/searchicon.svg';
import Upload from '../../assets/static_images/uploadpolicyactive.svg';
import WriteIcons from '../../assets/static_images/writeicon.svg';
import ShareIcon from '../../assets/static_images/share.svg';
import Button from '@mui/material/Button';
import FileIcon from '../../assets/static_images/fileicon.svg';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import PhotoIconActive from '../../assets/static_images/photoisonactive.svg';
import PhotoIcon from '../../assets/static_images/photoicon.svg';

const ProfileImage = ({ type, setOepnProfileImage, setReload }) => {
    const [processing, setProcessing] = useState(false);
    const [postMediaData, setPostMediaData] = useState([]);
    const imageInput = useRef(null);
    const [postImage, setPostImage] = useState(null);
    const [postImages, setPostImages] = useState([]);
    const theme = useTheme();

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onprimary = (primary) => reject(primary);
        });
    async function convertMedia() {
        if (imageInput.current.files[0].size < 52453290) {
            const file = await toBase64(imageInput.current.files[0]);
            const url = URL.createObjectURL(imageInput.current.files[0]);
            setPostMediaData([...postMediaData, file]);
            setPostImages([...postImages, url]);
            setPostImage(null);
        } else {
            alert('Size Must Be Smaller Than 5MB');
        }
    }
    async function submit() {
        setProcessing(true);
        const formData = new FormData();

        formData.append('post_media', JSON.stringify(postMediaData));
        formData.append('image_type', type);

        await axios
            .post(`/users/update-profile`, formData)
            .then(({ data }) => {
                setReload(true);
                setOepnProfileImage(false);
            })
            .catch((err) => console(err));
    }
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '90vw', position: 'relative' }}>
            <Grid item sx={{ position: 'absolute', top: '6rem', left: { md: '30%', xs: '5%' } }}>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: { md: '40vw', xs: '100vw' },
                        marginTop: '1rem',
                        height: { md: '50vh', xs: '60vh' },
                        overFlowY: 'scroll',
                        paddingX: '1rem'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Grid container sm={12}>
                            <Grid item sm={12}>
                                <Typography color="primary" variant="h5" sx={{ fontWeight: '700' }}>
                                    Update Your Profile Picture
                                </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                {postImages.length > 0 ? (
                                    <Grid container sx={{ marginY: '1rem' }}>
                                        <Grid item sm={12}>
                                            <Grid container spacing={1.5} display="flex">
                                                {postImages.map((image, index) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={10}
                                                            key={`img@${index}`}
                                                            sx={{ position: 'relative' }}
                                                            className="img-box"
                                                        >
                                                            {postMediaData[index].split(';base64')[0].split(':')[1].split('/')[0] ==
                                                            'image' ? (
                                                                <img src={image} alt="uploaded" className="uploaded-img" />
                                                            ) : (
                                                                <video className="uploaded-img" width="100%" controls src={image} />
                                                            )}

                                                            <Grid
                                                                item
                                                                onClick={() => {
                                                                    {
                                                                        console.log();
                                                                    }
                                                                    setPostImages((postImages) => {
                                                                        let temp = [...postImages];
                                                                        temp.splice(index, 1);
                                                                        return temp;
                                                                    });

                                                                    setTimeout(() => {
                                                                        textInput.current.focus();
                                                                    }, 0.5);
                                                                }}
                                                                display="flex"
                                                                direction="column"
                                                                justifyContent="start"
                                                                alignItems="start"
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: '0px',
                                                                    right: '5px',
                                                                    opacity: '0'
                                                                }}
                                                                className="trash cursor-pointer"
                                                            >
                                                                <Grid>
                                                                    {' '}
                                                                    <Badge
                                                                        color="primary"
                                                                        badgeContent={'X'}
                                                                        sx={{ marginLeft: 'auto' }}
                                                                    ></Badge>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        xs={12}
                                        sm={12}
                                        display="flex"
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        className="dashed"
                                        sx={{
                                            padding: '3rem',
                                            marginTop: '2rem',
                                            borderRadius: '10px',
                                            backgroundColor: `${theme.palette.primary.lighter}`
                                        }}
                                    >
                                        <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            xs={12}
                                            sm={12}
                                            className="cursor-pointer"
                                            onClick={() => imageInput.current.click()}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                display="flex"
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                sm={12}
                                                sx={{ paddingY: '2rem' }}
                                            >
                                                <Grid item sm={12} display="flex" justifyContent="center" alignItems="center">
                                                    {' '}
                                                    <img src={PhotoIcon} alt="logo" width={24} height={24} />
                                                </Grid>
                                                <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                    <Typography sx={{ fontSize: '16px' }} color="primary">
                                                        {' '}
                                                        <a href="#" sx={{ color: 'primary' }}>
                                                            Upload a file
                                                        </a>{' '}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <div sx={{ display: 'none' }}>
                                    <input
                                        ref={imageInput}
                                        type="file"
                                        accept="image/*,video/*"
                                        name="imageInput"
                                        onChange={convertMedia}
                                        className="hidden"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {!processing ? (
                            <Grid container sm={12} xs={12} display="flex" spacing={2} sx={{ marginTop: 'auto' }}>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        <Typography variant="h6">Proceed</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOepnProfileImage(false);
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container sm={12} spacing={2}>
                                <Grid item sm={12}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        sx={{
                                            backgroundColor: '#FC5856',
                                            color: '#FFFFFF',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <Typography variant="h6">PROCESSING</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ProfileImage;
