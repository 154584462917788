import React, { useState } from 'react';
import threeDot from '../../assets/static_images/threedot.svg';
import EditComment from 'components/comment/EditComment';
import { Avatar } from '@mui/material';
import { calculateTimeDifference } from 'Helpers';
import { Box, Typography, Grid, Card } from '@mui/material';
import DeleteComment from 'components/comment/DeleteComment';

const PolicyCommentBox = ({ comment, setComments, setCommentCount }) => {
    const [editorOpen, setEditorOpen] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [body, setBody] = useState(comment.comment_body);

    return (
        <Box sx={{ display: 'flex', alignItems: 'start', gap: '10px', mt: '15px' }}>
            <Box>
                <Avatar
                    style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                    src={comment.user?.profile_image}
                    alt="profile"
                />
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        background: '#F2F2F2',
                        px: 2,
                        py: 1,
                        width: '100%',
                        borderRadius: '0px 10px 10px 10px'
                    }}
                >
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">
                                {comment.user.first_name} {comment.user.last_name}
                            </Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography sx={{ fontSize: '12px' }}>{calculateTimeDifference(comment.createdAt)}</Typography>
                                <Box
                                    onClick={() => setOpenOption(!openOption)}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ position: 'relative', cursor: 'pointer' }}
                                >
                                    {openOption && (
                                        <Grid
                                            item
                                            sx={{
                                                zIndex: '1024',
                                                position: 'absolute',
                                                top: '1.5rem',
                                                right: '0rem',
                                                width: '6rem',
                                                height: '10rem'
                                            }}
                                        >
                                            <Card sx={{ borderRadius: '10px', width: '100%' }}>
                                                <Grid container display="flex" direction="column" justifyContent="center">
                                                    <Grid
                                                        item
                                                        display="flex"
                                                        justifyContent="center"
                                                        sx={{ borderBottom: '1px solid #000000' }}
                                                        onClick={() => {
                                                            setEditorOpen(true);
                                                        }}
                                                        className="cursor-pointer"
                                                    >
                                                        <Typography>Edit</Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            setOpenDeleteModal(true);
                                                        }}
                                                    >
                                                        <Typography>Delete</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    )}
                                    <img style={{ width: '20px', height: '20px', objectFit: 'cover' }} src={threeDot} alt="profile" />
                                </Box>
                            </Box>
                        </Box>
                        {/* comment */}
                        <Box>
                            {editorOpen ? (
                                <EditComment
                                    setBody={setBody}
                                    setEditorOpen={setEditorOpen}
                                    body={comment.comment_body}
                                    commentId={comment.comment_id}
                                    model={'policy'}
                                    setParentComments={setComments}
                                />
                            ) : (
                                <Typography variant="p">{comment.comment_body}</Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {openDeleteModal && (
                <DeleteComment
                    setCommentCount={setCommentCount}
                    commentId={comment.comment_id}
                    setStates={setComments}
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    type={'policy'}
                />
            )}
        </Box>
    );
};

export default PolicyCommentBox;
