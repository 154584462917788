import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import lodash from 'lodash';

import { formatDate } from '../../helpers/formatData';

function createData(no, name, department, item) {
    return { no, name, department, item };
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'no',
        align: 'left',
        disablePadding: false,
        label: 'No'
    },
    {
        id: 'departmentDivisions',
        align: 'left',
        disablePadding: false,
        label: 'Department Division'
    },
    {
        id: 'department',
        align: 'left',
        disablePadding: false,
        label: 'Department'
    },
    {
        id: 'action',
        align: 'left',
        disablePadding: true,
        label: 'Action'
    }
];

function OrderTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography varint="h5" sx={{ fontWeight: '700' }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const DepartmentDivisionTable = ({ departmentDivisions, setOpenModal, setOperation, setSelected }) => {
    const [order] = useState('asc');
    const [orderBy] = useState('no');
    const [selected] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        let res =
            departmentDivisions &&
            departmentDivisions.length > 0 &&
            departmentDivisions?.map((item, index) => {
                return createData(index + 1, item.name, item.department, item);
            });
        setRows(res);
    }, [departmentDivisions]);

    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <OrderTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        {rows &&
                            rows.length > 0 &&
                            stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        <TableCell component="th" id={labelId} scope="row" align="left">
                                            {/* <Link color="secondary" component={RouterLink} to=""> */}
                                            {row.no}
                                            {/* </Link> */}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" align="left">
                                            {/* <Link color="secondary" component={RouterLink} to=""> */}
                                            {row.name}
                                            {/* </Link> */}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" align="left">
                                            {/* <Link color="secondary" component={RouterLink} to=""> */}
                                            {row.department.name}
                                            {/* </Link> */}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container display="flex" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            setSelected(row.item);
                                                            setOperation('update');
                                                            setOpenModal(true);
                                                        }}
                                                    >
                                                        EDIT
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            setSelected(row.item);
                                                            setOperation('delete');
                                                            setOpenModal(true);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
DepartmentDivisionTable.propTypes = {
    departmentDivisions: PropTypes.array
};
export default DepartmentDivisionTable;
