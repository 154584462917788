/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Link, Grid, Box, InputLabel, OutlinedInput, Snackbar, useTheme, useMediaQuery, Typography } from '@mui/material';
import { htmlContent, urlify } from 'Helpers';
import { element } from 'prop-types';

const NewsBody = ({ news, type, setOpenGallery }) => {
    const [showMore, setShowMore] = useState(false);
    const [media, setMedia] = useState([]);

    useEffect(() => {
        if (news.image && news.video) {
            const imageMedia = news.image
                .filter((item) => item !== null && item !== undefined)
                .map((item) => ({ type: 'image', url: item }));

            const videoMedia = news.video
                .filter((item) => item !== null && item !== undefined)
                .map((item) => ({ type: 'video', url: item }));

            const combinedMedia = [...imageMedia, ...videoMedia];
            setMedia(combinedMedia);
        }
    }, [news.image, news.video]);

    const preRef = useRef(null);
    useEffect(() => {
        if (preRef.current) {
            const button = preRef.current.querySelector('#showMoreButton');
            if (button) {
                button.addEventListener('click', () => {
                    setShowMore(true);
                });
            }
        }
    }, []);

    return (
        <Box>
            <Box className="content-body" marginY="1rem" sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        textWrap: 'wrap',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {urlify(news.plain_text_body)?.length > 150
                        ? showMore
                            ? htmlContent(news.plain_text_body)
                            : htmlContent(news.plain_text_body, true, 200, preRef)
                        : htmlContent(news.plain_text_body)}
                </Box>

                {type === 'non-view' && media.length > 0 && (
                    <Box
                        mt={1}
                        className="cursor-pointer"
                        onClick={() => {
                            setOpenGallery(true);
                        }}
                        sx={{ position: 'relative' }}
                    >
                        <Grid
                            container
                            display="grid"
                            gap="1rem"
                            sx={{
                                gridTemplateColumns: media.length === 1 ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'
                            }}
                        >
                            {media.map((item, index) => {
                                let col = 12;

                                if (media.length > 3) {
                                    col = index === 0 ? 12 : 4;
                                } else if (media.length === 2) {
                                    col = 6;
                                }
                                if (media.length === 3) {
                                    if (index === 0) {
                                        col = 12;
                                    } else {
                                        col = 6;
                                    }
                                }

                                if (index < 3) {
                                    return (
                                        <Box
                                            key={`${news.news_id}lil${index}`}
                                            style={{
                                                gridColumn: `span ${media.length === 3 && index === 2 ? 2 : 1}`
                                            }}
                                        >
                                            {item.type === 'image' && (
                                                <img
                                                    src={`${item.url}`}
                                                    alt="pic"
                                                    className="img-fit"
                                                    style={{
                                                        borderRadius: '10px',
                                                        height: '336px',
                                                        width: '100%',
                                                        objectFit: media.length === 1 ? 'contain' : 'cover',
                                                        background: 'lightgray'
                                                    }}
                                                />
                                            )}
                                            {item.type === 'video' && (
                                                <video
                                                    className="img-fit"
                                                    controls
                                                    src={`${item.url}`}
                                                    style={{
                                                        borderRadius: '10px',
                                                        height: '336px',
                                                        width: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    );
                                } else if (index === 3) {
                                    return (
                                        <Box key={`${news.news_id}lil${index}`}>
                                            <Box sx={{ position: 'relative' }}>
                                                <Box>
                                                    {item.type === 'image' && (
                                                        <img
                                                            src={`${item.url}`}
                                                            alt="pic"
                                                            className="img-fit"
                                                            style={{
                                                                borderRadius: '10px',
                                                                height: '336px',
                                                                width: '100%',
                                                                objectFit: 'cover'
                                                            }}
                                                        />
                                                    )}
                                                    {item.type === 'video' && (
                                                        <video
                                                            className="img-fit"
                                                            controls
                                                            src={`${item.url}`}
                                                            style={{
                                                                borderRadius: '10px',
                                                                height: '336px',
                                                                width: '100%',
                                                                objectFit: 'cover'
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                                {media.length - 4 > 0 && (
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: '10px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                        className="image-cover"
                                                    >
                                                        <div>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    color: '#FFFFFF',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                +{media.length - 4}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                }
                            })}
                        </Grid>
                    </Box>
                )}

                {type === 'non-view' &&
                    news.audio &&
                    news.audio.length > 0 &&
                    news.audio.map((audio, index) => (
                        <Box key={index} display="flex" justifyContent="center" alignItems="center">
                            <audio controls>
                                <source
                                    src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                        user.user_id
                                    }/Social-news/${news.news_id.toString()}/${audio}`}
                                    type="audio/ogg"
                                />
                                <source
                                    src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                        user.user_id
                                    }/Social-news/${news.news_id.toString()}/${audio}`}
                                    type="audio/mpeg"
                                />
                            </audio>
                        </Box>
                    ))}

                {type === 'non-view' &&
                    news.file &&
                    news.file.length > 0 &&
                    news.file.map((file, index) => {
                        let source = file.split('/')[1];
                        return (
                            <Grid container sm={12} spacing={2} px={{ paddingY: '1rem', marginBottom: '.5rem' }} key={index}>
                                <Grid item sm={2} display="flex" justifyContent="center" alignItems="center">
                                    <img src={PdfIcon} width={32} height={32} alt="pdficon" />
                                </Grid>
                                <Grid
                                    item
                                    sm={10}
                                    display="flex"
                                    alignItems="center"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        window.open(
                                            `${process.env.REACT_APP_BACKEND_URL}/Uploads/${
                                                user.user_id
                                            }/Social-news/${news.news_id.toString()}/${source}`,
                                            '_blank',
                                            'fullscreen=yes'
                                        );
                                        return false;
                                    }}
                                >
                                    <Typography sx={{ fontSize: '16px' }}>{file.split('/')[0]}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default NewsBody;
