import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TopNews from 'components/right-side/TopNews';
const Side = ({ type }) => {
    return (
        <Grid container={12}>
            <TopNews type={type} />
        </Grid>
    );
};

export default Side;
