/* eslint-disable prettier/prettier */
const Divisions = {
    id: 'divisions',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'divisions',
            title: 'Divisions',
            type: 'item',
            url: '/dashboard/divisions',
            breadcrumbs:false
            // target: true //in order to open in new tab
        }
    ]
};

export default Divisions;
 