import React, { useState } from 'react';
import { Box, Button, Grid, Typography, Modal } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { processToUpper, timeDiffer, urlify } from 'Helpers';
import ViewPolicyModal from './ViewPolicyModal';

const MiniPolicyBody = ({ item }) => {
    const [showMore, setShowMore] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    return (
        <>
            <Box paddingX="1rem" marginBottom="1rem">
                <Box display="flex" alignItems="start" gap="0.5rem" sx={{ position: 'relative' }}>
                    <Box>
                        <UserAvatarComponent userData={item.user} />
                    </Box>
                    <Box>
                        <Typography variant="h6">{`${item.user?.first_name} ${item.user?.last_name}`}</Typography>
                        <Box display="flex" gap="0.5rem">
                            <Typography variant="subtitle1" sx={{ fontSize: '13px', opacity: '0.5' }}>{`${processToUpper(
                                item.user?.role?.role_type
                            )}`}</Typography>
                            <Typography variant="subtitle1" sx={{ fontSize: '13px', opacity: '0.5' }}>{`${timeDiffer(
                                new Date(),
                                new Date(item.createdAt)
                            )}`}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Grid item sm={12} sx={{ padding: '0px', mt: 1 }}>
                    {urlify(item?.plain_text_body).length > 35 ? (
                        <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                            {showMore ? urlify(item.plain_text_body) : `${urlify(item.plain_text_body).substring(0, 35)}...`}
                            {!showMore ? (
                                <Button
                                    className="btn"
                                    sx={{ fontSize: '13px' }}
                                    onClick={() => {
                                        setShowMore(!showMore);
                                    }}
                                >
                                    View more
                                </Button>
                            ) : (
                                <Button
                                    className="btn"
                                    onClick={() => {
                                        setShowMore(!showMore);
                                    }}
                                >
                                    View less
                                </Button>
                            )}
                        </Grid>
                    ) : (
                        <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                            {urlify(item.plain_text_body)}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    sm={12}
                    className="cursor-pointer"
                    onClick={() => {
                        setOpenViewModal(true);
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="between"
                >
                    <Typography sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }} color="primary">
                        View Policy
                    </Typography>
                </Grid>
            </Box>
            {openViewModal && (
                <Modal
                    keepMounted
                    open={openViewModal}
                    onClose={setOpenViewModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <ViewPolicyModal policyId={item.policy_id} setOpenViewModal={setOpenViewModal} />
                </Modal>
            )}
        </>
    );
};

export default MiniPolicyBody;
