/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import Grid from '@mui/material/Unstable_Grid2';
import EmojiIcon from '../../assets/static_images/emojiicon.svg';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Submit from '../../assets/static_images/submit.svg';
import axios from 'lib/axios';

const ReplayComment = ({
    parentId,
    postId,
    placeholder = 'Write Comment ..',
    setOpenReplayComment,
    setComments,
    commentType,
    setChildComment,
    type
}) => {
    const theme = useTheme();
    const [comment, setComment] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [parentCommentId, setParentCommentId] = useState(parentId);
    const [id, setId] = useState(`${type}_id`);
    console.log(id);
    const submitComment = async () => {
        try {
            const formData = new FormData();

            // if (type === 'news') {
            //     formData.append('news_id', postId);
            // } else if (type === 'post') {
            //     formData.append('post_id', postId);
            // } else {
            //     formData.append('post_id', postId);
            // }
            formData.append(id, postId);
            formData.append('comment_body', comment);
            formData.append('parent_comment_id', parseInt(parentCommentId));

            const url = `${type === 'post' ? '/comments' : `/${type}-comments`}`;

            if (comment != '') {
                axios.post(url, formData).then(({ data }) => {
                    if (data.success) {
                        setOpenReplayComment(false);
                        const newCommentId = data.comment.parent_comment_id;
                        const newComment = data.comment;

                        // if (commentType === 'level-2') {
                        //     setComments((prev) => {
                        //         prev = [data.comment, ...prev];
                        //         return prev;
                        //     });
                        // } else if (commentType === 'level-3') {
                        //     setChildComment((prev) => {
                        //         prev = [data.comment, ...prev];
                        //         return prev;
                        //     });
                        // }
                        setComments((prev) => {
                            prev = [...prev, data.comment];
                            return prev;
                        });

                        setComment('');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box display="flex" alignItems="center" style={{ zIndex: '1024' }}>
            <Box flex="1">
                <OutlinedInput
                    type="text"
                    value={comment}
                    color={theme.primary}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                    placeholder={placeholder}
                    fullWidth
                    onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                            submitComment();
                        }
                    }}
                />
            </Box>
            <Box>
                <Grid container display="flex" alignItems="center" spacing={3} style={{ padding: '.5rem' }}>
                    <Grid
                        item
                        className="cursor-pointer"
                        onClick={() => {
                            setShowEmojis(!showEmojis);
                        }}
                    >
                        {' '}
                        <img src={EmojiIcon} alt="EmojiIcon" />
                    </Grid>
                    <Grid
                        item
                        className="cursor-pointer"
                        onClick={() => {
                            submitComment();
                        }}
                    >
                        {' '}
                        <img src={Submit} alt="SubmitIcon" />
                    </Grid>
                </Grid>
                {showEmojis && (
                    <Modal open={showEmojis}>
                        <Box
                            sx={{ position: 'absolute', top: '50%', left: '50%', borderRadius: '15px', transform: 'translate(-50%, -50%)' }}
                        >
                            <Picker
                                data={data}
                                onEmojiSelect={(e) => {
                                    setShowEmojis(!showEmojis);
                                    setComment(`${comment} ${e.native}`);
                                }}
                            />
                        </Box>
                    </Modal>
                )}
            </Box>
        </Box>
    );
};

export default ReplayComment;
