/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import Grid from '@mui/material/Unstable_Grid2';
import EmojiIcon from '../../assets/static_images/emojiicon.svg';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Submit from '../../assets/static_images/submit.svg';
import axios from 'lib/axios';
const EditComment = ({
    body,
    commentId,
    placeholder = 'Write Comment ..',
    model = 'post',
    showSnack = null,
    setEditorOpen,
    setBody,
    setParentComments
}) => {
    const theme = useTheme();
    const [comment, setComment] = useState(body);
    const [showEmojis, setShowEmojis] = useState(false);
    const [url, setUrl] = useState(model == 'post' ? '/comments/comment/update' : `/${model}-comments/comment/update`);

    const submitComment = async () => {
        const formData = new FormData();
        formData.append('comment_id', parseInt(commentId));
        formData.append('comment_body', comment);
        axios.post(`${url}`, formData).then(({ data }) => {
            if (data.success) {
                console.log(data);
                setBody(comment);
                setParentComments((prev) => {
                    prev = prev.map((item) => {
                        console.log(item);
                        if (item.comment_id == data.comment.comment_id) {
                            item = data.comment;
                        }
                        return item;
                    });
                    return prev;
                });
                if (showSnack) {
                    showSnack(false, `Your comment is submitted. Thank you`);
                }
            }
        });
    };
    return (
        <Grid container display="flex" alignItems="center" style={{ zIndex: '1024' }}>
            <Grid item sm={9}>
                <OutlinedInput
                    type="text"
                    value={comment}
                    color={theme.primary}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                    placeholder={placeholder}
                    fullWidth
                    onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                            submitComment();
                            setEditorOpen(false);
                        }
                    }}
                />
            </Grid>
            <Grid item sm={3}>
                <Grid container display="flex" alignItems="center" spacing={3} style={{ padding: '.5rem' }}>
                    <Grid
                        item
                        className="cursor-pointer"
                        onClick={() => {
                            setShowEmojis(!showEmojis);
                        }}
                    >
                        {' '}
                        <img src={EmojiIcon} alt="EmojiIcon" />
                    </Grid>
                    <Grid
                        item
                        className="cursor-pointer"
                        onClick={() => {
                            submitComment();
                            setEditorOpen(false);
                        }}
                    >
                        {' '}
                        <img src={Submit} alt="SubmitIcon" />
                    </Grid>
                </Grid>
                {showEmojis && (
                    <Modal open={showEmojis}>
                        <Box
                            sx={{ position: 'absolute', top: '50%', left: '50%', borderRadius: '15px', transform: 'translate(-50%, -50%)' }}
                        >
                            <Picker
                                data={data}
                                onEmojiSelect={(e) => {
                                    setShowEmojis(!showEmojis);
                                    setComment(`${comment} ${e.native}`);
                                }}
                            />
                        </Box>
                    </Modal>
                )}
            </Grid>
        </Grid>
    );
};

export default EditComment;
