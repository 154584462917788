// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
let children = [
    {
        id: 'success_story',
        title: 'Success Story',
        type: 'item',
        url: '/get-social',
        breadcrumbs: false
    },
    {
        id: 'task_project_goals',
        title: 'Task, Project, Goals',
        type: 'item',
        url: '/tasks',
        breadcrumbs: false
    },
    {
        id: 'meeting_management',
        title: 'Meetings ',
        type: 'item',
        url: '/meeting',
        breadcrumbs: false
    },
    {
        id: 'movement_visits',
        title: 'Movement & Visits',
        type: 'item',
        url: '/movement-visit',
        breadcrumbs: false
    },
    {
        id: 'events',
        title: 'Events',
        type: 'item',
        url: '/news-events/events',
        breadcrumbs: false
    },
    {
        id: 'knowledge_skills',
        title: 'Knowledge & Skills',
        type: 'item',
        url: '/self-study',
        breadcrumbs: false
    },
    {
        id: 'voice',
        title: 'People Voice',
        type: 'item',
        url: '/voice',
        breadcrumbs: false
    }
];

const PeopleEngagement = {
    id: 'people_engagement',
    title: 'People Engagement',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default PeopleEngagement;
