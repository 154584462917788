// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';

// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
const children = [
    {
        id: 'officeLocation',
        title: 'Office Location',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'divisions',
                title: 'Divisions',
                type: 'item',
                url: '/dashboard/divisions',
                breadcrumbs: false
            },
            {
                id: 'districts',
                title: 'Districts',
                type: 'item',
                url: '/dashboard/districts',
                breadcrumbs: false
            },
            {
                id: 'locations',
                title: 'Locations',
                type: 'item',
                url: '/dashboard/locations',
                breadcrumbs: false
            }
        ]
    }
];
const OfficeLocation = {
    id: 'officeLocation',
    title: 'Office Location',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default OfficeLocation;
