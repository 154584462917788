/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';

const ContactInfo = ({ showSnack }) => {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [warningMessages, setWarningMessages] = useState(false);
    async function updateProfile() {
        const formData = new FormData();
        formData.append('phone_no', phone);
        formData.append('email', email);
        formData.append('street_address', address);
        await axios
            .post('/users/update-profile', formData)
            .then(({ data }) => {
                if (data.success) {
                    if (showSnack) {
                        showSnack(false, `${data.message}`);
                    }
                }
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        axios
            .post(`/users/user`, {
                id: JSON.parse(window.localStorage.getItem('authUser')).user_id.toString()
            })
            .then(({ data }) => {
                setPhone(data.data.phone_no);
                setEmail(data.data.email);
                setAddress(data.data.street_address);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <Grid sm={12} container display="flex" direction="column" spacing={2} sx={{ padding: '1.5rem' }}>
            <Grid item sm={12} sx={{ paddingY: '1rem' }}>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Edit Contact Info
                </Typography>
            </Grid>
            <Grid item sm={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Phone
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={phone}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Email
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={email}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                        disabled
                    />
                    <Typography sx={{ opacity: '60%' }}>Email Address Can't Be Changed</Typography>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Address
                    </InputLabel>
                </Grid>
                <Grid item>
                    <textarea
                        placeholder="Type Here ..."
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                        rows="5"
                        cols="100"
                        fullWidth
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.08);',
                            padding: '1rem',
                            opacity: '.9',
                            width: '100%',
                            fontSize: '1.5rem'
                        }}
                        type="text"
                        value={address}
                        autoFocus
                    ></textarea>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid sm={12} container display="flex" alignItems="center" spacing={2}>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                updateProfile();
                            }}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                // submitForm();
                            }}
                            sx={{ background: 'rgba(0, 0, 0, 0.38)' }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContactInfo;
