/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WriteIcon from '../../assets/static_images/writeicon.svg';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from 'lib/axios';
import Checkbox from '@mui/material/Checkbox';
import { Link, InputLabel, OutlinedInput, Snackbar } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import avatar1 from '../../assets/images/users/avatar-1.png';
import serchicon from '../../assets/static_images/searchicon.svg';
import Upload from '../../assets/static_images/uploadpolicyactive.svg';
import WriteIcons from '../../assets/static_images/writeicon.svg';
import ShareIcon from '../../assets/static_images/share.svg';
import Button from '@mui/material/Button';
import FileIcon from '../../assets/static_images/fileicon.svg';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import PhotoIcon from '../../assets/static_images/photoicon.svg';
import PhotoIconActive from '../../assets/static_images/photoisonactive.svg';
import { Box } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { useDispatch } from 'react-redux';
import { activeLoader } from 'store/reducers/loaderSlice';
const GroupModal = ({ setShowGroupModal, showSnack, setReload }) => {
    const imageInput = useRef(null);
    const [openImageUploader, setOpenImageUploader] = useState(true);
    const [postImage, setPostImage] = useState(null);
    const [postImages, setPostImages] = useState([]);
    const [existingpostImages, setExistingPostImages] = useState([]);
    const theme = useTheme();
    const [companyUsers, setCompanyUsers] = useState([]);
    const [participantids, setParticipantids] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [filterActive, setFilterActive] = useState('all');
    const [openSelectParticipant, setOpenSelectParticipant] = useState(false);
    const [openSelectManager, setOpenSelectManager] = useState(false);
    const [managerids, setManagerids] = useState([]);
    const [query, setQuery] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [postMediaData, setPostMediaData] = useState([]);
    const [validatorMessage, setValidatorMessage] = useState([]);
    const dispatch = useDispatch();
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onprimary = (primary) => reject(primary);
        });
    async function convertMedia() {
        if (imageInput.current.files[0].size < 52453290) {
            const file = await toBase64(imageInput.current.files[0]);
            const url = URL.createObjectURL(imageInput.current.files[0]);
            setPostMediaData([...postMediaData, file]);
            setPostImages([...postImages, url]);
            setPostImage(null);
        } else {
            alert('Size Must Be Smaller Than 5MB');
        }
    }
    async function fetchUsers() {
        await axios
            .get('/users')
            .then(({ data }) => {
                if (data.success) {
                    setCompanyUsers(
                        data.users.map((item) => {
                            setUserRoles((roles) => {
                                let found = false;
                                if (roles.length > 0) {
                                    roles.forEach((role) => {
                                        if (role.role_id == item.role_id) {
                                            found = true;
                                            role.count = role.count + 1;
                                        }
                                    });
                                }
                                if (!found) {
                                    return [
                                        ...roles,
                                        {
                                            role_id: item.role_id,
                                            role_name: item.role.role_type,
                                            count: 1
                                        }
                                    ];
                                } else {
                                    return [...roles];
                                }
                            });
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role.role_type
                            };
                        })
                    );
                    setFilteredUsers(
                        data.users.map((item) => {
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role.role_type
                            };
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        fetchUsers();
    }, []);
    async function submitGroup() {
        if (groupName === '') {
            setValidatorMessage([
                {
                    field: 'groupName',
                    message: 'Group name is required'
                }
            ]);
            return;
        } else if (participantids.length === 0) {
            setValidatorMessage([
                {
                    field: 'group_members',
                    message: 'select at least one member'
                }
            ]);
            return;
        } else if (managerids.length === 0) {
            setValidatorMessage([
                {
                    field: 'group_managers',
                    message: 'select at least one manager'
                }
            ]);
            return;
        }

        if (groupName && groupName != '') {
            dispatch(activeLoader(true));
            const formData = new FormData();
            formData.append('group_name', groupName);
            formData.append('group_description', groupDescription);
            formData.append('post_media', JSON.stringify(postMediaData));
            formData.append('group_members', JSON.stringify(participantids));
            formData.append('group_managers', JSON.stringify(managerids));

            await axios
                .post(`/groups`, formData)
                .then(({ data }) => {
                    if (data.success) {
                        if (showSnack) {
                            showSnack(false, `${data.message}`);
                        }
                        setReload(true);
                        setShowGroupModal(false);
                        dispatch(activeLoader(false));
                    }
                })
                .catch((err) => {
                    dispatch(activeLoader(false));
                    console(err);
                });
        } else {
            alert('plz set group name');
        }
    }
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            {!openSelectParticipant && !openSelectManager ? (
                <Grid item>
                    <Card
                        sx={{
                            padding: '.5rem',
                            paddingBottom: '0px',
                            borderRadius: '10px',
                            width: { md: '50vw' },
                            marginTop: '6rem',
                            maxHeight: '80vh',
                            marginX: '20px'
                        }}
                    >
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                                <Box display="flex" alignItems="center">
                                    <Box mr={1}>
                                        <img src={WriteIcon} alt="edit" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h6" color="primary">
                                            Create New Group
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <Badge
                                        color="primary"
                                        badgeContent={'X'}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setShowGroupModal(false);
                                        }}
                                        sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                                    ></Badge>
                                </Box>
                            </Box>
                            <Grid sx={{ height: '61vh' }}>
                                <div className="overflw">
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <Grid container display="flex" direction="column" spacing={1} sm={12}>
                                                <Grid item>
                                                    {' '}
                                                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                                                        Group Name
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item>
                                                    <OutlinedInput
                                                        type="text"
                                                        value={groupName}
                                                        name="password"
                                                        color="primary"
                                                        onChange={(e) => {
                                                            setGroupName(e.target.value);
                                                            setValidatorMessage((prev) =>
                                                                prev.filter((item) => item.field !== 'groupName')
                                                            );
                                                        }}
                                                        placeholder="Type Here .."
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {validatorMessage.find((error) => error.field === 'groupName') && (
                                                    <Typography sx={{ fontSize: '15px', color: 'red', ml: 1 }}>
                                                        {validatorMessage.find((error) => error.field === 'groupName').message}
                                                    </Typography>
                                                )}

                                                <Grid sm={12} item display="flex" aligItems="center">
                                                    <Typography>Group Description</Typography>
                                                    <Typography sx={{ opacity: '60%' }}>(Optinal)</Typography>
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <textarea
                                                        placeholder="Type Here ..."
                                                        onChange={(e) => {
                                                            setGroupDescription(e.target.value);
                                                        }}
                                                        rows="5"
                                                        cols="100"
                                                        fullWidth
                                                        style={{
                                                            borderRadius: '4px',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.08);',
                                                            padding: '1rem',
                                                            opacity: '.9',
                                                            width: '100%',
                                                            fontSize: '1rem'
                                                        }}
                                                        type="text"
                                                        value={groupDescription}
                                                        autoFocus
                                                    ></textarea>
                                                </Grid>
                                                <Grid item sm={12} display="flex" alignItems="center">
                                                    <Typography sx={{ fontWeight: '600' }}>Group Members</Typography>
                                                </Grid>
                                                {participantids.length > 0 ? (
                                                    <Grid item sm={12} sx={{ marginTop: '.5rem' }}>
                                                        <Grid sm={12} container>
                                                            {participantids.map((participantid, index) => {
                                                                let user = companyUsers.filter((usr) => {
                                                                    if (usr.user_id == participantid) {
                                                                        return usr;
                                                                    }
                                                                });
                                                                return (
                                                                    <Grid sm={6}>
                                                                        <Grid
                                                                            container
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            mr={1}
                                                                            spacing={1}
                                                                        >
                                                                            <Grid item sx={{ zIndex: '1' }}>
                                                                                <UserAvatarComponent
                                                                                    userData={user[0]}
                                                                                    style={{
                                                                                        borderRadius: '50%',
                                                                                        height: '2.5rem',
                                                                                        width: '2.5rem'
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="h6">{user[0].user_name}</Typography>
                                                                                <Typography sx={{ opacity: '60%' }}>
                                                                                    {user[0].role_name}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                        <Grid sm={12} sx={{ marginTop: '.5rem' }} container>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    background: '#E5E5E5',
                                                                    borderRadius: '10px',
                                                                    padding: '.5rem'
                                                                }}
                                                                onClick={() => {
                                                                    setOpenSelectParticipant(true);
                                                                }}
                                                            >
                                                                <Typography sx={{ opacity: '60%' }}>+ add more</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        onClick={() => {
                                                            setOpenSelectParticipant(true);
                                                        }}
                                                    >
                                                        <OutlinedInput
                                                            type="text"
                                                            value=""
                                                            name="password"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOpenSelectParticipant(true);
                                                            }}
                                                            placeholder="Add team members"
                                                            fullWidth
                                                        />
                                                        {validatorMessage.find((error) => error.field === 'group_members') && (
                                                            <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                                                {validatorMessage.find((error) => error.field === 'group_members').message}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                )}
                                                <Grid item sm={12} display="flex" alignItems="center">
                                                    <Typography sx={{ fontWeight: '600' }}>Group Managers</Typography>
                                                </Grid>
                                                {managerids.length > 0 ? (
                                                    <Grid item sm={12} mr={1}>
                                                        <Grid sm={12} container>
                                                            {managerids.map((managerid, index) => {
                                                                let user = companyUsers.filter((usr) => {
                                                                    if (usr.user_id == managerid) {
                                                                        return usr;
                                                                    }
                                                                });
                                                                user[0].profile_image = `${
                                                                    process.env.REACT_APP_BACKEND_URL
                                                                }/Uploads/User/ProfileImage/${user[0]?.user_id.toString()}/${
                                                                    user[0].profile_image
                                                                }`;
                                                                return (
                                                                    <Grid sm={6}>
                                                                        <Grid
                                                                            container
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            spacing={1}
                                                                            sx={{ mr: '10px' }}
                                                                        >
                                                                            <Grid item sx={{ zIndex: '1' }}>
                                                                                <UserAvatarComponent
                                                                                    userData={user[0]}
                                                                                    style={{
                                                                                        borderRadius: '50%',
                                                                                        height: '2.5rem',
                                                                                        width: '2.5rem'
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="h6">{user[0].user_name}</Typography>
                                                                                <Typography sx={{ opacity: '60%' }}>
                                                                                    {user[0].role_name}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                        <Grid sm={12} sx={{ marginTop: '.5rem' }} container>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    background: '#E5E5E5',
                                                                    borderRadius: '10px',
                                                                    padding: '.5rem'
                                                                }}
                                                                onClick={() => {
                                                                    setOpenSelectManager(true);
                                                                }}
                                                            >
                                                                <Typography sx={{ opacity: '60%' }}>+ add more</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        onClick={() => {
                                                            setOpenSelectManager(true);
                                                        }}
                                                    >
                                                        <OutlinedInput
                                                            type="text"
                                                            value=""
                                                            color="primary"
                                                            onClick={() => {
                                                                setOpenSelectManager(true);
                                                            }}
                                                            placeholder="Add team members"
                                                            fullWidth
                                                        />
                                                        {validatorMessage.find((error) => error.field === 'group_managers') && (
                                                            <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                                                {validatorMessage.find((error) => error.field === 'group_managers').message}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {openImageUploader ? (
                                                <>
                                                    <>
                                                        {postImages.length > 0 ? (
                                                            <Grid container sx={{ marginY: '1rem' }}>
                                                                <Grid item sm={12}>
                                                                    <Grid container spacing={1.5} display="flex">
                                                                        {postImages.map((image, index) => {
                                                                            return (
                                                                                <Grid
                                                                                    item
                                                                                    sm={6}
                                                                                    key={`img@${index}`}
                                                                                    sx={{ position: 'relative' }}
                                                                                    className="img-box"
                                                                                >
                                                                                    {postMediaData[index]
                                                                                        .split(';base64')[0]
                                                                                        .split(':')[1]
                                                                                        .split('/')[0] == 'image' ? (
                                                                                        <img
                                                                                            src={image}
                                                                                            alt="uploaded"
                                                                                            className="uploaded-img"
                                                                                        />
                                                                                    ) : (
                                                                                        <video
                                                                                            className="uploaded-img"
                                                                                            width="100%"
                                                                                            controls
                                                                                            src={image}
                                                                                        />
                                                                                    )}

                                                                                    <Grid
                                                                                        item
                                                                                        onClick={() => {
                                                                                            {
                                                                                                console.log();
                                                                                            }
                                                                                            setPostImages((postImages) => {
                                                                                                let temp = [...postImages];
                                                                                                temp.splice(index, 1);
                                                                                                return temp;
                                                                                            });

                                                                                            setTimeout(() => {
                                                                                                textInput.current.focus();
                                                                                            }, 0.5);
                                                                                        }}
                                                                                        display="flex"
                                                                                        direction="column"
                                                                                        justifyContent="start"
                                                                                        alignItems="start"
                                                                                        sx={{
                                                                                            position: 'absolute',
                                                                                            top: '0px',
                                                                                            right: '5px',
                                                                                            opacity: '0'
                                                                                        }}
                                                                                        className="trash cursor-pointer"
                                                                                    >
                                                                                        <Grid>
                                                                                            {' '}
                                                                                            <Badge
                                                                                                color="primary"
                                                                                                badgeContent={'X'}
                                                                                                sx={{ marginLeft: 'auto' }}
                                                                                            ></Badge>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            );
                                                                        })}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Grid
                                                                container
                                                                sm={12}
                                                                display="flex"
                                                                direction="column"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                className="dashed"
                                                                sx={{
                                                                    padding: '3rem',
                                                                    marginTop: '2rem',
                                                                    borderRadius: '10px',
                                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                                }}
                                                            >
                                                                <Badge
                                                                    color="primary"
                                                                    className="float-end cursor-pointer"
                                                                    badgeContent={'X'}
                                                                    onClick={() => {
                                                                        setOpenImageUploader(false);
                                                                    }}
                                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                                ></Badge>
                                                                <Grid
                                                                    item
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    sm={12}
                                                                    className="cursor-pointer"
                                                                    onClick={() => imageInput.current.click()}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        display="flex"
                                                                        direction="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        sm={12}
                                                                        sx={{ paddingY: '2rem' }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            sm={12}
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                        >
                                                                            {' '}
                                                                            <img src={PhotoIcon} alt="logo" width={24} height={24} />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                            sm={12}
                                                                        >
                                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                                {' '}
                                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                                    Add group image
                                                                                </a>{' '}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </>
                                                    <div sx={{ display: 'none' }}>
                                                        <input
                                                            ref={imageInput}
                                                            type="file"
                                                            accept="image/*,video/*"
                                                            name="imageInput"
                                                            onChange={convertMedia}
                                                            className="hidden"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid sm={12}>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        submitGroup();
                                    }}
                                >
                                    <Typography variant="h6">CREATE</Typography>
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
            {openSelectParticipant ? (
                <Grid item className="collapse">
                    <Card
                        sx={{
                            padding: '.5rem',
                            borderRadius: '10px',
                            width: { md: '50vw', xs: '90vw' },
                            marginTop: '6rem',
                            height: '80vh',
                            overFlowY: 'scroll'
                        }}
                        className="scroll"
                    >
                        <CardContent>
                            <Grid
                                container
                                display="flex"
                                justifyContent="between"
                                alignItems="center"
                                sx={{ paddingBottom: '1rem', position: 'relative' }}
                                sm={12}
                            >
                                <Grid container sm={12}>
                                    <Grid item sm={11}>
                                        <OutlinedInput
                                            value={query}
                                            name="query"
                                            onChange={(e) => {
                                                if (e.target.value == '') {
                                                    setFilteredUsers(companyUsers);
                                                }
                                                setQuery(e.target.value);
                                            }}
                                            placeholder="Search .."
                                            fullWidth
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Button
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setFilteredUsers(
                                                    filteredUsers.filter((user) => {
                                                        if (user.user_name == query) {
                                                            return user;
                                                        }
                                                    })
                                                );
                                            }}
                                            sx={{ height: '100%' }}
                                        >
                                            <img src={serchicon} alt="src" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box container display="flex" className="overflow-x" spacing={2}>
                                <Box
                                    item
                                    className={`${
                                        filterActive == 'all' ? 'filter-badge-active  cursor-pointer' : 'filter-badge cursor-pointer'
                                    }`}
                                    onClick={() => {
                                        setFilterActive('all');
                                    }}
                                >
                                    All({companyUsers.length})
                                </Box>
                                {userRoles.map((role) => {
                                    return (
                                        <Box
                                            item
                                            key={`${role.role_id}-role`}
                                            value={role.role_id}
                                            className={`${
                                                filterActive == role.role_id
                                                    ? 'filter-badge-active  cursor-pointer'
                                                    : 'filter-badge cursor-pointer'
                                            }`}
                                            sx={{
                                                marginX: '.3rem'
                                            }}
                                            onClick={() => {
                                                setFilterActive(role.role_id);
                                            }}
                                        >
                                            {role.role_name}({role.count})
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Grid
                                container
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sm={12}
                                className="border-bottom"
                            >
                                <Grid item sm={6}>
                                    <Typography variant="h6" color="primary">{`Selected(${participantids.length})`}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    display="flex"
                                    justifyContent="end"
                                    onClick={() => {
                                        setParticipantids(() => companyUsers.map((part) => part.user_id));
                                        setFilterActive('all');
                                    }}
                                >
                                    <Typography color="primary">Select All</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    maxHeight: '41vh',
                                    overFlowY: 'scroll'
                                }}
                                className="scroll"
                            >
                                {filteredUsers.length > 0 &&
                                    filteredUsers.map((item, index) => {
                                        if (item.role_id == filterActive || filterActive == 'all') {
                                            return (
                                                <Grid item sm={12} key={`${index}-users`}>
                                                    <Grid container display="flex" aligItems="center" sm={12} spacing={1}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        sx={{ marginX: '0px' }}
                                                                        checked={participantids.includes(item.user_id) ? true : false}
                                                                        onChange={() => {
                                                                            participantids.includes(item.user_id)
                                                                                ? setParticipantids((data) => {
                                                                                      return [
                                                                                          ...data.map((id) => {
                                                                                              if (id != item.user_id) {
                                                                                                  return id;
                                                                                              }
                                                                                          })
                                                                                      ];
                                                                                  })
                                                                                : setParticipantids((data) => [...data, item.user_id]);
                                                                        }}
                                                                    />
                                                                }
                                                                label={''}
                                                                sx={{ marginX: '0px' }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            onClick={() => {
                                                                participantids.includes(item.user_id)
                                                                    ? setParticipantids((data) => {
                                                                          return [
                                                                              ...data.map((id) => {
                                                                                  if (id != item.user_id) {
                                                                                      return id;
                                                                                  }
                                                                              })
                                                                          ];
                                                                      })
                                                                    : setParticipantids((data) => [...data, item.user_id]);
                                                            }}
                                                        >
                                                            <Grid container display="flex" aligItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <UserAvatarComponent
                                                                        userData={item}
                                                                        style={{
                                                                            borderRadius: '50%',
                                                                            height: '2.5rem',
                                                                            width: '2.5rem'
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="h6">{item.user_name}</Typography>
                                                                    <Typography>{item.role_name}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })}
                            </Grid>
                            <Grid container xs={12} sm={12} spacing={2}>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenSelectParticipant(false);
                                        }}
                                    >
                                        <Typography variant="h6">Select</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setParticipantids([]);
                                            setOpenSelectParticipant(false);
                                            setFilterActive('all');
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
            {openSelectManager ? (
                <Grid item className="collapse">
                    <Card
                        sx={{
                            padding: '.5rem',
                            borderRadius: '10px',
                            width: { md: '50vw', xs: '90vw' },
                            marginTop: '6rem',
                            height: '80vh',
                            overFlowY: 'scroll'
                        }}
                        className="scroll"
                    >
                        <CardContent>
                            <Grid
                                container
                                display="flex"
                                justifyContent="between"
                                alignItems="center"
                                sx={{ paddingBottom: '1rem', position: 'relative' }}
                                sm={12}
                            >
                                <Grid container sm={12}>
                                    <Grid item sm={11}>
                                        <OutlinedInput
                                            value={query}
                                            name="email"
                                            onChange={(e) => {
                                                if (e.target.value == '') {
                                                    setFilteredUsers(companyUsers);
                                                }
                                                setQuery(e.target.value);
                                            }}
                                            placeholder="Search .."
                                            fullWidth
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Button
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setFilteredUsers(
                                                    filteredUsers.filter((user) => {
                                                        if (user.user_name == query) {
                                                            return user;
                                                        }
                                                    })
                                                );
                                            }}
                                            sx={{ height: '100%' }}
                                        >
                                            <img src={serchicon} alt="src" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box container display="flex" className="overflow-x" spacing={2}>
                                <Box
                                    item
                                    className={`${
                                        filterActive == 'all' ? 'filter-badge-active  cursor-pointer' : 'filter-badge cursor-pointer'
                                    }`}
                                    onClick={() => {
                                        setFilterActive('all');
                                    }}
                                >
                                    All({companyUsers.length})
                                </Box>
                                {userRoles.map((role) => {
                                    return (
                                        <Box
                                            item
                                            key={`${role.role_id}-role`}
                                            value={role.role_id}
                                            className={`${
                                                filterActive == role.role_id
                                                    ? 'filter-badge-active  cursor-pointer'
                                                    : 'filter-badge cursor-pointer'
                                            }`}
                                            sx={{
                                                marginX: '.3rem'
                                            }}
                                            onClick={() => {
                                                setFilterActive(role.role_id);
                                            }}
                                        >
                                            {role.role_name}({role.count})
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Grid
                                container
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sm={12}
                                className="border-bottom"
                            >
                                <Grid item sm={6}>
                                    <Typography variant="h6" color="primary">{`Selected(${participantids.length})`}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    display="flex"
                                    justifyContent="end"
                                    onClick={() => {
                                        setManagerids(() => companyUsers.map((part) => part.user_id));
                                        setFilterActive('all');
                                    }}
                                >
                                    <Typography color="primary">Select All</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    maxHeight: '41vh',
                                    overFlowY: 'scroll'
                                }}
                                className="scroll"
                            >
                                {filteredUsers.map((item, index) => {
                                    if (item.role_id == filterActive || filterActive == 'all') {
                                        return (
                                            <Grid item sm={12} key={`${index}-users`}>
                                                <Grid container display="flex" aligItems="center" sm={12} spacing={1}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    sx={{ marginX: '0px' }}
                                                                    checked={managerids.includes(item.user_id) ? true : false}
                                                                    onChange={() => {
                                                                        managerids.includes(item.user_id)
                                                                            ? setManagerids((data) => {
                                                                                  return [
                                                                                      ...data.map((id) => {
                                                                                          if (id != item.user_id) {
                                                                                              return id;
                                                                                          }
                                                                                      })
                                                                                  ];
                                                                              })
                                                                            : setManagerids((data) => [...data, item.user_id]);
                                                                    }}
                                                                />
                                                            }
                                                            label={''}
                                                            sx={{ marginX: '0px' }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        onClick={() => {
                                                            managerids.includes(item.user_id)
                                                                ? setManagerids((data) => {
                                                                      return [
                                                                          ...data.map((id) => {
                                                                              if (id != item.user_id) {
                                                                                  return id;
                                                                              }
                                                                          })
                                                                      ];
                                                                  })
                                                                : setManagerids((data) => [...data, item.user_id]);
                                                        }}
                                                    >
                                                        <Grid container display="flex" aligItems="center" spacing={1}>
                                                            <Grid item>
                                                                <UserAvatarComponent
                                                                    userData={item}
                                                                    style={{
                                                                        borderRadius: '50%',
                                                                        height: '2.5rem',
                                                                        width: '2.5rem'
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="h6">{item.user_name}</Typography>
                                                                <Typography>{item.role_name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                })}
                            </Grid>
                            <Grid container xs={12} sm={12} spacing={2}>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setOpenSelectManager(false);
                                        }}
                                    >
                                        <Typography variant="h6">Select</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setManagerids([]);
                                            setOpenSelectManager(false);
                                            setFilterActive('all');
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography variant="h6">Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default GroupModal;
