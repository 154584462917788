import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
// import { Grid } from '@mui/material';
import Grid from '@mui/material/Grid';

// project import
// import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';

// types
import { openDrawer } from 'store/reducers/menu';
import LeftSide from './LeftSide/index';
import RightSide from './rightSide/index';
// ==============================|| MAIN LAYOUT ||============================== //
import axios from 'lib/axios';
import { rightSideDrawer, selectNewsNoticeType } from 'store/reducers/frontMenu';
import RightDrawer from './rightSide/RightDrawer';
import { useLocation } from 'react-router-dom';
import LoadingModal from 'components/modals/LoadingModal';
import { Modal } from '@mui/material';
import TopPosts from 'components/right-side/TopPosts';
import Side from 'components/news/Side';
import SideEvent from 'components/event/Side';
import SideMeeting from 'components/meeting/Side';
import SidePolicy from 'components/policies/Side';
import TopLibraries from 'components/right-side/TopLibraries';
import CompanyPosts from 'components/CompanyPosts';

const FrontLayout = () => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const noticeId = query.get('notice_id');

    const theme = useTheme();
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [styl, setStyl] = useState(
        matchesXs ? { display: 'flex', width: '100%' } : { display: 'flex', width: '100%', paddingX: { xs: '0rem', md: '10rem' } }
    );
    const [showArrow, setShowArrow] = useState(false);
    const { drawerOpen } = useSelector((state) => {
        return state.menu;
    });
    const [component, setComponent] = useState(null);
    const [column, setColumn] = useState(6);
    // drawer toggler
    const [open, setOpen] = useState(true);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    const { rightSideDrawerOpen, selectType } = useSelector((state) => state.frontMenu);
    const { isLoading } = useSelector((state) => state.loaderSlice);

    // set media wise responsive drawer
    useEffect(() => {
        if (!(window.localStorage.getItem('access_token') && window.localStorage.getItem('access_token') !== '')) {
            location.replace('/auth/login');
        } else {
            axios
                .get(`/sitesettings/home_page`)
                .then(({ data }) => {
                    if (data.success && `${window.location.origin}/` == window.location.href) {
                        data.setting?.page_settings?.map((page) => {
                            if (page.active == 1 && window.location.pathname != page.url) {
                                location.replace(page.url);
                            }
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }, []);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (document.location.pathname.toString().split('/')[1] == 'tasks') {
            setStyl({ display: 'flex', width: '100%', paddingLeft: { md: '10rem' }, paddingRight: '0px' });
        }
    }, [drawerOpen]);

    const { pathname } = useLocation();

    useEffect(() => {
        switch (pathname) {
            case '/get-social':
                setColumn(6);
                setComponent(<TopPosts type={`post`} />);
                setShowArrow(true);
                break;
            case '/leadersvoice':
                setColumn(6);
                setComponent(<TopPosts type={`leader_voice`} />);
                setShowArrow(true);
                break;
            case '/news-events/news':
                setColumn(6);
                setComponent(selectType === 'news' ? <Side type="news" /> : <Side type="notice" />);
                setShowArrow(true);
                break;
            case '/news-events/events':
                setColumn(6);
                setComponent(<SideEvent model={`event`} />);
                setShowArrow(true);
                break;
            case '/meeting':
                setColumn(6);
                setComponent(<SideMeeting />);
                setShowArrow(true);
                break;
            case '/policy-procedure':
                setColumn(6);
                setComponent(<SidePolicy />);
                setShowArrow(true);
                break;
            case '/company-library':
                setColumn(6);
                setComponent(<TopLibraries />);
                setShowArrow(true);
                break;
            case '/voice':
                setColumn(6);
                setComponent(<CompanyPosts companyId={JSON.parse(window.localStorage.getItem('authUser')).company_id} />);
                setShowArrow(true);
                break;

            default:
                setColumn(9);
                setComponent(null);
                setShowArrow(false);
        }
    }, [pathname, selectType]);

    useEffect(() => {
        if (noticeId) {
            dispatch(selectNewsNoticeType('notice'));
        }
    }, [noticeId]);
    return (
        <Box sx={styl}>
            <Grid container sm={12} direction="column" justifyContent="center">
                {showArrow && (
                    <Box
                        onClick={() => dispatch(rightSideDrawer(true))}
                        sx={{
                            position: 'fixed',
                            right: '0px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#30C6D9',
                            paddingY: '10px',
                            paddingX: '5px',
                            borderRadius: '2px',
                            display: { xs: 'block', md: 'none' },
                            zIndex: '100'
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M7.06668 8.47136C6.94169 8.34634 6.87148 8.1768 6.87148 8.00003C6.87148 7.82325 6.94169 7.65371 7.06668 7.52869L10.1233 4.47136C10.2448 4.34563 10.312 4.17722 10.3105 4.00243C10.3089 3.82763 10.2388 3.66042 10.1152 3.53682C9.99162 3.41321 9.82441 3.3431 9.64961 3.34158C9.47481 3.34006 9.30641 3.40726 9.18068 3.52869L6.12601 6.58603C5.75107 6.96108 5.54044 7.4697 5.54044 8.00003C5.54044 8.53036 5.75107 9.03897 6.12601 9.41403L9.18334 12.4714C9.30908 12.5928 9.47748 12.66 9.65228 12.6585C9.82707 12.657 9.99428 12.5868 10.1179 12.4632C10.2415 12.3396 10.3116 12.1724 10.3131 11.9976C10.3146 11.8228 10.2474 11.6544 10.126 11.5287L7.06668 8.47136Z"
                                fill="white"
                            />
                        </svg>
                    </Box>
                )}
                <Box item>
                    <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                </Box>

                <Grid item sm={12} sx={{ marginTop: { xs: '5.5rem', md: '6.5rem' }, width: '100%' }}>
                    {!matchesXs && (
                        <>
                            <Grid container sm={12} spacing={2} sx={{ width: '100%' }}>
                                <Grid item sm={3} sx={{ width: '100%' }}>
                                    <LeftSide />
                                </Grid>
                                <Grid item sm={column} sx={{ width: '100%' }}>
                                    <Outlet />
                                </Grid>
                                <Grid item sm={3} sx={{ width: '100%' }}>
                                    {component}
                                </Grid>
                            </Grid>

                            {/* global loading */}
                            {isLoading && (
                                <Modal
                                    keepMounted
                                    open={isLoading}
                                    aria-labelledby="keep-mounted-modal-title"
                                    aria-describedby="keep-mounted-modal-description"
                                >
                                    <LoadingModal />
                                </Modal>
                            )}
                        </>
                    )}
                    {matchesXs && (
                        <Grid>
                            {isLoading && (
                                <Modal
                                    keepMounted
                                    open={isLoading}
                                    aria-labelledby="keep-mounted-modal-title"
                                    aria-describedby="keep-mounted-modal-description"
                                >
                                    <LoadingModal />
                                </Modal>
                            )}
                            <Grid item sm={12} sx={{ marginX: { xs: '5px' } }}>
                                <Outlet />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {rightSideDrawerOpen && <RightDrawer />}

            {/* <Drawer open={true} handleDrawerToggle={handleDrawerToggle} /> */}
        </Box>
    );
};

export default FrontLayout;
