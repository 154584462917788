import React, { useState } from 'react';
import { Box, Typography, Button, InputLabel, Card, OutlinedInput } from '@mui/material';
import axios from 'lib/axios';
import { toast } from 'sonner';

export default function CreateFeedback() {
    const [formData, setFormData] = useState({
        subject: '',
        description: '',
        phone: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const user = JSON.parse(localStorage.getItem('authUser'));
    console.log(user);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('/feedback', {
                subject: formData.subject,
                description: formData.description,
                phone_number: formData.phone,
                user_id: user.user_id
            });
            if (response.data.success) {
                console.log('response', response);
                if (response.data) {
                    toast.success('Feedback submitted successfully!');
                    setFormData({
                        subject: '',
                        description: '',
                        phone: ''
                    });
                }
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            alert('Error submitting feedback. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    console.log(formData);

    return (
        <Card sx={{ height: '100%', width: '100%', p: 2 }} display="flex" justifyContent="center" alignItems="center">
            <Box sx={{ maxWidth: '600px', mx: 'auto', mt: 5 }}>
                <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
                    Create Feedback
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Subject</InputLabel>
                        <OutlinedInput
                            fullWidth
                            placeholder="Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </Box>

                    <Box sx={{ mb: 1 }}>
                        <InputLabel>Description</InputLabel>
                        <textarea
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                            rows={4}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '4px',
                                borderColor: '#ccc',
                                fontFamily: 'inherit',
                                fontSize: 'inherit',
                                resize: 'vertical'
                            }}
                        />
                    </Box>

                    <Box sx={{ mb: 3 }}>
                        <InputLabel>Phone Number</InputLabel>
                        <OutlinedInput
                            fullWidth
                            placeholder="Phone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                p: 1,

                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(30, 124, 136, 0.5)'
                                }
                            }}
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit Feedback'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Card>
    );
}
