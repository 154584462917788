/* eslint-disable prettier/prettier */
// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from '../../../components/third-party/SimpleBar';
import MiniDrawerStyled from './/MiniDrawerStyled';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';

// ==============================|| DRAWER CONTENT ||============================== //

const LeftSide = () => (
    <Box component="nav" sx={{ flexShrink: { md: 0 } }}>
        {/* <SimpleBar
                sx={{
                    '& .simplebar-content': {
                        display: 'flex',
                        flexDirection: 'column',
                    }
                }}
            > */}
        <Box
            sx={{
                overflowY: 'scroll',
                border: '1px solid #1E7C8840',
                borderRadius: '8px',
                backgroundColor: '#fff',
                height: 'calc(100vh - 7.5rem)'
            }}
        >
            <Navigation />
        </Box>
        {/* <NavCard /> */}
        {/* </SimpleBar> */}
    </Box>
);

export default LeftSide;
