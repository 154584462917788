import React from 'react';
import { Typography, Box } from '@mui/material';

const Privacy = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                maxHeight: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                oMirror Privacy Policy
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%', fontWeight: 'bold' }} mt={1}>
                Effective Date: March 31, 2024 (Version A)
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Using OMirror services means you’re trusting us with your information. We understand the importance of this responsibility,
                intangible value of a confidential information, and therefore we try to do everything possible to protect your contents.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                This Privacy Policy applies only for OMirror Web and Mobile Application services and doesn’t apply to services that have
                separate privacy policies.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                This Privacy Policy doesn’t apply to services offered by other companies or individuals, including products or Apps offer
                that may include OMirror services to which the policy applies.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your
                explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for
                your review. If changes are significant, we’ll provide a more prominent notice, including, email notification for certain
                services.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                This Privacy Policy is to help you understand what information we will collect and why we will collect it, and how you can
                stop sharing that limited information.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We want you to know the types of information we collect from OMirror subscribers and why we collect it;
            </Typography>
            <ul>
                <li style={{ marginTop: '10px' }}>
                    1. We collect oMirror client engagement data/number, not the contents, in a year/years of different features to
                    understand if our features are helping the organizations increase its people engagement. We analyze the data and
                    continually improve features, designs, processes so that organizations people engagement increases over the period of
                    time.1. We collect oMirror client engagement data/number, not the contents, in a year/years of different features to
                    understand if our features are helping the organizations increase its people engagement. We analyze the data and
                    continually improve features, designs, processes so that organizations people engagement increases over the period of
                    time.
                </li>
                <li style={{ marginTop: '10px' }}>
                    2. We collect feedbacks, errors report, and ideas provided by OMirror users to continually improve OMirror features and
                    services that help organizations get the maximum benefit of their investment.
                </li>
                <li style={{ marginTop: '10px' }}>
                    3. If installed in our cloud, we collect subscribers cloud space data to notify you to upgrade your space when
                    necessary.
                </li>
            </ul>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We want you to know the types of information we collect from OMirror subscribers and why we collect it;
            </Typography>
            <ul>
                <li style={{ marginTop: '10px' }}>
                    1. We collect oMirror client engagement data/number, not the contents, in a year/years of different features to
                    understand if our features are helping the organizations increase its people engagement. We analyze the data and
                    continually improve features, designs, processes so that organizations people engagement increases over the period of
                    time.1. We collect oMirror client engagement data/number, not the contents, in a year/years of different features to
                    understand if our features are helping the organizations increase its people engagement. We analyze the data and
                    continually improve features, designs, processes so that organizations people engagement increases over the period of
                    time.
                </li>
                <li style={{ marginTop: '10px' }}>
                    1. Your contents, posts, texts, records, reports, etc. that are your confidential information.
                </li>
                <li style={{ marginTop: '10px' }}>2. Users personal information and data.</li>
            </ul>
            {/*  */}
            <Typography variant="h5" sx={{ fontWeight: 'bold' }} mt={2}>
                Privacy policy for legal purpose:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We may have to use, share, or disclose OMirror subscribers name, list, user base, etc. on good- faith that is reasonably
                necessary to:
            </Typography>
            <ul>
                <li style={{ marginTop: '10px' }}>
                    1. Meet any applicable law, regulation, legal process, or enforceable governmental request.
                </li>
                <li style={{ marginTop: '10px' }}>
                    1. Meet any applicable law, regulation, legal process, or enforceable governmental request.
                </li>
                <li style={{ marginTop: '10px' }}>3. Detect, prevent, or otherwise address any fraud, security, or technical issues.</li>
                <li style={{ marginTop: '10px' }}>
                    4. Share subscribers name and number to legal entities, publishers, advertisers, and developers, etc.
                </li>
                <li style={{ marginTop: '10px' }}>5. Merger, acquisition, or sale.</li>
            </ul>
            {/*  */}
            <Typography variant="h5" sx={{ fontWeight: 'bold' }} mt={2}>
                Why we collect some data:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Maintain & improve our services
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We use subscriber engagement data to make sure OMirror is providing services as intended, such as; organizations engagement
                stat, user feedback stat, user ideas, error reports, etc. to improve our features and services.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Develop new services
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} mt={2}>
                We use engagement information to help us brainstorming new ideas. For example; your ideas may help us create more features
                or improve an existing feature t improve engagement.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Measure performance
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We use data analytics to understand how our services are used. For example, we analyze data about most engaging to lease
                engagement areas to optimize product designs and process.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Communicate
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We use information to interact with you directly. For example, we may send you a notification if we detect your cloud space
                needs to be extended to have uninterrupted services. If you contact us through email or website, we keep a record of
                requests and used those data to reduce future complains.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Protect OMirror
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We use information to help improve the security of our services. This includes detecting, preventing, and responding to
                security risks and technical issues that could harm OMirror services. For example, if we receive a feedback from user(s)
                about a potential hacking, we take actions and find solutions to improve OMirror security. We use algorithms to recognize
                patterns of threat and find solutions to protect oMirror.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We’ll ask for your consent before using an information that isn’t covered in this Privacy Policy.
            </Typography>
            {/*  */}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} mt={2}>
                User activity:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We give permission to OMirror client admins and admin permitted users to view specific user activity, for example; your
                supervisor may view your team engagement activity, engagement graph, team tasks, projects, goals, knowledge, etc. so that
                supervisors can take steps to improve team engagement and reward the best, if so applicable.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Your personal information
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                You can update your personal information and your profile information is available to all users within your organization,
                except few information like emergency contact, present and permanent address, etc. are kept confidential to company admin
                and selective users to use it on emergency.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Edit or delete content
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                You can edit or delete a content posted by you, except you cannot delete or edit a content if your account is blocked by
                Admin for some reasons.
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} mt={2}>
                Account password protection
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We recommend users and Admin to change password every 3 to 6 months and save it in a secure place to avoid a security breach
                by an unauthorized user who may leak your personal and company information to your competitors, enemies, etc. and take
                advantage of it.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                OMirror developers or Eyevary Limited staff, under no circumstances, will access to your account without your organization
                permission. You must notify your oMirror admin of any suspicion of breach into your account by any unauthorized person, or
                let us know through “user feedback form” at OMirror Sign-out TAB.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Security of your contents:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We, as well as our cloud service provider(s), shall ensure the security of your contents stored in our cloud services. We
                make strong security agreements with our cloud service providers so that under no circumstances our subscribers contents are
                leaked, breached, hacked, or stolen by any other person or company except the owner of the contents.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                If we detect something risky that we think you should know about, we’ll notify you and help guide you through steps to stay
                better protected.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We protect OMirror from unauthorized access, alteration, disclosure, or destruction of information we hold, including 2 Step
                Verification to help you protect your account
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We restrict access to your contents to Eyevary Limited employees, Developers, Service Providers, and Contractors, who need
                certain information in order to develop, update, or upgrade OMirror services. Anyone with the access to subscribers’
                information, contents, etc.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                are subject to strict Non-Disclosure Agreement (NDA) and may be disciplined, terminated, and/or face legal consequences if
                they fail to meet the obligations.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Blocking a user account:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Subscriber company admin may Block an user account for administrative reasons, unblock when needed, or delete an account if
                the user is not in that organization oMirror anymore.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                Some data you can delete yourself whenever you like, such as your personal info or the content you created or uploaded, like
                photos, documents, Video, etc.
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} mt={2}>
                Regulatory Compliance:
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We regularly review our Privacy Policy and make sure we process your information in ways that comply with regulatory
                policies.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                We may maintain cloud services and servers around the world and your information may be processed on servers located outside
                of the country where you live. Data protection laws may vary among countries; regardless we apply the same protections
                described in this policy.
            </Typography>
            <Typography variant="p" sx={{ opacity: '70%' }} mt={2}>
                If we receive any formal complaints, we work with the appropriate regulatory authorities, including local data protection
                authorities to resolve any complaints.
            </Typography>
        </Box>
    );
};

export default Privacy;
