import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Grid, Modal } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { convertDateAndTime, processToUpper, timeDiffer, urlify } from 'Helpers';
import { socket } from 'socket';
import VoiceViewer from 'components/modals/VoiceViewer';

const MiniPostCard = ({ item, type }) => {
    const [showMoreBtn, setShowMoreBtn] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [reactCount, setReactCount] = useState(type === 'news' ? parseInt(item.newsReactCount) : parseInt(item.noticeReactCount));
    const [OpenViewModal, setOpenViewModal] = useState(false);
    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on(`${type}_mini_react_added`, (id) => {
            if (id == item[`${type}_id`]) {
                setReactCount((prev) => prev + 1);
            }
        });
        socket.on(`${type}_mini_react_removed`, (id) => {
            if (id == item[`${type}_id`]) {
                setReactCount((prev) => prev - 1);
            }
        });
    }
    return (
        <>
            <Box paddingX="1rem" marginBottom="1rem">
                <Box display="flex" alignItems="center" gap="0.5rem" sx={{ position: 'relative' }}>
                    <Box>
                        <UserAvatarComponent userData={item.user} />
                    </Box>
                    <Box>
                        <Typography variant="h6" sx={{ fontSize: '14px' }}>{`${item.user?.first_name} ${item.user?.last_name}`}</Typography>
                        <Box display="flex" gap="0.5rem">
                            <Typography variant="subtitle1" sx={{ fontSize: '13px', opacity: '0.5' }}>{`${processToUpper(
                                item.user?.role?.role_type ?? 'N/A'
                            )}`}</Typography>
                            <Typography variant="subtitle1" sx={{ opacity: '0.5', fontSize: '13px' }}>
                                {convertDateAndTime(item.createdAt, false)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Grid item sm={12} sx={{ padding: '0px', fontSize: '14px' }}>
                    {urlify(item.plain_text_body).length > 35 ? (
                        <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                            {showMore ? urlify(item.plain_text_body) : `${urlify(item.plain_text_body).substring(0, 35)}...`}
                            {!showMore ? (
                                <Button
                                    className="btn"
                                    sx={{ fontSize: '13px' }}
                                    onClick={() => {
                                        setShowMore(!showMore);
                                    }}
                                >
                                    View more
                                </Button>
                            ) : (
                                <Button
                                    className="btn"
                                    onClick={() => {
                                        setShowMore(!showMore);
                                    }}
                                >
                                    View less
                                </Button>
                            )}
                        </Grid>
                    ) : (
                        <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                            {urlify(item.plain_text_body)}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    sm={12}
                    className="cursor-pointer"
                    onClick={() => {
                        // location.replace(
                        //     `${window.location.origin}/single-view/type=${type}&model_id=${item[`${type}_id`]}${`&comment_id=0`}`
                        // );
                        setOpenViewModal(true);
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Typography sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }} color="primary">
                        Likes ({reactCount})
                    </Typography>
                    <Typography
                        sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600', textTransform: 'capitalize' }}
                        color="primary"
                    >
                        View {`${type}`}
                    </Typography>
                </Grid>
            </Box>
            {OpenViewModal && (
                <Modal
                    keepMounted
                    open={OpenViewModal}
                    onClose={() => setOpenViewModal(false)}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid
                        item
                        sm={12}
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100vw', height: '100vh', paddingX: '1.5rem' }}
                    >
                        <VoiceViewer post={item} setOpenViewer={setOpenViewModal} model={type} type="view" />
                    </Grid>
                </Modal>
            )}
        </>
    );
};

export default MiniPostCard;
