import React from 'react';
import axios from 'lib/axios';
const HasPermission = async (setCanPost, model, action, key = null) => {
    await axios
        .post(`/has-permission`, {
            model: model,
            action: action,
            key: key
        })
        .then(({ data }) => {
            if (data.success) {
                setCanPost(true);
            }
        })
        .catch((err) => console.log(err));
};
export const HasPermissionReturn = async (model, action, key = null) => {
    await axios
        .post(`/has-permission`, {
            model: model,
            action: action,
            key: key
        })
        .then(({ data }) => {
            if (data.success) {
                return true;
            } else {
                return false;
            }
        })
        .catch((err) => console.log(err));
};

export default HasPermission;
