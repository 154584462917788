/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import Analytics from 'components/profile/Analytics';
import { Card } from '@mui/material';

const SupervisorAnalytics = () => {
    return (
        <Card
            sx={{
                height: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Analytics />
        </Card>
    );
};

export default SupervisorAnalytics;
