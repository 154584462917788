/* eslint-disable prettier/prettier */
// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

// ==============================|| DRAWER CONTENT ||============================== //
const links = [
    {
        name: 'Terms & Conditions',
        path: '/terms-and-conditions'
    },
    {
        name: 'Privacy Policy',
        path: '/privacy-policy'
    },
    {
        name: 'Refund Policy',
        path: '/refund-policy'
    }
];
const DrawerContent = () => (
    <SimpleBar
        sx={{
            '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
            }
        }}
    >
        <Navigation />
        {/* <NavCard /> */}
        {/* terms and condition */}
        <Box sx={{ mt: '2rem', pl: '28px', display: 'flex', gap: '0px 5px', flexWrap: 'wrap' }}>
            {links.map((link, index) => {
                return (
                    <Box>
                        <Link key={index} to={link.path} style={{ color: 'white', textDecoration: 'none' }}>
                            <Typography sx={{ fontSize: '13px' }}>
                                {link.name} {index != links.length - 1 ? '.' : ''}
                            </Typography>
                        </Link>
                    </Box>
                );
            })}
        </Box>
        <Box sx={{ pl: '28px', pr: '16px', pb: '1.5rem' }}>
            <Typography sx={{ fontSize: '13px', fontWeight: '700', color: 'white' }}>©Omirror 2.0.0</Typography>
        </Box>
    </SimpleBar>
);

export default DrawerContent;
