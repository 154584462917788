import {
    Box,
    Card,
    Button,
    OutlinedInput,
    Badge,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    CardContent
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import PhotoIcon from '../../assets/static_images/photoicon.svg';
import axios from 'lib/axios';
import DbounceSearch from 'components/DbounceSearch';
import debouce from 'lodash.debounce';
import SelectionItem from 'components/SelectionItem';

const AppStoreModal = ({ setApplications, setOpenModal, selectedApp = null }) => {
    // console.log('al', selectedApp);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(selectedApp ? selectedApp.application_category_id : '');
    const [postMediaFile, setPostMediaFile] = useState(null);
    const [image, setImage] = useState(null);
    const [validatorMessage, setValidatorMessage] = useState([]);
    const [name, setName] = useState(selectedApp ? selectedApp?.title : '');
    const [url, setUrl] = useState(selectedApp ? selectedApp?.redirect_link : '');
    const [isLoading, setIsLoading] = useState(false);
    // ----------
    const [userRoles, setUserRoles] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [placeHolderText, setPlaceHolderText] = useState('Search For User');
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterActive, setFilterActive] = useState('all');
    const [openSelectParticipant, setOpenSelectParticipant] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [departmentDivisions, setDepartmentDivisions] = useState([]);
    const [selectedDivisions, setSelectedDivisions] = useState(selectedApp ? selectedApp.divisions : []);
    const [selectedDepartment, setSelectedDepartment] = useState(selectedApp ? selectedApp.divisions : []);
    const [loadSearch, setLoadSearch] = useState(false);
    const [oldImage, setOldImage] = useState(selectedApp ? selectedApp?.image : '');

    const [participants, setParticipants] = useState([]);
    console.log(participants);
    useEffect(() => {
        if (selectedApp && filteredUsers.length > 0) {
            setParticipants(
                filteredUsers
                    .filter((user) => selectedApp.user_ids.includes(user.user_id))
                    .map((user) => ({ id: user.user_id, name: user.user_name }))
            );
        }
    }, [filteredUsers, selectedApp]);
    function handleChange(e) {
        setQuery(e.target.value);
    }
    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300);
    }, []);

    const theme = useTheme();
    const bannerInput = useRef(null);

    const handleChangeType = (event) => {
        const selectedValue = event.target.value;
        console.log('value', selectedValue);
        setSelectedCategory(selectedValue);
    };
    const handleImageUpload = () => {
        const file = bannerInput.current.files[0];
        if (file && file.size < 5245329) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
                setPostMediaFile(file); // Store the file for submission
            };
            reader.readAsDataURL(file);
        } else {
            alert('Size Must Be Smaller Than 5MB');
        }
    };

    const fetchDepartments = async () => {
        try {
            await axios.get(`/departments?s=${query}`).then(({ data }) => {
                if (data.success) {
                    setDepartments(() => data.departments);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCategories = async () => {
        try {
            await axios.get(`/app-store/category`).then(({ data }) => {
                if (data.success) {
                    setCategories(() => data.categories);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    const fetchDivisions = async () => {
        try {
            const { data } = await axios.get(`/department-divisions?s=${query}&department_id=&with_dept=true`);

            if (data.success) {
                setDepartmentDivisions(data.departmentDivisions);
            }
        } catch (error) {
            console.log(error);
        }
    };
    async function fetchUsers() {
        await axios
            .get('/users')
            .then(({ data }) => {
                if (data.success) {
                    setCompanyUsers(
                        data.users.map((item) => {
                            setUserRoles((roles) => {
                                let found = false;
                                if (roles.length > 0) {
                                    roles.forEach((role) => {
                                        if (role.role_id == item.role_id) {
                                            found = true;
                                            role.count = role.count + 1;
                                        }
                                    });
                                }
                                if (!found) {
                                    return [
                                        ...roles,
                                        {
                                            role_id: item.role_id,
                                            role_name: item.role?.role_type,
                                            count: 1
                                        }
                                    ];
                                } else {
                                    return [...roles];
                                }
                            });
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role?.role_type
                            };
                        })
                    );
                    setFilteredUsers(
                        data.users.map((item) => {
                            return {
                                user_id: item.user_id,
                                user_name: item.first_name,
                                location_id: item.location_id,
                                location_name: item.location.location_name,
                                role_id: item.role_id,
                                role_name: item.role?.role_type
                            };
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    }
    // useEffect(() => {
    //     if (selectedApp) {
    //         setSelectedCategory(selectedApp.application_category_id || '');
    //     }
    // }, [selectedApp]);
    const handleSubmit = async () => {
        if (name === '') {
            setValidatorMessage([{ field: 'name', message: 'Name is required' }]);
            return;
        } else if (url === '') {
            setValidatorMessage([{ field: 'url', message: 'Url is required' }]);
            return;
        } else if (!postMediaFile && !selectedApp) {
            setValidatorMessage([{ field: 'icon', message: 'Icon is required' }]);
            return;
        }

        const formData = new FormData();
        formData.append('title', name);
        formData.append('redirect_link', url);
        formData.append('image', postMediaFile);
        formData.append('application_category_id', selectedCategory);

        if (participants.length > 0) {
            formData.append('user_ids', JSON.stringify(participants.map((user) => user.id)));
        }
        if (selectedDepartment.length > 0) {
            formData.append('departments', JSON.stringify(selectedDepartment.map((department) => department.id)));
        }
        if (selectedDivisions.length > 0) {
            formData.append('divisions', JSON.stringify(selectedDivisions.map((division) => division.id)));
        }

        try {
            setIsLoading(true);
            const res = selectedApp
                ? await axios.patch(`/app-store/${selectedApp.id}`, formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  })
                : await axios.post('/app-store', formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  });
            console.log(res);
            if (res.data.success) {
                if (selectedApp) {
                    setApplications((prev) => prev.map((app) => (app.id === res.data.application.id ? res.data.application : app)));
                } else {
                    setApplications((prev) => [res.data.application, ...prev]);
                }
                console.log('testing', res.data.application);
                setOpenModal(false);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchDepartments(), fetchDivisions(), fetchUsers()]).then(() => {
            setLoading(() => false);
        });
    }, []);

    useEffect(() => {
        setQuery(() => '');
        setLoadSearch((prev) => {
            prev = false;
            return prev;
        });
        setPlaceHolderText((prev) => {
            if (filterActive == 'all') {
                fetchUsers();
                prev = 'Search For User';
            } else if (filterActive == 'department') {
                fetchDepartments();
                prev = 'Search For Department';
            } else if (filterActive == 'division') {
                fetchDivisions();
                prev = 'Search For Division';
            }
            setLoadSearch((prev) => {
                prev = true;
                return prev;
            });
            return prev;
        });
        // if (!metaData) {
        //     setSelectedEntries([]);
        // }
        fetchCategories();
    }, [filterActive]);

    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh', width: '100vw' }}>
                {!openSelectParticipant && (
                    <Card sx={{ padding: '1rem', position: 'relative', width: { md: '40vw', xs: '100%' } }}>
                        <Badge
                            color="primary"
                            badgeContent={'X'}
                            className="cursor-pointer"
                            onClick={() => setOpenModal(false)}
                            sx={{ marginLeft: 'auto', marginRight: '1rem', position: 'absolute', top: '1rem', right: '0' }}
                        ></Badge>
                        <Box>
                            <Box mt={2}>
                                {/* Name */}
                                <Box>
                                    <Typography variant="h6">Name</Typography>
                                    <OutlinedInput
                                        type="text"
                                        value={name}
                                        color="primary"
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            setValidatorMessage((prev) => prev.filter((item) => item.field !== 'name'));
                                        }}
                                        placeholder="New project name"
                                        fullWidth
                                    />
                                    {validatorMessage.find((error) => error.field === 'name') && (
                                        <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                            {validatorMessage.find((error) => error.field === 'name').message}
                                        </Typography>
                                    )}
                                </Box>
                                {/* Type */}
                                <Box mt={1}>
                                    <Typography variant="h6">Type</Typography>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select labelId="demo-simple-select-label" value={selectedCategory} onChange={handleChangeType}>
                                            <MenuItem value="" disabled>
                                                Select category
                                            </MenuItem>
                                            {categories.map((category) => (
                                                <MenuItem key={category.key} value={category.application_category_id}>
                                                    {category.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* URL */}
                                <Box mt={1}>
                                    <Typography variant="h6">Url</Typography>
                                    <OutlinedInput
                                        type="text"
                                        value={url}
                                        color="primary"
                                        onChange={(e) => {
                                            setUrl(e.target.value);
                                            setValidatorMessage((prev) => prev.filter((item) => item.field !== 'url'));
                                        }}
                                        placeholder="Enter project URL"
                                        fullWidth
                                    />
                                    {validatorMessage.find((error) => error.field === 'url') && (
                                        <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                            {validatorMessage.find((error) => error.field === 'url').message}
                                        </Typography>
                                    )}
                                </Box>
                                {/* select user */}
                                <Box
                                    mt={1}
                                    onClick={() => {
                                        setOpenSelectParticipant(true);
                                    }}
                                >
                                    <Typography variant="h6">Select user</Typography>

                                    {!participants.length > 0 && !selectedDepartment.length > 0 && !selectedDivisions.length > 0 && (
                                        <OutlinedInput type="text" color="primary" placeholder="Add team members" fullWidth readonly />
                                    )}
                                    {participants.length > 0 && (
                                        <OutlinedInput
                                            type="text"
                                            color="primary"
                                            value={participants.map((user) => user.name).join(', ')}
                                            placeholder="Add team members"
                                            fullWidth
                                            readonly
                                        />
                                    )}
                                    {selectedDepartment.length > 0 && (
                                        <OutlinedInput
                                            type="text"
                                            color="primary"
                                            value={selectedDepartment.map((user) => user.name).join(', ')}
                                            placeholder="Add team members"
                                            fullWidth
                                            readonly
                                        />
                                    )}
                                    {selectedDivisions.length > 0 && (
                                        <OutlinedInput
                                            type="text"
                                            color="primary"
                                            value={selectedDivisions.map((user) => user.name).join(', ')}
                                            placeholder="Add team members"
                                            fullWidth
                                            readonly
                                        />
                                    )}
                                    {validatorMessage.find((error) => error.field === 'participants') && (
                                        <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                            {validatorMessage.find((error) => error.field === 'participants').message}
                                        </Typography>
                                    )}
                                </Box>
                                {/* Add Image */}
                                <Box mt={1}>
                                    <Typography variant="h6">Uploaded Icon</Typography>
                                    {oldImage ? (
                                        <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                                            <Box sx={{ borderRadius: '1rem' }}>
                                                <img src={oldImage} className="uploaded-img" alt="Uploaded Icon" />
                                            </Box>
                                            <Badge
                                                color="primary"
                                                badgeContent={'X'}
                                                onClick={() => {
                                                    setOldImage('');
                                                }}
                                                sx={{ position: 'absolute', top: '0', right: '0' }}
                                            />
                                        </Box>
                                    ) : (
                                        <>
                                            {image == null ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{
                                                        borderRadius: '10px',
                                                        backgroundColor: `${theme.palette.primary.lighter}`,
                                                        padding: '2rem',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => bannerInput.current.click()}
                                                >
                                                    <input
                                                        ref={bannerInput}
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleImageUpload}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <img src={PhotoIcon} style={{ marginRight: '1rem' }} alt="PicIcon" />
                                                        <Typography sx={{ opacity: '0.6', fontSize: '13px' }}>Add Icon</Typography>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                                                    <Box sx={{ borderRadius: '1rem' }}>
                                                        <img src={image} className="uploaded-img" alt="Uploaded Icon" />
                                                    </Box>
                                                    <Badge
                                                        color="primary"
                                                        badgeContent={'X'}
                                                        onClick={() => {
                                                            setImage(null);
                                                            setPostMediaFile(null); // Clear the uploaded file
                                                        }}
                                                        sx={{ position: 'absolute', top: '0', right: '0' }}
                                                    />
                                                </Box>
                                            )}
                                        </>
                                    )}
                                    {validatorMessage.find((error) => error.field === 'icon') && (
                                        <Typography sx={{ fontSize: '15px', color: 'red' }}>
                                            {validatorMessage.find((error) => error.field === 'icon').message}
                                        </Typography>
                                    )}
                                </Box>

                                {/* Submit */}
                                <Box mt={2}>
                                    <Button
                                        disabled={isLoading}
                                        variant="contained"
                                        onClick={handleSubmit}
                                        fullWidth
                                        sx={{ backgroundColor: 'primary.main', color: 'white' }}
                                    >
                                        {isLoading ? 'Loading....' : 'Submit'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                )}
                {openSelectParticipant && (
                    <Box className="collapse">
                        <Card
                            sx={{
                                padding: '.5rem',
                                borderRadius: '10px',
                                width: { md: '40vw', xs: '100%' },
                                marginTop: '6rem',
                                overflowY: 'scroll'
                            }}
                        >
                            <CardContent className="scroll" sx={{ maxHeight: '60vh' }}>
                                <Box
                                    display="flex"
                                    justifyContent="between"
                                    alignItems="center"
                                    sx={{ paddingBottom: '1rem', position: 'relative' }}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        {loadSearch && (
                                            <DbounceSearch debouncedResults={debouncedResults} placeHolderText={placeHolderText} />
                                        )}
                                    </Box>
                                </Box>
                                <Box display="flex" className="overflow-x">
                                    <Box container display="flex" className="overflow-x" mb={1}>
                                        <Box
                                            className={`${
                                                filterActive == 'all'
                                                    ? 'filter-badge-active  cursor-pointer'
                                                    : 'filter-badge cursor-pointer'
                                            }`}
                                            onClick={() => {
                                                setParticipants([]);
                                                setSelectedDepartment([]);
                                                setSelectedDivisions([]);
                                                setFilterActive('all');
                                            }}
                                        >
                                            All({companyUsers.length})
                                        </Box>
                                        <Box
                                            className={
                                                filterActive == 'department'
                                                    ? 'filter-badge-active  cursor-pointer'
                                                    : 'filter-badge cursor-pointer'
                                            }
                                            sx={{
                                                marginX: '.3rem'
                                            }}
                                            onClick={() => {
                                                setParticipants([]);
                                                setSelectedDepartment([]);
                                                setSelectedDivisions([]);
                                                setFilterActive('department');
                                            }}
                                        >
                                            Department
                                        </Box>
                                        <Box
                                            className={
                                                filterActive == 'division'
                                                    ? 'filter-badge-active  cursor-pointer'
                                                    : 'filter-badge cursor-pointer'
                                            }
                                            sx={{
                                                marginX: '.3rem'
                                            }}
                                            onClick={() => {
                                                setParticipants([]);
                                                setSelectedDepartment([]);
                                                setSelectedDivisions([]);
                                                setFilterActive('division');
                                            }}
                                        >
                                            Division
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" className="border-bottom">
                                    <Box>
                                        <Typography variant="h6" color="primary">{`Selected(${participants.length})`}</Typography>
                                    </Box>
                                    <Box
                                        onClick={() => {
                                            if (filterActive === 'all') {
                                                setParticipants(companyUsers.map((part) => ({ id: part.user_id, name: part.user_name })));
                                            } else if (filterActive === 'department') {
                                                setSelectedDepartment(
                                                    departments.map((part) => ({ id: part.department_id, name: part.department_name }))
                                                );
                                            } else if (filterActive === 'division') {
                                                setSelectedDivisions(
                                                    departmentDivisions.map((part) => ({
                                                        id: part.department_division_id,
                                                        name: part.division_name
                                                    }))
                                                );
                                            }
                                        }}
                                    >
                                        <Typography color="primary" sx={{ cursor: 'pointer' }}>
                                            Select All
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ overflowY: 'scroll', height: '50vh' }}>
                                    {!loading && (
                                        <>
                                            {filterActive == 'all' &&
                                                filteredUsers.length > 0 &&
                                                filteredUsers.map((item, index) => {
                                                    return (
                                                        <SelectionItem
                                                            key={`${index}-users`}
                                                            item={item}
                                                            id_key={'user_id'}
                                                            name={'user_name'}
                                                            targetedArray={participants}
                                                            setTargetedArray={setParticipants}
                                                            has_avater={true}
                                                        />
                                                    );
                                                })}
                                            {departments.length > 0 &&
                                                filterActive == 'department' &&
                                                departments.map((item, index) => {
                                                    return (
                                                        <SelectionItem
                                                            key={`${index}-depts`}
                                                            item={item}
                                                            id_key={'department_id'}
                                                            name={'name'}
                                                            targetedArray={selectedDepartment}
                                                            setTargetedArray={setSelectedDepartment}
                                                        />
                                                    );
                                                })}
                                            {departmentDivisions?.length > 0 &&
                                                filterActive == 'division' &&
                                                departmentDivisions.map((item, index) => {
                                                    return (
                                                        <SelectionItem
                                                            key={`${index}-dept_divis`}
                                                            item={item}
                                                            id_key={'department_division_id'}
                                                            name={'name'}
                                                            targetedArray={selectedDivisions}
                                                            setTargetedArray={setSelectedDivisions}
                                                        />
                                                    );
                                                })}
                                        </>
                                    )}
                                </Box>
                            </CardContent>
                            {/* buttons */}
                            <Box display="flex" alignItems="center" gap={2}>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenSelectParticipant(false);
                                    }}
                                >
                                    <Typography variant="h6">Select</Typography>
                                </Button>

                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenSelectParticipant(false);
                                    }}
                                    sx={{
                                        backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                        color: '#000000'
                                    }}
                                >
                                    <Typography variant="h6">Cancel</Typography>
                                </Button>
                            </Box>
                        </Card>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default AppStoreModal;
