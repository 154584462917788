/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Post from 'pages/leaders-voice/Post';
import Gallery from 'components/Slider';
import Cross from '../../assets/static_images/cross.svg';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from '@mui/material/Badge';
const BottomFilter = ({ items = null, setMethod, setOpenViewer }) => {
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: { md: '25rem', xs: '90vw' },
                        position: 'absolute',
                        top: { md: '65vh', xs: '20vh' },
                        left: { md: '40vw', xs: '5vw' },
                        paddingX: '1rem'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Grid
                            container
                            sm={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ paddingBottom: '1rem' }}
                        >
                            <Grid item sm={11} display="flex" alignItems="center">
                                <Typography variant="h5" color="primary" sx={{ fontWeight: '500' }}>
                                    Filter
                                </Typography>
                            </Grid>
                            <Grid item sm={1} display="flex" alignItems="center" justifyContent="center">
                                <Badge
                                    color="primary"
                                    badgeContent={'X'}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setOpenViewer(false);
                                    }}
                                ></Badge>
                            </Grid>
                        </Grid>
                        <Grid container sm={12} display="flex" direction="column">
                            {items &&
                                items.length > 0 &&
                                items.map((item) => {
                                    return (
                                        <Grid
                                            item
                                            sm={12}
                                            className="cursor-pointer"
                                            sx={{ padding: '.6rem' }}
                                            onClick={() => {
                                                setMethod(item);
                                                setOpenViewer(false);
                                            }}
                                        >
                                            <Typography sx={{ opacity: '70%' }}>{item.name}</Typography>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default BottomFilter;
