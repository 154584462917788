import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Badge, Skeleton } from '@mui/material';
import Policy from 'components/policies/Policy';
import axios from 'lib/axios';

const ViewPolicyModal = ({ policyId, setOpenViewModal }) => {
    const [reactions, setReactions] = useState([]);
    const [policy, setPolicy] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchPolicy = async () => {
        setIsLoading(true);
        try {
            const res = await axios.get(`/policies/policy/${policyId}/0`);

            if (res.data.success) {
                setIsLoading(false);
                setPolicy(res.data.policy);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };
    async function fetchReacts() {
        axios.get('/reacts').then(({ data }) => {
            if (data.success) {
                setReactions(data.reacts);
            }
        });
    }
    useEffect(() => {
        fetchPolicy();
        fetchReacts();
    }, []);
    return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
            <Card sx={{ width: { lg: '50vw', md: '90vw', xs: '90vw' }, position: 'relative', mx: 2, borderRadius: '10px' }}>
                <Box sx={{ position: 'absolute', top: '5px', right: '20px' }}>
                    <Badge
                        color="primary"
                        badgeContent={'X'}
                        className="cursor-pointer"
                        onClick={() => {
                            setOpenViewModal(false);
                        }}
                        sx={{ marginLeft: 'auto' }}
                    ></Badge>
                </Box>
                <Box mt={2} sx={{ height: '50vh', overflowY: 'scroll' }}>
                    {isLoading ? (
                        <Box px={2}>
                            <Skeleton variant="circular" width={40} height={40} />

                            <Skeleton width={`100%`} height={50} />
                            <Skeleton width={`100%`} height={50} />
                            <Skeleton width={`100%`} height={50} />
                        </Box>
                    ) : (
                        <>{policy.policy_id && <Policy policy={policy} setPolicies={() => {}} reacts={reactions} formSidebar={true} />}</>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default ViewPolicyModal;
