/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Upcoming from '../assets/static_images/upcoming.svg';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import avatar1 from '../assets/images/users/avatar-1.png';

import { Box } from '@mui/material';
import UserAvatarComponent from './UserAvatarComponent';
import { Button } from '@mui/material/index';
import MiniEventPost from 'pages/events/MiniEventPost';
const UpcomingList = ({ list, model, setOffset }) => {
    return (
        <Box sx={{ height: 'calc(100vh - 7.5rem)', overflowY: 'scroll' }}>
            <Box>
                <Box display="flex" alignItems="center" gap="0.5rem">
                    <Grid item display="flex" alignItems="center" justifyContent="center">
                        <img src={Upcoming} alt="icon" />
                    </Grid>
                    <Grid item>
                        <Typography color="primary">{`Upcoming ${model.charAt(0).toUpperCase() + model.slice(1)}s`}</Typography>
                    </Grid>
                </Box>
                <Box marginY="1rem">
                    {list?.length > 0 &&
                        list.map((item, index) => {
                            return <MiniEventPost key={index} item={item} model={model} />;
                        })}
                    <Box marginY="1rem">
                        {list.length > 5 ? (
                            <Box display="flex" justifyContent="center" mb={2}>
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: '50px' }}
                                    onClick={() => {
                                        setOffset(0);
                                    }}
                                >
                                    View Less
                                </Button>
                            </Box>
                        ) : (
                            list.length === 5 && (
                                <Box display="flex" justifyContent="center" mb={2}>
                                    <Button
                                        variant="contained"
                                        sx={{ borderRadius: '50px' }}
                                        onClick={() => {
                                            setOffset((prev) => prev + limit);
                                        }}
                                    >
                                        View More
                                    </Button>
                                </Box>
                            )
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UpcomingList;
