/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Avatar, Box, Grid, Card, Typography, Badge } from '@mui/material';
import Modal from '@mui/material/Modal';
import Gallery from 'components/Gallery';
import avatar1 from '../../assets/images/users/avatar-1.png';

const AvatarImageViewer = ({ isOpen, closeModal, data }) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                closeModal(false);
            }
        };
        window.addEventListener('keydown', handleEsc);
    }, []);
    console.log(data);
    return (
        <Modal
            keepMounted
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                sx={{
                    widht: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box sx={{ width: '80%', position: 'relative' }}>
                    {/* <Box sx={{ position: 'absolute', right: { md: '10rem', xs: '2rem' }, top: '4rem' }}>
                        <Badge
                            color="primary"
                            badgeContent={'X'}
                            className="cursor-pointer"
                            onClick={() => {
                                closeModal(false);
                            }}
                            sx={{ marginLeft: 'auto' }}
                        ></Badge>
                    </Box> */}
                    <Gallery images={[`${data?.profile_image}`]} setOpenGallery={closeModal} name={data.first_name} videos={[]} />
                </Box>
            </Box>
        </Modal>
    );
};

export default AvatarImageViewer;
