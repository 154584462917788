/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import parse from 'html-react-parser';
import axios from 'lib/axios';
import Comment from '../../assets/static_images/comment.svg';
import Reacts from '../../components/Reacts';
import CommentList from 'components/comment/CommentList';
import CreateComment from 'components/comment/CreateComment';
import CreateVoice from 'components/modals/CreateVoice';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Cross from '../../assets/static_images/cross.svg';
import Button from '@mui/material/Button';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import Blank from '../../assets/static_images/blank.svg';
import EditPost from 'components/modals/EditPost';
import { Alert } from '@mui/lab';
import DeleteModal from 'components/modals/DeleteModal';
import { Link, InputLabel, OutlinedInput, Snackbar, useTheme, useMediaQuery } from '@mui/material';
import { Avatar, Box } from '@mui/material';
import YouTubePlayer from 'components/YouTubePlayer';
import Gallery from 'components/Gallery';
import ViewReactModal from 'components/modals/ViewReactModal';
import ParentComment from 'components/comment/ParentComment';
import { socket } from '../../socket';
import PostBody from 'components/post/PostBody';
import PostHeader from 'components/post/PostHeader';
const Post = ({ post, reacts, fetchPosts, setPosts, type = 'non-view', borderNone, commentHideOff = true }) => {
    const textInput = useRef(null);
    const [contentUrl, setContentUrl] = useState('');
    const [content, setContent] = useState('');
    const [shareOpen, setShareOpen] = useState(false);
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [commentOpen, setCommentOpen] = useState(false);
    const [reactCount, setReactCount] = useState(parseInt(post.postReactCount));
    const [comments, setComments] = useState([]);
    const [openEditor, setOpenEditor] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [user, setUser] = useState(post.user);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);
    const [fetchedCommentsCount, setFetchedCommentsCount] = useState(0);
    const [viewAllComments, setViewAllComments] = useState(true);
    const [openGallery, setOpenGallery] = useState(false);
    const [openSnack, setShowSnack] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [commentCount, setCommentCount] = useState(post.postCommentCount);
    const [hideComments, setHideComments] = useState(commentHideOff);
    const [openViewReactModal, setOpenViewReactModal] = useState(false);

    function handleSnackClose() {
        setShowSnack(false);
    }
    useEffect(() => {
        let id = post.post_id.toString();
        let url = `/Uploads/${user.user_id}/Social-Post/${id}/index.html`;
        setContentUrl(`${process.env.REACT_APP_BACKEND_URL}/${url}`);
        axios
            .get(`${url}`)
            .then(({ data }) => {
                setContent(data);
            })
            .catch((err) => console.log(err));

        axios.post(`social-posts/reacted/${post.post_id}`).then(({ data }) => {
            if (data.success) {
                setReacted(data.reacted);
                if (data.react && data.react.react) {
                    setReaction(data.react.react.react_name);
                    if (data.react.react.react_id == 1) {
                        setReactionImg(Like);
                    } else if (data.react.react.react_id == 2) {
                        setReactionImg(Love);
                    } else if (data.react.react.react_id == 3) {
                        setReactionImg(Haha);
                    } else if (data.react.react.react_id == 4) {
                        setReactionImg(Wow);
                    } else if (data.react.react.react_id == 5) {
                        setReactionImg(Sad);
                    } else if (data.react.react.react_id == 6) {
                        setReactionImg(Angry);
                    }
                }
            }
        });
    }, []);

    const fetchComments = async () => {
        const formData = new FormData();
        formData.append('offset', parseInt(0));
        formData.append('child_offset', parseInt(offset));
        formData.append('limit', parseInt(limit));
        formData.append('child_limit', parseInt(5));
        formData.append('order_by', orderBy);

        await axios.post(`/comments/${post.post_id}`, formData).then(({ data }) => {
            if (data.success) {
                setComments(data.comments);
                setFetchedCommentsCount(data.count);
                // setOffset((ofst) => parseInt(ofst) + 5);
                // setFetchedCommentsCount((prev) => prev + 5);
            }
        });
    };
    const [orderBy, setOrderBy] = useState('created');
    useEffect(() => {
        fetchComments();
    }, [offset]);
    useEffect(() => {
        setComments((prev) => {
            prev = [];
            return prev;
        });
        fetchComments();
    }, [orderBy, hideComments]);
    useEffect(() => {
        listen_to_channel();
    }, []);

    function listen_to_channel() {
        socket.on('post_react_added', (post_id) => {
            if (post_id == post.post_id) {
                setReactCount((prev) => prev + 1);
            }
        });
        socket.on('post_react_removed', (post_id) => {
            if (post_id == post.post_id) {
                setReactCount((prev) => prev - 1);
            }
        });
    }
    const [id, setId] = useState(`post-${post.post_id}`);
    const adjustReact = async () => {
        await axios
            .delete('/post-reacts/destroy', { data: { post_id: post.post_id } })
            .then(({ data }) => {
                if (data.success) {
                    setReacted(false);
                    setReactCount((prev) => {
                        // prev = parseInt(prev) - 1;
                        return prev;
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <Box
            id={() => id}
            sx={
                type == 'view'
                    ? { paddingX: '0rem', marginY: '1rem', minHeight: '100%', minWidth: '100%' }
                    : {
                          paddingX: '0rem',
                          marginY: '0rem',
                          minHeight: '100%',
                          minWidth: '100%'
                      }
            }
        >
            <Box
                sx={{
                    borderRadius: '10px',
                    padding: '1rem',
                    width: '100%',
                    backgroundColor: '#fff',
                    border: `${!borderNone && '1px solid #1E7C8840'}`
                }}
            >
                <Box>
                    {/* card header  */}
                    <PostHeader
                        post={post}
                        openOption={openOption}
                        setOpenOption={setOpenOption}
                        setOpenEditor={setOpenEditor}
                        setOpenDeleteModal={setOpenDeleteModal}
                    />
                    <PostBody post={post} type={type} setOpenGallery={setOpenGallery} />
                    {post.external_video_link && post.external_video_link != '' && (
                        <Box sx={{ position: 'relative', paddingBottom: '1rem' }}>
                            <Box sx={{ height: '400px', width: '100%', borderRadius: '1rem' }}>
                                <YouTubePlayer videoId={post.external_video_link} />
                            </Box>
                        </Box>
                    )}
                    {/* reaction section  */}
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="1rem" sx={{ marginTop: '1rem' }}>
                        <Box display="flex" alignItems="center" gap="1rem">
                            <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                                    <Box
                                        className="cursor-pointer"
                                        onClick={() => {
                                            adjustReact();
                                            setReactOpen(!reactOpen);
                                        }}
                                    >
                                        <Box>
                                            {reacted ? (
                                                <Box display="flex" alignItems="center" gap="0.5rem">
                                                    <Box>
                                                        <img
                                                            src={reactionImg}
                                                            alt="icn"
                                                            style={{
                                                                height: '1rem',
                                                                width: '1rem'
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>{reaction}</Typography>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box display="flex" alignItems="center" gap="0.5rem">
                                                    <Box>
                                                        <img
                                                            src={Blank}
                                                            alt="icn"
                                                            style={{
                                                                height: '1rem',
                                                                width: '1rem'
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>Like</Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                    {reactOpen ? (
                                        <Grid
                                            item
                                            sx={{
                                                zIndex: '1024',
                                                position: 'absolute',
                                                top: '-4rem',
                                                left: '1.5rem',
                                                width: '23rem'
                                            }}
                                        >
                                            <Reacts
                                                modelValue={post}
                                                reacts={reacts}
                                                setReactOpen={setReactOpen}
                                                setReacted={setReacted}
                                                setReactCount={setReactCount}
                                                setReactionImg={setReactionImg}
                                                setReaction={setReaction}
                                            />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                className="cursor-pointer"
                                onClick={() => {
                                    setCommentOpen(!commentOpen);
                                    setHideComments(false);
                                }}
                                display="flex"
                                alignItems="center"
                                gap="0.5rem"
                            >
                                <Box>
                                    <img
                                        src={Comment}
                                        alt="icn"
                                        style={{
                                            height: '1rem',
                                            width: '1rem'
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>Comment</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className="cursor-pointer"
                            onClick={() => {
                                setCommentOpen(false);
                                setHideComments(!hideComments);
                            }}
                        >
                            <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>{`${commentCount} Comments`}</Typography>
                        </Box>
                    </Box>
                    {/* stats section  */}
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ gap: '0.25rem', cursor: 'pointer' }}
                        onClick={() => {
                            setOpenViewReactModal(true);
                        }}
                    >
                        {reacted ? <Typography variant="subtitle1">You</Typography> : <></>}
                        {reactCount > 0 && (
                            <Typography variant="h6">
                                {reacted ? 'and' : ''} {reactCount} {reactCount > 1 ? "other's" : 'other'}
                            </Typography>
                        )}
                        {(reactCount > 0 || reacted) && <Typography variant="subtitle1">reacted On this post</Typography>}
                    </Box>
                    {/* comments section  */}
                    {commentOpen ? (
                        <Grid item sm={12}>
                            <CreateComment setComments={setComments} comments={comments} post={post} setCommentCount={setCommentCount} />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    {comments.length > 0 && !hideComments && (
                        <Grid item xs={12}>
                            <Box>
                                <ParentComment
                                    comments={comments}
                                    setComments={setComments}
                                    setCommentCount={setCommentCount}
                                    setOrderBy={setOrderBy}
                                    orderBy={orderBy}
                                    // type="get-social"
                                    type="post"
                                />
                            </Box>

                            {fetchedCommentsCount !== comments.length && (
                                <>
                                    {limit > 9 && comments.length > 9 && (
                                        <Grid container display="flex" justifyContent="start" sm={12}>
                                            <Grid
                                                item
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setLimit((prev) => {
                                                        prev = 10;
                                                        return prev;
                                                    });
                                                    setOffset((prevOffset) => {
                                                        prevOffset = prevOffset + limit;
                                                        return prevOffset;
                                                    });
                                                    // setViewAllComments(false);
                                                }}
                                            >
                                                <Typography variant="subtitle1">Show 10 more comments</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {fetchedCommentsCount > parseInt(post.postCommentCount) ? (
                                <Grid container display="flex" justifyContent="start" sm={12}>
                                    <Grid
                                        item
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setHideComments(true);
                                        }}
                                    >
                                        <Typography variant="subtitle1">{`Hide comments`}</Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    )}
                </Box>
            </Box>
            {openEditor ? (
                <Modal
                    keepMounted
                    open={openEditor}
                    onClose={setOpenEditor}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <EditPost
                        setOpenEditor={setOpenEditor}
                        post={post}
                        content={content}
                        user={user}
                        fetchPosts={fetchPosts}
                        setPosts={setPosts}
                        showSnack={(isError, message) => {
                            setSnackOptions({ isError, message });
                            setShowSnack(true);
                        }}
                        setOpenOption={setOpenOption}
                    />
                </Modal>
            ) : (
                <></>
            )}

            {openGallery ? (
                <Modal
                    keepMounted
                    open={openGallery}
                    onClose={setOpenGallery}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
                        <Grid item sm={10} xs={10} sx={{ position: 'relative', top: '7vh' }}>
                            <Gallery images={post.image} videos={post.video} setOpenGallery={setOpenGallery} />
                        </Grid>
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
            {openDeleteModal ? (
                <Modal
                    keepMounted
                    open={openDeleteModal}
                    onClose={setOpenDeleteModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <DeleteModal
                        setOpenDeleteModal={setOpenDeleteModal}
                        url={`/social-posts/delete`}
                        id={post.post_id}
                        showSnack={(isError, message) => {
                            setSnackOptions({ isError, message });
                            setShowSnack(true);
                        }}
                        setPosts={setPosts}
                    />
                </Modal>
            ) : (
                <></>
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
            {openViewReactModal && (
                <ViewReactModal
                    post={post}
                    type="post"
                    endpoint="/post-reacts/list"
                    setOpenModal={setOpenViewReactModal}
                    openModal={openViewReactModal}
                />
            )}
        </Box>
    );
};

export default Post;
