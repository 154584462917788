import React, { useState, useEffect } from 'react';
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Button,
    CircularProgress
} from '@mui/material';
import axios from 'lib/axios';
import FeedbackModal from 'components/modals/FeedbackModal';

export default function Feedback() {
    const [feedbacks, setFeedbacks] = useState([]);
    const [filter, setFilter] = useState('last_6_months');
    const [tabValue, setTabValue] = useState('all');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleTabChange = (value) => {
        setTabValue(value);
    };

    const handleOpenModal = async () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedFeedback(null);
        setModalOpen(false);
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = () => {
        setSearchQuery(searchTerm);
    };

    // mark as read
    const markAsRead = async () => {
        try {
            const res = await axios.post(`/feedback/mark-as-read`, {
                feedback_id: selectedFeedback?.feedback_id
            });
            if (res.data.success) {
                setFeedbacks((prev) =>
                    prev.map((item) => (item.feedback_id === selectedFeedback?.feedback_id ? { ...item, is_read: true } : item))
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchFeedbacks = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `/feedback?filter_type=${filter}&is_read=${tabValue === 'all' ? '' : tabValue}&search=${searchQuery}`
                );
                if (response.data.success) {
                    setFeedbacks(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedbacks();
    }, [filter, tabValue, searchQuery]);

    return (
        <>
            <Card sx={{ p: 2, height: '100vh', width: '100%' }}>
                {/* title and filter */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
                        Feedback List
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                        <FormControl sx={{ width: '10rem' }}>
                            <Select
                                value={filter}
                                onChange={handleFilterChange}
                                sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: '#30C6D9' } }}
                            >
                                <MenuItem value="today">Today</MenuItem>
                                <MenuItem value="this_month">This Month</MenuItem>
                                <MenuItem value="last_3_months">Last 3 Months</MenuItem>
                                <MenuItem value="last_6_months">Last 6 Months</MenuItem>
                                <MenuItem value="last_year">Last Year</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {/* tabs and search */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    {/* tabs */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" gap={1}>
                            <Box
                                sx={{
                                    bgcolor: tabValue === 'all' ? '#1E7C88' : 'rgba(48, 198, 217, 0.25)',
                                    borderRadius: '5px',
                                    color: 'white',
                                    cursor: 'pointer',
                                    px: 3,
                                    py: 1,
                                    border: '1px solid rgba(30, 124, 136, 0.1)',
                                    color: tabValue === 'all' ? 'white' : '#1E7C88'
                                }}
                                onClick={() => handleTabChange('all')}
                            >
                                <Typography>All</Typography>
                            </Box>
                            <Box
                                sx={{
                                    bgcolor: tabValue === 'true' ? '#1E7C88' : 'rgba(48, 198, 217, 0.25)',
                                    borderRadius: '5px',
                                    color: 'white',
                                    cursor: 'pointer',
                                    px: 3,
                                    py: 1,
                                    border: '1px solid rgba(30, 124, 136, 0.1)',
                                    color: tabValue === 'true' ? 'white' : '#1E7C88'
                                }}
                                onClick={() => handleTabChange('true')}
                            >
                                <Typography>Read</Typography>
                            </Box>
                            <Box
                                sx={{
                                    bgcolor: tabValue === 'false' ? '#1E7C88' : 'rgba(48, 198, 217, 0.25)',
                                    borderRadius: '5px',
                                    color: 'white',
                                    cursor: 'pointer',
                                    px: 3,
                                    py: 1,
                                    border: '1px solid rgba(30, 124, 136, 0.1)',
                                    color: tabValue === 'false' ? 'white' : '#1E7C88'
                                }}
                                onClick={() => handleTabChange('false')}
                            >
                                <Typography>Unread</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {/* search */}
                    <Box display="flex" gap={1}>
                        <OutlinedInput placeholder="Search" value={searchTerm} onChange={handleSearchInputChange} />
                        <Button
                            variant="contained"
                            onClick={handleSearch}
                            sx={{
                                bgcolor: '#1E7C88',
                                '&:hover': {
                                    bgcolor: '#30C6D9'
                                }
                            }}
                        >
                            Search
                        </Button>
                    </Box>
                </Box>

                {/* table */}
                <TableContainer sx={{ marginTop: '2rem' }}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px" mt={5}>
                            <CircularProgress sx={{ color: '#1E7C88' }} />
                        </Box>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Name</TableCell>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Job Title</TableCell>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Company Name</TableCell>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Phone</TableCell>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Date</TableCell>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Subject</TableCell>
                                    <TableCell sx={{ fontSize: '16px', borderBottom: '2px solid #30C6D9' }}>Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feedbacks.map((feedback) => (
                                    <TableRow
                                        key={feedback.id}
                                        sx={{
                                            bgcolor: !feedback.is_read ? 'rgba(30, 124, 136, 0.05)' : 'white',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            setSelectedFeedback(feedback);
                                            handleOpenModal();
                                        }}
                                    >
                                        <TableCell sx={{ fontSize: '16px' }}>
                                            {feedback.userDetails?.first_name + ' ' + feedback.userDetails?.last_name}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '16px' }}>
                                            {feedback.userDetails?.job_title ? feedback.userDetails?.job_title : 'N/A'}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '16px' }}>
                                            {feedback.userDetails?.company?.company_name ?? 'N/A'}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '16px' }}>{feedback.phone_number}</TableCell>
                                        <TableCell sx={{ fontSize: '16px' }}>{new Date(feedback.createdAt).toLocaleDateString()}</TableCell>
                                        <TableCell sx={{ fontSize: '16px', color: '#1E7C88', maxWidth: '20ch' }}>
                                            {feedback.subject?.length > 60 ? feedback.subject.substring(0, 60) + '...' : feedback.subject}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '16px', maxWidth: '20ch' }}>
                                            {feedback.description?.length > 60
                                                ? feedback.description.substring(0, 60) + '...'
                                                : feedback.description}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {feedbacks.length === 0 && !loading && (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px" mt={5}>
                        <Typography sx={{ fontSize: '20px', opacity: 0.5 }}>No feedback found</Typography>
                    </Box>
                )}
            </Card>
            {modalOpen && <FeedbackModal open={modalOpen} onClose={handleCloseModal} feedback={selectedFeedback} markAsRead={markAsRead} />}
        </>
    );
}
