/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import Badge from '@mui/material/Badge';
import peopleactive from '../../assets/static_images/peopleactive.svg';
import peopledeactive from '../../assets/static_images/peoplesdeactive.svg';
import noresponsedeactive from '../../assets/static_images/noresponsedeactive.svg';
import noresponsestat from '../../assets/static_images/noresponsestat.svg';
import declinestat from '../../assets/static_images/declinestat.svg';
import declineactivestat from '../../assets/static_images/declineddeactive.svg';
import attendingstat from '../../assets/static_images/attendingstat.svg';
import attendingdeactive from '../../assets/static_images/attendingdeactive.svg';
import { useTheme } from '@mui/material/styles';
import eye from '../../assets/static_images/eye.svg';
import eyeactive from '../../assets/static_images/eyeactive.svg';
import { Avatar, Box } from '@mui/material';
const EventParticipants = ({ policyUsers, setShowParticipants }) => {
    const [filter, setFilter] = useState('all');
    const theme = useTheme();
    const [seen, setSeen] = useState(0);
    const [unseen, setUnseen] = useState(0);
    const [participants, setParticipants] = useState(() => {
        return policyUsers.map((user) => {
            if (user.seen) {
                setSeen((seen) => seen + 1);
            } else {
                setUnseen((unseen) => unseen + 1);
            }
            return user;
        });
    });
    const timeDiffer = (now, date) => {
        var diffMs = now - date; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        return diffDays > 0
            ? `${diffDays} day${diffDays > 1 ? 's' : ''}, ${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : diffHrs > 0
            ? `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
            : `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
    };
    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
            <Grid item sx={{ position: 'relative', top: '5rem' }}>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: { lg: '40vw', md: '90vw' },
                        marginTop: '1rem',
                        height: '80vh',
                        overFlowY: 'scroll',
                        paddingX: '1rem'
                    }}
                    className="scroll"
                >
                    <CardContent>
                        <Box sx={{ position: 'absolute', right: '.5rem', top: '1.5rem' }}>
                            <Badge
                                color="primary"
                                badgeContent={'X'}
                                className="cursor-pointer"
                                onClick={() => {
                                    setShowParticipants(false);
                                }}
                                sx={{ marginLeft: 'auto', marginRight: '1rem' }}
                            ></Badge>
                        </Box>
                        <Grid
                            container
                            spacing={2}
                            sm={12}
                            display="flex"
                            alignItems="center"
                            sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.38)', paddingY: '.5rem' }}
                        >
                            {filter == 'all' ? (
                                <Grid item>
                                    <Grid
                                        container
                                        display="flex"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                            backgroundColor: `${theme.palette.primary.lighter}`,
                                            borderRadius: '4px',
                                            px: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Grid item display="flex" alignItems="center" justifyContent="center">
                                            <img src={peopleactive} className="img-fit" alt="pep" />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography color="primary">All({participants.length})</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    onClick={() => {
                                        setFilter('all');
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Grid container display="flex" alignItems="center" spacing={1}>
                                        <Grid item display="flex" alignItems="center" justifyContent="center">
                                            <img src={peopledeactive} className="img-fit" alt="pep" />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ opacity: '0.6' }}>All({participants.length})</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {filter == 'seen' ? (
                                <Grid item>
                                    <Grid
                                        container
                                        display="flex"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                            backgroundColor: `${theme.palette.primary.lighter}`,
                                            borderRadius: '4px',
                                            px: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Grid item display="flex" alignItems="center" justifyContent="center">
                                            <img src={eyeactive} className="img-fit" alt="pep" />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography color="primary">Seen ({seen})</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    onClick={() => {
                                        setFilter('seen');
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Grid container display="flex" alignItems="center" spacing={1}>
                                        <Grid item display="flex" alignItems="center" justifyContent="center">
                                            <img src={eye} className="img-fit" alt="pep" />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ opacity: '0.6' }}>Seen ({seen})</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {filter == 'unseen' ? (
                                <Grid item>
                                    <Grid
                                        container
                                        display="flex"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                            backgroundColor: `${theme.palette.primary.lighter}`,
                                            borderRadius: '4px',
                                            px: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Grid item display="flex" alignItems="center" justifyContent="center">
                                            <img src={eyeactive} className="img-fit" alt="pep" />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography color="primary">Unseen ({unseen})</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    onClick={() => {
                                        setFilter('unseen');
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Grid container display="flex" alignItems="center" spacing={1}>
                                        <Grid item display="flex" alignItems="center" justifyContent="center">
                                            <img src={eye} className="img-fit" alt="pep" />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ opacity: '0.6' }}>Unseen ({unseen})</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Box
                            sx={{
                                maxHeight: '100%',
                                overFlowY: 'scroll',
                                paddingY: '1.5rem'
                            }}
                            className="scroll"
                        >
                            <Box>
                                {participants.map((participant, index) => {
                                    console.log(participant);
                                    if (filter == 'all') {
                                        return (
                                            <Box display="flex" alignItems="center" justifyContent="space-between" key={index} mt={1}>
                                                <Box display="flex" alignItems="start">
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Avatar src={participant.profile_image} alt={participant.first_name} />
                                                    </Box>
                                                    <Box ml={1}>
                                                        <Box>
                                                            <Typography variant="h6">{participant.first_name}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ opacity: '0.6', fontSize: '12px' }}>
                                                                {participant.role.role_type}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box>
                                                    {participant.seen ? (
                                                        <Typography sx={{ opacity: '0.6' }}>
                                                            Seen {timeDiffer(new Date(), new Date(participant.seen_at))}
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{ opacity: '0.6' }}>Unseen</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    }
                                    if (filter == 'seen' && participant.seen) {
                                        return (
                                            <Box display="flex" alignItems="center" justifyContent="space-between" key={index} mt={1}>
                                                <Box display="flex" alignItems="start">
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Avatar src={participant.profile_image} alt={participant.first_name} />
                                                    </Box>
                                                    <Box ml={1}>
                                                        <Box>
                                                            <Typography variant="h6">{participant.first_name}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ opacity: '0.6', fontSize: '12px' }}>
                                                                {participant.role.role_type}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box>
                                                    {participant.seen ? (
                                                        <Typography sx={{ opacity: '0.6' }}>
                                                            Seen {timeDiffer(new Date(), new Date(participant.seen_at))}
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{ opacity: '0.6' }}>Unseen</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    }
                                    if (filter == 'unseen' && !participant.seen) {
                                        return (
                                            <Box display="flex" alignItems="center" justifyContent="space-between" key={index} mt={1}>
                                                <Box display="flex" alignItems="start">
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Avatar src={participant.profile_image} alt={participant.first_name} />
                                                    </Box>
                                                    <Box ml={1}>
                                                        <Box>
                                                            <Typography variant="h6">{participant.first_name}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ opacity: '0.6', fontSize: '12px' }}>
                                                                {participant.role.role_type}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box>
                                                    {participant.seen ? (
                                                        <Typography sx={{ opacity: '0.6' }}>
                                                            Seen {timeDiffer(new Date(), new Date(participant.seen_at))}
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{ opacity: '0.6' }}>Unseen</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    }
                                })}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default EventParticipants;
