// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
    const theme = useTheme();
    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 0 } }}>
            {/* <FormControl
                sx={{
                    width: { xs: '100%' },
                    input: {
                        '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: '#FFFFFF'
                        }
                    }
                }}
            >
                <OutlinedInput
                    size="small"
                    id="header-search"
                    startAdornment={
                        <InputAdornment
                            position="start"
                            sx={{
                                mr: -0.5,
                                color: '#FFFFFF',
                                bgcolor: '#FFFFFF',
                                input: {
                                    '&::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: 'blue'
                                    }
                                },
                                border: `1px solid ${theme.palette.primary.main}`
                            }}
                        >
                            <SearchOutlined />
                        </InputAdornment>
                    }
                    aria-describedby="header-search-text"
                    inputProps={{
                        'aria-label': 'weight'
                    }}
                    placeholder="Search"
                    variant="outlined"
                    sx={{ bgcolor: '#145159' }}
                />
            </FormControl> */}
        </Box>
    );
};

export default Search;
