/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Posts from 'pages/leaders-voice/Posts';
import Modal from '@mui/material/Modal';
import PostViewer from 'components/modals/PostViewer';
import { Box } from '@mui/material';
import { Toolbar, useMediaQuery } from '@mui/material';
import About from 'pages/Profile/About';

const ViewProfileData = ({ userId }) => {
    const [userData, setUserDate] = useState();
    const [posts, setPosts] = useState([]);
    const [offest, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sahredMedia, setSharedMedia] = useState();
    const [selected, setSelected] = useState();
    const [oepnViewer, setOpenViewer] = useState(false);
    useEffect(() => {
        fetchPosts();
    }, [userId]);
    async function fetchPosts() {
        await axios
            .post(`/social-posts/myvoice`, {
                user_id: userId
            })
            .then(({ data }) => {
                if (data.success) {
                    setPosts(data.posts);
                }
            })
            .catch((err) => console.log(err));
    }

    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <Grid sm={12} container>
            {!matchesXs ? (
                <Grid sm={0} md={3} item sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.17)' }}>
                    <About userId={userId} />
                </Grid>
            ) : (
                <></>
            )}

            <Grid md={9} sm={12} item className="overflwNew">
                <Grid sm={12} item>
                    <Grid sm={12} container sx={{ paddingTop: '1.5rem', paddingX: '1.5rem' }}>
                        <Grid item sm={12}>
                            <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                Recent Activities
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sm={12} sx={{ padding: { md: '1.5rem', xs: '0rem' } }}>
                        {posts.length > 0 && <Posts posts={posts} fetchPosts={fetchPosts} setPosts={setPosts} />}
                    </Grid>
                </Grid>
            </Grid>
            {oepnViewer ? (
                <Modal
                    keepMounted
                    open={oepnViewer}
                    onClose={setOpenViewer}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
                        <PostViewer
                            model={selected.model}
                            modelId={selected.model_id}
                            sharedId={selected.shared_id}
                            setOpenViewer={setOpenViewer}
                        />
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default ViewProfileData;
