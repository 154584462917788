import { useEffect, useState } from 'react';
import { Box, Badge } from '@mui/material/index';

const UnseenCount = ({ item, data }) => {
    const [count, setCount] = useState(0);
    const [isMatch, setIsMatch] = useState(false);

    useEffect(() => {
        const findMenu = Object.keys(data).find((menu) => menu === item.id);
        if (findMenu) {
            setIsMatch(true);
            const menuCount = data[findMenu];
            if (menuCount > 0) {
                setCount(menuCount);
            }
        }
    }, [data, item.id]);
    return (
        <>
            {isMatch && count > 0 && (
                <Box sx={{ marginLeft: '1rem' }}>
                    <Badge badgeContent={count} color="primary"></Badge>
                </Box>
            )}
        </>
    );
};

export default UnseenCount;
