/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { socket } from '../../socket';
import { htmlContent, urlify, processToUpper, timeDiffer, convertDateAndTime } from 'Helpers';
const MiniPost = ({ post, setSelected, setOpenViewer }) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [showMore, setShowMore] = useState(false);
    const [reactCount, setReactCount] = useState(parseInt(post.postReactCount));
    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('post_mini_react_added', (post_id) => {
            if (post_id == post.post_id) {
                setReactCount((prev) => prev + 1);
            }
        });
        socket.on('post_mini_react_removed', (post_id) => {
            if (post_id == post.post_id) {
                setReactCount((prev) => prev - 1);
            }
        });
    }
    const preRef = useRef(null);
    useEffect(() => {
        if (preRef.current) {
            const Showbutton = preRef.current.querySelector('#showMoreButton');
            const Hidebutton = preRef.current.querySelector('#showLessButton');

            if (Showbutton) {
                Showbutton.addEventListener('click', () => {
                    setShowMore(true);
                });
            } else {
                if (Hidebutton) {
                    Hidebutton.addEventListener('click', () => {
                        setShowMore(false);
                    });
                }
            }
        }
    }, [showMore]);
    return (
        <Box paddingX="1rem" marginBottom="1rem">
            <Box display="flex" alignItems="center" gap="0.5rem" sx={{ position: 'relative' }}>
                <Box>{post.user && <UserAvatarComponent userData={post.user} />}</Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px' }}>{`${post.user?.first_name} ${post.user?.last_name}`}</Typography>
                    <Box display="flex" gap="0.5rem">
                        <Typography variant="subtitle1" sx={{ fontSize: '13px', opacity: '0.5' }}>{`${processToUpper(
                            post.user?.role.role_type
                        )}`}</Typography>
                        <Typography variant="subtitle1" sx={{ opacity: '0.5', fontSize: '13px' }}>
                            {convertDateAndTime(post.createdAt, false)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Grid item sm={12} sx={{ padding: '0px', mt: 1 }}>
                {post && post.plain_text_body && urlify(post?.plain_text_body).length > 35 ? (
                    <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                        {showMore
                            ? htmlContent(post.plain_text_body, false, 0, preRef, true)
                            : htmlContent(post.plain_text_body, true, 35, preRef)}
                    </Grid>
                ) : (
                    <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                        {post && htmlContent(post.plain_text_body)}
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                sm={12}
                className="cursor-pointer"
                onClick={() => {
                    setSelected(post);
                    setOpenViewer(true);
                }}
                display="flex"
                spacing={2}
            >
                <Typography color="primary" sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }}>
                    Likes ({reactCount})
                </Typography>{' '}
                <Typography color="primary" sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }}>
                    View Post
                </Typography>
            </Grid>
        </Box>
    );
};

export default MiniPost;
