// assets
import { UserOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UserOutlined,
    MedicineBoxOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
let children = [
    {
        id: 'selfAnalytics',
        title: 'Self Analytics',
        type: 'item',
        url: '/self-analytics',
        breadcrumbs: false
    },
    {
        id: 'departmentAnalytics',
        title: 'Department Analytics',
        type: 'item',
        url: '/department-analytics',
        breadcrumbs: false
    },
    {
        id: 'divisionAnalytics',
        title: 'Division Analytics',
        type: 'item',
        url: '/division-analytics',
        breadcrumbs: false
    },
    // {
    //     id: 'supervisorAnalytics',
    //     title: 'Supervisor Analytics',
    //     type: 'item',
    //     url: '/supervisor-analytics',
    //     breadcrumbs: false
    // },
    {
        id: 'organizationAnalytics',
        title: 'Organization Analytics',
        type: 'item',
        url: '/organization-analytics',
        breadcrumbs: false
    }
];

const Analytics = {
    id: `analyticsTab`,
    title: 'Analytics',
    type: 'group',
    icon: OrgIcon,
    children: children
};

export default Analytics;
