/* eslint-disable prettier/prettier */
const Companies = {
    id: 'companies',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'companies',
            title: 'Companies',
            type: 'item',
            url: '/dashboard/companies',
            breadcrumbs: false
            // target: true //in order to open in new tab
        }
    ]
};

export default Companies;
