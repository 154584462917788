/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import NotificationItem from './Notification';
import {List} from '@mui/material';
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none'
};
const NotificationList = ({notifications, setBellActive}) => {
    
  
    return (
        <List
            component="nav"
            sx={{
                p: 1.25,
                '& .MuiListItemButton-root': {
                    py: 0.5,
                    '& .MuiAvatar-root': avatarSX,
                    '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                },
                height: '70vh',
                overFlowY: 'scroll'
            }}
            className="scroll"
        >
            {
                notifications.map((notification) => {
                    return <NotificationItem key={notification.notification_id} notification={notification} setBellActive={setBellActive} />;
                })}
        </List>
    );
};

export default NotificationList;
