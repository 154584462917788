import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import GroupProfileImg from '../../assets/static_images/groupimg.svg';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import GroupModal from 'components/modals/GroupModal';
import { Alert } from '@mui/lab';
import { Link, InputLabel, OutlinedInput, Box, Toolbar, useMediaQuery, Snackbar } from '@mui/material';
import DeleteModal from 'components/modals/DeleteModal';
import HasPermission from 'utils/HasPermission';
import FootNote from 'components/FootNote';
import GroupCard from './GroupCard';
const Group = () => {
    const [canPost, setCanPost] = useState(false);

    const [openSnack, setShowSnack] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    function handleSnackClose() {
        setShowSnack(false);
    }
    const [groups, setGroups] = useState([]);
    const [reload, setReload] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    useEffect(() => {
        HasPermission(setCanPost, 'Group', 'create', 'group');
        fetchGroups();
    }, [reload]);
    async function fetchGroups() {
        await axios
            .get(`/groups`)
            .then(({ data }) => {
                setGroups(data.groups);
            })
            .catch((err) => console.log(err));
    }
    return (
        <Box
            sx={{
                maxHeight: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <FootNote />
            <Grid container sm={12} sx={{ padding: { md: '1rem', xs: '0px' } }}>
                {canPost && (
                    <Grid
                        sm={12}
                        container
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flexDirection: 'column', marginY: '1rem', width: '100%' }}
                    >
                        <Grid sm={4}>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                    setShowGroupModal(true);
                                }}
                            >
                                Create New Group
                            </Button>
                        </Grid>
                    </Grid>
                )}

                <Grid item sm={12} xs={12}>
                    <Typography variant="h5" color="primary">
                        My Groups
                    </Typography>
                </Grid>
                <Grid container sx={{ width: '100%', mt: 1 }}>
                    {groups?.map((group, index) => {
                        return <GroupCard setGroups={setGroups} group={group} key={index} />;
                    })}
                </Grid>

                <Grid container sm={12}>
                    {showGroupModal ? (
                        <Modal
                            keepMounted
                            open={showGroupModal}
                            onClose={setShowGroupModal}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                        >
                            <GroupModal
                                setShowGroupModal={setShowGroupModal}
                                showSnack={(isError, message) => {
                                    setSnackOptions({ isError, message });
                                    setShowSnack(true);
                                }}
                                setReload={setReload}
                            />
                        </Modal>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                        {snackOptions.message}
                    </Alert>
                </Snackbar>
            </Grid>
        </Box>
    );
};

export default Group;
