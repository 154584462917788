/* eslint-disable prettier/prettier */
// const Employees = {
//     id: 'employees',
//     title: 'Navigation',
//     type: 'group',
//     children: [
//         {
//             id: 'employees',
//             title: 'User List',
//             type: 'item',
//             url: '/dashboard/employees',
//             breadcrumbs:false
//             // target: true //in order to open in new tab
//         },
//         {
//             id: 'employeeactivity',
//             title: 'Employee Activities',
//             type: 'item',
//             url: '/dashboard/employee-activity',
//             breadcrumbs:false
//             // target: true //in order to open in new tab
//         }
//     ]
// };
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
// export default Employees;
const children = [
    {
        id: 'users',
        title: 'Users',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'employees',
                title: 'User List',
                type: 'item',
                url: '/dashboard/employees',
                breadcrumbs: false
                // target: true //in order to open in new tab
            },
            {
                id: 'employeeactivity',
                title: 'User Activities',
                type: 'item',
                url: '/dashboard/employee-activity',
                breadcrumbs: false
                // target: true //in order to open in new tab
            }
        ]
    }
];
const Users = {
    id: 'users',
    title: 'users',
    type: 'group',
    children: children,
    icon: icons.MedicineBoxOutlined
};

export default Users;
