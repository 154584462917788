/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Card } from '@mui/material';
import { Typography, Badge } from '@mui/material';
import MiniEventPost from 'pages/events/MiniEventPost';
import EventByDateSkeleton from 'components/skeleton/EventByDateSkeleton';
import EventByDate from 'components/event/EventByDate';
const EventByDateModal = ({ formattedDate, eventsByDate, handleClose, eventIsLoading }) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card
                sx={{
                    borderRadius: '10px',
                    maxWidth: { lg: '100vw' },
                    width: { lg: '30vw', md: '50vw', xs: '90vw' },
                    marginTop: '1rem',
                    padding: '2rem',
                    mx: '1rem',
                    position: 'relative'
                }}
            >
                <Box sx={{ position: 'absolute', right: '1rem', top: '0.5rem', cursor: 'pointer' }}>
                    <Badge
                        color="primary"
                        badgeContent={'X'}
                        showZero
                        onClick={() => {
                            handleClose();
                        }}
                    ></Badge>
                </Box>
                {eventIsLoading ? (
                    <EventByDateSkeleton />
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1F7C88' }}>
                            {new Date(formattedDate)?.toLocaleDateString('en-US', options)}
                        </Typography>
                        <Typography id="simple-modal-description" sx={{ opacity: '70%' }}>{`There  ${
                            eventsByDate?.length > 1 ? 'are' : 'is'
                        } ${eventsByDate?.length} ${eventsByDate.length > 1 ? 'events' : 'event'}`}</Typography>
                        <Box sx={{ borderTop: '2px dashed #1E7C8840', pt: '10px', mt: '10px', maxHeight: '55vh' }} className="scroll">
                            {eventsByDate?.map((event, index) => (
                                <EventByDate event={event} key={index} />
                            ))}
                        </Box>
                    </Box>
                )}
            </Card>
        </Box>
    );
};

export default EventByDateModal;
