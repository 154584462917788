/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import CardContent from '@mui/material/CardContent';
import avatar1 from '../../assets/images/users/avatar-1.png';
import ThreeDot from '../../assets/static_images/threedot.svg';
import MeetingPeople from '../../assets/static_images/meeting.svg';
import { useTheme } from '@mui/material/styles';
import FileIcon from '../../assets/static_images/fileicon.svg';
import Button from '@mui/material/Button';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import axios from 'lib/axios';
import FileDownload from 'js-file-download';
import DownloadIcon from '../../assets/static_images/downloadicon.svg';
import { Box } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';

const MeetingDetails = ({ meeting, setOpenDetails, heighLiter, closeMeeting, timeLeft, showSnack }) => {
    const [dateTime, setDateTime] = useState(() => {
        let date = `${new Date(meeting.meeting_date).toString()?.split(' ')[1]} ${
            new Date(meeting.meeting_date).toString()?.split(' ')[2]
        } ${new Date(meeting.meeting_date).toString()?.split(' ')[3]}
        `;
        var hours = new Date(meeting.meeting_date).getHours();
        var minutes = new Date(meeting.meeting_date).getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return `${date} ${strTime}`;
    });
    const pdfInput = useRef(null);
    const theme = useTheme();
    const [postPdfs, setPostPdfs] = useState([]);
    const [comment, setComment] = useState('');
    const [postPdfNames, setPostPdfNames] = useState([]);
    const [postPdfsData, setPostPdfsData] = useState([]);
    const [coordinator, setCoordinator] = useState();
    const [resolution, setResolution] = useState('');
    const [isClosed, setIsClosed] = useState(false);
    const [meetingResolutions, setMeetingResolutions] = useState(() => {
        return meeting?.resolutions ? meeting.resolutions : '';
    });
    const [meetingFileNames, setMeetingFileNames] = useState(() => {
        return meeting?.file_names ? meeting.file_names : '';
    });
    const [participants, setParticipants] = useState(() => {
        return [
            ...meeting.meeting_users.filter((user) => {
                if (user.is_coordinator) {
                    setCoordinator(user);
                } else {
                    return user;
                }
            })
        ];
    });
    async function convertPdf() {
        if (pdfInput.current.files[0].size < 52453290) {
            const file = pdfInput.current.files[0];
            const url = URL.createObjectURL(pdfInput.current.files[0]);
            setPostPdfsData([...postPdfsData, file]);
            setPostPdfs([...postPdfs, url]);
            setPostPdfNames([...postPdfNames, pdfInput.current.files[0].name]);
        } else {
            alert('Size Must Be Smaller Than 50MB');
        }
    }
    async function close_meeting() {
        const formData = new FormData();
        formData.append('meeting_id', meeting.meeting_id);
        formData.append('resolutions', resolution);
        postPdfsData.forEach((pdf, index) => {
            formData.append(`docs-${index}`, pdf);
        });
        await axios
            .post('/meetings/close-meeting', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                if (data.success) {
                    if (data.meeting.resolutions && data.meeting.resolutions != '') {
                        setMeetingResolutions(data.meeting.setMeetingResolutions);
                    }
                    if (data.meeting.file_names && data.meeting.file_names != '') {
                        setMeetingFileNames(data.meeting.file_names);
                    }
                    if (showSnack) {
                        showSnack(false, `${data.message}`);
                        setIsClosed(true);
                    }
                }
            })
            .catch((err) => console.log(err));
    }
    async function download_file(path) {
        await axios
            .post(`/projects/download`, {
                file_path: path
            })
            .then((res) => {
                FileDownload(res.data, res.data.file_name);
            })
            .catch((err) => console.log(err));
    }
    return (
        <Box container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw', height: '100vh' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                    overflowY: 'scroll',
                    position: 'relative'
                }}
            >
                <Badge
                    color="primary"
                    badgeContent={'X'}
                    className="cursor-pointer"
                    sx={{ position: 'absolute', top: '2.5rem', right: '2.8rem' }}
                    onClick={() => {
                        setOpenDetails(false);
                    }}
                ></Badge>
                <Card
                    sx={{
                        padding: '.5rem',
                        borderRadius: '10px',
                        width: { md: '50vw', xs: '90vw' },
                        marginTop: { md: '1rem', xs: '6rem' },
                        maxHeight: '80vh',
                        overflowY: 'scroll',
                        marginX: '20px'
                    }}
                >
                    <CardContent>
                        <Box sx={{ marginBottom: '0rem' }}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '1.2rem', height: '2rem' }}>
                                    <img src={MeetingPeople} className="img-fit" alt="people" />
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <Typography sx={{ fontSize: '1rem' }} color={heighLiter}>
                                        {meeting.title}
                                        <span style={{ opacity: '60%', color: 'black', marginLeft: '10px' }}>
                                            ({`${meeting.is_formal ? 'Formal' : 'Informal'}`})
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Grid container sm={12} display="flex" sx={{ paddingY: '1rem' }}>
                            <Grid item sm={6} xs={12}>
                                <Grid sm={12} xs={12} container display="flex" direction="column">
                                    <Grid item sm={12} xs={12}>
                                        <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                            Date & Time
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Typography color={heighLiter}>{dateTime}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid sm={12} container display="flex" direction="column">
                                    <Grid item sm={12}>
                                        <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                            Duration
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography color={heighLiter}>{meeting.meeting_duration}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sm={12}>
                            <Grid item sm={12}>
                                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                    Meeting Coordinator
                                </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Grid container sm={12} display="flex" alignItems="center" sx={{ paddingY: '1rem' }}>
                                    <Grid
                                        item
                                        sm={1.5}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ padding: '.2rem', borderRadius: '50%' }}
                                    >
                                        <UserAvatarComponent userData={coordinator?.user} />
                                    </Grid>
                                    <Grid item sm={10}>
                                        <Grid container sm={12} display="flex" direction="column">
                                            <Grid item>
                                                <Typography>
                                                    {coordinator?.user.first_name}
                                                    {` `}
                                                    {coordinator?.user.last_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{ opacity: '60%' }}>{coordinator?.user.role?.role_type}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sm={12}>
                            <Grid item sm={12}>
                                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                    Participants
                                </Typography>
                            </Grid>
                            <Grid container xs={12} sm={12}>
                                {participants?.map((participant, index) => {
                                    return (
                                        <Grid key={`parti-${participant.id}-${index}`} item sm={6} xs={12}>
                                            <Grid container sm={12} display="flex" alignItems="center" sx={{ paddingY: '.25em' }}>
                                                <Grid
                                                    item
                                                    sm={4}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{ height: '2.5rem', width: '2.5rem', borderRadius: '50%' }}
                                                >
                                                    <UserAvatarComponent userData={participant?.user} />
                                                </Grid>
                                                <Grid item sm={10} ml={1}>
                                                    <Grid container sm={12} display="flex" direction="column">
                                                        <Grid item>
                                                            <Typography>
                                                                {participant?.user.first_name}
                                                                {` `}
                                                                {participant?.user.last_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography sx={{ opacity: '60%' }}>
                                                                {participant?.user.role?.role_type}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid container sm={12} sx={{ paddingY: '1rem' }}>
                            <Grid item sm={12} xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                    Agenda
                                </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography sx={{ opacity: '60%' }}>{meeting.agenda}</Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid container sm={12} xs={12} sx={{ paddingY: '1rem' }}>
                            <Grid item sm={12} xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                    Details
                                </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography sx={{ opacity: '60%' }}>{meeting.details}</Typography>
                            </Grid>
                        </Grid> */}
                        {meetingResolutions ? (
                            <Grid container sm={12} sx={{ paddingY: '1rem' }}>
                                <Grid item sm={12}>
                                    <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                        Resolutions
                                    </Typography>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography sx={{ opacity: '60%' }}>{meetingResolutions}</Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {meetingFileNames ? (
                            <Grid container sm={12} sx={{ paddingY: '1rem' }}>
                                <Grid item sm={12} xs={12}>
                                    <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                        Attachments
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <Typography sx={{ opacity: '60%' }}>{meetingResolutions}</Typography>
                                </Grid>
                                {meetingFileNames &&
                                    meetingFileNames?.map((reqfile, index) => {
                                        return (
                                            <Grid
                                                item
                                                sm={12}
                                                key={`file-${index}`}
                                                sx={{ margin: '.5rem' }}
                                                onClick={() => {
                                                    download_file(`./public/Uploads/Meeting/${meeting.meeting_id}/${reqfile.name}`);
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Grid container sm={12} display="flex" spacing={2} aligItems="center">
                                                    <Grid item sm={10} display="flex" aligItems="cetner">
                                                        <img src={FileIcon} alt="ii" sx={{ height: '2rem', marginX: '.5rem' }} />
                                                        <Typography sx={{ paddingX: '1rem' }}>{reqfile.name}</Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sm={2}
                                                        display="flex"
                                                        aligItems="center"
                                                        justifyContent="center"
                                                        sx={{ background: '#E5E5E5' }}
                                                    >
                                                        {/* <Link href={link} underline="always"> */}
                                                        <img src={DownloadIcon} alt="downicon" />
                                                        <Typography sx={{ opacity: '60%', marginLeft: '.25rem' }}>Download</Typography>
                                                        {/* </Link> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </CardContent>
                    {/* close meeting */}
                    {closeMeeting && !isClosed && (
                        <Box
                            sx={{
                                padding: '.2rem',
                                borderRadius: '1rem',
                                width: { md: '50vw', xs: '90vw' },
                                height: '100%',
                                overflowY: 'scroll',
                                marginTop: '.5rem'
                            }}
                        >
                            <CardContent>
                                <Grid container sm={12} sx={{ paddingY: '0rem' }}>
                                    <Grid item sm={12}>
                                        <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                            Resolution
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <textarea
                                            placeholder="Type Here ..."
                                            onChange={(e) => {
                                                setResolution(e.target.value);
                                            }}
                                            rows="3"
                                            cols="100"
                                            fullWidth
                                            style={{
                                                borderRadius: '4px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.08);',
                                                padding: '1rem',
                                                width: '100%',
                                                fontSize: '1rem'
                                            }}
                                            type="text"
                                            value={resolution}
                                        ></textarea>
                                    </Grid>
                                    <Grid xs={12} sm={12} sx={{ height: '15rem' }} className="over">
                                        <Typography variant="h6" sx={{ fontWeight: '700' }}>
                                            Attach Files
                                        </Typography>
                                        {postPdfs.length > 0 ? (
                                            <Grid container sx={{ marginY: '1rem' }}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={2} display="flex" direction="column">
                                                        {postPdfs.map((pdf, index) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    sm={12}
                                                                    key={`img@${index}`}
                                                                    sx={{ position: 'relative' }}
                                                                    className="img-box"
                                                                >
                                                                    <Grid container display="flex" alignItems="center" sm={12}>
                                                                        <Grid
                                                                            item
                                                                            sm={2}
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                        >
                                                                            <img src={PdfIcon} width={32} height={32} alt="pdficon"></img>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            sm={10}
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                window.open(pdf, '_blank', 'fullscreen=yes');
                                                                                return false;
                                                                            }}
                                                                        >
                                                                            <Typography sx={{ fontSize: '16px' }}>
                                                                                {postPdfNames[index]}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        onClick={() => {
                                                                            setPostPdfs((postPdfs) => {
                                                                                let temp = [...postPdfs];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });
                                                                            setPostPdfNames((postPdfNames) => {
                                                                                let temp = [...postPdfNames];
                                                                                temp.splice(index, 1);
                                                                                return temp;
                                                                            });

                                                                            setTimeout(() => {
                                                                                pdfInput.current.focus();
                                                                            }, 0.5);
                                                                        }}
                                                                        display="flex"
                                                                        direction="column"
                                                                        justifyContent="start"
                                                                        alignItems="start"
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            right: '5px',
                                                                            opacity: '0'
                                                                        }}
                                                                        className="trash cursor-pointer"
                                                                    >
                                                                        <Grid>
                                                                            {' '}
                                                                            <Badge
                                                                                color="primary"
                                                                                badgeContent={'X'}
                                                                                sx={{ marginLeft: 'auto' }}
                                                                            ></Badge>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                sm={12}
                                                display="flex"
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                className="dashed"
                                                sx={{
                                                    padding: '1.5rem',
                                                    marginTop: '.5rem',
                                                    borderRadius: '10px',
                                                    backgroundColor: `${theme.palette.primary.lighter}`
                                                }}
                                            >
                                                <Badge
                                                    color="primary"
                                                    className="float-end cursor-pointer"
                                                    badgeContent={'X'}
                                                    onClick={() => {
                                                        setOpenPdfUploader(false);
                                                    }}
                                                    sx={{ marginLeft: 'auto', marginTop: '-2rem', marginRight: '-2rem' }}
                                                ></Badge>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sm={12}
                                                    className="cursor-pointer"
                                                    onClick={() => pdfInput.current.click()}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sm={12}
                                                        sx={{ paddingY: '.5rem' }}
                                                    >
                                                        <Grid
                                                            item
                                                            sm={12}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            sx={{ marginTop: '.5rem' }}
                                                        >
                                                            {' '}
                                                            <img src={FileIcon} alt="logo" width={24} height={24} />
                                                        </Grid>
                                                        <Grid item display="flex" justifyContent="center" alignItems="center" sm={12}>
                                                            <Typography sx={{ fontSize: '16px' }} color="primary">
                                                                {' '}
                                                                <a href="#" sx={{ color: 'primary' }}>
                                                                    Upload a file
                                                                </a>{' '}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {postPdfs.length > 0 ? (
                                            <Grid sx={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    sx={{ backgroundColor: 'primary' }}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        pdfInput.current.click();
                                                    }}
                                                >
                                                    <Typography variant="h6">+ FILES </Typography>
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        <div sx={{ display: 'none' }}>
                                            <input
                                                ref={pdfInput}
                                                type="file"
                                                // accept=".pdf"
                                                name="pdfInput"
                                                onChange={convertPdf}
                                                className="hidden"
                                            />
                                        </div>
                                        <Grid item sm={12}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    close_meeting();
                                                }}
                                                sx={{ background: `#0FC66A` }}
                                            >
                                                <Typography variant="h6">Close Meeting</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Box>
                    )}
                </Card>
            </Box>
        </Box>
    );
};

export default MeetingDetails;
