const Roles = {
    id: 'group-role',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'role',
            title: 'Job Roles',
            type: 'item',
            url: '/dashboard/roles',
            breadcrumbs: false
        }
    ]
};

export default Roles;
