const Permissions = {
    id: 'group-permission',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'permission',
            title: 'Permission List',
            type: 'item',
            url: '/dashboard/permissions',
            breadcrumbs: false
        }
    ]
};

export default Permissions;
