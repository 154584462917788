import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import UserAvatarComponent from 'components/UserAvatarComponent';
import { socket } from 'socket';
import { convertDateAndTime } from 'Helpers';

const MiniEventPost = ({ item, model, type }) => {
    const [showMore, setShowMore] = useState(false);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric' };

    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('event_mini_react_added', (event_id) => {
            if (post_id == post.event_id) {
                setReactCount((prev) => prev + 1);
            }
        });
        socket.on('event_mini_react_removed', (event_id) => {
            if (post_id == post.event_id) {
                setReactCount((prev) => prev - 1);
            }
        });
    }
    return (
        <Box marginBottom="1rem">
            <Box display="flex" alignItems="start" gap="0.5rem" sx={{ position: 'relative' }}>
                <Box>
                    <UserAvatarComponent userData={item.user} />
                </Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px' }}>{`${item.user?.first_name} ${item.user?.last_name}`}</Typography>
                    {model === 'meeting' ? (
                        <Box
                            display="flex"
                            sx={{
                                alignItems: { lg: 'center', md: 'start' },
                                gap: { lg: '10px' }
                            }}
                        >
                            <Typography
                                sx={{
                                    textOverflow: 'ellipsis',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    opacity: '0.5'
                                }}
                            >
                                {item.user.role.role_type}
                            </Typography>
                            <Typography sx={{ opacity: '0.5', fontSize: '12px' }}>
                                {new Date(item.meeting_date).toLocaleDateString('en-US', options).substring(10, 28)}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display="flex" gap={1} alignItems="center">
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize: '13px',
                                    opacity: '0.5'
                                }}
                            >
                                {item.user.role.role_type}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ opacity: '0.5', fontSize: '13px' }}>
                                {/* {new Date(item.due_data).toLocaleDateString('en-US', options).replace(/^\w+,\s/, '')} ,{' '}
                                {new Date(item.due_data).toLocaleTimeString('en-US', timeOptions)} */}
                                {convertDateAndTime(item.due_data, false)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {model === 'meeting' ? (
                <>
                    <Grid item sm={12} sx={{ padding: '0px' }} mt={1}>
                        <Grid sm={12} item sx={{ fontSize: '14px' }}>
                            <Typography variant="h6">{`${
                                item?.agenda?.length > 35 && type !== 'event-by-date' ? `${item.agenda.substring(0, 35)}...` : item.agenda
                            }`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} sx={{ padding: '0px' }} mt={1}>
                        <Grid sm={12} item>
                            {item.details?.length > 50 ? (
                                <Typography>
                                    {showMore ? item.details : `${item.details.substring(0, 50)}...`}
                                    {!showMore ? (
                                        <Button
                                            className="btn"
                                            sx={{ fontSize: '13px' }}
                                            onClick={() => {
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            View more
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn"
                                            onClick={() => {
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            View less
                                        </Button>
                                    )}
                                </Typography>
                            ) : (
                                <Typography>{item.details}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    {' '}
                    <Grid item sm={12} sx={{ padding: '0px' }} mt={1}>
                        <Grid sm={12} item>
                            <Typography variant="h6" sx={{ fontSize: '14px' }}>{`${
                                item?.post_title?.length > 35 && type !== 'event-by-date'
                                    ? `${item.post_title.substring(0, 35)}...`
                                    : item.post_title
                            }`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} sx={{ padding: '0px', fontSize: '14px' }}>
                        <Grid sm={12} item>
                            {item.plain_text_body?.length > 35 ? (
                                <Typography>
                                    {showMore ? item.plain_text_body : `${item.plain_text_body.substring(0, 35)}...`}
                                    {!showMore ? (
                                        <Button
                                            className="btn"
                                            sx={{ fontSize: '13px' }}
                                            onClick={() => {
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            View more
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn"
                                            onClick={() => {
                                                setShowMore(!showMore);
                                            }}
                                        >
                                            View less
                                        </Button>
                                    )}
                                </Typography>
                            ) : (
                                <Typography>{item.plain_text_body}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}

            {/* <Grid item sm={12} sx={{ padding: '0px' }}>
            {urlify(item.plain_text_body).length > 35 ? (
                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                    {showMore
                        ? urlify(post.plain_text_body)
                        : `${urlify(post.plain_text_body).substring(0, 35)}...`}
                    {!showMore ? (
                        <Button
                            className="btn"
                            sx={{ fontSize: '13px' }}
                            onClick={() => {
                                setShowMore(!showMore);
                            }}
                        >
                            View more
                        </Button>
                    ) : (
                        <Button
                            className="btn"
                            onClick={() => {
                                setShowMore(!showMore);
                            }}
                        >
                            View less
                        </Button>
                    )}
                </Grid>
            ) : (
                <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                    {urlify(post.plain_text_body)}
                </Grid>
            )}
        </Grid> */}
            <Grid
                item
                sm={12}
                className="cursor-pointer"
                onClick={() => {
                    if (model == 'event') {
                        location.replace(`${window.location.origin}/single-view/type=${model}&model_id=${item.event_id}&comment_id=0`);
                    }
                }}
            >
                <Typography sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }} color="primary">
                    View Event Details
                </Typography>
            </Grid>
        </Box>
    );
};

export default MiniEventPost;
