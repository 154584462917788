/* eslint-disable react/prop-types */
import { useRef, useState, useEffect } from 'react';

import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { BellOutlined, CloseOutlined, GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import avatar from '../assets/static_images/profileicon.svg';
import axios from 'lib/axios';

const NotificationItem = ({ notification, setBellActive }) => {
    const [seen, setSeen] = useState(false);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    async function seenRequest() {
        await axios.get(`/notifications/seen/${notification.notification_id}`).then(({ data }) => {
            if (data.success) {
                location.replace(`${window.location.origin}${notification.redirection_location}`);
            }
        });
    }
    useEffect(() => {
        console.log('i am called');
        setSeen(() => {
            return notification.seen ? true : false;
        });
    }, [notification]);
    return (
        <Grid
            sm={12}
            xs={12}
            container
            display="flex"
            alignItems="center"
            className="cursor-pointer"
            spacing={1}
            onClick={() => {
                seenRequest();
            }}
            sx={
                seen
                    ? { padding: '.5rem', marginY: '.2rem', borderRadius: '.5rem' }
                    : {
                          background: 'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(90, 124, 136, 0) 100%)',
                          padding: '.5rem',
                          marginY: '.2rem',
                          borderRadius: '.5rem'
                      }
            }
        >
            <Grid item sm={1.3} xs={2} sx={{ borderRadius: '50%' }} display="flex" justifyContent="center" alignItems="center">
                <Avatar
                    src={
                        notification.avatar != null
                            ? `${process.env.REACT_APP_BACKEND_URL}/Uploads/User/ProfileImage/${notification.from_user_id.toString()}/${
                                  notification.avatar
                              }`
                            : null
                    }
                    className="img-fit"
                    alt="N"
                />
            </Grid>
            <Grid item sm={10} xs={10}>
                <Typography sx={seen ? {} : { fontWeight: '700' }}>{notification.title}</Typography>
                <Typography sx={{ fontSize: '12px', opacity: '60%' }}>
                    {' '}
                    {new Date(notification.createdAt).toLocaleDateString('en-US', options).substring(10, 28)} ,{' '}
                    {new Date(notification.createdAt).toLocaleTimeString('en-US')}
                </Typography>
            </Grid>
            <Divider />
        </Grid>
    );
};

export default NotificationItem;
