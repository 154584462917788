// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';

// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
const children = [
    {
        id: 'menuStatus',
        title: 'Menu Status',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'menuStatus',
                title: 'Feature Status',
                type: 'item',
                url: '/dashboard/districts',
                breadcrumbs: false
            },
            {
                id: 'notice',
                title: 'Notice',
                type: 'item',
                url: '/dashboard/notice',
                breadcrumbs: false
            },
            {
                id: 'footNote',
                title: 'FootNotes',
                type: 'item',
                url: '/dashboard/footnotes',
                breadcrumbs: false
            },
            {
                id: 'AppStore',
                title: 'App Store',
                type: 'item',
                url: '/dashboard/app-store',
                breadcrumbs: false
            }
        ]
    }
];
const MenuStatus = {
    id: 'menuStatus',
    title: 'Menu Status',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default MenuStatus;
