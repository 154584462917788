import React from 'react';
import { Modal, Box, Typography, Button, Stack } from '@mui/material';

export default function TitleDeleteModal({ open, handleClose, loading, onConfirm }) {
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="delete-modal" aria-describedby="delete-confirmation-modal">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                    outline: 'none'
                }}
            >
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Delete Job Title
                </Typography>

                <Typography variant="body1" sx={{ mb: 3 }}>
                    Are you sure you want to delete this job title? This action cannot be undone.
                </Typography>

                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onConfirm}
                        disabled={loading}
                        sx={{
                            bgcolor: '#d32f2f',
                            '&:hover': {
                                bgcolor: '#b71c1c'
                            }
                        }}
                    >
                        {loading ? 'Deleting...' : 'Delete'}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
}
