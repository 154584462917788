/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Post from 'pages/leaders-voice/Post';
import Gallery from 'components/Slider';
import Cross from '../../assets/static_images/cross.svg';
import { Box, Badge } from '@mui/material';
import News from 'components/news/News';
import Notice from 'components/notice/Notice';

const VoiceViewer = ({ post, setOpenViewer, model, type }) => {
    const [modelse, setModelse] = useState();
    const [reactions, setReactions] = useState([]);
    useEffect(() => {
        let url;
        if (model === 'news') {
            url = `/news/fetch/${post.news_id}/0`;
        } else if (model === 'notice') {
            url = `notice/single/${post.notice_id}`;
        } else if (model === 'post') {
            url = `/social-posts/post/${post.post_id}/0`;
        }
        fetchPost(url);
        axios.get('/reacts').then(({ data }) => {
            if (data.success) {
                setReactions(data.reacts);
            }
        });
    }, []);
    async function fetchPost(url) {
        await axios
            .get(url)
            .then(({ data }) => {
                if (data.success) {
                    if (model === 'news') {
                        setModelse(data.news);
                    } else if (model === 'post') {
                        setModelse(data.post);
                    } else {
                        setModelse(data.notice);
                    }
                }
            })
            .catch((err) => console.log(err));
    }
    console.log(post?.image);
    // console.log(modelse.image);
    return (
        <Grid
            container
            sm={post?.image.length > 0 ? 9 : 6}
            display="flex"
            alignItems="start"
            bgcolor="#ffff"
            sx={{ borderRadius: '10px', position: 'relative', overflow: 'hidden' }}
        >
            <Box>
                <Badge
                    color="primary"
                    badgeContent={'X'}
                    className="cursor-pointer"
                    onClick={() => {
                        setOpenViewer(false);
                    }}
                    sx={{ position: 'absolute', right: '20px', top: '20px' }}
                ></Badge>
            </Box>

            {post?.image && modelse?.image?.length > 0 && (
                <Grid item sm={post?.image.length > 0 ? 7 : 0}>
                    <Gallery arr={modelse?.image} user={JSON.parse(window.localStorage.getItem('authUser'))} post={post} />
                </Grid>
            )}

            {/* )} */}
            {model === 'news' && (
                <Grid item sm={post?.image.length > 0 ? 5 : 12} sx={{ height: '80vh', overFlowY: 'scroll' }} className="scroll">
                    {modelse && <News news={modelse} reacts={reactions} type="view" borderNone={true} />}
                </Grid>
            )}

            {model === 'notice' && (
                <Grid
                    item
                    sm={post?.image.length > 0 ? 5 : 12}
                    sx={{ height: '80vh', overFlowY: 'scroll', paddingTop: '0.5rem' }}
                    className="scroll"
                >
                    {modelse && <Notice notice={modelse} reacts={reactions} type="view" />}
                </Grid>
            )}
            {model === 'post' && (
                <Grid item sm={post.image.length > 0 ? 5 : 12} sx={{ height: '80vh', overFlowY: 'scroll' }} className="scroll">
                    {modelse && <Post post={modelse} reacts={reactions} type={`view`} borderNone={true} commentHideOff={false} />}
                </Grid>
            )}
        </Grid>
    );
};

export default VoiceViewer;
