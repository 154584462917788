import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import Navigation from '../LeftSide/Navigation/index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { leftSideDrawerOpen } from 'store/reducers/frontMenu';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

export default function FrontDrawer() {
    const { openLeftSidebar } = useSelector((state) => state.frontMenu);
    const dispatch = useDispatch();

    return (
        <Drawer open={openLeftSidebar} onClose={() => dispatch(leftSideDrawerOpen(false))} sx={{ zIndex: '1090' }}>
            <Box sx={{ maxWidth: 250 }} role="presentation">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        margin: '0px 30px 16px 0px',
                        position: 'sticky',
                        top: '0px',
                        backgroundColor: 'white',
                        zIndex: 100,
                        width: '100%',
                        padding: '10px'
                    }}
                >
                    <Typography fontWeight="fontWeightMedium" component="h2" sx={{ fontSize: '20px' }}>
                        Menu
                    </Typography>
                    <Typography fontWeight="fontWeightMedium" component="h2" onClick={() => dispatch(leftSideDrawerOpen(false))}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M15 5.00009C14.8437 4.84387 14.6318 4.7561 14.4108 4.7561C14.1898 4.7561 13.9779 4.84387 13.8216 5.00009L9.99997 8.82176L6.1783 5.00009C6.02203 4.84387 5.81011 4.7561 5.58914 4.7561C5.36817 4.7561 5.15624 4.84387 4.99997 5.00009C4.84374 5.15637 4.75598 5.36829 4.75598 5.58926C4.75598 5.81023 4.84374 6.02215 4.99997 6.17843L8.82164 10.0001L4.99997 13.8218C4.84374 13.978 4.75598 14.19 4.75598 14.4109C4.75598 14.6319 4.84374 14.8438 4.99997 15.0001C5.15624 15.1563 5.36817 15.2441 5.58914 15.2441C5.81011 15.2441 6.02203 15.1563 6.1783 15.0001L9.99997 11.1784L13.8216 15.0001C13.9779 15.1563 14.1898 15.2441 14.4108 15.2441C14.6318 15.2441 14.8437 15.1563 15 15.0001C15.1562 14.8438 15.244 14.6319 15.244 14.4109C15.244 14.19 15.1562 13.978 15 13.8218L11.1783 10.0001L15 6.17843C15.1562 6.02215 15.244 5.81023 15.244 5.58926C15.244 5.36829 15.1562 5.15637 15 5.00009Z"
                                fill="black"
                                fill-opacity="0.38"
                            />
                        </svg>
                    </Typography>
                </Box>
                <Navigation />
            </Box>
        </Drawer>
    );
}
