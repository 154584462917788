/* eslint-disable prettier/prettier */
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
// scroll bar
import 'simplebar/src/simplebar.css';
import { ThemeProvider, styled } from '@mui/material/styles';
import theme from '../src/styles/theme'
// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { positions, Provider } from "react-alert";
// import AlertMUITemplate from "react-alert-template-mui";
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
// serviceWorkerRegistration.register();

  
// const options = {
//     position: positions.MIDDLE
//   };

root.render(
    // <StrictMode>
        <ReduxProvider store={store}>
            <BrowserRouter basename="/">
            <ThemeProvider theme={theme}>
            {/* <Provider template={AlertMUITemplate} {...options}> */}
            <App />
            {/* </Provider> */}
            </ThemeProvider>
               
            </BrowserRouter>
        </ReduxProvider>
    // </StrictMode>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
