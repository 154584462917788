import React from 'react';
import { JaaSMeeting } from '@jitsi/react-sdk';
import Loader from 'components/Loader';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
const VideoCall = ({ token, roomName, onClose, disableVideo = false }) => {
    const handleClose = () => {
        onClose(false);
    };
    return (
        <Card
            sx={{
                height: '75vh',
                width: '75vw',
                position: 'absolute',
                top: '50%',
                left: '50%',
                borderRadius: '15px',
                transform: 'translate(-50%, -50%)',
                padding: '1.5rem',
                margin: '1.5rem'
            }}
        >
            <Grid container sm={12} display="flex" direction="column">
                <Grid item sm={12} display="flex" justifyContent="end" sx={{ paddingY: '-1rem' }}>
                    <Badge
                        color="secondary"
                        badgeContent={'X'}
                        showZero
                        onClick={() => {
                            handleClose();
                        }}
                        className="cursor-pointer"
                    ></Badge>
                </Grid>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            height: '70vh',
                            width: '74vw',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            borderRadius: '15px',
                            transform: 'translate(-50%, -50%)',
                            marginTop: '1rem'
                        }}
                    >
                        <JaaSMeeting
                            appId={process.env.REACT_APP_CALLING_APPID}
                            roomName={roomName}
                            jwt={token}
                            configOverwrite={{
                                disableThirdPartyRequests: true,
                                disableLocalVideoFlip: true,
                                backgroundAlpha: 0.5,
                                disableVideo: disableVideo
                            }}
                            interfaceConfigOverwrite={{
                                VIDEO_LAYOUT_FIT: 'nocrop',
                                MOBILE_APP_PROMO: false,
                                TILE_VIEW_MAX_COLUMNS: 4,
                                DISABLE_RINGING: false
                            }}
                            spinner={Loader}
                            onApiReady={(dfs) => {
                                console.log('api ready');
                                // "dataChannelClosed"
                                // "readyToClose"
                                // "suspendDetected"
                                // console.log('api call', dfs.getSupportedEvents());
                                // dfs.getIFrame().style.height = '400px';
                            }}
                            onReadyToClose={() => {
                                handleClose();
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default VideoCall;
