import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, Box, Badge } from '@mui/material';

const MenuStats = ({ item }) => {
    const { registeredUsersCount } = useSelector((state) => {
        return state.menuStat.stats;
    });
    const [canShowStat, setCanShowStat] = useState(false);
    const [statCount, setStatCount] = useState(() => {
        if (item.id == 'registeredUser') {
            setCanShowStat((prev) => (prev = true));
            return registeredUsersCount;
        }
        if (item.id == 'messenger') {
            setCanShowStat((prev) => (prev = true));
            return registeredUsersCount;
        }
        return null;
    });

    return (
        <>
            {canShowStat && statCount && (
                <Box sx={{ marginLeft: '1rem' }}>
                    <Badge badgeContent={statCount} color="primary"></Badge>
                </Box>
            )}
        </>
    );
};

export default MenuStats;
