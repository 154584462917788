import React, { useState } from 'react';
import { Modal, Box, Typography, OutlinedInput, Button, Stack, InputLabel, Badge } from '@mui/material';
import axios from 'lib/axios';
import { toast } from 'sonner';

export default function CreateEditJobTitleModal({ open, handleClose, jobTitle = null, setJobTitles }) {
    const [title, setTitle] = useState(jobTitle?.title || '');
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem('authUser'));

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let response;
            if (jobTitle) {
                response = await axios.post(`/job-title/update`, {
                    title,
                    job_title_id: jobTitle.job_title_id
                });
            } else {
                response = await axios.post('/job-title', {
                    title,
                    company_id: user.company_id
                });
            }
            if (response.data.success) {
                if (jobTitle) {
                    setJobTitles((prev) => prev.map((item) => (item.job_title_id === jobTitle.job_title_id ? response.data.data : item)));
                    toast.success('Job title updated successfully');
                } else {
                    setJobTitles((prev) => [...prev, response.data.data]);
                    toast.success('Job title created successfully');
                }
                handleClose();
            }
        } catch (error) {
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Something went wrong');
            }
            console.error('Error submitting job title:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="job-title-modal" aria-describedby="job-title-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                    outline: 'none'
                }}
            >
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Badge color="primary" badgeContent={'X'} className="cursor-pointer" onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </Box>

                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    {jobTitle ? 'Edit Job Title' : 'Create Job Title'}
                </Typography>

                <Stack spacing={3}>
                    <Box>
                        <InputLabel>Job Title</InputLabel>
                        <OutlinedInput fullWidth placeholder="Enter job title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Box>

                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                        sx={{
                            bgcolor: '#1E7C88',
                            '&:hover': {
                                bgcolor: '#1E7C88'
                            }
                        }}
                    >
                        {loading ? 'Loading...' : jobTitle ? 'Update' : 'Create'}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
}
