/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { InputLabel, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import axios from 'lib/axios';
import VideoCall from 'components/call/VideoCall';
import Notice from '../../assets/static_images/noticenotify.svg';
const NoticeNotify = ({ isOpen, closeModal, notice }) => {
    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a target="_target" href="' + url + '">' + url + '</a>';
        });
    }
    async function handleResponse(type) {
        if (type == 'details') {
            await axios
                .post('/notice/viewed', { notice_id: notice.notice_id })
                .then(({ data }) => {
                    if (data.success) {
                        closeModal(true);
                        window.location.replace(`/news-events/news?notice_id=${notice.notice_id}`);
                    } else {
                        closeModal(true);
                    }
                })
                .catch((err) => console.log(err));
        } else if (type == 'close') {
            await axios
                .post('/notice/viewed', { notice_id: notice.notice_id })
                .then(({ data }) => {
                    if (data.success) {
                        closeModal(true);
                    } else {
                        closeModal(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }
    return (
        <Modal
            keepMounted
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                <Box
                    sx={{
                        heigh: '30vh',
                        width: '30vw',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        borderRadius: '15px',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <Card
                        sx={{
                            borderRadius: '10px',
                            width: '40vw',
                            marginTop: '1rem',
                            minHeight: '50vh',
                            maxHeight: '80vh',
                            overFlowY: 'scroll',
                            paddingX: '1rem'
                        }}
                        className="scroll"
                    >
                        <CardContent>
                            <Grid
                                container
                                display="flex"
                                aligItems="center"
                                justifyContent="cetner"
                                sm={12}
                                sx={{ marginY: '4rem' }}
                                spacing={2}
                            >
                                <Grid item sm={12} display="flex" aligItems="center" justifyContent="center">
                                    <img src={Notice} alt="noticenoty" />
                                </Grid>
                                <Grid item sm={12} display="flex" aligItems="center" justifyContent="center">
                                    <Typography variant="h4" color="primary">
                                        {notice.notice_title}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} display="flex" aligItems="center" justifyContent="center">
                                    <Typography>
                                        {`${urlify(notice.plain_text_body).substring(0, 250)}`}
                                        {notice.plain_text_body.length > 250 ? '...' : ''}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container sm={12} spacing={2}>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            handleResponse('details');
                                        }}
                                    >
                                        <Typography>NOTICE DETAILS</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6}>
                                    <Button
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            // handleResponse('details');
                                            handleResponse('close');
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(30, 124, 136, 0.05)',
                                            color: '#000000'
                                        }}
                                    >
                                        <Typography>CLOSE</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Modal>
    );
};

export default NoticeNotify;
