// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import frontMenu from './frontMenu';
import loaderSlice from './loaderSlice';
import menuStat from './menuStat';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, frontMenu, loaderSlice, menuStat });

export default reducers;
