/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import CoverImg from '../../assets/static_images/cover.png';
import CameraIcon from '../../assets/static_images/cameraicon.svg';
// import Avatar from '../../assets/images/users/avatar-1.png';
import EditIcon from '../../assets/static_images/editIcon.svg';
import { useTheme } from '@mui/material/styles';
import { Chip, ListItemButton, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import TabSelector from 'components/TabSelector';
import Profile from 'components/profile/Index';
import Group from 'components/profile/Group';
import Activity from 'components/profile/Activity';
import Settings from 'components/profile/Settings';
import Analytics from 'components/profile/Analytics';
import Modal from '@mui/material/Modal';
import ProfileImage from 'components/modals/ProfileImage';
import { Box } from '@mui/material';
import { Routes, Route, useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import FootNote from 'components/FootNote';
import About from './About';
import { useModal } from 'context/ModalContext';
import AvatarImageViewer from 'components/modals/AvatarImageViewer';
import ViewProfileData from './ViewProfileData';
import { activeLoader } from 'store/reducers/loaderSlice';
import { useDispatch } from 'react-redux';

const ViewProfile = () => {
    let { params } = useParams();
    const theme = useTheme();
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [openProfileImage, setOepnProfileImage] = useState(false);
    const [userData, setUserDate] = useState();
    const [activeTab, setActiveTab] = useState(matchesXs ? 'about' : 'profile');
    const [reload, setReload] = useState(false);
    const [type, setType] = useState('profile_pic');
    const { isOpen, content, openModal, closeModal } = useModal();
    const [loading, setLoading] = useState(false);

    const [tabs, setTabs] = useState();

    // console.log(matchesPhone);

    useEffect(() => {
        params !== 'null' && fetchUserData();
    }, [reload]);
    useEffect(() => {
        userData &&
            setTabs((prev) => {
                prev = matchesXs
                    ? {
                          about: <About userId={userData?.user_id} />,
                          profile: <ViewProfileData userId={userData?.user_id} />
                      }
                    : {
                          profile: <ViewProfileData userId={userData?.user_id} />
                      };
                return prev;
            });
    }, [userData]);
    async function fetchUserData() {
        setLoading(true);
        dispatch(activeLoader(true));
        await axios
            .post(`/users/user`, {
                id: params
            })
            .then(({ data }) => {
                // if (data.success) {

                setUserDate((prev) => {
                    prev = data.data;
                    setLoading(false);
                    dispatch(activeLoader(false));
                    return prev;
                });
                // }
            })
            .catch((err) => console.log(err));
    }
    return (
        <>
            {params === 'null' ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center "
                    sx={{
                        height: { xs: 'calc(100vh - 1.5rem)', md: 'calc(100vh - 7.5rem)' },
                        overflowY: 'auto'
                    }}
                >
                    <Typography variant="h3" color="red">
                        User not found
                    </Typography>
                </Box>
            ) : (
                <Box display="flex">
                    <Box
                        flex="1"
                        sx={{
                            height: { xs: 'calc(100vh - 1.5rem)', md: 'calc(100vh - 7.5rem)' },
                            overflowY: 'auto'
                        }}
                    >
                        <Grid
                            container
                            sm={12}
                            sx={{
                                background: '#FFFFFF',
                                borderRadius: '1rem'
                            }}
                            display="flex"
                        >
                            <Grid
                                sm={12}
                                item
                                sx={{
                                    height: '35vh',
                                    width: '100vw',
                                    backgroundImage: `url(${userData?.cover_image ? userData.cover_image : CoverImg})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                display="flex"
                                alignItems="end"
                            >
                                <Grid
                                    sm={12}
                                    container
                                    display="flex"
                                    alignItems="end"
                                    sx={{ height: '100%', overFlowY: 'scroll', position: 'relative' }}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={6}
                                        sx={{ paddingX: '1rem', height: '5rem' }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ borderRadius: '50%', border: '.25rem solid #FFFFFF', position: 'relative' }}
                                        >
                                            <Avatar
                                                src={`${userData?.profile_image ? userData.profile_image : null}`}
                                                className="img-fit round"
                                                alt={userData?.first_name}
                                                onClick={() => {
                                                    openModal(<AvatarImageViewer isOpen={true} closeModal={closeModal} data={userData} />);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4} xs={6} sx={{ paddingBottom: '1rem' }}>
                                        <Grid sm={12} container dispaly="flex" direction="column">
                                            <Grid item sm={12}>
                                                <Typography variant="h5" sx={{ color: '#FFFFFF' }}>
                                                    {userData?.first_name} {userData?.last_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Typography sx={{ color: '#FFFFFF' }}>{`${userData?.role?.role_type}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box
                                sm={12}
                                sx={{
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.17)',
                                    paddingX: '.25rem',
                                    width: '100%',
                                    overflowX: 'scroll'
                                }}
                            >
                                <Box
                                    sm={12}
                                    display="flex"
                                    sx={{ paddingTop: { md: '5rem', xs: '1rem' }, width: { md: '100%', xs: '100vw' }, overflowX: 'scroll' }}
                                >
                                    {matchesXs && (
                                        <Box>
                                            <ListItemButton
                                                item
                                                selected={activeTab == 'about' ? true : false}
                                                sx={{
                                                    '&.Mui-selected': {
                                                        bgcolor: 'primary.light',
                                                        background:
                                                            'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                        color: 'primary.main',
                                                        borderBottom: `3px solid ${theme.palette.primary.main}`
                                                    }
                                                }}
                                                onClick={() => {
                                                    setActiveTab('about');
                                                }}
                                            >
                                                <ListItemText
                                                    sx={{ whiteSpace: 'nowrap', width: '100%' }}
                                                    primary={
                                                        <Typography
                                                            variant="subtitle"
                                                            sx={{
                                                                color:
                                                                    activeTab == 'profile' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                            }}
                                                        >
                                                            About
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </Box>
                                    )}
                                    <Box>
                                        <ListItemButton
                                            item
                                            selected={activeTab == 'profile' ? true : false}
                                            sx={{
                                                '&.Mui-selected': {
                                                    bgcolor: 'primary.light',
                                                    background:
                                                        'linear-gradient(90deg, rgba(30, 124, 136, .1) 0%, rgba(30, 124, 136, 0) 100%)',
                                                    color: 'primary.main',
                                                    borderBottom: `3px solid ${theme.palette.primary.main}`
                                                }
                                            }}
                                            onClick={() => {
                                                setActiveTab('profile');
                                            }}
                                        >
                                            <ListItemText
                                                sx={{ whiteSpace: 'nowrap', width: '100%' }}
                                                primary={
                                                    <Typography
                                                        variant="subtitle"
                                                        sx={{
                                                            color: activeTab == 'profile' ? 'rgba(30, 124, 136, 1)' : 'rgba(0, 0, 0, 0.87)'
                                                        }}
                                                    >
                                                        View Profile
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Box>
                                </Box>
                            </Box>
                            <Grid item sm={12} sx={{ paddingX: '1rem' }}>
                                {tabs && <TabSelector activeTab={activeTab} tabs={tabs} />}
                            </Grid>
                            <Grid item sm={12}>
                                {openProfileImage ? (
                                    <Modal
                                        keepMounted
                                        open={openProfileImage}
                                        onClose={setOepnProfileImage}
                                        aria-labelledby="keep-mounted-modal-title"
                                        aria-describedby="keep-mounted-modal-description"
                                    >
                                        <ProfileImage type={type} setReload={setReload} setOepnProfileImage={setOepnProfileImage} />
                                    </Modal>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ViewProfile;
