import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    button,
    Snackbar
} from '@mui/material';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Plus from '../../assets/static_images/plus.svg';
import AddSearch from 'components/AddSearch';
import axios from 'lib/axios';
import DepartmentDivisionModal from 'components/modals/DepartmentDivisionModal';
import Modal from '@mui/material/Modal';
import DepartmentDivisionTable from './DepartmentDivisionTable';
import { Alert } from '@mui/lab';
const DepartmentDivision = () => {
    const theme = useTheme();
    const [departmentDivisions, setDepartmentDivisions] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState();
    const [operation, setOperation] = useState('fetch');
    const [loading, setLoading] = useState(false);
    const [openSnack, setShowSnack] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    function handleSnackClose() {
        setShowSnack(false);
    }
    async function fetchDepartments() {
        await axios.get(`/department-divisions?s=&department_id=&with_dept=true`).then(({ data }) => {
            if (data.success) {
                setDepartmentDivisions(data.departmentDivisions);
            }
        });
    }
    const showSnack = (isError, message) => {
        setSnackOptions({ isError, message });
        setShowSnack(true);
    };
    function handleSubmit(setProcessing, departmentName, department_id, operation, department_division_id) {
        setProcessing(true);
        let url = '';
        let body = {};

        if (departmentName) {
            body = {
                ...body,
                name: departmentName
            };
        }
        if (department_id) {
            body = {
                ...body,
                department_id: department_id
            };
        }

        if (operation == 'create') {
            url = `/department-divisions`;
            axios.post(`${url}`, body).then(({ data }) => {
                if (data.success) {
                    setDepartmentDivisions((prev) => {
                        prev = [...prev, data.department];
                        return prev;
                    });
                    body = {};
                    setOpenModal(false);
                }
            });
        } else if (operation == 'update') {
            body = { ...body, department_division_id };
            url = `/department-divisions/update`;
            axios.post(`${url}`, body).then(({ data }) => {
                if (data.success) {
                    setDepartmentDivisions((prev) => {
                        return [
                            ...prev.map((item) => {
                                if (item.department_division_id == data.department_division.department_division_id) {
                                    return data.department_division;
                                } else {
                                    return item;
                                }
                            })
                        ];
                    });
                    body = {};
                    setOpenModal(false);
                }
            });
        } else if (operation == 'delete') {
            url = `/departmentDivisions/destroy`;
            axios.post(`${url}`, body).then(({ data }) => {
                if (data.success) {
                    setDepartmentDivisions((prev) => {
                        return [
                            ...prev.filter((item) => {
                                if (item.department_id != department_id) {
                                    return item;
                                }
                            })
                        ];
                    });
                    body = {};
                    setOpenModal(false);
                }
            });
        }
        setProcessing(false);
    }
    useEffect(() => {
        fetchDepartments();
    }, []);
    return (
        <Box key={'wrapper'} sx={{ width: '100%' }}>
            <Card sx={{ borderRadius: '10px', padding: '1rem' }}>
                <Grid container sm={12} display="flex" direction="column">
                    <Grid item sm={12}>
                        <AddSearch
                            text={`ADD NEW DEPARTMENT DIVISION`}
                            setOpenModal={setOpenModal}
                            setOperation={setOperation}
                            setSelected={setSelected}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <DepartmentDivisionTable
                            departmentDivisions={departmentDivisions}
                            setOpenModal={setOpenModal}
                            setOperation={setOperation}
                            setSelected={setSelected}
                        />
                    </Grid>
                </Grid>
            </Card>
            {openModal && operation != 'fetch' ? (
                <DepartmentDivisionModal
                    isOpen={openModal}
                    setOpen={setOpenModal}
                    setLoading={setLoading}
                    department={selected}
                    operation={operation}
                    handleSubmit={handleSubmit}
                    showSnack={showSnack}
                />
            ) : (
                <></>
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DepartmentDivision;
