// assets
import { DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const ProjectManagement = {
    id: 'group-projectManagement',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'projectManagement',
            title: 'Project Management',
            type: 'item',
            url: '/dashboard/project-management',
            icon: icons.projectManagementOutlined,
            breadcrumbs: false
        }
    ]
};

export default ProjectManagement;
