import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const VerifyOtp = Loadable(lazy(() => import('pages/auth/VerifyOtp')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/auth',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'verify-otp',
            element: <VerifyOtp />
        }
    ]
};

export default LoginRoutes;
