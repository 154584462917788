import { Grid, Link, InputLabel, OutlinedInput, Snackbar } from '@mui/material';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoId }) => {
    const opts = {
        // height: '390',
        // width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0
        }
    };
    // const handleReady = (event) => setVideo(() => event.target);
    return (
        <Grid sm={12} container>
            <Grid item sm={12}>
                <YouTube videoId={videoId} opts={opts} />
            </Grid>
        </Grid>
    );
};

export default YouTubePlayer;
