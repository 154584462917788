/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Grid from '@mui/material/Unstable_Grid2';
import { materialLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Box, Button } from '@mui/material';
import Cross from '../../assets/static_images/cross.svg';

const NoticeGalleryModal = ({ images, activeIndex = 0, model = 'notice', setOpenGallery }) => {
    const sliderRef = useRef();
    const [zoomLevel, setZoomLevel] = useState(1);

    const handleOnClick = (index) => {
        sliderRef.current.slickGoTo(index);
    };

    const handleZoomIn = () => {
        if (zoomLevel < 5) {
            setZoomLevel((prevZoom) => prevZoom * 1.2);
        }
    };

    const handleZoomOut = () => {
        if (zoomLevel > 1) {
            setZoomLevel((prevZoom) => prevZoom / 1.2);
        }
    };

    const settings = {
        infinite: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: false,
        autoplaySpeed: 2000,
        beforeChange: () => setZoomLevel(1)
    };

    useEffect(() => {
        handleOnClick(activeIndex);
    }, [activeIndex]);

    return (
        <Grid container sm={12} display="flex" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} sx={{ position: 'relative' }}>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            flexDirection: 'column',
                            top: '0',
                            left: '0',
                            zIndex: 99,
                            padding: '10px',
                            marginTop: '1rem',
                            marginLeft: '1rem'
                        }}
                    >
                        <Button variant="contained" color="primary" onClick={handleZoomIn} sx={{ fontSize: { md: '20px' } }}>
                            +
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleZoomOut}
                            sx={{ fontSize: { md: '20px' }, backgroundColor: '#fff', color: 'black' }}
                        >
                            -
                        </Button>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            right: '1rem',
                            top: '1rem',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '50%',
                            padding: '.7rem',
                            zIndex: 99,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '1rem',
                            marginRight: '1rem'
                        }}
                        className="cursor-pointer"
                        onClick={() => setOpenGallery(false)}
                    >
                        <img src={Cross} alt="img" />
                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {images.map((item, index) => {
                        return (
                            <div key={`gallery@${index}`} className="img-fit-slide" sx={{ padding: '1rem', marginLeft: '3rem' }}>
                                <div className="center-center imgcontainer" style={{ background: 'black' }}>
                                    <div>
                                        <input type="checkbox" id={`zoomCheck-${index}-${model}`} />
                                        <label for={`zoomCheck-${index}-${model}`}>
                                            <img
                                                src={item}
                                                alt="pic"
                                                style={{
                                                    maxHeight: '38.9vw',
                                                    width: '100%',
                                                    objectFit: 'contain',
                                                    transform: `scale(${zoomLevel})`,
                                                    transition: 'transform 0.3s ease'
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </Grid>
        </Grid>
    );
};

export default NoticeGalleryModal;
