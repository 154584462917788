import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import axios from 'lib/axios';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Toolbar, useMediaQuery } from '@mui/material';

const ActivitySummary = () => {
    const [hourList, setHourList] = useState([
        { id: 0, cat: '12am', count: 0 },
        { id: 1, cat: '', count: 0 },
        { id: 2, cat: '', count: 0 },
        { id: 3, cat: '03am', count: 0 },
        { id: 4, cat: '', count: 0 },
        { id: 5, cat: '', count: 0 },
        { id: 6, cat: '06am', count: 0 },
        { id: 7, cat: '', count: 0 },
        { id: 8, cat: '', count: 0 },
        { id: 9, cat: '09am', count: 0 },
        { id: 10, cat: '', count: 0 },
        { id: 11, cat: '', count: 0 },
        { id: 12, cat: '12pm', count: 0 },
        { id: 13, cat: '', count: 0 },
        { id: 14, cat: '', count: 0 },
        { id: 15, cat: '03pm', count: 0 },
        { id: 16, cat: '', count: 0 },
        { id: 17, cat: '', count: 0 },
        { id: 18, cat: '06pm', count: 0 },
        { id: 19, cat: '', count: 0 },
        { id: 20, cat: '09pm', count: 0 },
        { id: 21, cat: '', count: 0 },
        { id: 22, cat: '11pm', count: 0 },
        { id: 23, cat: '', count: 0 }
    ]);
    const theme = useTheme();
    const { primary } = theme.palette;
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState();
    const [reactCount, setReactCount] = useState(0);
    const [commentCount, setCommentCount] = useState(0);
    useEffect(() => {
        fetchSummary();
    }, []);
    async function fetchSummary() {
        await axios.get(`/activities/summary`).then(({ data }) => {
            if (data.success) {
                data.result.summary.map((item) => {
                    if (item.activity_type == 'react') {
                        setReactCount(parseInt(item.typeCount));
                    } else if (item.activity_type == 'comment') {
                        setCommentCount(parseInt(item.typeCount));
                    }
                });
                data.result.daily_activity.map((activity) => {
                    const hour = Math.floor(new Date(activity.updatedAt).getHours());
                    setHourList(() => {
                        return [
                            ...hourList?.map((item) => {
                                if (item.id == hour) {
                                    item.count = item.count + 1;
                                }
                            })
                        ];
                    });
                });
                setOptions(() => {
                    return {
                        chart: {
                            id: 'basic-bar',
                            animations: {
                                enabled: true,
                                easing: 'easeinout',
                                speed: 800,
                                animateGradually: {
                                    enabled: true,
                                    delay: 150
                                },
                                dynamicAnimation: {
                                    enabled: true,
                                    speed: 350
                                }
                            },
                            toolbar: {
                                show: false
                            }
                        },
                        colors: [theme.palette.primary.main],
                        yaxis: {
                            show: false
                        },
                        xaxis: {
                            categories: [
                                ...hourList.map((item) => {
                                    return item.cat;
                                })
                            ]
                        },
                        grid: {
                            show: false
                        },
                        dataLabels: {
                            enabled: false
                        }
                    };
                });
                setSeries(() => {
                    return [
                        {
                            name: 'Daily Activity',
                            data: [
                                ...hourList.map((item) => {
                                    return item.count;
                                })
                            ]
                        }
                    ];
                });
            }
        });
    }
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Grid sm={12} container display="flex" direction="column" alignItems="center" spacing={2} sx={{ padding: '1rem' }}>
            <Grid item sm={12}>
                {!matchesXs ? (
                    <Typography variant="h4" sx={{ fontWeight: '700' }} color="primary">
                        My Engagement Analytics
                    </Typography>
                ) : (
                    <Typography variant="h5" sx={{ fontWeight: '600' }} color="primary">
                        My Engagement Analytics
                    </Typography>
                )}
            </Grid>
            <Grid item sm={12} sx={{ marginY: '1rem' }}>
                <Grid container sm={12} spacing={2} gap={2}>
                    <Grid item sm={3.5} xs={12} sx={{ borderRadius: '.5rem', background: '#1E7C88', width: '100%' }}>
                        <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1rem' }}>
                            <Grid item sm={12}>
                                <Typography color="white">Engagements</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="h3" sx={{ fontWeight: '500' }} color="white">
                                    {reactCount + commentCount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={3.5} xs={12} sx={{ borderRadius: '.5rem', background: '#1E7C88', width: '100%' }}>
                        <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1rem' }}>
                            <Grid item sm={12}>
                                <Typography color="white">Likes</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="h3" sx={{ fontWeight: '500' }} color="white">
                                    {reactCount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={3.5} xs={12} sx={{ borderRadius: '.5rem', background: '#1E7C88', width: '100%' }}>
                        <Grid container sm={12} display="flex" direction="column" sx={{ padding: '1rem' }}>
                            <Grid item sm={12}>
                                <Typography color="white">Comments</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="h3" sx={{ fontWeight: '500' }} color="white">
                                    {commentCount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Typography variant="h6" color="primary">
                    Daily Activity
                </Typography>
                {series.length > 0 && options ? <Chart options={options} series={series} type="bar" width="100%" height={450} /> : <></>}
            </Grid>
        </Grid>
    );
};

export default ActivitySummary;
