/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Avatar from '../assets/images/users/avatar-1.png';
import CompanyIcon from '../assets/static_images/company.svg';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import VoiceViewer from 'components/modals/VoiceViewer';
import { Box } from '@mui/material';
import RightSideSkeleton from './skeleton/RightSideSkeleton';
const CompanyPosts = ({ companyId }) => {
    const [showMore, setShowMore] = useState(false);
    const [openViewer, setOpenViewer] = useState(false);
    const [selected, setSelected] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const processToUpper = (text) => {
        return text
            .split('_')
            .map((item) => item.replace(item[0], item[0].toUpperCase()))
            .join(' ');
    };
    const timeDiffer = (now, date) => {
        var diffMs = now - date; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        return diffDays > 0 ? `${diffDays}d, ${diffHrs}h ago` : diffHrs > 0 ? `${diffHrs}h ago` : `${diffMins}m, ago`;
    };

    const [posts, setPosts] = useState([]);
    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a target="_target" href="' + url + '">' + url + '</a>';
        });
    }
    useEffect(() => {
        setIsLoading(true);
        axios
            .post('/social-posts/comapny-posts', {
                company_id: JSON.parse(window.localStorage.getItem('authUser'))?.company_id
            })
            .then(({ data }) => {
                setIsLoading(false);
                setPosts(data.posts);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }, []);
    return (
        <Grid sm={12} container>
            <Grid item sm={12} display="flex" alignItems="center" sx={{ paddingX: '0px' }}>
                <Card
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - 0.5rem)' },
                        overflowY: 'scroll',
                        border: '1px solid #30C6D9'
                    }}
                >
                    <CardContent sx={{ paddingX: '0px' }}>
                        <Grid
                            container
                            sm={12}
                            display="flex"
                            alignItems="center"
                            spacing={1}
                            sx={{ borderBottom: '1px solid #30C6D9', paddingY: '.5rem', marginLeft: '0.5rem' }}
                        >
                            <Grid item display="flex" alignItems="center" justifyContent="center">
                                <img src={CompanyIcon} alt="icon" />
                            </Grid>
                            <Grid item>
                                <Typography color="primary">Company Posts</Typography>
                            </Grid>
                        </Grid>
                        <Box container sm={12}>
                            {isLoading && (
                                <>
                                    {new Array(10).fill('loading').map((_, index) => (
                                        <RightSideSkeleton key={index} />
                                    ))}
                                </>
                            )}
                            {posts?.map((post, index) => {
                                return (
                                    <Box key={`pst-${index}`}>
                                        <Box sx={{ paddingX: '.5rem', paddingY: '10px' }}>
                                            <Box sx={{ padding: '0px' }}>
                                                <Box
                                                    display="flex"
                                                    spacing={1}
                                                    sx={{
                                                        position: 'relative',
                                                        display: 'flex'
                                                        // flexDirection: 'column',
                                                    }}
                                                    gap={1}
                                                >
                                                    <Box>
                                                        <img
                                                            src={Avatar}
                                                            className="img-fit"
                                                            alt="avatar"
                                                            style={{ borderRadius: '50%', width: '40px' }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ padding: '0px' }}>
                                                        <Box
                                                        // display="flex"
                                                        // direction="column"
                                                        // alignItems="start"
                                                        // justifyContent="center"
                                                        // spacing={1}
                                                        >
                                                            <Box>
                                                                <Typography variant="h6">{`${post.user?.first_name} ${post.user.last_name}`}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Box display="flex" alignItems="center" gap={1}>
                                                                    <Box sx={{ padding: '0px' }}>
                                                                        <Typography variant="subtitle1">{`${processToUpper(
                                                                            post.user.role.role_type
                                                                        )}`}</Typography>
                                                                    </Box>
                                                                    <Box sx={{ paddingY: '0px' }}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            sx={{ opacity: '0.5' }}
                                                                        >{`${timeDiffer(
                                                                            new Date(),
                                                                            new Date(post.createdAt)
                                                                        )}`}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Grid item sm={12} sx={{ padding: '0px' }}>
                                                {urlify(post.plain_text_body).length > 35 ? (
                                                    <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                                        {showMore
                                                            ? urlify(post.plain_text_body)
                                                            : `${urlify(post.plain_text_body).substring(0, 35)}...`}
                                                        {!showMore ? (
                                                            <Button
                                                                className="btn"
                                                                sx={{ fontSize: '13px' }}
                                                                onClick={() => {
                                                                    setShowMore(!showMore);
                                                                }}
                                                            >
                                                                View more
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="btn"
                                                                onClick={() => {
                                                                    setShowMore(!showMore);
                                                                }}
                                                            >
                                                                View less
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <Grid item sx={{ paddingY: '0rem', marginY: '0rem' }}>
                                                        {urlify(post.plain_text_body)}
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Box
                                                item
                                                sm={12}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setSelected(post);
                                                    setOpenViewer(true);
                                                }}
                                            >
                                                <Typography
                                                    sx={{ marginRight: '.5rem', fontSize: '14px', fontWeight: '600' }}
                                                    color="primary"
                                                >
                                                    View post
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            {openViewer ? (
                <Modal
                    keepMounted
                    open={openViewer}
                    onClose={setOpenViewer}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid
                        item
                        sm={12}
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100vw', height: '100vh', paddingX: '1.5rem' }}
                    >
                        <VoiceViewer post={selected} setOpenViewer={setOpenViewer} model="post" />
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default CompanyPosts;
