import React, { useEffect, useState } from 'react';
import { Modal, Box, Card, Avatar, Typography, Badge, ListItemIcon, useTheme, useMediaQuery } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
// import Check from '@mui/icons-material/Check';
import { CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/dist';
import axios from 'lib/axios';
import ViewProfileModal from './ViewProfileModal';
import ReactViewProfile from './ReactViewProfile';
// import Avatar from '../../assets/images/users/avatar-1.png';
const ViewReactModal = ({ setOpenModal, openModal, post = {}, type, endpoint }) => {
    const [value, setValue] = useState('all');
    const [allCount, setAllCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [reactTab, setReactTab] = useState([]);
    const [reactss, setReacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);
    const [reactCategory, setReactCategory] = useState(null);
    const [viewProfile, setViewProfile] = useState(false);
    const [userProfileData, setUserProfileDate] = useState({});
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const findReact = (id) => {
        switch (id) {
            case 1:
                return Like;
            case 2:
                return Love;
            case 3:
                return Haha;
            case 4:
                return Wow;
            case 5:
                return Sad;
            case 6:
                return Angry;
            default:
                return null;
        }
    };
    // console.log(findReact('like'));
    const theme = useTheme();
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    let showEmojis = 3;
    if (matchesXs) {
        showEmojis = 2;
    } else {
        showEmojis = 3;
    }

    const fetchReacts = async () => {
        const requestBody = {
            offset: 0,
            limit: 10,
            react_id: reactCategory,
            comment_id: post?.comment_id
        };
        if (type === 'news') {
            requestBody.news_id = post.news_id;
        } else if (type === 'post') {
            requestBody.post_id = post.post_id;
        } else if (type === 'notice') {
            requestBody.notice_id = post.notice_id;
        } else if (type === 'event') {
            requestBody.event_id = post.event_id;
        } else if (type === 'reward') {
            requestBody.reward_id = post.reward_id;
        } else if (type === 'policy') {
            requestBody.policy_id = post.policy_id;
        } else {
            requestBody.post_id = post.post_id;
        }
        try {
            setIsLoading(true);

            const res = await axios.post(endpoint, requestBody);

            if (res.data.success) {
                if (reactTab.length == 0) {
                    setReactTab(res.data.reacts_smmary);
                }
                if (allCount == 0) {
                    setAllCount(res.data.reafts?.length);
                }

                if (type === 'reward' || type === 'policy') {
                    setReacts(res.data.reacts);
                } else {
                    setReacts(res.data.reafts);
                }

                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    async function fetchUserData(id) {
        setProfileLoading(true);
        await axios
            .post(`/users/user`, {
                id: id
            })
            .then(({ data }) => {
                if (data) {
                    console.log('data--------', data.data);
                    setUserProfileDate(data.data);
                    setViewProfile(true);
                    setProfileLoading(false);
                }
            })
            .catch((err) => {
                setProfileLoading(false);
                console.log(err);
            });
    }

    useEffect(() => {
        fetchReacts();
    }, [reactCategory, post]);

    return (
        <>
            <Modal
                keepMounted
                open={openModal}
                onClose={setOpenModal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '100vw', height: '100%' }}
                    onClick={() => setOpenModal(false)}
                >
                    <Card
                        sx={{
                            p: 2,
                            position: 'relative',
                            mx: { xs: '10px' },
                            minWidth: { xl: '20vw', lg: '30vw', md: '35vw', sm: '80vw', xs: '80vw' }
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Box>
                            <Badge
                                color="primary"
                                badgeContent={'X'}
                                className="cursor-pointer"
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                                sx={{ position: 'absolute', right: '20px' }}
                            ></Badge>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography sx={{ ml: '5px' }}>All</Typography>

                                                        <Typography sx={{ ml: '5px' }}>{allCount}</Typography>
                                                    </Box>
                                                }
                                                value="all"
                                                onClick={() => setReactCategory(null)}
                                            />
                                            {reactTab?.slice(0, showEmojis)?.map((react, index) => {
                                                // console.log(react.react_name && findReact(react?.react_id));
                                                return (
                                                    <Tab
                                                        key={index}
                                                        label={
                                                            <Box display="flex" alignItems="center">
                                                                {react.react_id && (
                                                                    <img src={findReact(react?.react_id)} alt={react.react_name} />
                                                                )}

                                                                <Typography sx={{ ml: '5px' }}>{react.count}</Typography>
                                                            </Box>
                                                        }
                                                        onClick={() => setReactCategory(react?.react_id)}
                                                        value={react.react_name.toLowerCase()}
                                                    />
                                                );
                                            })}
                                        </TabList>
                                        {((matchesXs && reactTab?.length > 3) || (!matchesXs && reactTab?.length > 2)) && (
                                            <Box>
                                                <Button
                                                    id="fade-button"
                                                    aria-controls={open ? 'fade-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                    sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                                                >
                                                    More
                                                </Button>
                                                <Menu
                                                    id="fade-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'fade-button'
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                >
                                                    {reactTab.slice(showEmojis, 7).map((react) => {
                                                        return (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setAnchorEl(null);
                                                                    setValue(react.react_name.toLowerCase());
                                                                    setReactCategory(react?.react_id);
                                                                }}
                                                                sx={{ display: 'flex', justifyContent: 'space-between' }}
                                                            >
                                                                <Box display="flex" alignItems="center">
                                                                    {react.react_id && (
                                                                        <img src={findReact(react?.react_id)} alt={react.react_name} />
                                                                    )}

                                                                    <Typography sx={{ ml: '5px' }}>{react.count}</Typography>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        ml: 2
                                                                    }}
                                                                >
                                                                    {react.react_name.toLowerCase() === value ? (
                                                                        <ListItemIcon>
                                                                            <CheckOutlined />
                                                                        </ListItemIcon>
                                                                    ) : (
                                                                        <ListItemIcon></ListItemIcon>
                                                                    )}
                                                                </Box>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Menu>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '45vh',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden'
                                        }}
                                    >
                                        {['all', 'like', 'wow', 'love', 'haha', 'sad', 'angry'].map((value) => {
                                            return (
                                                <TabPanel value={value} sx={{ px: 0 }}>
                                                    {isLoading ? (
                                                        <Typography>Loading...</Typography>
                                                    ) : (
                                                        <>
                                                            {reactss?.map((user, index) => {
                                                                return (
                                                                    <Box
                                                                        key={index}
                                                                        sx={{
                                                                            mb: 1,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Box sx={{ position: 'relative' }}>
                                                                                <Avatar
                                                                                    src={user.user?.profile_image ?? null}
                                                                                    style={{
                                                                                        width: '50px',
                                                                                        height: '50px',
                                                                                        borderRadius: '50%',
                                                                                        objectFit: 'cover'
                                                                                    }}
                                                                                    alt={user.user.first_name}
                                                                                />
                                                                                <Box
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        zIndex: 10,
                                                                                        bottom: '-2px',
                                                                                        right: '-3px'
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={findReact(user.react_id)}
                                                                                        alt={user.user.first_name}
                                                                                        style={{
                                                                                            width: 20,
                                                                                            height: 20,
                                                                                            marginLeft: 'auto'
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>

                                                                            <Typography sx={{ ml: 1 }}>{user.user.first_name}</Typography>
                                                                        </Box>
                                                                        {/* <Link to={`/view-profile/${user.user_id}`}> */}
                                                                        <Button
                                                                            onClick={() => {
                                                                                fetchUserData(user.user.user_id);
                                                                                // setViewProfile(true);
                                                                            }}
                                                                            variant="contained"
                                                                        >
                                                                            View Profile
                                                                        </Button>
                                                                        {/* </Link> */}
                                                                    </Box>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </TabPanel>
                                            );
                                        })}
                                    </Box>
                                </TabContext>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Modal>

            {viewProfile && (
                <Modal
                    keepMounted
                    open={viewProfile}
                    onClose={setViewProfile}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <ReactViewProfile setViewProfile={setViewProfile} viewProfile={viewProfile} profileData={userProfileData} />
                </Modal>
            )}
        </>
    );
};

export default ViewReactModal;
