import React from 'react';
import { Box, Card, Skeleton } from '@mui/material';

const RightSideSkeleton = () => {
    return (
        <Box sx={{ mx: '10px', mt: '5px' }}>
            <Card sx={{ p: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
                    <Skeleton variant="circular" width={40} height={40} />

                    <Box>
                        <Skeleton variant="text" width={100} height={10} sx={{ fontSize: '1rem' }} />

                        <Skeleton variant="text" width={100} height={10} sx={{ fontSize: '1rem' }} />
                    </Box>
                </Box>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Card>
        </Box>
    );
};

export default RightSideSkeleton;
