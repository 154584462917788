import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TopPolicies from 'components/right-side/TopPolicies';
const Side = () => {
    return (
        <Grid container={12}>
            <TopPolicies />
        </Grid>
    );
};

export default Side;
