import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import DeleteIcon from '../../../assets/static_images/delete.svg';
import { Box } from '@mui/material';
const VoiceActivity = ({ showSnack }) => {
    const [activities, setActivities] = useState([]);
    const [selected, setSelected] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    function selectAll() {
        setSelected(() => {
            return [
                ...activities.map((item) => {
                    return item.activity_id;
                })
            ];
        });
    }
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post('/activities/sumitted_voice', {});
            console.log(response);
            setActivities(response.data.activities);
        };
        fetchData();
    }, []);
    async function deleteActivity(id) {
        try {
            const ids = id ? [id] : selected;
            const res = await axios.delete('/activities/delete', {
                data: {
                    ids: JSON.stringify(ids)
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (res.status === 200) {
                if (showSnack) {
                    showSnack(false, res.data.message);
                }
                if (id) {
                    setActivities((activities) => activities.filter((activity) => activity.activity_id !== id));
                } else {
                    setActivities((activities) => {
                        return [
                            ...activities.filter((activity) => {
                                if (!selected.includes(activity.activity_id)) {
                                    return activity;
                                }
                            })
                        ];
                    });
                }
                setAllSelected(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    function formatHTML(text) {
        return <div className="content" dangerouslySetInnerHTML={{ __html: text }}></div>;
    }
    return (
        <Box>
            <Grid sm={12} container display="flex" direction="column" sx={{ padding: '1rem' }}>
                <Grid sm={12} item sx={{ paddingY: '1rem' }}>
                    {' '}
                    <Typography variant="h6" sx={{ fontWeight: '700' }}>
                        Voice
                    </Typography>
                </Grid>
                <Grid sm={12} item sx={{ paddingY: '1rem' }}>
                    <Grid sm={12} container display="flex" alignItems="center">
                        {activities?.length > 0 ? (
                            <Grid item sm={10} display="flex" alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{ marginX: '0px' }}
                                            checked={allSelected}
                                            onChange={() => {
                                                if (!allSelected) {
                                                    selectAll();
                                                } else {
                                                    setSelected([]);
                                                }
                                                setAllSelected((prev) => {
                                                    return !prev;
                                                });
                                            }}
                                        />
                                    }
                                    label={''}
                                    sx={{ marginX: '0px' }}
                                />
                                <Typography sx={{ opacity: '60%' }}>Select All</Typography>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid item sm={2} display="flex" alignItems="cetner" justifyContent="end" sx={{ marginLeft: 'auto' }}>
                            {allSelected ? (
                                <Grid
                                    container
                                    sm={12}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="end"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        deleteActivity();
                                    }}
                                >
                                    <Grid item sm={2} display="flex" alignItems="cetner" justifyContent="cetner">
                                        <img src={DeleteIcon} className="img-fit" alt="delicon" />
                                    </Grid>
                                    <Grid item sm={6} display="flex" alignItems="cetner" justifyContent="cetner">
                                        <Grid>
                                            <Typography sx={{ opacity: '60%' }}>Delete</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {activities?.map((activity, index) => {
                    return (
                        <Grid item sm={12} key={`activity-${index}-${activity.activity_id}`}>
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ borderRadius: '.5rem', border: '1px solid #ADADAD', padding: '1rem', marginY: '.5rem' }}
                                className="cursor-pointer"
                            >
                                <Box display="flex" sx={{ width: '100%' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{ marginX: '0px' }}
                                                checked={selected.includes(activity.activity_id) ? true : false}
                                                onChange={() => {
                                                    selected.includes(activity.activity_id)
                                                        ? setSelected((data) => {
                                                              return [
                                                                  ...data.map((id) => {
                                                                      if (id != activity.activity_id) {
                                                                          return id;
                                                                      }
                                                                  })
                                                              ];
                                                          })
                                                        : setSelected((data) => [...data, activity.activity_id]);
                                                }}
                                            />
                                        }
                                        label={''}
                                        sx={{ marginX: '0px' }}
                                    />
                                    <Box
                                        onClick={() => {
                                            location.replace(`${window.location.origin}${activity.link}`);
                                        }}
                                    >
                                        <Grid container sm={12} display="flex" direction="column">
                                            <Grid item sm={12}>
                                                <Typography>{formatHTML(activity.description)}</Typography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Typography sx={{ opacity: '60%', fontSize: '12px' }}>
                                                    {new Intl.DateTimeFormat('en-US', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    }).format(new Date(activity.createdAt))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="cetner"
                                    justifyContent="cetner"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setSelected([activity.activity_id]);
                                        deleteActivity(activity.activity_id);
                                    }}
                                >
                                    <img src={DeleteIcon} className="img-fit" alt="delicon" />
                                </Box>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default VoiceActivity;
